import planModel from '../../../models/plan-model';
import CropModel from '../../../models/crop-model';

// Navigate back to pinning view.
const CropCloseButton = {
    view: () => {
        const isLoaded = CropModel.state.isLoaded;
        return <button class={`btn btn-edit-pins close-button btn-circle  ${!isLoaded ? ' btn-inactive' : ''}`} type='submit'
            onclick={isLoaded && (() => CropModel.quit())}><i class='icon-close'/></button>;
    }
};

// Reset all changes
const CropResetButton = {
    view: () =>  {
        const isResetable = CropModel.state.isResetable;
        return <button className={`btn btn-clear-all btn-pill ${!isResetable ? ' btn-inactive' : ''}`} type='submit'
            onclick={isResetable && (() => CropModel.handleReset())}>Reset</button>;
    }
};

// Save and return to staking view if unstaked, color view if staked.
const CropDoneButton = {
    view: () => {
        const isCroppable = CropModel.state.isCroppable;
        return <button className={`btn btn-done btn-primary btn-pill ${!isCroppable ? ' btn-inactive' : ''}`} type='submit'
            onclick={isCroppable && (() => CropModel.saveCrop())}>Done</button>;
    }
};

// Undo the last step
const CropUndoButton = {
    view: () => {
        const isUndoable = CropModel.state.isUndoable;
        return <button class={`btn btn-undo btn-circle ${!isUndoable ? ' btn-inactive' : ''}`} type='submit' onclick={isUndoable && (() => CropModel.handleUndo())}>
            <i class='icon-undo'></i>
        </button>;
    }
};

// Displays the record title with a crop icon
const CropRecordTitle = {
    view: () =>
        <div className='stakeable-title-wrap crop-title'>
            <i className='icon-crop'/>
            <span className='stakeable-title-text'>{planModel.recordTitle}</span>
        </div>
};

class CropHeader {

    view() {
        return <div class='plan-header'>
            <div className='stakeable-menu-controls controls-left'>
                <CropCloseButton/>
            </div>
            <div class='stakeable-menu-controls controls-center'>
                <CropRecordTitle />
            </div>
            <div class='stakeable-menu-controls controls-right'>
                <CropUndoButton />
                <CropResetButton />
                <CropDoneButton />
            </div>
        </div>;
    }

}

export default CropHeader;
