import constants from 'util/data/constants';
import notificationsModel from '../../models/notifications-model';

const ToolboxMenu = {
    oninit: ({state}) => {
        state.isExpanded = true;
    },

    view: ({state, attrs: {toolbox}}) => {
        const isExpanded = state && state.isExpanded;
        const selectedClass = !notificationsModel.isAnyOfToolboxSelected(toolbox.toolboxId) ? 'none-selected' : notificationsModel.isAllOfToolboxSelected(toolbox) ? 'all-selected' : 'some-selected';
        return <div class="toolbox-menu collapsible-card">
            <div class="toolbox-menu-header">
                <span class='header-text'><img class="toolbox-icon" src={constants.mediaURL + toolbox.attributes.icon.mediaId} /><span>{toolbox.name}</span>
                </span>
                <i class={`${isExpanded ? 'icon-chevron-up' : 'icon-chevron-down'}`} onclick={() => {
                    state.isExpanded = !state.isExpanded;
                }}/>
            </div>
            {isExpanded && <div class="toolbox-menu-inner">
                <div class={`toolbox-select-deselect-all ${selectedClass}`}>
                    <span class="toolbox-select-all" onclick={() => notificationsModel.selectAllToolbox(toolbox)}>Select All</span> | <span class="toolbox-deselect-all" onclick={() => notificationsModel.deSelectAllToolbox(toolbox)}>Deselect All</span></div>
                {toolbox.group.groups.map((toolGroup) => {
                    const categoryClass = notificationsModel.getCategoryClass(toolbox.toolboxId, toolGroup.toolGroupId);
                    return <div class="toolgroup-section">
                        <div class="top-level-option category-row">
                            <i onclick={() => notificationsModel.toggleCategory(toolbox, toolGroup)} class={`icon-checkbox-${categoryClass}`}/><span class="full-width-label">{toolGroup.name}</span></div>
                        {toolGroup.tools.map((tool) => {
                            const toolSetting =  notificationsModel.getToolSetting(toolbox.toolboxId, toolGroup.toolGroupId, tool.toolId);
                            return <div class={`inner-level-option tool-row ${notificationsModel.isSavingTool(toolbox.toolboxId, toolGroup.toolGroupId, tool.toolId) ? 'saving' : ''}`}>
                                <i class="spinner teal"/>
                                <i onclick={() => notificationsModel.toggleTool(toolbox, toolGroup, tool.toolId)} class={`icon-checkbox-${toolSetting ? 'on' : 'off'}`}/>
                                <img class="tool-icon" src={constants.mediaURL + tool.assetForm.assetType.attributes.icon.mediaId} />
                                <span class="tool-name">{tool.name}</span>
                            </div>; 
                        })}
                    </div>;
                })}
            </div>
            }
        </div>;
    }
};

export default ToolboxMenu;
