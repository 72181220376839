import router from 'uav-router';
import randomId from 'util/numbers/random-id';
import pageSelector from 'views/page-selector';
import constants from 'util/data/constants';
import api from 'legacy/util/api';
import getImageSource from 'legacy/util/data/get-image-source';
import store from 'util/data/store';
import formModel from 'models/form-model';
import siteModel from 'models/site-model';
import toolboxModel from 'models/toolbox-model';
import download from 'legacy/util/device/download';
import screenHelper from 'legacy/util/device/screen-helper';
import featureModel from 'models/feature-model';
import helpers from 'legacy/util/api/helpers';

const supported = {
    extensions: {
        pdf: 1,
        odt: 1,
        ods: 1,
        odp: 1,
        odg: 1,
        odf: 1
    },
    mimeTypes: {
        'application/pdf': 1
    }
};

const imageMimeTypes = {
    'image/png': 1,
    'image/jpg': 1,
    'image/jpeg': 1,
    'image/bmp': 1,
    'image/gif': 1,
    'image/webp': 1
};

const mediaModel = {

    view: null,

    close() {

        siteModel.view = null;

    },

    isImage: mimeType => imageMimeTypes[mimeType],

    isSupportedFileType(media) {

        const extension = media.label.split('.').pop().toLowerCase();

        const mimeType = media.mimeType;

        return supported.extensions[extension] || supported.mimeTypes[mimeType];

    },

    download: mediaId => mediaModel.getMedia(mediaId).then(media => download.force(media)),

    getLinkTool(media) {

        const mimeType = media.mimeType && media.mimeType.split('/')[0];

        return toolboxModel.linkTools[mimeType] || toolboxModel.linkTools.file;

    },

    getMetadata: mediaId => new Promise((resolve, reject) => {

        const xhr = new XMLHttpRequest();

        xhr.open('HEAD', constants.mediaURL + mediaId);

        xhr.onload = () => {

            if (xhr.status === 200) {

                const mimeType = xhr.getResponseHeader('content-type');

                let name = 'Untitled';

                try {

                    name = xhr.getResponseHeader('content-disposition').match(/filename=["']?([^'"]*)/)[1];

                } catch (e) {

                    if (mimeType) {

                        name += `.${mimeType.split('/')[1]}`;

                    }

                }

                resolve({
                    mimeType,
                    name
                });

            } else {

                reject();

            }

        };

        xhr.onerror = reject;

        xhr.send();

    }),

    getThumbnail(mediaId, mimeType, name) {

        let src,
            isPhoto;

        if (mediaModel.isImage(mimeType)) {

            // Filestack can't handle gifs
            if (mimeType === 'image/gif') {

                src = constants.mediaURL + mediaId;

            } else {

                src = getImageSource(mediaId, {width: 400});

            }

            isPhoto = true;

        } else if (mimeType.startsWith('video')) {

            src = constants.mediaURL + '8VgMxsdsLPsWGQjL8iYl';

        } else if (mimeType.startsWith('audio')) {

            src = constants.mediaURL + 'pnCZ3GDXlIi-L5PCNTtx';

        } else {

            src = constants.mediaURL + 'E1wQRT_baM7Vj3hb3j3w';

        }

        return {
            mediaId,
            src,
            isPhoto,
            name
        };

    },

    getMedia: mediaId => new Promise(resolve => {

        if (!mediaId) {

            return resolve();

        }

        if (store.media[mediaId]) {

            return resolve(store.media[mediaId]);

        }

        api.rpc.request([['getMediaItemById', {mediaId}]]).then(_media => {

            _media.attributes = _media.attributes || {};

            store.setContainerValue(store.media, _media.mediaId, _media);

            resolve(_media);

        });

    }),

    getMediaAndRedraw(mediaId) {

        if (store.media[mediaId]) {

            return store.media[mediaId];

        }

        mediaModel.getMedia(mediaId).then(media => {

            if (media) {

                m.redraw();

            }

        });

    },

    getMax360PhotoSize() {

        if (mediaModel.max360PhotoSize !== undefined) {

            return mediaModel.max360PhotoSize;

        }

        if (window.WebGLRenderingContext) {

            try {

                let canvas = document.createElement('canvas');

                const gl = canvas.getContext('webgl');

                const textureSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);

                canvas = null;

                mediaModel.max360PhotoSize = textureSize;

            } catch (e) {

                mediaModel.max360PhotoSize = 0;

            }

        } else {

            mediaModel.max360PhotoSize = 0;

        }

        return mediaModel.max360PhotoSize;

    },

    createLayer(mediaId, assetId) {

        if (screenHelper.small()) {

            return;

        }

        siteModel.view = null;

        const documentId = randomId();

        api.rpc.create('Document', {
            documentId: documentId,
            mediaId,
            projectId: router.params.projectId
        });

        pageSelector(documentId, store.assets[assetId]);

    },

    getFeature: (mediaId, assetId) => {

        const asset = store.assets[assetId];

        const featureId = asset.featureIds && asset.featureIds.find(id => {

            const feature = store.features[id];

            return feature && feature.properties.mediaId === mediaId;

        });

        return store.features[featureId];

    },

    editFeature(feature, mediaId) {

        siteModel.view = null;

        if (feature) {

            featureModel.panToFeatures([feature]);

            formModel.editFeature(feature);

        } else {

            formModel.addMediaFeature(mediaId);

        }

    },

    getIcon(assetId) {

        const asset = store.assets[assetId],
            mediaList = helpers.list(asset.media),
            media = mediaList && mediaList[0],
            assetType = store.assetTypes[asset.assetTypeId];

        if (media) {

            const isImage = asset.assetTypeId === toolboxModel.linkTools.image.assetForm.assetType.assetTypeId;

            if (isImage && mediaModel.isImage(media.mimeType)) {

                return <img class="asset-icon asset-thumbnail" src={getImageSource(media.mediaId, {width: 400})}/>;

            }

        }

        return <img class="asset-icon" src={constants.mediaURL + assetType.attributes.icon.mediaId}/>;

    }

    /**
     * Given a media object, creates a clone of the media and updates the store once published.
     * COMMENTED OUT UNTIL WE GET https://unearth.atlassian.net/browse/API-579
     */
    // createClone(media) {

    //     publish.await({

    //         changeType: 'modified',

    //         recordType: 'media',

    //         test: change => change.filestackHandle === media.filestackHandle && change.status === 'active',

    //         callback: finalMedia => {

    //             // Store the cloned media once published
    //             store.setContainerValue(store.media, finalMedia.mediaId, finalMedia);

    //             m.redraw();

    //         }

    //     });

    //     return api.rpc.create('Media', {

    //         label: media.label,

    //         filestackHandle: media.filestackHandle,

    //         projectId: router.params.projectId

    //     });

    // }

};

export default mediaModel;
