import filterModel from 'models/table/filter-model';
import commonFilterModel from 'models/table/common-filter-model';
import definedOptionsModel from 'models/table/filter-types/defined-options-model';
import numberRangeModel from 'models/table/filter-types/number-range-model';
import booleanFilterModel from 'models/table/filter-types/boolean-filter-model';
import textFilterModel from 'models/table/filter-types/text-filter-model';
import dateRangeModel from 'models/table/filter-types/date-range-model';
import placesFilterModel from 'models/table/filter-types/places-filter-model';
import Radio from 'views/radio/radio';
import constants from 'util/data/constants';
import DateRange from 'views/date-range/date-range';
import ConfigMenu from 'views/table/filter-menu/config-menu/config-menu';
import tableModel from 'models/table/table-model';
import stopPropagation from 'util/events/stop-propagation';
import popupModel from 'models/popover-model';
import peopleModel from 'models/people/people-model';
import SharedColumnMessage from 'views/table/filter-menu/shared-column-message';
import PlaceSearch from 'views/place-search/place-search';

const MIN_SELECT_ALL = 2;

const SPECIAL_RADIO_OPT = <div class="special-radio-row"><span class="radio-opt active"><span class="radio-circle"></span><span class="radio-opt-label">type-specific filter settings</span></span></div>;

const checkOptionToCssClass = {
    true: 'icon-checkbox-on',
    mixed: 'icon-checkbox-mixed',
    false: 'icon-checkbox-off',
    undefined: 'icon-checkbox-on' // no value is "on" by default
};

const CommonHeader =  {
    view: ({attrs: {name}}) => {
        const sortKey = name;
        return <Header styles={tableModel.styles.getCommonColumnHeaderStyles(name)}
            hideSort={commonFilterModel.isSortDisabled(name)} name={commonFilterModel.displayName(name)}
            onSortToggle={() => filterModel.toggleCommonSortField(sortKey, commonFilterModel.nameToViewKey[name])} />;
    }
};

const Header = {
    view: ({attrs: {styles, onSortToggle, hideSort, name}}) => {
        styles = styles || tableModel.styles.currentHeader;
        onSortToggle = onSortToggle || (() => filterModel.toggleSortField());
        return <div className={`filter-header ${styles}`} onclick={() => popupModel.close()}>
            <span className="filter-name">{name || filterModel.currentFilterDisplayName}</span>
            {!hideSort && <span className="sort-control" onclick={(e) => {
                e.stopPropagation();
                onSortToggle();
            }}> <i className="icon-arrow-up"/><i className="icon-arrow-down"/>
            </span>}
        </div>;
    }
};

const SelectAll = {
    view: ({attrs: {selectAllFn, switchAllChecksFn}}) => {
        const {isAllSelected, isAllDeselected} = selectAllFn();
        const selectAllCssClass = `select-all${isAllSelected ? ' disabled' : ''}`;
        const deselectAllCssClass = `select-all${isAllDeselected ? ' disabled' : ''}`;
        return <div className="bulk-selection-links">
            <span>
                <div className={selectAllCssClass} onclick={() => switchAllChecksFn(true)}>
                    Select All
                </div>
                &nbsp;|&nbsp;
                <div className={deselectAllCssClass} onclick={() => switchAllChecksFn(false)}>Deselect All</div>
            </span>
        </div>;
    }

};

const filterMenus = {

    /**
     *  Control type Filters
     */

    definedOptions: {
        oninit: () => {
            definedOptionsModel.init();
        },
        view: () => {
            const options = definedOptionsModel.options;
            const selectedControlName = filterModel.opts.selectedControlName;
            return <div class="filter-menu-inner">
                <Header/>
                <div class="filter-contents">
                    <SharedColumnMessage />
                    {!selectedControlName && MIN_SELECT_ALL <= options.length && <SelectAll selectAllFn={() => definedOptionsModel.isAllSelected()} switchAllChecksFn={(isOn) => definedOptionsModel.switchAllChecks(isOn)}/>}
                    {selectedControlName ?
                        <div class='filter-option-row'>
                            <div class="filter-option" onclick={() => definedOptionsModel.toggleOption(selectedControlName)}>
                                <i className={checkOptionToCssClass[definedOptionsModel.state.checkedOptions[selectedControlName]]} />{selectedControlName}
                            </div>
                        </div>
                        : options.map((option, i) => {
                            return <div class={`filter-option-row ${definedOptionsModel.state.checkedOptions[option] === 'mixed' ? 'mixed' : ''}`}>
                                <div class="filter-option" onclick={() => definedOptionsModel.toggleOption(option)}>
                                    <i className={checkOptionToCssClass[definedOptionsModel.state.checkedOptions[option]]}/><span class="filter-option-label">{definedOptionsModel.isUserObjType ? peopleModel.displayNameOrEmail(option) : option}</span>
                                </div>
                            </div>;
                        })}
                    {!selectedControlName &&
                    //!selectedControlName && controlType.attributes.required && (Not all required controls have fields in them so we need isEmpty for all).
                    <div class={`filter-option-row ${definedOptionsModel.state.isEmptyChecked === 'mixed' ? 'mixed' : ''}`}>
                        <div class="filter-option" onclick={() => definedOptionsModel.toggleEmptyState()}>
                            <i className={checkOptionToCssClass[definedOptionsModel.state.isEmptyChecked]}/><span class="filter-option-label">is empty</span>
                        </div>
                    </div>}
                    {definedOptionsModel.hasAnyMixedOptions() &&
                    <div class="differing-filters-exist-message">* Type-specific filters differ. Current results include only Types that meet this condition.</div>}
                </div>
            </div>;
        }
    },

    dateFilter: {
        oninit: () => {
            dateRangeModel.init();
        },
        customRange: () => {
            return {
                view: () => {
                    return <div>custom range
                        <DateRange
                            hideDone={true}
                            onDateChange={() => dateRangeModel.rangeUpdated()}
                            model={dateRangeModel.state.rangeModel}/>
                    </div>;
                }
            };
        },
        options: () => ['all', 'today', 'last 7 days', 'last 14 days', 'last 30 days', filterMenus.dateFilter.customRange],
        view: () => {
            const combinedFiltersAreMixed = dateRangeModel.state.selectedRadioIndex === -1;
            const options = filterMenus.dateFilter.options();
            return <div class={`filter-menu-inner${combinedFiltersAreMixed ? ' mixed-state' : ''}`}>
                <Header/>
                <div class="filter-contents">
                    <SharedColumnMessage />
                    {combinedFiltersAreMixed && SPECIAL_RADIO_OPT}
                    <div><Radio key={filterModel.filterColumnKey} defaultOption={options[dateRangeModel.state.selectedRadioIndex]} options={options} onchange={(value, i) => dateRangeModel.selectDateRangeRadio(i) }/></div>
                </div>
            </div>;
        }
    },

    textFilter: {
        oninit: () => {
            textFilterModel.init();
        },
        customString: () => {
            return {
                view: () => {
                    return <div>
                        <label className="sublabel">contains</label>
                        <input type="text" value={textFilterModel.state.queryParam} oninput={e => textFilterModel.setQueryParam(e)}/>
                    </div>;
                }
            };
        },
        options: () => ['all', 'is empty', 'is not empty', filterMenus.textFilter.customString],
        view: () => {
            const combinedFiltersAreMixed = textFilterModel.state.selectedRadioIndex === -1;
            return <div class={`filter-menu-inner${combinedFiltersAreMixed ? ' mixed-state' : ''}`}>
                <Header/>
                <div class="filter-contents">
                    <SharedColumnMessage />
                    {combinedFiltersAreMixed && SPECIAL_RADIO_OPT}
                    <div><Radio key={filterModel.filterColumnKey} defaultOption={filterMenus.textFilter.options()[textFilterModel.state.selectedRadioIndex]} options={filterMenus.textFilter.options()} onchange={(value, i) => textFilterModel.selectRadio(i)}/></div>
                </div>
            </div>;
        }
    },

    numberFilter: {
        oninit: () => {
            numberRangeModel.init();
        },
        allValues: () => {
            return {
                view: () => {
                    const sign = numberRangeModel.state.selectedSign;
                    const enabledCssClass = numberRangeModel.state.selectedRadioIndex === 2 ? 'number-input' : 'number-input disabled';
                    return <div class="number-filter-equation">all values
                        <select class="operator-select" onchange={stopPropagation(e => {
                            numberRangeModel.selectSign(e.target.value);
                        })} onclick={stopPropagation}>
                            <option selected={sign === 'gte'} value={'gte'} key={'>='}>{'≥'}</option>
                            <option selected={sign === 'lte'} value={'lte'} key={'<='}>{'≤'}</option>
                            <option selected={sign === 'eq'} value={'eq'} key={'=='}>{'='}</option>
                        </select>
                        <input type="number" className={enabledCssClass} value={numberRangeModel.state.range} step="any" oninput={e =>
                            numberRangeModel.setRange(e.target.value)
                        }/>
                    </div>;
                }
            };
        },
        customRange: () => {
            return {
                view: () => {
                    const enabledCssClass = numberRangeModel.state.selectedRadioIndex === 3 ? 'number-input' : 'number-input disabled';
                    return <div class="number-filter-custom"><span class="range-label">custom range</span>
                        <div class="range-inputs"><input type="number" className={enabledCssClass} value={numberRangeModel.state.rangeLow} step="any" oninput={e =>
                            numberRangeModel.setRangeLow(e.target.value)
                        }/>
                        <span class="range-to">to</span>
                        <input type="number" className={enabledCssClass} value={numberRangeModel.state.rangeHigh} step="any" oninput={e => numberRangeModel.setRangeHigh(e.target.value)}/>
                        </div>
                    </div>;
                }
            };
        },
        options: () => ['all', 'is empty', filterMenus.numberFilter.allValues, filterMenus.numberFilter.customRange],
        view: () => {
            const combinedFiltersAreMixed = numberRangeModel.state.selectedRadioIndex === -1;
            const options = filterMenus.numberFilter.options();
            return <div class={`filter-menu-inner${combinedFiltersAreMixed ? ' mixed-state' : ''}`}>
                <Header/>
                <div class="filter-contents">
                    <SharedColumnMessage />
                    {combinedFiltersAreMixed && SPECIAL_RADIO_OPT}
                    <div><Radio key={filterModel.filterColumnKey} defaultOption={options[numberRangeModel.state.selectedRadioIndex]} options={options} onchange={(value, i) => numberRangeModel.selectRadio(i)}/></div>
                </div>
            </div>;
        }
    },

    booleanFilter: {
        oninit() {
            booleanFilterModel.init();
        },
        options: () => ['any', 'on', 'off'],
        view: () => {
            const combinedFiltersAreMixed = booleanFilterModel.state.selectedRadioIndex === -1;
            return <div class={`filter-menu-inner${combinedFiltersAreMixed ? ' mixed-state' : ''}`}>
                <Header/>
                <div class="filter-contents">
                    <SharedColumnMessage />
                    {combinedFiltersAreMixed && SPECIAL_RADIO_OPT}
                    <div><Radio key={filterModel.filterColumnKey} defaultOption={filterMenus.booleanFilter.options()[booleanFilterModel.state.selectedRadioIndex]} options={filterMenus.booleanFilter.options()} onchange={(value, i) => booleanFilterModel.selectRadio(i)}/></div>
                </div>
            </div>;
        }
    },

    placesFilter: {
        oninit: () => {
            placesFilterModel.init();
        },
        options: () => ['any', 'none', filterMenus.placesFilter.placeSearch],
        placeSearch: {
            view: () =>
                <div className="place-search-filter">
                    <label>includes</label>
                    <PlaceSearch
                        selectedItems={placesFilterModel.state.selectedItems}
                        onSelect={(place) => placesFilterModel.onSelectPlace(place)}
                        onDelete={(place) => placesFilterModel.onDeletePlace(place)}
                        openUp={tableModel.tableMode === 'table-bottom'}
                    />
                </div>
        },
        view: () => {
            const combinedFiltersAreMixed = false;
            return <div class="filter-menu place-search">
                <div class={`filter-menu-inner${combinedFiltersAreMixed ? ' mixed-state' : ''}`}>
                    <Header hideSort={true}/>
                    <div class="filter-contents visible">
                        <SharedColumnMessage/>
                        {combinedFiltersAreMixed && SPECIAL_RADIO_OPT}
                        <div>
                            <Radio
                                key={filterModel.filterColumnKey}
                                defaultOption={filterMenus.placesFilter.options()[placesFilterModel.state.selectedRadioIndex]}
                                options={filterMenus.placesFilter.options()}
                                onchange={(value, i) => placesFilterModel.selectRadio(i)}/>
                        </div>
                    </div>
                </div>
            </div>;
        }
    },

    /**
     *  Common filters
     */

    dateCreatedFilter: {
        customRange: () => {
            return {
                view: () => {
                    return <div>custom range
                        <DateRange
                            hideDone={true}
                            onDateChange={() => commonFilterModel.rangeUpdated()}
                            model={commonFilterModel.addedDateRange}/>
                    </div>;
                }
            };
        },
        options: () => ['all', 'today', 'last 7 days', 'last 14 days', 'last 30 days', filterMenus.dateCreatedFilter.customRange],
        view: () => {
            return <div class="filter-menu-inner added-date-filter">
                <CommonHeader name='createdDateTime'/>
                <div class="filter-contents">
                    <Radio defaultOption={filterMenus.dateCreatedFilter.options()[commonFilterModel.selectedDateRangeIndex]} options={filterMenus.dateCreatedFilter.options()} onchange={(value, i) => commonFilterModel.selectDateRangeRadio(i)}/>
                </div>
            </div>;
        }
    },

    lastUpdatedFilter: {
        customRange: () => {
            return {
                view: () => {
                    return <div>custom range
                        <DateRange
                            hideDone={true}
                            onDateChange={() => commonFilterModel.rangeUpdated()}
                            model={commonFilterModel.updatedDateRange}/>
                    </div>;
                }
            };
        },
        options: () => ['all', 'today', 'last 7 days', 'last 14 days', 'last 30 days', filterMenus.lastUpdatedFilter.customRange],
        view: () => {
            return <div class="filter-menu-inner added-date-filter">
                <CommonHeader name='updatedDateTime'/>
                <div class="filter-contents">
                    <Radio defaultOption={filterMenus.lastUpdatedFilter.options()[commonFilterModel.updatedDateRangeIndex]} options={filterMenus.lastUpdatedFilter.options()} onchange={(value, i) => commonFilterModel.selectUpdatedRange(i)}/>
                </div>
            </div>;
        }
    },

    addedByFilter: {
        view: () => {
            const selectedControlName = filterModel.opts.selectedControlName;
            const usersList = selectedControlName ? Object.values(commonFilterModel.users).find((user) => user.name === selectedControlName) : Object.values(commonFilterModel.users);
            return <div class="filter-menu-inner">
                <CommonHeader name='addedBy'/>
                <div class="filter-contents">
                    {usersList.length >= MIN_SELECT_ALL && <SelectAll selectAllFn={() => commonFilterModel.areAllUserIdsChecked()} switchAllChecksFn={(isOn) => commonFilterModel.switchAllAddedBy(isOn)}/>}
                    {usersList.map((user, i) => {
                        return <div class="filter-option-row">
                            <div class="filter-option" onclick={() => commonFilterModel.toggleCheckedUsers(user.value)}>
                                <i className={checkOptionToCssClass[commonFilterModel.checkedUsers[user.value]]}/><span class="filter-option-label">{user.name}</span>
                            </div>
                        </div>;
                    })}
                </div>
            </div>;
        }
    },

    categoryFilter: {
        view: () => {
            return <div class="filter-menu-inner">
                <CommonHeader name='category'/>
                <div class="filter-contents">
                    {commonFilterModel.toolGroups.length >= MIN_SELECT_ALL && <SelectAll selectAllFn={() => commonFilterModel.areAllAssetCategoriesChecked()} switchAllChecksFn={(isOn) => commonFilterModel.switchAllAssetTypes(isOn)}/>}
                    {commonFilterModel.toolGroups.map((toolGroup) => {
                        const toolGroupId = toolGroup.toolGroupId;
                        const assetTypes = commonFilterModel.getToolGroupAssetTypes(toolGroupId);
                        if (!assetTypes || assetTypes.length === 0) {
                            return undefined;
                        }
                        return <div class="filter-option-row">
                            <div class="filter-option" onclick={() => commonFilterModel.toggleCheckedToolGroups(toolGroupId)}>
                                <i className={checkOptionToCssClass[commonFilterModel.checkedToolGroups[toolGroupId]]}/><span class="filter-option-label">{toolGroup.name}</span>
                            </div>
                        </div>;
                    })}
                </div>
            </div>;
        }
    },

    assetTypeFilter: {
        menuGroup: (toolGroup, assetTypes) => {
            return <div class="filter-section">
                <div class="filter-section-title">{assetTypes.length > 0 && toolGroup.name}</div>
                {assetTypes.map((assetType) => {
                    const assetTypeId = assetType.assetTypeId;
                    return <div class="filter-option-row">
                        <div onclick={() => commonFilterModel.toggleAssetType(assetTypeId)} class="filter-option"><i className={checkOptionToCssClass[commonFilterModel.checkedAssetTypes[assetTypeId]]}/><span class="filter-option-label">{assetType.name}</span></div>
                    </div>;
                })}
            </div>;
        },
        view() {
            const toolGroups = commonFilterModel.toolGroups;
            return <div class="filter-menu-inner">
                <CommonHeader name='contentType'/>
                <div class="filter-contents">
                    <SelectAll selectAllFn={() => commonFilterModel.areAllAssetTypesChecked()} switchAllChecksFn={(isOn) => commonFilterModel.switchAllAssetTypes(isOn)}/>
                    {toolGroups.map((toolGroup) => {
                        const assetTypes = commonFilterModel.getToolGroupAssetTypes(toolGroup.toolGroupId);
                        return assetTypes.length > 0 ? filterMenus.assetTypeFilter.menuGroup(toolGroup, assetTypes) : <div/>;
                    })
                    }
                </div>
            </div>;
        }
    },

    placesAndLevelsFilter: {
        inView: () => ({
            view: () => <>
                in view {commonFilterModel.selectedPlacesLevelsIndex === 1 && <a onclick={() => tableModel.fetch()}>(update)</a>}
            </>
        }),
        drawSearchArea: () => ({
            view: () => <>
                draw search area {commonFilterModel.selectedPlacesLevelsIndex === 4 && <a>(redraw)</a>}
            </>
        }),
        options: () => {
            const views = filterMenus.placesAndLevelsFilter;
            return ['all', views.inView, views.drawSearchArea];
        },
        view: () => {
            const options = filterMenus.placesAndLevelsFilter.options();
            return <div class="filter-menu-inner places-filter">
                <CommonHeader name='location'/>
                <div class="filter-contents">
                    <Radio defaultOption={options[commonFilterModel.selectedPlacesLevelsIndex]} options={options} onchange={(value, i) => commonFilterModel.selectPlacesLevelsRadio(i)}/>
                </div>
            </div>;
        }
    },

    nameFilter: {
        view: () => {
            return <div class="filter-menu-inner primary-label-filter-inner">
                <CommonHeader name='name' displayName='Name'/>
                <div class="filter-contents">
                    <label className="sublabel">contains</label>
                    <input type="text" defaultValue={commonFilterModel.nameQueryString} oninput={e => commonFilterModel.setNameQueryString(e.target.value)}/>
                </div>
            </div>;
        }
    },

    unearthIdFilter: {
        view: () => {
            return <div class="filter-menu">
                <div class="filter-menu-inner">
                    <CommonHeader name='unearthId'/>
                    <div class="filter-contents">
                        <label class="sublabel">ID</label>
                        {commonFilterModel.assetIdMatch &&
                         commonFilterModel.assetIdMatch.length !== 20 &&
                        <label class="sublabel error">The Unearth ID must be 20 characters long</label>
                        }
                        <input type="text"
                            value={commonFilterModel.assetIdMatch || ''}
                            oninput={e => commonFilterModel.assetIdInput(e.target.value)}/>
                    </div>
                </div>
            </div>;
        }
    }

};

const controlTypeNameToId = constants.controlTypeNameToId;

export default {
    [controlTypeNameToId.name]: filterMenus.textFilter,
    [controlTypeNameToId.text]: filterMenus.textFilter,
    [controlTypeNameToId.paragraph]: filterMenus.textFilter,
    [controlTypeNameToId.dropdown]: filterMenus.definedOptions,
    [controlTypeNameToId.radio]: filterMenus.definedOptions,
    [controlTypeNameToId.length]: filterMenus.numberFilter,
    [controlTypeNameToId.area]: filterMenus.numberFilter,
    [controlTypeNameToId.volume]: filterMenus.numberFilter,
    [controlTypeNameToId.number]: filterMenus.numberFilter,
    [controlTypeNameToId.date]: filterMenus.dateFilter,
    [controlTypeNameToId.multiselect]: filterMenus.definedOptions,
    [controlTypeNameToId.dateTime]: filterMenus.dateFilter,
    [controlTypeNameToId.URL]: filterMenus.textFilter,
    [controlTypeNameToId.multiselect]: filterMenus.definedOptions,
    [controlTypeNameToId.multitext]: filterMenus.textFilter,
    [controlTypeNameToId.user]: filterMenus.definedOptions,
    [controlTypeNameToId.toggle]: filterMenus.booleanFilter,
    [controlTypeNameToId.place]: filterMenus.placesFilter,
    definedOptions: filterMenus.definedOptions,
    assetTypeFilter: filterMenus.assetTypeFilter,
    addedByFilter: filterMenus.addedByFilter,
    categoryFilter: filterMenus.categoryFilter,
    numberFilter: filterMenus.numberFilter,
    dateCreatedFilter: filterMenus.dateCreatedFilter,
    lastUpdatedFilter: filterMenus.lastUpdatedFilter,
    textFilter: filterMenus.textFilter,
    placesAndLevelsFilter: filterMenus.placesAndLevelsFilter,
    nameFilter: filterMenus.nameFilter,
    unearthIdFilter: filterMenus.unearthIdFilter,
    configMenu: ConfigMenu
};
