import exportPDFModel from 'models/export-pdf-model';
import swap from 'legacy/util/swap';
import store from 'util/data/store';
import router from 'uav-router';

const LeftRight = {
    oncreate: () => {
        exportPDFModel.postRenderLeftRight();
    },

    onupdate: () => {
        exportPDFModel.postRenderLeftRight();
    },

    view: () => {
        const assetTypes = exportPDFModel.assetTypeMap ? Object.values(exportPDFModel.assetTypeMap) : false;
        const showLegend = assetTypes && assetTypes.length > 0;
        const headerValues = exportPDFModel.getHeaderValues();
        return <div className="legend" style={exportPDFModel.legendPositionStyle} id={exportPDFModel.legendPositionId}>
            <div id={exportPDFModel.legendTitleId} className="legend-title" style={exportPDFModel.legendTitleStyle}>
                {store.project.name}
            </div>
            <div id={exportPDFModel.legendProjectContainerId}>
                {headerValues && headerValues.length > 0 && headerValues.map((key) =>{
                    return <div className="legend-value-container" style={exportPDFModel.legendProjectValueStyle}>
                        <label className="legend-value-label" style={exportPDFModel.legendFieldLabelStyle}>{key}</label>
                        <span className="legend-field-value" style={exportPDFModel.legendFieldValueStyle}>{exportPDFModel.pdfHeaderValues[key] || ''}</span>
                    </div>;
                })}
            </div>
            {showLegend && <div className="legend-asset-types" style={exportPDFModel.legendAssetTypesStyle}>
                {<div style={exportPDFModel.legendSectionTitleStyle}>Legend</div>}
                {assetTypes.reverse().map((assetType) =>{
                    return <div style={exportPDFModel.assetTypeContainerStyle} className="asset-type-container">
                        <img className="asset-type-icon-pdf" style={exportPDFModel.assetTypeIconStyle} src={`https://media.unearthlabs.com/${assetType.attributes.icon.mediaId}`}/>
                        <div style="display: inline-block">
                            <span style={exportPDFModel.assetTypeValueStyle}>{assetType.name}</span>
                        </div>
                    </div>;
                })}
            </div>}
        </div>;
    }
};

const TopBottom = {

    renderColumns: (assetTypes) => {
        const {columnLengths} = exportPDFModel;
        const columns = [];
        let i = assetTypes.length - 1;
        columnLengths.forEach((length, index) => {
            const columnDivs = [];
            for (let j = 0; j < length; j++) {
                const assetType = assetTypes[i];
                i--;
                columnDivs.push(<div style={exportPDFModel.assetTypeContainerStyle} className="asset-type-container">
                    <img className="asset-type-icon-pdf" style={exportPDFModel.assetTypeIconStyle} src={`https://media.unearthlabs.com/${assetType.attributes.icon.mediaId}`}/>
                    <div style="display: inline-block"><span style={exportPDFModel.assetTypeValueStyle}>{assetType.name}</span></div>
                </div>);
            }
            columns.push(<div id={`column-${index}`} style={exportPDFModel.legendAssetTypesStyle}>
                {columnDivs}
            </div>);
        });

        return columns;
    },

    oncreate: () => {
        if (!exportPDFModel.isLoading) {
            exportPDFModel.postRenderTopBottom();
        }
    },

    onupdate: () => {
        if (!exportPDFModel.isLoading) {
            exportPDFModel.postRenderTopBottom();
        }
    },

    view: () => {
        if (!exportPDFModel.assetTypeMap || exportPDFModel.isLoading) {
            return <></>;
        }
        const assetTypes = Object.values(exportPDFModel.assetTypeMap);
        const headerValues = exportPDFModel.getHeaderValues();
        return <div id={exportPDFModel.legendPositionId} className="legend"
            style={exportPDFModel.legendPositionStyle}>
            <div id={exportPDFModel.legendProjectContainerId} class={ assetTypes.length > 0 ? 'header-horizontal' : 'header-horizontal empty'}
                style={exportPDFModel.legendProjectContainerStyle}>
                <div id={exportPDFModel.legendTitleId} className="legend-title" style={exportPDFModel.legendTitleStyle}>
                    {store.project.name}
                </div>
                {headerValues && headerValues.length > 0 ? headerValues.map((key) => {
                    return <div className="legend-value-container"
                        style={exportPDFModel.legendProjectValueStyle}>
                        <label className="legend-value-label"
                            style={exportPDFModel.legendFieldLabelStyle}>{key}</label>
                        <span className="legend-field-value"
                            style={exportPDFModel.legendFieldValueStyle}>{exportPDFModel.pdfHeaderValues[key] || ''}</span>
                    </div>;
                }) : <div className="legend-value-container" style={exportPDFModel.legendProjectValueStyle}/>}
            </div>
            <div id={exportPDFModel.legendAssetTypesContainerId}>
                {assetTypes.length > 0 && <div id={exportPDFModel.legendContainerId} className="legend-container" style={exportPDFModel.legendSectionTitleStyle}>
                    <div className="legend-title">Legend</div>
                </div>}
                <div className="columns-container">
                    {assetTypes.length > 0 && TopBottom.renderColumns(assetTypes)}
                </div>
            </div>
        </div>;
    }
};


const PDFExporter = {

    onremove: () => exportPDFModel.cleanup(),

    oninit: () => {
        if (router.params.view !== 'pdf') {
            exportPDFModel.init();
        }
    },


    view: () =>
        <div class="exporter">
            <div class="pdf-selector">
                <div className="pdf-box" style={exportPDFModel.PDFPositionStyle}/>
                <div style={exportPDFModel.PDFBoxInnerStyle}
                    className="pdf-box-inner"/>
            </div>
            <div>
                <div id={exportPDFModel.compassId}
                    className="compass"
                    style={exportPDFModel.compassStyle}>N<i className="icon-location-filled"
                        style={exportPDFModel.compassIconStyle}/>
                </div>
                {swap(exportPDFModel.position, {
                    top: () => <TopBottom/>,
                    bottom: () => <TopBottom/>,
                    left: () => <LeftRight/>,
                    right: () => <LeftRight/>,
                    default: () => <LeftRight/>
                })}
            </div>
        </div>
};

export default PDFExporter;
