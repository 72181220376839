import MeMenu from 'views/side-nav/me-menu';
import HelpBox from 'views/side-nav/help-box';
import Toolbox from 'views/toolbox';
import exportPDFModel from 'models/export-pdf-model';
import onBodyClick from 'legacy/util/dom/on-body-click';
import chargebee from 'util/chargebee';
import formatDate from 'legacy/util/date/format-date';
import ExportMenu from 'views/side-nav/export-menu';
import AccountMenu from 'views/side-nav/account-menu/account-menu';
import api from 'legacy/util/api';
import filterModel from 'models/table/filter-model';
import siteModel from 'models/site-model';
import publish from 'legacy/util/api/publish';
import download from 'legacy/util/device/download';
import router from 'uav-router';
import store from 'util/data/store';
import popupModel from 'models/popover-model';
import initializer from 'util/initializer';
import toolboxModel from 'models/toolbox-model';
import proxyRequest from 'util/network/proxy-request';
import userModel from 'models/user-model';
import constants from 'util/data/constants';
import message from 'legacy/components/message';
import getToolInterface from 'util/interfaces/get-tool-interface';
import ProjectMenu from 'views/side-nav/project-menu';
import panelModel from 'models/panel-model';

const popups = {
    tools: Toolbox,
    help: HelpBox,
    menu: MeMenu,
    export: ExportMenu,
    account: AccountMenu,
    project: ProjectMenu
};

const exportFormats = ['XLS', 'CSV', 'GeoJSON', 'PDF Map', 'ESRI Shapefile', 'KML'];

initializer.add(() => {
    sideNavModel.exportFormats = Array.from(exportFormats);
});

const sideNavModel = {

    selectedFormat: 'XLS',

    popupFor: id => sideNavModel.popupId === id ? m(popups[id]) : null,

    close() {

        if (sideNavModel.selected) {

            sideNavModel.selected.classList.remove('selected');

            sideNavModel.selected = null;

            sideNavModel.popupId = null;

            m.redraw();

        }

    },

    toggle: popupId => e => {

        const target = e.currentTarget;

        const doOpen = sideNavModel.selected !== target;

        popupModel.close();

        e.stopPropagation();

        sideNavModel.close();

        if (doOpen) {

            target.classList.add('selected');

            sideNavModel.selected = target;

            sideNavModel.popupId = popupId;

            onBodyClick.once(sideNavModel.close);

        }

    },

    cancelExport() {

        sideNavModel.exporting = false;

        if (sideNavModel.abortExport) {

            sideNavModel.abortExport();

        }

    },

    createExport(exportFilename) {

        const isCustomExport = !!toolboxModel.customExports[sideNavModel.selectedFormat];

        let exportFormat = sideNavModel.selectedFormat;

        if (isCustomExport) {
            exportFormat = 'XLS';
        }

        return new Promise(resolve => {

            sideNavModel.abortExport = publish.await({
                changeType: 'new',
                recordType: 'export',
                test: change => change.creatorId === userModel.userId,
                callback: change => resolve(change)
            });

            const args = isCustomExport
                ? {
                    projectId: router.params.projectId,
                    isVisible: true
                } : filterModel.getArgs();

            api.rpc.request([['exportContent', Object.assign({
                exportFilename,
                exportFormat
            }, args, {
                limit: 0,
                offset: 0,
                include: undefined,
                groupBy: undefined,
                order: undefined
            })]]);

        });

    },

    createFileAsset(mediaId, label) {
        const tool = toolboxModel.linkTools.file;
        const toolInterface = getToolInterface(tool);
        const asset = toolInterface.createAsset({
            mediaId,
            label
        });
        api.rpc.request([['createContent', api.apiSafeContent(asset)]]);
    },

    doCustomExport(transformationID, location) {
        proxyRequest(constants.customExportUrl, {
            method: 'POST',
            data: {
                projectId: siteModel.projectId,
                mediaId: location.replace(constants.mediaURL, ''),
                userId: userModel.userId,
                transformationId: transformationID
            }
        }).then(result => {
            if (sideNavModel.exporting) {
                const mediaId = result.mediaId;
                publish.await({
                    changeType: 'modified',
                    recordType: 'media',
                    test: change => change.mediaId === mediaId && change.status === 'active',
                    callback: ({label}) => {
                        label = label || sideNavModel.selectedFormat + '.xlsx';
                        download(constants.mediaURL + result.mediaId, label);
                        sideNavModel.createFileAsset(result.mediaId, label);
                    }
                });
            }
        }).catch(e => {
            console.error(e);
            message.show('Something went wrong while preparing your export. Please try again or contact support.', 'error');
        }).finally(() => {
            sideNavModel.exporting = false;
            m.redraw();
        });

    },

    prepExport() {

        panelModel.close();

        if (sideNavModel.selectedFormat === 'PDF Map') {

            sideNavModel.close();

            exportPDFModel.open();

        } else {

            sideNavModel.exporting = true;

            const transformationID = toolboxModel.customExports[sideNavModel.selectedFormat];

            const date = new Date();

            const exportFilename = siteModel.name
                .replace(/\s+/g, '-')
                .replace(/[^a-zA-Z\d-]/g, '')
                    + `-${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

            this.createExport(exportFilename).then((update) => {

                if (update.status === 'failed') {

                    message.show('Something went wrong while preparing your export. Please try again or contact support.', 'error');

                    sideNavModel.exporting = false;

                    m.redraw();

                } else if (transformationID) {

                    this.doCustomExport(transformationID, update.location);

                } else {

                    download(update.location, exportFilename + '.zip');

                    sideNavModel.exporting = false;

                    m.redraw();

                }

            });

        }

    },

    buyNow() {

        chargebee({accountId: store.account.accountId}).then(() => {
            m.redraw();
        });

    },

    setUpBuyNow() {

        const account = store.account;

        const dateExpires = new Date(account.trialExpiration);

        const dateInterval = formatDate.readableInterval(dateExpires, new Date());

        sideNavModel.isTrial = !isNaN(dateInterval.num);

        if (dateInterval.unit === 'min') {

            dateInterval.unit = dateInterval.num === 1 ? 'minute' : 'minutes';

        }

        if (dateInterval.unit === 'sec') {

            dateInterval.unit = dateInterval.num === 1 ? 'second' : 'seconds';

        }

        sideNavModel.dateInterval = dateInterval;
    },

    openMetaAsset(tab) {
        const metaProjectId = store.account.attributes.metaProjectId;
        this.isOpeningMetaAsset = true;
        api.rpc.request([['listContent', {
            projectId: metaProjectId,
            'properties._projectId': {
                eq: siteModel.projectId
            },
            limit: 1
        }]]).then(([asset]) => router.set({
            projectId: metaProjectId,
            assetId: asset.contentId,
            tab
        }));
    }

};

export default sideNavModel;
