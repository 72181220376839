import deleteContent from 'legacy/util/data/delete-content';
import pointMenu from 'legacy/components/point-menu';
import convertMithril from 'util/dom/convert-mithril';
import formModel from 'models/form-model';
import siteModel from 'models/site-model';
import Table from 'views/table/table';
import assetModel from 'models/asset-model';
import mediaViewerModel from 'models/media-viewer-model';
import AssetForm from 'views/asset-form/asset-form';
import featureModel from 'models/feature-model';
import screenHelper from 'legacy/util/device/screen-helper';
import tableModel from 'models/table/table-model';
import store from 'util/data/store';
import popupModel from 'models/popover-model';
import userModel from 'models/user-model';
import router from 'uav-router';

function deleteAsset(assetId) {

    if (formModel.assetId === assetId && formModel.toolInterface && formModel.toolInterface.draw) {

        formModel.toolInterface.draw.onStop = null; // prevent autosaving feature

    }

    deleteContent(assetId).then(() => {

        if (formModel.toolInterface) {

            formModel.toolInterface.close();

        }

        siteModel.sidebar = Table;

        m.redraw();

    });

    pointMenu.close();

}

function popup(assetId, hasFeatures, canDelete) {
    const asset = store.assets[assetId];
    const showMediaViewer = asset.mediaIds && asset.mediaIds.length > 0;
    // First check we're on the links tab, then check the menu is for a different (ie linked) asset
    const showUnlinkOption = formModel.assetId && router.params.tab === 'Links' && assetId !== formModel.assetId;
    const isInfoPanelOpen = siteModel.sidebar === AssetForm;
    const tool = store.tools[asset.attributes.toolId];
    const hasProjectTabs = !isInfoPanelOpen && siteModel.isMetaProject && userModel.isAccountAdmin;
    return convertMithril.toDom(<div>
        {showUnlinkOption && <div className="menu-option" onclick={() => {
            formModel.linksModel.unlink(assetId);
            pointMenu.close();
        }}>
            <i className="icon-unlink"/> Unlink
        </div>}
        {(!isInfoPanelOpen || showUnlinkOption) && <div className="menu-option" onclick={() => {
            formModel.viewAsset(assetId, 'Properties');
            pointMenu.close();
        }}>
            <i className="icon-asset-form"/> View {tool.name} Properties
        </div>}
        {!isInfoPanelOpen && <div class="menu-option" onclick={() => {
            formModel.viewAsset(assetId, 'Comments');
            pointMenu.close();
        }}>
            <i class="icon-comment"/> Comments
        </div>}
        {!isInfoPanelOpen && <div className="menu-option" onclick={() => {
            formModel.viewAsset(assetId, 'Links');
            pointMenu.close();
        }}>
            <i className="icon-link"/> Links
        </div>}
        {hasProjectTabs && <div className="menu-option" onclick={() => {
            formModel.viewAsset(assetId, 'People', false);
            pointMenu.close();
        }}>
            <i className="icon-user"/>People
        </div>}
        {hasProjectTabs && <div className="menu-option" onclick={() => {
            formModel.viewAsset(assetId, 'Places/Levels', false);
            pointMenu.close();
        }}>
            <i className="icon-place-with-levels"/>Places/Levels
        </div>}
        {showMediaViewer && <div className="menu-option" onclick={() => {
            mediaViewerModel.open(asset.mediaIds);
            pointMenu.close();
        }}>
            <i className="icon-expand"/> View Media
        </div>}
        {hasFeatures || assetModel.getCompleteLayerId(assetId)
            ? <div class="menu-option map-status" onclick={() => {
                assetModel.panToAsset(assetId);
                pointMenu.close();
                if (screenHelper.small()) {
                    formModel.visible = false;
                    if (!tableModel.isCollapsed) {
                        tableModel.sideBarToggle();
                    }
                    m.redraw();
                }
            }}>
                <i class="icon-center-on-map"/> Center on Map
            </div>
            : assetModel.canAddToMap(assetId) && <div class="menu-option map-status" onclick={() => {
                formModel.viewAsset(assetId, 'Properties');
                formModel.addToMap();
                pointMenu.close();
            }}>
                <span class="tip-text">This asset is unmapped.</span>
                <i class="icon-center-on-map"/> Add to Map
            </div>
        }
        {hasFeatures && !userModel.isViewOnly && !tool.featureTypes.find(ft => ft.attributes.interface === 'project') &&
            <div className="menu-option" onclick={() => {
                featureModel.deleteFeaturesFromAsset(assetId);
                pointMenu.close();
            }}>
                <i className="icon-remove-from-map"/> Remove from Map
            </div>
        }
        {!userModel.isViewOnly && assetModel.supportsCloning(assetId) && <div className="menu-option" onclick={() => {
            formModel.viewAsset(assetModel.createClone(assetId).contentId, 'Properties', true);
            pointMenu.close();
        }}>
            <i className="icon-duplicate"/> Duplicate
        </div>}
        {canDelete &&
        <div class="menu-option remove-link" onclick={() => {
            deleteAsset(assetId);
            pointMenu.close();
        }}>
            <i class="icon-trash"/> Delete
        </div>}
    </div>);

}


const AssetOptions = {
    view: ({attrs: {assetId}}) => {
        const asset = store.assets[assetId],
            hasFeatures = asset.featureIds && asset.featureIds.length > 0,
            canDelete = deleteContent.isAllowed(assetId);

        return hasFeatures || canDelete
            ? <i class="icon-ellipsis asset-options" onclick={e => {
                popupModel.close();
                return pointMenu({
                    e,
                    content: () => popup(assetId, hasFeatures, canDelete),
                    cssClass: 'link-list asset-options',
                    forceDesktop: true,
                    noLine: true
                });
            }}/> : null;
    }
};

export default AssetOptions;
