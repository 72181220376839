import isMetric from 'util/numbers/is-metric';
import measure from 'legacy/util/numbers/measure';
import round from 'util/numbers/round';
import ColorPicker from 'views/color-picker/color-picker';
import onBodyClick from 'legacy/util/dom/on-body-click';
import SidebarToggle from 'views/sidebar-toggle';
import screenHelper from 'legacy/util/device/screen-helper';
import constants from 'util/data/constants';
import featureModel from 'models/feature-model';
import volumeModel from 'models/volume-model';
import {length, pixelsToMeters} from 'util/geo';
import store from 'util/data/store';
import Toggle from 'views/toggle';
import siteModel from 'models/site-model';
import drawPaletteModel from 'models/draw-palette-model';
import oneUpModel from 'models/one-up-model';

const Range = {

    oninit({state, attrs: {propKey, units, step = 0.1}}) {

        if (units === 'yards') {
            state.transform = value => measure.yardsToMeters(value);
        } else {
            state.transform = value => value;
        }

        state.default = drawPaletteModel.defaultValue(propKey);

        if (units === 'yards') {
            state.default = round(measure.metersToYards(state.default), 1 / Number(step));
        }
    },

    oncreate({state, dom, attrs}) {
        const range = dom.firstElementChild;
        const propKey = attrs.propKey;
        range.value = drawPaletteModel.getStateValue(propKey, state.default);
        range.nextElementSibling.value = range.value;
        attrs.feature.properties[attrs.propKey] = drawPaletteModel.getStateValue(propKey, attrs.feature.properties[propKey]);
    },

    view: vnode => {
        const {state, attrs: {propKey, render, min = 0, max = 1, step = 0.1}} = vnode;

        const redraw = e => render(properties => {
            const value = Math.max(Math.min(Number(e.target.value), max), min);
            properties[propKey] = state.transform(value);
            drawPaletteModel.persistValue(propKey, value);
            e.redraw = false;
        });

        return <div class="control-group range-control">
            <input class="range-input" type="range" min={min} max={max} step={step} value={drawPaletteModel.getStateValue(propKey, state.default)} oninput={redraw} />
            <input class="range-text" type="number" step={step} value={drawPaletteModel.getStateValue(propKey, state.default)} oninput={e => render(properties => {
                e.target.value = e.target.value || 0;
                const value = Math.max(Math.min(Number(e.target.value), max), min);
                properties[propKey] = state.transform(value);
                drawPaletteModel.persistValue(propKey, e.target.value);
            }, true)} onblur={redraw} />
        </div>;
    }
};

const ColorSelector = {
    oncreate({state, attrs: {propKey, feature}}) {
        feature.properties[propKey] = drawPaletteModel.getStateValue(propKey, feature.properties[propKey]);
        state.toggle = () => {
            if (!state.open) {
                onBodyClick.once(state.toggle);
            }
            state.open = !state.open;
            m.redraw();
        };
    },
    view: ({state, attrs: {propKey, feature, render}}) =>
        <div class="control-group color-group">
            <div class="color-picker-toggle" onclick={() => state.toggle()}>
                <div class="color-picker-select" style={`background-color:${feature.properties[propKey]};`} />
            </div>
            {state.open && <ColorPicker
                containerId="color-picker-wrap-draw"
                starterColor={feature.properties[propKey]}
                setColorFn={(hex) => render(() => {
                    feature.properties[propKey] = hex;
                    drawPaletteModel.persistValue(propKey, feature.properties[propKey]);
                })}
            />}
        </div>
};

const BorderPicker = {
    view: ({attrs: {propKey, feature, render}}) =>
        <div class="line-style-set border-opts control-group">
            <div class={'draw-style-btn' + (feature.properties[propKey] ? '' : ' active')}
                onclick={() => render(properties => {
                    properties[propKey] = false;
                })}>
                <span class="line-style line-small">
                    <label class="line-style-label">Solid</label>
                </span>
            </div>
            <div class={'draw-style-btn' + (feature.properties[propKey] ? ' active' : '')}
                onclick={() => render(properties => {
                    properties[propKey] = true;
                })}>
                <span class="line-style line-small dashed">
                    <label class="line-style-label">Dashed</label>
                </span>
            </div>
        </div>
};

const Textarea = {
    view: ({attrs: {propKey, feature, render}}) =>
        <div class="control-group">
            <textarea oninput={e => render(properties => {
                properties[propKey] = e.target.value;
                drawPaletteModel.persistValue(propKey, properties[propKey]);
            })} value={feature.properties[propKey]} />
        </div>
};

const Icon = {
    view: ({attrs: {propKey, feature, render}}) =>
        <div class="control-group icon-control">
            {feature.properties[propKey] && <img class="thumbnail" src={constants.mediaURL + feature.properties[propKey]} />}
            <div class="btn btn-pill btn-secondary btn-small" onclick={() =>
                oneUpModel.addUploadFlow( {
                    accept: 'image/*',
                    maxFiles: 1,
                    name: 'Import'
                }).then(([media]) => render(properties => {
                    const mediaId = media.mediaId;
                    properties[propKey] = mediaId;
                    featureModel.addImage(mediaId, constants.mediaURL + mediaId, feature);
                }))
            }>Choose Image</div>
        </div>
};

function isUpsideDown(coordinates, properties) {

    const metersPerEm = properties._textSizeMeters || pixelsToMeters(properties._textSizePx || 0, properties._latitude, siteModel.map.getZoom());

    const offset = properties._textOffset[0] * metersPerEm;

    const halfLength = length(coordinates, 'meters') / 2;

    let tally = 0,
        segment;

    // Find the segment which contains the centerpoint of the line
    coordinates.find((point, i) => {

        segment = [point, coordinates[i + 1]];

        tally += length(segment, 'meters');

        return tally - offset >= halfLength;

    });

    // If the first point in the center segment is east
    // of the second point, then the segment is "upside-down"

    if (!segment) {
        return false;
    }

    return segment[0][0] > segment[1][0];

}

const DPad = {
    oninit({state, attrs: {render, propKey, feature}}) {
        let interval,
            timeout;
        const properties = feature.properties;
        state.move = (index, change) => {
            properties[propKey] = properties[propKey] ? Array.from(properties[propKey]) : [0, 0];
            if (feature.geometry.type === 'LineString' && isUpsideDown(feature.geometry.coordinates, properties)) {
                change = change * -1;
            }
            const move = () => render(props => {
                props[propKey][index] = round(props[propKey][index] + change, 10);
                drawPaletteModel.persistValue(propKey, properties[propKey]);
            });
            timeout = setTimeout(() => {
                interval = setInterval(move, 10);
            }, 250);
            move();
        };
        state.stop = () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    },
    view: ({state: {move, stop}, attrs: {propKey, feature}}) => {
        const title = feature.properties[propKey] ? feature.properties[propKey].join(', ') : '0, 0';
        return <div class="control-group d-pad" title={title}>
            <i class="icon-up" onmousedown={() => move(1, -0.1)} onmouseup={stop} onmouseleave={stop} />
            <i class="icon-down" onmousedown={() => move(1, 0.1)} onmouseup={stop} onmouseleave={stop} />
            <i class="icon-left" onmousedown={() => move(0, -0.1)} onmouseup={stop} onmouseleave={stop} />
            <i class="icon-right" onmousedown={() => move(0, 0.1)} onmouseup={stop} onmouseleave={stop} />
            <i class="icon-expand" />
        </div>;
    }
};

const RotationToggle = {
    view: ({attrs: {propKey, render, feature}}) =>
        <Toggle active={feature.properties[propKey]} onchange={doFlip => render(properties => {
            properties[propKey] = doFlip ? 180 : 0;
            drawPaletteModel.persistValue(propKey, properties[propKey]);
        })}/>
};

// const TextAlignment = {
//     view: ({attrs: {render, propKey, feature}}) => {
//         return <div class="control-group checkbox">
//             <input type="checkbox" checked={feature[propKey] === 'map'} onchange={e => render(properties => {
//                 properties[propKey] = e.target.checked ? 'map' : 'viewport';
//             })} />
//             <label>Align text with line</label>
//         </div>;
//     }
// };

const featureControls = {
    _lineColor: {
        label: () => 'Line Color',
        ui: (render, feature) => <ColorSelector propKey="_lineColor" feature={feature} render={render} />
    },
    _lineOpacity: {
        label: () => 'Line Opacity',
        ui: (render, feature) => <Range propKey="_lineOpacity" feature={feature} render={render} />
    },
    _lineDasharray: {
        label: () => 'Line Style',
        ui: (render, feature) => <BorderPicker propKey="_lineDasharray" feature={feature} render={render}/>
    },
    _lineWidthPx: {
        label: () => 'Line Width (pixels)',
        ui: (render, feature) => <Range propKey="_lineWidthPx" render={render} feature={feature} min="1" max="100" step="1" />
    },
    _lineWidthMeters: {
        label: state => `Line Width (${state.units})`,
        ui: (render, feature, state) => <Range propKey="_lineWidthMeters" feature={feature} render={render} min="0.1" max="100" step="0.1" units={state.units} />
    },
    _iconImage: {
        label: () => 'Icon Image',
        ui: (render, feature) => <Icon propKey="_iconImage" render={render} feature={feature} />
    },
    _iconSizePx: {
        label: () => 'Icon Width (pixels)',
        ui: (render, feature) => <Range propKey="_iconSizePx" render={render} feature={feature} min="1" max="100" step="1" />
    },
    _iconSizeMeters: {
        label: state => `Icon Width (${state.units})`,
        ui: (render, feature, state) => <Range propKey="_iconSizeMeters" feature={feature} render={render} min="0.1" max="100" step="0.1" units={state.units} />
    },
    _fillColor: {
        label: () => 'Fill Color',
        ui: (render, feature) => <ColorSelector propKey="_fillColor" feature={feature} render={render} />
    },
    _fillOpacity: {
        label: () => 'Fill Opacity',
        ui: (render, feature) => <Range propKey="_fillOpacity" feature={feature} render={render} />
    },
    _textColor: {
        label: () => 'Text Color',
        ui: (render, feature) => <ColorSelector propKey="_textColor" feature={feature} render={render} />
    },
    _textSizePx: {
        label: () => 'Text Size (pixels)',
        ui: (render, feature) => <Range propKey="_textSizePx" render={render} feature={feature} min="1" max="200" step="1" />
    },
    _textSizeMeters: {
        label: state => `Text Size (${state.units})`,
        ui: (render, feature, state) => <Range propKey="_textSizeMeters" feature={feature} render={render} min="0.1" max="200" step="0.1" units={state.units} />
    },
    _textHaloColor: {
        label: () => 'Text Stroke Color',
        ui: (render, feature) => <ColorSelector propKey="_textHaloColor" feature={feature} render={render} />
    },
    _textHaloWidthPx: {
        label: () => 'Text Stroke Width (pixels)',
        ui: (render, feature) => <Range propKey="_textHaloWidthPx" render={render} feature={feature} min="0" max="20" step="1" />
    },
    _textHaloWidthMeters: {
        label: state => `Text Stroke Width (${state.units})`,
        ui: (render, feature, state) => <Range propKey="_textHaloWidthMeters" feature={feature} render={render} min="0" max="20" step="0.1" units={state.units} />
    },
    _rotation: {
        label: (state, feature) => feature.geometry.type === 'LineString' ? 'Flip Icon' : 'Rotation°',
        ui: (render, feature) =>
            feature.geometry.type === 'LineString'
                ? <RotationToggle propKey="_rotation" feature={feature} render={render} />
                : <Range propKey="_rotation" feature={feature} render={render} min="-360" max="360" step="1" />
    },
    _textField: {
        label: () => 'Text',
        ui: (render, feature) => <Textarea propKey="_textField" feature={feature} render={render} />
    },
    _textOffset: {
        label: () => 'Text Position',
        ui: (render, feature) => <DPad propKey="_textOffset" feature={feature} render={render} />
    }
    // _textAlignment: {
    //     label: () => 'Text Alignment',
    //     ui: (render, feature) => <TextAlignment propKey="_textAlignment" feature={feature} render={render} />
    // }
};

const DrawPalette = {

    oninit({attrs: {feature}, state}) {
        state.units = isMetric() ? 'meters' : 'yards';
        const featureType = store.featureTypes[feature.properties.featureTypeId];
        state.propKeys = Object.keys(featureControls).filter(propKey => featureType.properties[propKey] !== undefined);
        state.visible = !screenHelper.small();
        drawPaletteModel.init(feature);
    },

    view: ({state, attrs: {feature, render}}) => {
        return state.propKeys.length && !volumeModel.hasHeatMap
            ? <div class={`draw-palette ${state.visible ? 'visible' : ''}`}>
                <div class="draw-palette-scroll">
                    {state.propKeys.map(propKey => {
                        const featureControl = featureControls[propKey];
                        return featureControl
                            ? <fieldset key={propKey}>
                                <label>{featureControl.label(state, feature)}</label>
                                {featureControl.ui(render, feature, state)}
                            </fieldset>
                            : null;
                    })}
                    {drawPaletteModel.showReset() && <fieldset>
                        <a onclick={() => {
                            render((properties) => drawPaletteModel.resetDefaults(properties));
                        }} class="restore-link">Restore Style Defaults</a>
                    </fieldset>}
                </div>
                <SidebarToggle class="show-on-small" onclick={() => {
                    state.visible = !state.visible;
                }}/>
            </div>
            : null;
    }
};

export default DrawPalette;
