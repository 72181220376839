import formModel from 'models/form-model';
import placesTabModel from 'models/places-tab-model';
import GeometryDetail from 'views/geometry-detail/geometry-detail';
import OptionsPopup from 'views/options-popup/options-popup';
import measure from 'legacy/util/numbers/measure';
import roundIfNLong from 'util/numbers/round-if-n-long';
import debounce from 'util/events/debounce';
import pointMenu from 'legacy/components/point-menu/point-menu';
import popoverModel from 'models/popover-model';
import router from 'uav-router';
import helpers from 'legacy/util/api/helpers';
import toolboxModel from 'models/toolbox-model';
import capitalize from 'util/data/capitalize';

const PlacesTab  = {

    pointMenuOpts: {
        padding: {
            left: 370,
            top: -80
        }
    },

    onscroll: debounce(() => {
        pointMenu.close();
        popoverModel.close();
    }),

    // init(state) {
    //     placesTabModel.init(formModel.childProjectId);
    //     state.assetId = formModel.assetId;
    // },
    //
    // oninit: ({state}) => PlacesTab.init(state),
    //
    // onupdate({state}) {
    //     if (state.assetId !== formModel.assetId) {
    //         state.assetId = formModel.assetId;
    //         PlacesTab.init(state);
    //     }
    // },

    onremove() {
        if (router.params.tab !== 'Places/Levels') {
            placesTabModel.cleanUp();
        }
    },

    oninit({state}) {
        state.projectSingular = capitalize(toolboxModel.projectSingular);
    },

    view: ({state}) =>  {

        if (placesTabModel.isLoading) {
            return <div className="loader-v2 block"/>;
        }

        const projectId = formModel.childProjectId;
        const places = placesTabModel.places ? Object.values(placesTabModel.places) : undefined;

        return <div className="tab-content places-tab" onscroll={PlacesTab.onscroll}>
            <div className="tip">
                <div className="add-place-button btn btn-pill btn-secondary btn-smaller"
                    onclick={() => placesTabModel.openPlaceForEditing(projectId)}>
                    <span class="btn-label"><i class="icon-plus"/><i className="icon-place-with-levels"/></span>
                </div>
                Sub-divide your site area by adding Places.
                You'll be able to tag and retrieve data by Place and Levels within.
            </div>
            <div className="place-container">
                <div>
                    {placesTabModel.feature && <GeometryDetail
                        featureId={placesTabModel.feature.id}
                        customElement={<>
                            <div className="left half">{'Avg Elevation'}</div>
                            <div className="left half">{placesTabModel.getTotalAverageElevation(places)}</div>
                        </>}
                        header={<>
                            <div className="place-name">
                                <i className="icon-place"/> {state.projectSingular} Bounds
                                <div className="ellipsis-wrap">
                                    <OptionsPopup options={[{
                                        label: 'Edit Boundary',
                                        icon: 'icon-edit',
                                        callback: () => placesTabModel.editPlaceBounds(projectId)
                                    }
                                    ]}
                                    pointMenuOpts={{
                                        padding: {
                                            left: 370,
                                            top: -24
                                        }
                                    }}
                                    />
                                </div>
                            </div>
                        </>}/>}
                </div>
            </div>
            <>
                {places && places.map((place) => {
                    const levels = place.levels.items;
                    return <div className="place-container">
                        <div className="place-name">
                            <i className="icon-place"/>{place.name}
                            <div className="ellipsis-wrap">
                                <OptionsPopup options={[{
                                    label: 'Edit Place',
                                    icon: 'icon-edit',
                                    callback: () => placesTabModel.openPlaceForEditing(projectId, place.placeId)
                                },
                                {
                                    label: 'Delete Place',
                                    icon: 'icon-trash',
                                    isDelete: true,
                                    callback: () => placesTabModel.deletePlace(place)
                                }
                                ]}
                                pointMenuOpts={PlacesTab.pointMenuOpts}
                                />
                            </div>
                        </div>
                        <GeometryDetail mockFeature={{
                            geometry: place.boundary,
                            type: 'Feature',
                            properties: {
                                _textSizePx: 18
                            }
                        }} header={<></>}
                        customElement={<>
                            <div className="left half">{'Avg Elevation'}</div>
                            <div className="left half">{placesTabModel.getAverageElevation(helpers.list(place.levels))}</div>
                        </>}
                        />
                        {levels && levels.length !== 0 && <div>
                            {levels.map((level) => {
                                return <div className={`level-container${level.levelId === placesTabModel.activeLevelId ? ' edit-active' : ''}`}>
                                    <div className="level-name" id={level.levelId}>
                                        {level.shortName}
                                        <div className="ellipsis-wrap">
                                            <OptionsPopup options={[{
                                                label: 'Edit Level',
                                                icon: 'icon-edit',
                                                callback: () => placesTabModel.openModifyLevelEditor(
                                                    document.getElementById(level.levelId),
                                                    level)
                                            },
                                            {
                                                label: 'Delete Level',
                                                icon: 'icon-trash',
                                                isDelete: true,
                                                callback: () => placesTabModel.deleteLevel(level.levelId)
                                            }
                                            ]}
                                            pointMenuOpts={PlacesTab.pointMenuOpts}
                                            />
                                        </div>
                                    </div>
                                    {level.elevation && <label>{
                                        roundIfNLong(measure.metersToFeet(level.elevation), 3)} ft ASL
                                    </label>}
                                </div>;
                            })}
                        </div>}
                        <div className="add-level-to-place" onclick={function () {
                            placesTabModel.openNewLevelEditor(this, place);
                        }}>
                            + Add level to {place.name}
                        </div>
                    </div>;
                })}
            </>
            <div className="add-place" onclick={() => placesTabModel.openPlaceForEditing(projectId)}>
                <i className="icon-add-place"/>
                Add a Place
            </div>
        </div>;
    }

};

export default PlacesTab;
