import cache from 'legacy/util/data/cache';
import userModel from 'models/user-model';
import router from 'uav-router';
import message from 'legacy/components/message';
import randomId from 'util/numbers/random-id';
import constants from 'util/data/constants';
import {wsUrl} from 'util/data/env';
import initializer from 'util/initializer';
import publish from 'legacy/util/api/publish';
import login from 'legacy/components/auth/login';
import dialogModel from 'models/dialog-model';
import rpcCache from 'legacy/util/api/rpc-cache';
import element from 'util/dom/element';
import loaderModel from 'models/loader-model';
import cookie from 'util/data/cookie';
import layerModel from 'models/layer-model';
import startTrial from 'legacy/components/auth/sign-up/start-trial';
import {datadogRum} from '@datadog/browser-rum';
import accountModel from 'models/account-model';
import RequestPool from 'util/network/request-pool';
import store from 'util/data/store';
import toolboxModel from 'models/toolbox-model';
import analytics from 'util/data/analytics';

let socket,
    interval,
    queue = [],
    offline,
    isReconnecting;

const requests = {};

const _send = data => socket.send(JSON.stringify(data));

const requestPool = new RequestPool(5, 200, _send);


function clearRequests() {

    Object.keys(requests).forEach(key => {

        if (!requests[key].ignoreClearPending) {

            delete requests[key];

        }

    });

    requestPool.clearRequests();

}

function clearPendingRequests() {

    clearRequests();

    publish.clearCallbacks();

}

const errorMessage = element`<span>Something went wrong. Consider <a onclick="location.reload()">refreshing</a>.</span>`;

let ignoreSlowConnection,
    connectionIsSlow = false;

const showError = () => {

    message.show(errorMessage, 'error');

};

const slowConnection = element`<span>Poor internet connection detected. <a>Ignore</a>.</span>`;

slowConnection.lastElementChild.onclick = () => {

    message.hide();

    ignoreSlowConnection = true;

};

if (navigator.connection) {

    navigator.connection.onchange = () => {

        if (offline) {

            return;

        }

        if (!ignoreSlowConnection
            && !connectionIsSlow
            && (['slow-2g', '2g'].indexOf(navigator.connection.effectiveType) !== -1
                || navigator.connection.rtt > 1000)) {

            message.show(slowConnection, 'warning', true);

            connectionIsSlow = true;

        } else {

            connectionIsSlow = false;

        }

    };

}

function goOffline() {

    if (offline) {

        return;

    }

    offline = true;

    clearInterval(interval);

    document.body.classList.add('offline');

    message.show(
        element`<span><i class="icon-error unearth"></i> No internet connection is available. We\'ll keep trying to reconnect.</span>`,
        'error',
        true
    );

    window.addEventListener('online', goOnline);

}

function goOnline() {

    if (!offline) {

        return;

    }

    offline = false;

    document.body.classList.remove('offline');

    message.hide();

    window.removeEventListener('online', goOnline);

    reconnect();

}

window.addEventListener('offline', goOffline);

const isConnected = () => {

    const connected = socket && socket.readyState === WebSocket.OPEN;

    if (window.navigator.onLine === false) {

        goOffline();

    } else if (offline) {

        goOnline();

    }

    return connected;

};


const send = data => {

    if (isConnected()) {

        requestPool.addRequest(data);

    } else {

        if (data.type === 'request') {

            queue.push(() => {

                data.sessionId = socket.sessionId;

                _send(data);

            });

        }

        reconnect();

    }

};

function processError(msg) {

    if (socket.readyState === WebSocket.OPEN) {

        showError();

        console.error(msg);

    } else {

        reconnect();

    }

}

function expiredSession() {

    dialogModel.open({
        warning: true,
        text: 'Your session has expired. Please log in again to continue.',
        onOkay: logout
    });

}

function siteNotFound() {
    router.set({projectId: store.account.attributes.metaProjectId});

    dialogModel.open({
        headline: `Unable to load the requested ${toolboxModel.projectSingular}`,
        text: `The ${toolboxModel.projectSingular} was not found or you do not have access.`
    });
    
}

function processMessage(e) {

    const data = JSON.parse(e.data);

    const id = data.requestId || data.msgId;

    requestPool.unlockRequest(id);

    if ((data.type === 'response' || data.type === 'rpc-results') && requests[id]) {

        const request = requests[id];

        if (!data.payload || data.payload.error || data.success === false || data.payload.errorCount) {

            if (data.code === 4040 || data.code === 4001) {

                expiredSession();

            } else if (data.payload && data.payload.results && data.payload.results.find(result => result.error === 'Not Found')) {

                siteNotFound(); 
                
            }  else if (request.ignoreErrors) {

                console.warn(data);

                if (request.reject) {

                    request.reject(data);

                }

            } else {

                processError(JSON.stringify(data));

                if (request.reject) {

                    request.reject(data);

                }

            }

        } else {

            if (request.resolve) {

                request.resolve(data.payload.body || data.payload.results);

            }

        }

        delete requests[id];

    } else if (data.type === 'publish') {

        if (data.payload.changes) {

            data.payload.changes.forEach(publish);

        } else {

            publish(data.payload);

        }

    }

}

function connectionResolve() {

    isReconnecting = false;

    if (methods.onreconnect) {

        methods.onreconnect();

    }

}

function connectionReject() {

    isReconnecting = false;

    logout();

}

function reconnect() {

    if (isReconnecting) {

        return;

    }

    isReconnecting = true;

    clearInterval(interval);

    connect(null, connectionResolve, connectionReject);

}

function handleClose(e) {

    console.error(e);

    setTimeout(reconnect, 2000);

}

function init(data) {

    socket.sessionId = data.payload.sessionId;

    const userId = data.payload.userInfo['cognito:username'];

    const lastLoggedInUser = cache.get('user');

    if (lastLoggedInUser && lastLoggedInUser !== userId) {

        clearCaches();

    }

    if (router.params.clientId) {

        router.url.removeReplace('clientId');

    } else if (data.clientId) {

        cache.set('clientId', data.clientId);

    }

    let promise = Promise.resolve();

    if (userId && userId !== 'anonymous') {

        promise = accountModel.loadAccount(userId);

        cache.set('user', userId);

    }

    socket.addEventListener('message', processMessage);

    socket.addEventListener('close', handleClose);

    queue.forEach(fn => fn());

    queue = [];

    clearInterval(interval);

    interval = setInterval(() => {

        if (isConnected()) {

            send({
                type: 'ping',
                sessionId: socket.sessionId
            });

        } else {

            reconnect();

        }

    }, 20000);

    return promise;

}

function connect(opts, resolve, reject) {

    const {secret, invitationId, projectId, userId, signup, signUpCode} = router.params;

    const isInvite = secret && invitationId && projectId;

    let clientId = cache.get('clientId');

    if (!clientId) {

        clientId = router.params.clientId;

        if (!clientId) {

            clientId = startTrial.completedSelfSignUp = cookie.readCookie('ue_clientId', true);

        }

        if (clientId) {

            cache.set('clientId', clientId);

        }

    }

    if (!opts && clientId) {

        opts = {
            type: 'reconnect',
            clientId
        };

    }

    if (!clientId && isInvite) {

        opts = {
            type: 'connect',
            clientId: randomId(),
            msgId: randomId(),
            payload: {
                secret,
                invitationId,
                projectId
            }
        };

    }

    if (signup === 'fromlink') {
        // NOTE: if we get a signup of 'fromlink' then send a special
        // payload to the wss for login
        opts = {
            type: 'connect',
            clientId: randomId(),
            msgId: randomId(),
            payload: {
                signup,
                userId,
                signUpCode
            }
        };

    }

    if (isConnected() && userModel) {

        if (resolve) {

            resolve();

            resolve = null;

        }

    } else if (opts && (opts.username && opts.password || opts.anonymous || opts.clientId || isInvite)) {

        function connectionError(e) {

            console.error(e);

            return setTimeout(() => connect(clientId ? null : opts, resolve, reject), 1000);

        }

        let preventLoader;

        if (socket && socket.close && socket.sessionId) {

            preventLoader = true;

            socket.removeEventListener('close', handleClose);

            socket.close();

        }

        try {

            socket = new WebSocket(wsUrl);

        } catch (e) {

            connectionError(e);
        }

        opts.clientId = opts.clientId || randomId();

        opts.type = opts.type || 'connect';


        function open() {

            send(opts);

        }

        function firstMessage(e) {

            const data = JSON.parse(e.data);

            // It's possible for a change notification to
            // arrive before the connection
            if (data.payload.changes) {

                return;

            }

            socket.removeEventListener('message', firstMessage);

            socket.removeEventListener('open', open);

            if (data.success === false || data.payload.success === false) {

                reset();

                if (data.code === 4040) {

                    expiredSession();

                } else if (reject) {

                    reject();

                }

            } else {

                if (!preventLoader) {

                    loaderModel.load();

                }

                init(data).then(() => {

                    if (resolve) {

                        resolve();

                    }

                    resolve = null;

                    if (!preventLoader) {

                        loaderModel.hide();

                    }

                }).catch(err => {

                    console.error(err);

                    logout();

                    setTimeout(() => message.show('There is a problem with your account. Please contact support.', 'error'), 500);

                });

            }

        }

        socket.removeEventListener('message', firstMessage);

        socket.removeEventListener('open', open);

        socket.removeEventListener('error', connectionError);

        socket.addEventListener('message', firstMessage);

        socket.addEventListener('open', open);

        socket.addEventListener('error', connectionError);

    } else if (reject) {

        reject();

    }

}

function clearCaches() {

    rpcCache.clear();

    cache.clear();

    userModel.reset();

}

function reset() {

    clearInterval(interval);

    if (socket) {

        socket.sessionId = null;

        socket.removeEventListener('close', handleClose);

        socket.close();

    }

    clearCaches();

    cookie.readCookie('ue_clientId', true); // clear the trial sign up cookie

    datadogRum.setRumGlobalContext({
        usr: undefined,
        acct: undefined
    });

}

function logout() {

    login.logout();

    reset();

    router.set();

    cookie.removeCookie('messagesUtk');

}

initializer.add(() => {

    clearRequests();

    methods.onreconnect = null;

});

function makeRequest(resolve, reject, ignoreErrors, ignoreClearPending) {

    const msgId = randomId();

    requests[msgId] = {
        resolve,
        reject,
        ignoreErrors,
        ignoreClearPending
    };

    return msgId;

}

function sendRequest(verb, path, opts = {}) {

    const msgId = makeRequest(opts.resolve, opts.reject, opts.ignoreErrors);

    send({
        msgId,
        sessionId: socket.sessionId,
        type: 'request',
        payload: {
            verb,
            path,
            type: 'http',
            body: opts.data,
            args: opts.args,
            platform: 'web',
            version: constants.apiVersion,
            analytics: verb === 'get' ? undefined : analytics.fetch()
        }
    });

}

function sendMessage(type, opts = {}) {

    const msgId = makeRequest(opts.resolve, opts.reject, opts.ignoreErrors, opts.ignoreClearPending);

    send({
        type,
        msgId,
        sessionId: socket.sessionId,
        payload: opts.data
    });

    return () => {
        delete requests[msgId];
        requestPool.clearQueued(msgId);
    };

}

function stream(type, args, callback, resolve) {

    // const date = new Date();

    const msgId = makeRequest(data => {
        abort();
        resolve(data[0].count);
    });

    const abort = publish.await({
        changeType: 'stream',
        recordType: type,
        test: change => change.streamId.startsWith(msgId),
        callback,
        persist: true
    });

    const method = {
        content: 'streamContent',
        features: 'streamContentFeatures',
        geojson: 'streamContentFeaturesGeoJson'
    }[type];

    args.pageSize = 100;

    args.includeLinked = layerModel.state.doShowLinks || undefined;

    send({
        type: 'request',
        msgId,
        sessionId: socket.sessionId,
        payload: {
            version: constants.apiVersion,
            platform: 'web',
            rpc: [[method, args]]
        }
    });

    return () => {
        abort();
        requestPool.clearQueued(msgId);
    };

}

const methods = {
    connect,
    logout,
    reset,
    clearPendingRequests,
    connectionIsSlow: () => connectionIsSlow,
    showError,
    get: (path, opts) => sendRequest('get', path, opts),
    put: (path, opts) => sendRequest('put', path, opts),
    post: (path, opts) => sendRequest('post', path, opts),
    patch: (path, opts) => sendRequest('patch', path, opts),
    delete: (path, opts) => sendRequest('delete', path, opts),
    send: sendMessage,
    sendRaw: send,
    stream,
    getSessionId: () => socket && socket.sessionId
};

export default methods;
