import debounce from 'util/events/debounce';
import 'modernizr';
import pointMenu from 'legacy/components/point-menu';
import initializer from 'util/initializer';

const checkIsSmall = () => document.body.offsetWidth < 768;

let touching,
    isSmall = checkIsSmall(),
    resizeHandlers = [];

initializer.add(() => {
    resizeHandlers = [];
});

window.addEventListener('resize', debounce(() => {

    isSmall = checkIsSmall();

    pointMenu.close();

    resizeHandlers.forEach(fn => fn());

}));

window.addEventListener('touchstart', () => {

    touching = true;

});

window.addEventListener('touchend', e => {

    touching = e.touches.length > 0;

});

const screenHelper = {
    touching: () => touching,
    small: () => isSmall,
    large: () => !isSmall,
    canEditLayers: () => document.body.offsetWidth >= 1024,
    ie: navigator.userAgent.indexOf('MSIE ') > 0,
    ios: navigator.userAgent.match(/Macintosh/) && Modernizr.touchevents,
    isAndroidWebView: () => window.unearthAndroidInterface,
    isIosWebView: () => window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.closeweb,
    onResize: fn => {
        resizeHandlers.push(fn);
        return () => resizeHandlers.splice(resizeHandlers.indexOf(fn), 1);
    }
};

export default screenHelper;
