import initializer from 'util/initializer';

const IS_DRAWING = 'isDrawing';
/*
 * Globally accessible app-wide state
 */
const appModel = {

    state: {},

    cleanup() {
        Object.assign(appModel.state, {
            [IS_DRAWING]: false, // Notes if currently drawing on the map, to prevent other click events
            editingProjectId: '' // state.editingProjectId may be a meta project or a child project
        });
        Object.preventExtensions(appModel.state);
    },

    getState(key) {
        return appModel.state[key];
    },

    setState(key, value) {
        appModel.state[key] = value;
        m.redraw();
    },

    
    /* ----- Drawing state ----- */

    startDrawing() {
        this.setState(IS_DRAWING, true);
    },

    stopDrawing() {
        this.setState(IS_DRAWING, false);
    },

    get isDrawing() {
        return this.getState(IS_DRAWING);
    }

};

window.state = appModel;

initializer.add(() => appModel.cleanup());

export default appModel;
