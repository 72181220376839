import socket from 'util/network/socket';
import publish from 'legacy/util/api/publish';
import formatDate from 'legacy/util/date/format-date';

const mockAPIResponses = {
    listContent: [],
    countContent: {count: 0}
};

// We don't want to allow creating or modifying anything in preview mode,
// and we can't support loading existing assets because they would be
// from a different toolbox.
function disableWriteAPIs() {

    socket.stream = () => new Function();

    const send = socket.send;

    socket.send = (type, opts) => {

        const mockResponses = [];

        if (opts.data && opts.data.rpc) {

            opts.data.rpc = opts.data.rpc
                .filter((rpc, i) => {

                    const methodName = rpc[0];

                    const mockResponse = mockAPIResponses[methodName];

                    if (mockResponse) {

                        mockResponses.push({
                            index: i,
                            response: mockResponse
                        });

                        return null;

                    }

                    let changeType;

                    if (methodName.startsWith('create')) {
                        changeType = 'new';
                    } else if (methodName.startsWith('modify')) {
                        changeType = 'modified';
                    } else if (methodName.startsWith('delete')) {
                        changeType = 'deleted';
                    }

                    if (changeType) {

                        mockResponses.push({
                            index: i,
                            response: rpc[1]
                        });

                        // Send a mock change update
                        setTimeout(() => {

                            const now = formatDate.forPython(new Date());

                            const verb = {
                                new: 'create',
                                modified: 'modify',
                                deleted: 'delete'
                            }[changeType];

                            publish(Object.assign({
                                createdDateTime: now,
                                updatedDateTime: now
                            }, rpc[1], {
                                changeType,
                                recordType: methodName.replace(verb, '').toLowerCase(),
                                changedBy: {
                                    sessionId: socket.getSessionId()
                                }
                            }));

                        }, 500);

                        return null;

                    }

                    return rpc;

                });

            if (!opts.data.rpc.length) {

                return setTimeout(() => opts.resolve(mockResponses.map(mock => mock.response)), 500);

            }
        }

        const resolve = opts.resolve;

        opts.resolve = results => {

            mockResponses.forEach(mock => results.splice(mock.index, mock.response));

            resolve(results);

        };

        return send(type, opts);

    };

}

const previewToolbox = () => new Promise(resolve => {

    disableWriteAPIs();

    const receiveToolbox = e => {
        window.removeEventListener('message', receiveToolbox);
        resolve([[e.data]]);
    };

    window.addEventListener('message', receiveToolbox, false);

    window.parent.postMessage('sendToolbox', '*');

    window.addEventListener('error', err => {
        window.parent.postMessage(err.message, '*');
    });

});

export default previewToolbox;
