import constants from 'util/data/constants';
import hubspot from 'util/hubspot/hubspot';
import {datadogRum} from '@datadog/browser-rum';
import api from 'legacy/util/api';
import store from 'util/data/store';
import socket from 'util/network/socket';
import router from 'uav-router';
import dialogModel from 'models/dialog-model';
import peopleModel from 'models/people/people-model';
import PersonModel from 'models/people/person-model';
import deepMerge from 'util/data/deep-merge';

// Handles data and logic related to the logged-in user
class userModel extends PersonModel {
    constructor() {
        super();
        this.preferences = {};
    }

    init(userId) {
        return api.rpc.requests( [
            ['getUser', {userId}]
        ], true)
            .then(([user]) => {
                this.setCurrent(user);
            });
    }

    reset() {
        Object.assign(this, {
            userId: undefined,
            givenName: undefined,
            familyName: undefined,
            emailAddress: undefined,
            phoneNumber: undefined,
            role: undefined,
            status: undefined,
            preferences: undefined,
            isInvited: undefined,
            isSuperAdmin: undefined,
            isLimited: undefined,
            isNewUser: undefined,
            isVisible: true,
            rolesAssignable: undefined,
            projectIds: undefined
        });
    }

    get name() {
        return this.givenName;
    }

    get rolesUserCanAssign() {
        if (this.rolesAssignable) {
            return this.rolesAssignable;
        }
        const assignableRoles = Object.values(constants.accountUserRoles).filter(role => role.key !== 'guest' && role.key !== 'superadmin');
        if (this.isSuperAdmin) {
            this.rolesAssignable = assignableRoles;
        } else {
            const rank = peopleModel.permissionLevel(this.role);
            this.rolesAssignable = assignableRoles.filter(role => peopleModel.permissionLevel(role.key) <= rank);
        }
        return this.rolesAssignable;
    }

    // Given an assetId, determines if the logged in user is assigned to a user control on that asset
    isAssignedToAControl(assetIdOrRecord) {
        const record = typeof assetIdOrRecord === 'string' ? store.assets[assetIdOrRecord] : assetIdOrRecord;
        const allControls = store.tools[record.attributes.toolId].assetForm.controls;
        const userCtrlTypeId = constants.controlTypeNameToId.user;
        return allControls.find(ctrl => ctrl.controlTypeId === userCtrlTypeId && record.properties[ctrl.label] === this.userId);
    }

    // Given an assetId, determines if the logged in user has ability to edit it
    isContentEditable(assetIdOrRecord) {
        const record = typeof assetIdOrRecord === 'string' ? store.assets[assetIdOrRecord] : assetIdOrRecord;
        return !this.isViewOnly && (!this.isLimited || this.userId === record.authorId  || this.isAssignedToAControl(assetIdOrRecord));
    }

    getEditableClass(content) {
        if (content) {
            return this.isContentEditable(content.contentId) ? '' : 'limited-user';
        }
        return this.isLimited ? 'limited-user' : '';
    }

    setCurrent(user) {
        // Set user data, roles, and permissions
        Object.assign(this, user, {
            isInvited: user.status === 'invited',
            isViewOnly: user.role === 'viewer' || user.isInvited,
            isNewUser: false
        });

        // Set preferences
        this.preferences = this.preferences || {};
        const sitePreferences = this.preferences.sitePreferences || {};
        // This is a temporary measure to clean up sitePreferences > filters, which is no longer used.
        Object.keys(sitePreferences).forEach(siteId => {
            delete sitePreferences[siteId].filters;
        });

        // Set applicable body classes
        document.body.classList[this.isViewOnly ? 'add' : 'remove']('view-only');
        document.body.classList[this.status === 'trial' ? 'add' : 'remove']('trial');

        // Add datadog and hubspot
        if (constants.isDeployed) {
            datadogRum.addRumGlobalContext('usr', {
                id: this.userId,
                name: this.displayName(),
                email: this.emailAddress
            });
        }
        hubspot.loadAndTrack();

        // Set cookie
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        document.cookie = `user_id=${this.userId};Domain=unearthlabs.com;expires=${date.toUTCString()}`;
    }

    setAccountUserData(account) {
        // Set account user data, roles, and permissions
        const accountUser = account.users.find(u => u.userId === this.userId);
        if (accountUser) {
            this.role = accountUser.role;
            this.emailAddress = accountUser.emailAddress;
            this.phoneNumber = accountUser.phoneNumber;
            this.isAccountAdmin = {
                admin: 'admin',
                owner: 'owner'
            }[accountUser.role] || this.isSuperAdmin;
        } else {
            this.role = 'hidden';
            this.isAccountAdmin = this.isSuperAdmin;
        }
        this.isViewOnly = this.role === 'viewer';
        this.isLimited = this.role === 'limited';

        // Set applicable body classes
        document.body.classList[this.isViewOnly ? 'add' : 'remove']('view-only');

        // Add data dog
        if (constants.isDeployed) {
            datadogRum.addRumGlobalContext('acct', {
                id: account.accountId,
                name: account.name
            });
        }
        this.checkAccountStatus();
    }

    setPreference(key, value) {
        this.preferences[key] = value;
        return api.patch.user({
            userId: this.userId,
            preferences: this.preferences
        });
    }

    getPreference(key) {
        return this.preferences[key];
    }

    mergePreferences(preferences) {
        deepMerge(preferences, this.preferences);
        return api.patch.user({
            userId: this.userId,
            preferences: this.preferences
        });
    }

    checkAccountStatus() {
        if (!store.accounts) {
            return;
        }
        const accounts = Object.values(store.accounts);
        if (accounts.length === 0) {
            // no accounts (owner kicked user off all accounts. This happens when account is null)
            this.showAccessDeniedMessage();
            return 'accessDenied';
        }

        let status = '';
        for (let i = 0; i < accounts.length; ++i) {
            switch (accounts[i].status) {
            case 'active':
                return 'active';
            case 'trial':
                return 'trial';
            case 'suspended':
            case 'expired':
                status = status === 'suspended' ? 'suspended' : accounts[i].status;
            }
        }

        // all accounts are suspended or expired
        if (status === 'suspended' || status === 'expired') {
            this.showAccessDeniedMessage(status);
            socket.reset();
            peopleModel.reset();
        }

        return status;
    }

    showAccessDeniedMessage(status = 'denied') {
        switch (status) {
        case 'expired': // EXPIRED ACCOUNT
            return dialogModel.open({
                warning: true,
                cssClass: 'large block-signin',
                text: <div>
                    <p>Hi, {this.name}.</p>
                    <p>We hope you’ve enjoyed using Unearth!</p>
                    <p><span class="user-dialog-callout">Your free trial has ended,</span></p>
                    <p>but don’t worry, we’ve saved all your data. To continue creating maps, collaborating with your team, and streamlining operations, you’ll need to purchase a subscription.</p>
                    <p>If you need help deciding which plan is right for you, give us a call at <a href="tel:12065351041">206.535.1041</a> or send an email to <a class="buy-email" href="mailto:sales@unearthlabs.com">sales@unearthlabs.com</a>.</p></div>,
                onOkay: () => router.set(),
                okayText: 'Log out'
            });
        case 'suspended': // SUSPENDED ACCOUNT
            return dialogModel.open({
                warning: true,
                cssClass: 'large block-signin',
                text: <div><p>Hi, {this.name}.</p>
                    <p><span class="user-dialog-callout">Your account is currently suspended.</span></p>
                    <p>We’ve saved all of your data, but to get access you will need to purchase a subscription.</p>
                    <p>Give us a call at <a href="tel:12065351041">206.535.1041</a> or send an email to <a class="buy-email" href="mailto:sales@unearthlabs.com">sales@unearthlabs.com</a>.</p></div>,
                onOkay: () => router.set(),
                okayText: 'Log out'
            });
        default: // ACCOUNT NOT ASSIGNED
            return dialogModel.open({
                cssClass: 'large',
                warning: true,
                text: <div><p>Hi, {this.name}. </p>
                    <p>You don’t have access to this account.</p>
                    <p>To receive access, you will need to contact your account administrator. </p>
                    <p>If you believe this is an error, please contact us at <a class="buy-email" href="mailto:support@unearthlabs.com">support@unearthlabs.com</a>.</p></div>,
                onOkay: () => router.set(),
                okayText: 'Okay'
            });
        }
    }

}

export default new userModel();
