import Component from 'legacy/util/component';

let onEnter;

class NextButton extends Component {

    show(opts) {

        this.onclick = opts.onclick;

        this.enable();

        this.unhide();

    }

    showDisabled() {

        this.disable();

        this.unhide();

    }

    enable() {

        window.addEventListener('keydown', onEnter);

        this._el.classList.add('enabled');

    }

    disable() {

        window.removeEventListener('keydown', onEnter);

        this._el.classList.remove('enabled');

    }

    unhide() {

        this.spin(false);

        this._el.style.display = '';

        this._el.classList.add('visible');

    }

    hide() {

        window.removeEventListener('keydown', onEnter);

        this._el.classList.remove('visible');

        setTimeout(() => {

            this._el.style.display = 'none';

        }, 200);

    }

    spin(trueOrFalse) {

        this._el.classList[trueOrFalse ? 'add' : 'remove']('spinning');

    }

    next() {

        this.spin(true);

        this.onclick();

    }

    onEnter(e) {

        if (e && (e.which || e.keyCode) === 13) {

            const tagName = e.target.tagName.toUpperCase();

            if (tagName === 'INPUT' || tagName === 'TEXTAREA') {

                e.stopPropagation();

            } else {

                this.next();

            }

        }

    }

    render(html) {

        html`
        <div class="next-button" style="display:none">
            <div class="pulse"></div>
            <div class="icon-arrow-right" onclick="${this.next}"></div>
            <div class="spinner"></div>
        </div>`;

        if (onEnter) {

            window.removeEventListener('keydown', onEnter);

        }

        onEnter = this.onEnter.bind(this);

    }

}

export default NextButton;
