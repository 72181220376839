function ajax(url, options = {}) {

    const xhr = new XMLHttpRequest();

    options.method = options.method || 'GET';

    xhr.open(options.method, url);

    if (options.headers) {

        Object.keys(options.headers).forEach(header => {

            xhr.setRequestHeader(header, options.headers[header]);

        });

    }

    options.headers = options.headers || {};

    if (options.data) {

        if (!options.headers['Content-Type'] || options.headers['Content-Type'].indexOf('json') !== -1) {

            xhr.setRequestHeader('Content-Type', 'application/json');

            options.data = JSON.stringify(options.data);

        }

    }

    options.error = e => {

        if (options.reject) {

            options.reject(e, xhr.status, xhr.statusText);

        }

    };

    xhr.onload = () => {

        if (xhr.status === 200) {

            let data;

            if (!options.responseType) {

                try {

                    data = JSON.parse(xhr.responseText);

                } catch (err) {

                    data = xhr.responseText;

                }

            } else {

                data = xhr.response;

            }

            if (data.errorMessage) {

                options.error();

            } else if (options.resolve) {

                options.resolve(data);

            }

        } else {

            options.error();

        }

    };

    if (options.onprogress) {

        xhr.upload.onprogress = e => {

            options.onprogress(parseInt(e.loaded / e.total * 100));

        };

    }

    if (options.responseType) {

        xhr.responseType = options.responseType;

    }

    xhr.onerror = options.error;

    xhr.send(options.data);

    return xhr;

}

export default ajax;
