import filterModel from '../filter-model';
import deepCloneObject from 'util/data/deep-clone-object';

const MIXED_RADIO_OPTIONS = -1;

export const combinedTaggedRadio = (assetTypeIds = filterModel.opts.assetTypeIds, controlName = filterModel.opts.controlType.name) => {
    let anOptionInUse = null;
    let filterCount = 0;
    for (let i = 0; i < assetTypeIds.length; i++) {
        const id = assetTypeIds[i];
        if (filterModel.filterStates[id] && filterModel.filterStates[id][controlName]) {
            filterCount++;
            if (anOptionInUse === null) {
                anOptionInUse = filterModel.filterStates[id][controlName].selectedRadioIndex;
            } else if (anOptionInUse !== filterModel.filterStates[id][controlName].selectedRadioIndex) {
                return MIXED_RADIO_OPTIONS; // found a conflicting pair, no need to check the rest
            }
        }
    }
    if (filterCount < assetTypeIds.length && anOptionInUse > 0) {
        return MIXED_RADIO_OPTIONS; // found at least one filter is on and one is not (show as mixed)
    }
    return anOptionInUse || 0;
};

export const combinedPlacesFilterType = (filterState, sharedColumn) => {
    const assetTypeIds = sharedColumn.assetTypeIds;
    const {name} = sharedColumn.controlType;
    filterState = deepCloneObject(filterState);
    if (filterState.selectedRadioIndex !== 2) {
        filterState.selectedItems = [];
        return filterState;
    }

    const placeIds = {};
    let max = 0;
    for (let i = 0; i < assetTypeIds.length; ++i) {
        const id = assetTypeIds[i];
        if (filterModel.isFilterInUse(id, name)) {
            max++;
            const otherFilterState = filterModel.filterStates[id][name];
            if (otherFilterState.selectedRadioIndex === 2) {
                for (let j = 0; j < otherFilterState.selectedItems.length; j++) {
                    const placeId = otherFilterState.selectedItems[j];
                    placeIds[placeId] = placeIds[placeId] === undefined ? 1 : placeIds[placeId] + 1;
                }
            }
        }
    }

    filterState.selectedItems = [];
    Object.keys(placeIds).forEach((placeId) => {
        const count = placeIds[placeId];
        if (count === max) {
            filterState.selectedItems.push(placeId);
        }
    });
    return filterState;
};

export const combineFilterSelections = (filterState, sharedColumn) => {
    const assetTypeIds = sharedColumn.assetTypeIds;
    const {name} = sharedColumn.controlType;
    let filterCount = 0;

    // Get all in use filters for this tag
    for (let i = 0; i < assetTypeIds.length; ++i) {
        const id = assetTypeIds[i];
        if (filterModel.isFilterInUse(id, name)) {
            filterCount++;
            const otherFilterState = filterModel.filterStates[id][name];
            if (i === 0) {
                filterState = deepCloneObject(otherFilterState);
            } else {
                setToMergedBooleanValues(filterState.checkedOptions, otherFilterState.checkedOptions);
                filterState.isEmptyChecked = mergedBoolean(filterState.isEmptyChecked, otherFilterState.isEmptyChecked);
            }
        }
    }

    // Only one filter is applied and others are not (so show any applied (ie, 'false') filters as 'mixed')
    if (filterCount === 1 && filterCount <= assetTypeIds.length) {
        Object.keys(filterState.checkedOptions)
            .forEach(key => {
                const val = filterState.checkedOptions[key];
                filterState.checkedOptions[key] = val === false ? 'mixed' : val;
            });
        const isEmptyVal = filterState.isEmptyChecked;
        filterState.isEmptyChecked = isEmptyVal === false ? 'mixed' : isEmptyVal;
    }
    return filterState;
};


const setToMergedBooleanValues = (checkedOptionsA, checkedOptionsB) => {
    let numMixed = 0;
    Object.keys(checkedOptionsA).forEach(key => {
        checkedOptionsA[key] = mergedBoolean(checkedOptionsA[key], checkedOptionsB[key]);
        if (checkedOptionsA[key] === 'mixed') {
            numMixed++;
        }
    });
    return numMixed;
};

// Given two boolean values, returns the value of one if they are the same, if not, returns the string "mixed"
const mergedBoolean = (optionA, optionB) => {
    return optionA !== optionB ? 'mixed' : optionA;
};
