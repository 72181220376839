import constants from 'util/data/constants';
import loadExternal from 'legacy/util/data/load-external';
import userModel from 'models/user-model';
import ajax from 'legacy/util/api/ajax';
import router from 'uav-router';

/**
 * postHubspotUser: This is a post api call for form constants.hubspot.formGuid.
 *
 * @param hubspotData
 */
const postHubspotUser = (hubspotData) => {
    const portalId = constants.hubspotPortalId;
    const formGuid = constants.hubspotFormId;
    const url = 'https://api.hsforms.com/submissions/v3/integration/submit/' + portalId + '/' + formGuid;
    ajax(url, {method: 'POST', data: hubspotData});
};

/**
 * load: This functions loads in all of the hubspot utils.
 *      it also sets a few cookies for tracking.
 *
 * @param onReady: callback once libs are loaded.
 * @returns {Promise | Promise}
 */
const load = () => {
    if (window.HubSpotConversations) {
        return Promise.resolve();
    }
    return new Promise(resolve => {
        window.hsConversationsSettings = {
            loadImmediately: false
        };
        window.hsConversationsOnReady = [resolve];
        loadExternal.loadExternal(`https://js.hs-scripts.com/${constants.hubspotPortalId}.js`);
    });
};

/**
 * Calls the identify hubspot api call. Track page view is a hubspot work around.
 * Identify will only be called after a page view.
 */
const trackUser = () => {
    const _hsq = window._hsq = window._hsq || [];
    // Attempts to identify the user.
    _hsq.push(['setPath', window.location.pathname]);
    _hsq.push(['identify', {
        email: userModel.emailAddress
    }]);
    // Tracks a page view.
    _hsq.push(['trackPageView']);
};

/**
 * Used to call identify on a user's email.
 * This will not replace the cookie that is already set by Hubspot.
 */
const loadAndTrack = () => {
    if (userModel && userModel.emailAddress && router.params.view !== 'pdf') {
        load().then(trackUser);
    }
};

/**
 * loadHubspotChat: loads the chat widget and opens it.
 */
const loadHubspotChat = () => {
    if (!window.HubSpotConversations) {
        load().then(() => {
            if (userModel && userModel.emailAddress) {
                trackUser();
            }
            window.HubSpotConversations.widget.load();
            window.HubSpotConversations.widget.refresh();
            window.HubSpotConversations.widget.open();
        });
        return;
    }
    if (window.HubSpotConversations.widget.status().loaded) {
        window.HubSpotConversations.widget.open();
    } else {
        window.HubSpotConversations.widget.load();
        window.HubSpotConversations.widget.open();
    }
};

export default {postHubspotUser, loadHubspotChat, load, loadAndTrack, trackUser};
