import stakeableModel from 'models/stakeable-model';
import planModel from 'models/plan-model';
import {ClearButton, StakeableTitle, UndoButton} from 'views/stakeable/header-components';
import CloseButton from 'views/close-button';

// Button to crop the asset being edited
const CropButton = {
    view: () => {
        const doneLoading = !stakeableModel.state.isLoading;
        return <button class={`btn btn-crop btn-circle ${!doneLoading ? ' btn-inactive' : ''}`} type='submit' onclick={doneLoading && (() => planModel.goToCropStep())}>
            <i class='icon-crop'></ i>
        </button>;
    }
};

// Continue to preview changes in staking flow
const NextButton = {
    view: () => {
        const minStakesReached = stakeableModel.state.minStakesReached;
        return <button class={`btn btn-next btn-primary btn-pill ${!minStakesReached ? ' btn-inactive' : ''}`} type='submit' onclick={minStakesReached && (() => planModel.nextFromStaking())}>
            <span class='btn-label'>Next</span>
        </button>;
    }
};

class PlanHeader {
    view() {
        return <div class='plan-header'>
            <div class='stakeable-menu-controls controls-left'>
                <CloseButton onclick={() => stakeableModel.close()} />
            </div>
            <div class='stakeable-menu-controls controls-center'>
                <StakeableTitle />
            </div>
            <div class='stakeable-menu-controls controls-right'>
                <CropButton />
                <UndoButton />
                <ClearButton />
                <NextButton />
            </div>

        </div>;

    }

}

export default PlanHeader;
