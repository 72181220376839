import Draw from './draw';
import nearestPoint from '@turf/nearest-point-on-line';
import mapboxgl from 'mapbox-gl';

class LineString extends Draw {

    constructor(opts) {

        super(opts);

        this.addVertex = this._addVertex.bind(this);
        this.onMouseMove = this._onMouseMove.bind(this);
        this.checkLineClicked = this._checkLineClicked.bind(this);

        this.lastVertexOffset = 1;

        this.minVertexCount = 2;

        this.type = 'LineString';

    }

    // Accessing coordinates through this function makes it easy to
    // re-use this class for polygons.
    getCoordinates() {

        return this.feature.geometry.coordinates;

    }

    updateTally() {

        const coordinates = this.getCoordinates();

        if (coordinates.length > 1) {

            const displayLength = this.length(coordinates);

            if (this.tally) {

                this.tally.setLngLat(coordinates[coordinates.length - this.lastVertexOffset])
                    .setHTML(displayLength);
                
            } else {

                this.tally = new mapboxgl.Popup({
                    closeOnClick: false,
                    closeButton: false,
                    className: 'tally',
                    offset: [0, -10]
                }).setLngLat(coordinates[coordinates.length - this.lastVertexOffset])
                    .setText(displayLength)
                    .addTo(this.map)
                    .on('close', () => {

                        this.tally = null;

                    });

            }

        }

    }

    render() {

        if (this.getCoordinates().length > 1) {

            this.source.setData(this.source._data);

            if (this.isNewFeature) {

                this.updateTally();

            }

            this.removePopup();

        }

    }

    syncVertexCoordinates(vertex) {

        this.getCoordinates()[vertex.index] = vertex.getLngLat().toArray();

    }

    updateFeatureOnVertexDrag(vertex) {

        if (!this.isNewFeature) {

            vertex.on('drag', e => {

                this.syncVertexCoordinates(vertex);

                if (this.isNewFeature) {

                    this.onMouseMove(e);

                } else {

                    this.render();

                }

                this.justDragged = true;

            }).on('dragend', () => {
                setTimeout(() => {
                    this.justDragged = false;
                }, 250);
            });

        }

    }

    _onMouseMove(e) {

        const coordinates = this.getCoordinates();

        coordinates[coordinates.length - this.lastVertexOffset] = (e.lngLat || e.target._lngLat).toArray();

        this.render();

    }

    lastVertexClick(e) {

        if (e) {

            e.stopPropagation();

        }

        const coordinates = this.getCoordinates();

        // Remove line segment that follows the mouse cursor
        if (coordinates.length > this.vertices.length) {

            coordinates.pop();

        }

        this.render();

        this.stop();

        if (this.onComplete) {

            this.onComplete(this.feature);

        }

    }

    _addVertex(e, index) {

        const lngLat = e.lngLat.toArray(),
            vertex = this.makeVertex(lngLat, index)
                .on('dragstart', () => this.removePopup())
                .on('dragend', () => {

                    if (this.onVertexChanged) {

                        this.onVertexChanged(this.feature);

                    }

                });

        this.updateFeatureOnVertexDrag(vertex);

        this.getCoordinates().push(lngLat);

        this.vertices.slice(1).forEach(v => {

            v._element.onclick = null;

            v._element.classList.remove('click-to-close');

        });

        if (vertex.index) {

            vertex._element.classList.add('click-to-close');

            vertex._element.onclick = this.lastVertexClick.bind(this);

        } else {

            this.getCoordinates().push(lngLat);

            this.source._data.features.push(this.feature);

            this.map.on('mousemove', this.onMouseMove);

        }

        if (this.onVertexAdded) {

            this.onVertexAdded(this.feature);

        }

    }

    newFeature() {

        const id = this.randomId();

        this.feature = {
            type: 'Feature',
            id,
            geometry: {
                type: 'LineString',
                coordinates: []
            },
            properties: Object.assign(this.properties, {_id: id})
        };

        this.map.on('click', this.addVertex);

        return this;

    }

    showPopup(point, button) {

        this.popup = new mapboxgl.Popup({
            closeOnClick: true,
            closeButton: false,
            className: 'draw-btn',
            anchor: 'bottom',
            offset: [0, 0]
        }).setLngLat(point)
            .setDOMContent(button)
            .addTo(this.map)
            .on('close', () => {

                this.popup = null;

            });

    }

    _checkLineClicked(e) {

        if (this.justDragged) {
            return;
        }

        const bbox = [[e.point.x - 3, e.point.y - 3], [e.point.x + 3, e.point.y + 3]],
            clickedFeature = this.map.queryRenderedFeatures(bbox, {
                filter: ['==', '_id', this.feature.id],
                validate: false
            })[0];

        if (clickedFeature) {

            const point = nearestPoint(
                {
                    type: 'LineString',
                    coordinates: this.getCoordinates(true)
                },
                e.lngLat.toArray()
            );

            const button = document.createElement('i');

            button.classList.add('icon-plus');

            button.onclick = () => {

                this.getCoordinates(true).splice(
                    point.properties.index + 1,
                    0,
                    point.geometry.coordinates
                );

                this.removeVertices();

                this.editFeature();

                this.removePopup();

            };

            this.showPopup(point.geometry.coordinates, button);

        }

    }

    removeVertex(vertex) {

        this.getCoordinates().splice(vertex.index, 1);

    }

    onVertexClick(vertex) {

        return e => {

            e.stopPropagation();

            if (this.popup) {

                return this.removePopup();

            }

            if (this.vertices.length <= this.minVertexCount) {

                return;

            }

            const button = document.createElement('i');

            button.classList.add('icon-trash');

            button.onclick = () => {

                this.removeVertex(vertex);

                this.render();

                this.removeVertices();

                this.edit(this.feature);

                if (this.onVertexChanged) {

                    this.onVertexChanged(this.feature);

                }

            };

            const container = this.map.getContainer();

            this.showPopup(
                this.map.unproject({
                    x: e.clientX - container.offsetLeft,
                    y: e.clientY - container.offsetTop
                }),
                button
            );

        };

    }

    editFeature() {

        const coordinates = this.getCoordinates();

        coordinates.forEach(lngLat => {

            const vertex = this.makeVertex(lngLat)
                .on('dragstart', () => this.removePopup())
                .on('dragend', () => {

                    if (this.onVertexChanged) {

                        this.onVertexChanged(this.feature);

                    }

                });

            this.updateFeatureOnVertexDrag(vertex);

            vertex._element.onclick = this.onVertexClick(vertex);

        });

        this.map.on('click', this.checkLineClicked);

        return this;

    }

    removeTally() {

        if (this.tally) {

            this.tally.remove();

        }

    }

    removePopup() {

        if (this.popup) {

            this.popup.remove();

        }

    }

    removeEventListeners() {

        this.map.off('click', this.addVertex);
        this.map.off('mousemove', this.onMouseMove);
        this.map.off('click', this.checkLineClicked);

        this.removeTally();
        this.removePopup();

    }

    completeFeature() {
        if (this.isNewFeature && this.getCoordinates().length > 2) {
            this.lastVertexClick();
        }
    }

}

export default LineString;
