import tableModel from 'models/table/table-model';

export default {
    view: () =>
        <div class="layout-control">
            <i class="icon-layout-table-bottom" onclick={() => tableModel.setMode('table-bottom', 2000)}/>
            <i class="icon-layout-table-full" onclick={() => tableModel.setMode('table-full', 2000)}/>
            <i class="icon-layout-list-left" onclick={() => tableModel.setMode('list-left', 2000)}/>
        </div>
};
