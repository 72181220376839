import store from 'util/data/store';
import modalModel from 'models/modal-model';
import accountModel from 'models/account-model';
import AccountSelector from 'views/side-nav/account-menu/account-selector';

const accountMenu = {
    oninit: ({state}) => {
        state.showAccounts = false;
        accountModel.updateActiveMenuItem();
    },

    closeModal: (e) => {
        e.stopPropagation();
        accountModel.menu.resetActive();
        modalModel.close();
    },

    toggleAccountList: (e, state) => {
        e.stopPropagation();
        state.showAccounts = !state.showAccounts;
    },

    view: ({state}) => {
        return <div class="account-menu side-nav-popup">
            <div class="padding-wrap">
                <div class="side-nav-popup-subtitle">Unearth's OnePlace<span class="unearth-trademark">TM</span> for</div>
                <div class="side-nav-popup-title">{store.account.name}</div>
                <ul class="side-nav-popup-links">
                    {accountModel.menu && accountModel.menu.items.map((item, index) =>
                        <li key={index} class={`side-nav-popup-item ${item.itemId}`}>
                            <a class={`${accountModel.menu.activeItemId === item.itemId ? 'active' : 'inactive'}`} onclick={(e) => accountModel.menu.selectItem(e, index)}>{item.text}</a></li> )}
                </ul>
            </div>
            <div class="account-selector-wrap">
                <div onclick={(e) => state.toggleAccountList(e, state)} class={`account-selector select ${state.showAccounts ? 'open' : 'closed'}`}>
                    <div class="padding-wrap"><div class="select-title account-option">Switch Account <i class="spinner"/></div></div>
                </div>
                {state.showAccounts && <AccountSelector /> }
            </div>
        </div>;
    }

};

export default accountMenu;
