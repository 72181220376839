import store from 'util/data/store';
import router from 'uav-router';
import rpc from 'legacy/util/api/rpc';
import placeModel from 'models/place-model';
import filterModel from 'models/table/filter-model';
import initializer from 'util/initializer';
import peopleModel from 'models/people/people-model';
import toolboxModel from 'models/toolbox-model';
import siteModel from 'models/site-model';
import capitalize from 'util/data/capitalize';
import tableModel from './table-model';
import debounce from 'util/events/debounce';

/**
 * CommonFilterModel: For all common filters.
 */
const CUSTOM_DATE_RANGE_INDEX = 5;

// View Keys
const DATE_VIEW_KEY = 'dateCreatedFilter';
const LAST_UPDATED_VIEW_KEY = 'lastUpdatedFilter';
const NAME_VIEW_KEY = 'nameFilter';
const CATEGORY_VIEW_KEY = 'categoryFilter';
const ASSET_TYPE_VIEW_KEY = 'assetTypeFilter';
const ADDED_BY_VIEW_KEY = 'addedByFilter';
const PLACES_AND_LEVELS_VIEW_KEY = 'placesAndLevelsFilter';
const UNEARTH_ID_KEY = 'unearthIdFilter';

// Field Names
const NAME = 'name';
const CATEGORY = 'category';
const DATE = 'createdDateTime';
const UPDATED = 'captureDateTime';
const TYPE = 'contentType';
const ADDED_BY = 'addedBy';
const PLACES = 'location';
const UNEARTH_ID = 'unearthId';

class CommonFilterModel {

    constructor() {
        this.setNameQueryString = debounce(this._setNameQueryString.bind(this), 400);
    }

    cleanup() {
        this.toolGroupAssetTypes = {};
        this.checkedAssetTypes = {};
        this.assetTypeCounts = {};
        this.checkedUsers = {};
        this.checkedToolGroups = {};
        this.checkedPlaces = {};
        this.checkedLevels = {};
        this.users = [];
        this.daysByIndex = [undefined, 1, 7, 14, 30];
        this.addedDateRange = {};
        this.updatedDateRange = {};
        this.selectedPlacesLevelsIndex = 0;
        this.selectedDateRangeIndex = 0;
        this.updatedDateRangeIndex = 0;
        this.nameQueryString = '';
        delete this.assetIdMatch;
        this.sortDisabled = {
            [NAME]: true,
            [CATEGORY]: true,
            [ADDED_BY]: true,
            [PLACES]: true
        };
        this.displayAs = {
            [NAME]: 'Name',
            [CATEGORY]: 'Category',
            [DATE]: 'Added Date/Time',
            'addedDateTime': 'Added Date/Time',
            'lastUpdated': 'Last Updated',
            [UPDATED]: 'Last Updated',
            [TYPE]: 'Type',
            [ADDED_BY]: 'Added By',
            [PLACES]: 'Location',
            'places': 'Location',
            [UNEARTH_ID]: 'Unearth ID'
        };
        this.isFilteredFn = {
            [NAME]: () => !!commonFilterModel.nameQueryString,
            [CATEGORY]: () => !commonFilterModel.areAllAssetCategoriesChecked().isAllSelected,
            [DATE]: () => commonFilterModel.updatedDateRangeIndex !== 0,
            [UPDATED]: () => commonFilterModel.updatedDateRangeIndex !== 0,
            [TYPE]: () => !commonFilterModel.areAllAssetTypesChecked().isAllSelected,
            [ADDED_BY]: () => !commonFilterModel.areAllUserIdsChecked().isAllSelected,
            [PLACES]: () => commonFilterModel.selectedPlacesLevelsIndex !== 0,
            'places': () => commonFilterModel.selectedPlacesLevelsIndex !== 0,
            [UNEARTH_ID]: () => commonFilterModel.assetIdMatch && commonFilterModel.assetIdMatch.length > 0
        };
        this.nameToViewKey = {
            [NAME]: NAME_VIEW_KEY,
            [CATEGORY]: CATEGORY_VIEW_KEY,
            [DATE]: DATE_VIEW_KEY,
            [UPDATED]: LAST_UPDATED_VIEW_KEY,
            [TYPE]: ASSET_TYPE_VIEW_KEY,
            [ADDED_BY]: ADDED_BY_VIEW_KEY,
            [PLACES]: PLACES_AND_LEVELS_VIEW_KEY,
            [UNEARTH_ID]: UNEARTH_ID_KEY
        };
        this._nameControlFilters = null;
    }

    isSortDisabled(fieldName) {
        return this.sortDisabled[fieldName] || false;
    }

    getState() {
        const selectedPlacesLevelsIndex = this.selectedPlacesLevelsIndex;
        return {
            nameQueryString: this.nameQueryString,
            selectedPlacesLevelsIndex: selectedPlacesLevelsIndex === 4 ? undefined : selectedPlacesLevelsIndex,
            checkedUsers: this.checkedUsers,
            checkedToolGroups: this.checkedToolGroups,
            checkedPlaces: this.checkedPlaces,
            checkedLevels: this.checkedLevels,
            checkedAssetTypes: this.checkedAssetTypes,
            selectedDateRangeIndex: this.selectedDateRangeIndex,
            updatedDateRangeIndex: this.updatedDateRangeIndex,
            addedDateRange: Object.assign({}, this.addedDateRange),
            updatedDateRange: Object.assign({}, this.updatedDateRange),
            assetIdMatch: this.assetIdMatch
        };
    }

    resetAllFilters() {
        this.cleanup();
        return this.init().then(() => this.initializeDefaults());
    }

    setState(stateObject) {
        Object.assign(this, stateObject);
    }

    initializeDefaults() {
        this.switchAllPlacesAndLevels(true, true);
        this.switchAllAssetTypes(true, true);
    }

    init() {
        return Promise.all([this.checkExistingUsers(true), this.fetchExistingTools()]);
    }

    isFiltered(fieldName) {
        const isFilteredFn = this.isFilteredFn[fieldName];
        if (isFilteredFn) {
            return isFilteredFn();
        }
        return false;
    }

    isSorted(fieldName) {
        return filterModel.isCommonColumnSorted(this.nameToViewKey[fieldName]);
    }

    displayName(fieldName) {
        return this.displayAs[fieldName] || capitalize(fieldName);
    }

    fetchExistingTools() {
        return rpc.request([['countContent', {
            projectId: router.params.projectId,
            siteId: router.params.siteId,
            isVisible: true,
            groupBy: 'assetTypeId'
        }]]).then((results) => {
            this.assetTypeCounts = results;
            m.redraw();
        });
    }

    checkExistingUsers(isChecked) {
        return rpc.request([['countContent', {
            projectId: router.params.projectId,
            siteId: router.params.siteId,
            isVisible: true,
            groupBy: 'authorId'
        }]]).then((resultCounts) => {
            const userIds = Object.keys(resultCounts);
            this.users = {};
            for (let i = 0; i < userIds.length; i++) {
                if (resultCounts[userIds[i]] > 0) {
                    const user = peopleModel.getPerson(userIds[i]);
                    if (user) {
                        this.users[user.userId] = {
                            name: peopleModel.displayName(user),
                            value: user.userId
                        };
                        if (isChecked !== undefined) {
                            this.checkedUsers[user.userId] = isChecked;
                        }
                    } else {
                        const userId = userIds[i];
                        this.users[userId] = {
                            name: 'Unknown User',
                            value: userId
                        };
                        if (isChecked !== undefined) {
                            this.checkedUsers[userId] = isChecked;
                        }
                    }
                }
            }
        }).then(m.redraw);
    }

    onNewContent(assetId) {
        const asset = store.assets[assetId];
        const authorId = asset.authorId;
        this.addNewUser(authorId);
        if (this.assetTypeCounts[asset.assetTypeId]) {
            this.assetTypeCounts[asset.assetTypeId] = this.assetTypeCounts[asset.assetTypeId] + 1;
            return;
        }
        this.showNewAsset(asset.assetTypeId, authorId);
    }

    onDeletedContent(assetId) {
        const assetTypeId = store.assets[assetId].assetTypeId;
        if (this.assetTypeCounts[assetTypeId]) {
            this.assetTypeCounts[assetTypeId] = this.assetTypeCounts[assetTypeId] - 1;
            if (this.assetTypeCounts[assetTypeId] === 0) {
                delete this.assetTypeCounts[assetTypeId];
            }
        }
    }

    get toolGroups() {
        return Object.values(store.toolGroups);
    }

    getToolGroupAssetTypes(toolGroupId) {
        return store.toolGroups[toolGroupId].tools.map((tool) => tool.assetForm.assetType).filter((assetType) =>
            this.assetTypeCounts[assetType.assetTypeId] && this.assetTypeCounts[assetType.assetTypeId] > 0
        );
    }

    getCommonPropertyCount() {
        let i = 5;
        if (toolboxModel.hasMultipleGroups) {
            ++i;
        }
        if (!siteModel.isMetaProject) {
            ++i;
        }
        i += tableModel.getSharedColumnCount();

        return i;
    }

    areAllAssetTypesChecked() {
        let isAllSelected = true;
        let isAllDeselected = true;
        for (const toolGroup of this.toolGroups) {
            for (const assetType of this.getToolGroupAssetTypes(toolGroup.toolGroupId)) {
                if (this.checkedAssetTypes[assetType.assetTypeId]) {
                    isAllDeselected = false;
                } else {
                    isAllSelected = false;
                }
            }
        }
        return {isAllSelected, isAllDeselected};
    }

    areAllAssetCategoriesChecked() {
        let isAllSelected = true;
        let isAllDeselected = true;
        for (const checked of Object.values(this.checkedToolGroups)) {
            if (checked) {
                isAllDeselected = false;
            } else {
                isAllSelected = false;
            }
        }
        return {isAllSelected, isAllDeselected};
    }

    areAllUserIdsChecked() {
        let isAllSelected = true;
        let isAllDeselected = true;
        for (const userId of Object.keys(this.checkedUsers)) {
            if (this.checkedUsers[userId]) {
                isAllDeselected = false;
            } else {
                isAllSelected = false;
            }
        }
        return {isAllSelected, isAllDeselected};
    }

    areAllPlacesAndLevelsChecked() {
        let isAllSelected = true;
        let isAllDeselected = true;
        const places = placeModel.allPlaces;
        for (const place of places) {
            if (this.checkedPlaces[place.placeId]) {
                isAllDeselected = false;
            } else {
                isAllSelected = false;
            }
            const levels = place.levels.items || [];
            for (const level of levels.items || []) {
                if (this.checkedLevels[level.levelId]) {
                    isAllDeselected = false;
                } else {
                    isAllSelected = false;
                }
            }
        }
        return {isAllSelected, isAllDeselected};
    }

    _setNameQueryString(query) {
        this.nameQueryString = query;
        filterModel.filtersChanged();
    }

    /**
     Creates an array of filters checking that control against the value passed. 
     Returns the array of filters, which can then be used within a listContent request.
     
     example: 
        generateNameFiltersForQuery({in: [null, '']})
     returned: 
        [{properties.Name: {in: [null, '']}, assetTypeId: '29348324'}, 
         {properties.Address: {in: [null, '']}, assetTypeId: '988745465'}, ...]
    */
    generateNameFiltersForQuery(filterValue) {
        const filters = [];
        const nameControlFilters = this.getNameControlFilters();
        Object.keys(nameControlFilters).forEach(nameControlLabel => {
            const nameFilterKey = `properties.${nameControlLabel}`;
            filters.push({
                [nameFilterKey]: filterValue,
                assetTypeIdIn: nameControlFilters[nameControlLabel]
            });
        });
        return filters;
    }

    /**
     Return a mapping of all nameControls to an array of asset types that share it.
     Cache it on the model for reuse within this session.
    */
    getNameControlFilters() {
        if (!this._nameControlFilters) {
            this._nameControlFilters = {};
            const assetTypes = Object.values(store.assetTypes);
            assetTypes.forEach(assetType => {
                const nameControlLabel = store.assetTypeIdToNameControl[assetType.assetTypeId];
                if (nameControlLabel) {
                    if (this._nameControlFilters[nameControlLabel]) {
                        this._nameControlFilters[nameControlLabel].push(assetType.assetTypeId);
                    } else {
                        this._nameControlFilters[nameControlLabel] = [assetType.assetTypeId];
                    }
                }
            });
        }
        return this._nameControlFilters;
    }

    setNameQueryFilters(filters) {
        if (!this.nameQueryString) {
            return;
        }

        const query = this.nameQueryString.toLowerCase();
        const longEnoughForStringMatch = query.length >= 2; // Ignore 1 letter queries.

        // Loop through all asset types checking for two routes to match:
        // 1) Via name control label value (map out all name controls per asset type against query)
        const assetNameFilters = this.generateNameFiltersForQuery({ilike: `%${query}%`});
        // 2) Via asset type name that matches, and no custom name value provided
        const assetTypeNameFilters = [];

        if (longEnoughForStringMatch) {
            const assetTypes = Object.values(store.assetTypes);
            assetTypes.forEach(assetType => {
                if (assetType.name.toLowerCase().includes(query)) {
                    const nameControlLabel = store.assetTypeIdToNameControl[assetType.assetTypeId];
                    if (nameControlLabel) {
                        // Only include asset type name matches if the asset's name control is empty:
                        assetTypeNameFilters.push({
                            [`properties.${nameControlLabel}`]: {eq: null},
                            assetTypeId: assetType.assetTypeId
                        }, {
                            [`properties.${nameControlLabel}`]: {eq: ''},
                            assetTypeId: assetType.assetTypeId
                        });
                    } else {
                        // And include if there is no name control for the asset type
                        assetTypeNameFilters.push({assetTypeId: assetType.assetTypeId});
                    }
                }
            });
        }
        filters.push(...assetNameFilters, ...assetTypeNameFilters);
    }

    toggleCheckedUsers(userId) {
        this.checkedUsers[userId] = !this.checkedUsers[userId];
        filterModel.filtersChanged();
    }

    toggleCheckedToolGroups(toolGroupId) {
        this.checkedToolGroups[toolGroupId] = !this.checkedToolGroups[toolGroupId];
        const assetTypes = commonFilterModel.getToolGroupAssetTypes(toolGroupId);
        assetTypes.forEach(assetType => {
            this.checkedAssetTypes[assetType.assetTypeId] = this.checkedToolGroups[toolGroupId];
        });
        filterModel.filtersChanged();
    }

    toggleAssetType(assetTypeId, skipFetch) {
        this.checkedAssetTypes[assetTypeId] = !this.checkedAssetTypes[assetTypeId];
        const toolGroupId = store.assetTypeIdToToolGroupId[assetTypeId];
        if (this.checkedAssetTypes[assetTypeId]) {
            this.checkedToolGroups[toolGroupId] = true;
        } else {
            let areAnyChecked = false;
            const assetTypes = commonFilterModel.getToolGroupAssetTypes(toolGroupId);
            for (const assetType of assetTypes) {
                if (this.checkedAssetTypes[assetType.assetTypeId]) {
                    areAnyChecked = true;
                    break;
                }
            }
            if (!areAnyChecked) {
                this.checkedToolGroups[toolGroupId] = false;
            }
        }
        if (skipFetch) {
            return;
        }
        filterModel.filtersChanged();
    }

    turnOffAllAddedByExcept(authorId) {
        this.switchAllAddedBy(false, true);
        this.checkedUsers[authorId] = true;
        filterModel.filtersChanged();
    }

    turnOffAllToolGroupsExcept(toolGroupId) {
        this.switchAllAssetTypes(false, true);
        this.toggleCheckedToolGroups(toolGroupId);
    }

    turnOffAllAssetTypesExcept(assetTypeId) {
        this.switchAllAssetTypes(false, true);
        this.toggleAssetType(assetTypeId);
    }

    turnOffAllPlacesExcept(placeId) {
        this.switchAllPlacesAndLevels(false, true);
        this.checkedPlaces[placeId] = true;
        filterModel.filtersChanged();
    }

    turnOffAllLevelsExcept(levelId) {
        this.switchAllPlacesAndLevels(false, true);
        this.checkedLevels[levelId] = true;
        filterModel.filtersChanged();
    }

    togglePlace(placeId) {
        this.checkedPlaces[placeId] = !this.checkedPlaces[placeId];
        filterModel.filtersChanged();
    }

    toggleLevel(levelId) {
        this.checkedLevels[levelId] = !this.checkedLevels[levelId];
        filterModel.filtersChanged();
    }

    switchAllAssetTypes(isOn, skipFetch = false) {
        this.toolGroups.forEach(({toolGroupId}) => {
            this.getToolGroupAssetTypes(toolGroupId).forEach(({assetTypeId}) => {
                this.checkedAssetTypes[assetTypeId] = isOn;
            });
            this.checkedToolGroups[toolGroupId] = isOn;
        });
        if (skipFetch) {
            return;
        }
        filterModel.filtersChanged();
    }

    switchAllAddedBy(isOn, skipFetch = false) {
        Object.keys(this.users).forEach((userId) => {
            this.checkedUsers[userId] = isOn;
        });
        if (skipFetch) {
            return;
        }
        filterModel.filtersChanged();
    }

    switchAllPlacesAndLevels(isOn, skipFetch = false) {
        const places = placeModel.allPlaces;
        for (const place of places) {
            this.checkedPlaces[place.placeId] = isOn;
            const levels = place.levels;
            for (const level of levels.items || []) {
                this.checkedLevels[level.levelId] = isOn;
            }
        }
        if (skipFetch) {
            return;
        }
        filterModel.filtersChanged();
    }

    selectDateRangeRadio(i) {
        if (this.selectedDateRangeIndex === i) {
            return;
        }
        this.selectedDateRangeIndex = i;
        filterModel.filtersChanged();
    }

    selectUpdatedRange(i) {
        if (this.updatedDateRangeIndex === i) {
            return;
        }
        this.updatedDateRangeIndex = i;
        filterModel.filtersChanged();
    }

    rangeUpdated() {
        if (this.selectedDateRangeIndex === CUSTOM_DATE_RANGE_INDEX || this.updatedDateRangeIndex === CUSTOM_DATE_RANGE_INDEX) {
            filterModel.filtersChanged();
        }
    }

    selectPlacesLevelsRadio(i) {
        if (i === 2) {
            this.selectedPlacesLevelsIndex = i;
            filterModel.drawSearchArea();
        } else if (this.selectedPlacesLevelsIndex !== i) {
            this.selectedPlacesLevelsIndex = i;
            filterModel.filtersChanged();
        }
    }

    assetIdInput(value) {
        if (value === '' || value === undefined) {
            delete this.assetIdMatch;
            filterModel.filtersChanged();
            return;
        }
        this.assetIdMatch = value.trim();
        if (this.assetIdMatch.length === 20) {
            filterModel.filtersChanged();
        }
    }

    showNewAsset(assetTypeId, authorId) {
        if (this.assetTypeCounts[assetTypeId] === undefined) {
            this.assetTypeCounts[assetTypeId] = 1;
            this.toggleAssetType(assetTypeId, true);
        }
        if (this.checkedUsers[authorId] === undefined) {
            this.addNewUser(authorId);
        }
    }

    addNewUser(authorId) {
        if (!this.users[authorId]) {
            const user = peopleModel.getPerson(authorId);
            if (user) {
                this.users[authorId] = {
                    name: peopleModel.displayName(user),
                    value: authorId
                };
            }
        }
        this.checkedUsers[authorId] = true;
    }
}

const commonFilterModel = new CommonFilterModel();
initializer.add(() => commonFilterModel.cleanup());

export default commonFilterModel;
