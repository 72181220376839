import message from 'legacy/components/message';
import CropTool from '../util/draw/crop-tool';
import store from '../util/data/store';
import planModel from './plan-model';
import MapModel from './map-model';
import api from '../legacy/util/api/api';
import dialogModel from 'models/dialog-model';
import padBounds from '../util/geo/pad-bounds';
import helpers from 'legacy/util/api/helpers';

const CROP_SOURCE_ID = '_draw_crop_boundary_source';

/**
 * Handles logic and state for plan cropping view.
 * (/views/plan/crop.js
 */
class CropModel {

    constructor() {
        this.map = null;
        this.cropTool = null;
        this.feature = null;
        this.animateClass = '';
        this.state = {};
        this.resetState();
    }

    resetState() {
        const isLoaded = this.state.isLoaded || false;
        this.state = {
            isCroppable: false, // Wait until map style is loaded.
            isResetable: false, // At least 1 vertex to clear
            isUndoable: false, // Polygon shape created (can be processed)
            isLoaded: isLoaded
        };
    }

    init() {
        this.plan = store.plans[planModel.recordId];
        this.map = new MapModel({container: 'mapbox-crop'}, {
            layercontrolOff: true,
            basemapOff: true,
            geolocateOff: true
        });
        this.map.mapLoaded().then(() => {
            this.state.isLoaded = true;
            this.map.setUpEditingLayer(planModel.cropTileset);
            this.setMaxBounds();
            this.map.createGeoJSONSource(CROP_SOURCE_ID);
            this.awaitCropping();
            m.redraw();
        });
    }

    /**
     * Creates a crop tool for drawing on the map.
     */
    awaitCropping() {
        this.cropTool = new CropTool({map: this.map, source: this.map.getSource(CROP_SOURCE_ID)}).create();
    }


    /**
     * Set max bounds on the cropping map to avoid accidentally panning away.
     */
    setMaxBounds() {
        const assetBounds = this.map.getBounds();
        const paddedBounds = padBounds(this.map, assetBounds, 300);
        this.map.setMaxBounds(paddedBounds);
    }


    // ------------------------- Crop Tool Controls --------------------------

    /**
     * Undoes the last step from the crop tool.
     */
    handleUndo() {
        this.cropTool.undoStep();
    }

    /**
     * Clears all vertices made by the crop tool and resets state back to default.
     */
    handleReset() {
        this.cropTool.remove();
        this.resetState();
        m.redraw();
        this.awaitCropping();
    }

    // ------------------------- Saving / Quitting --------------------------

    /**
     * Closes the cropping view and returns to the staking step.
     */
    returnToStakingStep() {
        this.animateClass = 'fade-body-out';
        setTimeout(() => {
            this.animateClass = '';
            planModel.isCropping = false;
            m.redraw();
        }, 300);
    }

    /**
     * If there are unsaved changes, confirms with user and returns to staking step.
     */
    quit() {
        //  Only display close warning if there are unsaved changes (ie., if it's undoable.)
        if (this.state.isUndoable) {
            dialogModel.open({
                headline: 'Quit and discard changes?',
                text: 'Please note that this operation cannot be undone.',
                yesClass: 'btn btn-pill btn-red',
                noText: 'Cancel',
                noClass: 'btn btn-pill btn-secondary',
                onYes: () => {
                    this.returnToStakingStep();
                }
            });
        } else {
            this.returnToStakingStep();
        }
    }

    /**
     * Confirms with user before sending the crop boundary to the API,
     * and returning to the staking step.
     */
    saveCrop() {
        const sectionIds = helpers.list(this.plan.sectionIds);
        const sectionId = sectionIds[0];
        const boundary = this.cropTool.getFinalBoundary();
        dialogModel.open({
            headline: 'Ready to save?',
            text: 'Please note that your changes may not be immediately visible while the layer is being processed.',
            yesText: 'Save',
            yesClass: 'btn btn-pill btn-primary',
            noText: 'Cancel',
            noClass: 'btn btn-pill btn-red',
            onYes: () => {
                message.show('<span>Cropping the page <i class=\'spinner spinning\'></i></span>', 'success', true);
                this.resetState();
                this.cropTool.freeze();
                planModel.awaitCropChanges();
                m.redraw();
                return api.post.crop(sectionId, boundary).then(() => {
                    message.hide();
                    this.returnToStakingStep();
                }).catch((error) => {
                    if (error.payload && error.payload.error === 'BadCropBoundary') {
                        this.handleReset();
                        message.show('Unable to process crop with the boundary provided. Please try again or contact support', 'error');
                    } else {
                        message.show('Something went wrong. Please try again or contact support.', 'error');
                    }
                });
            }
        });
    }


    // ------------------------- Checks for updates needed for view/css --------------------------

    /*
     * Calls for buttons to update, and if any were updated, triggers redraw.
     */
    updateButtonStates() {
        const doneUpdated = this.doneButtonUpdated();
        const undoUpdated = this.undoButtonUpdated();
        const resetUpdated = this.resetButtonUpdated();
        if (doneUpdated || undoUpdated || resetUpdated) {
            m.redraw();
        }
    }

    /**
     * Checks if the crop tool is currently a completed polygon.
     */
    doneButtonUpdated() {
        // If it's disabled and it shouldn't be:
        if (!this.state.isCroppable && this.cropTool.type === 'Polygon') {
            this.state.isCroppable = true;
            return true;
            // If it's enabled and it shouldn't be:
        } else if (this.state.isCroppable && this.cropTool.type !== 'Polygon') {
            this.state.isCroppable = false;
            return true;
        }
        return false;
    }

    /**
     * Checks if there is at least one draw step that can be undone.
     */
    undoButtonUpdated() {
        // If it's disabled and it shouldn't be:
        if (!this.state.isUndoable && this.cropTool.steps.items.length) {
            this.state.isUndoable = true;
            return true;
            // If it's enabled and it shouldn't be:
        } else if (this.state.isUndoable && !this.cropTool.steps.items.length) {
            this.state.isUndoable = false;
            return true;
        }
        return false;
    }

    /**
     * Checks if there is at least one vertex that can be cleared.
     */
    resetButtonUpdated() {
        // If it's disabled and it shouldn't be:
        if (!this.state.isResetable && this.cropTool.vertices.length) {
            this.state.isResetable = true;
            return true;
            // If it's enabled and it shouldn't be:
        } else if (this.state.isResetable && !this.cropTool.vertices.length) {
            this.state.isResetable = false;
            return true;
        }
        return false;
    }

    // ------------------------- Exiting Cleanup --------------------------

    /**
     * Teardown mapbox resources and reset state to defaults.
     */
    onRemove() {
        this.resetState();
        if (this.cropTool) {
            this.cropTool.remove();
        }
        if (this.map) {
            this.map.remove();
        }
    }

}

export default new CropModel();
