import stakeableModel from 'models/stakeable-model';

// Displays the asset title and info popup message
export const StakeableTitle = {
    view: () =>
        <div class="stakeable-title-wrap">
            <span class="stakeable-title-text">{stakeableModel.recordTitle}</span>
            <i onclick={() => stakeableModel.showPopupMessage('how-to-pin-popup', true)} class="icon-help"></i>
        </div>
};

// Undo the last step
export const UndoButton = {
    view: () => {
        const isUndoable = stakeableModel.state.isUndoable;
        return <button type="submit" class={`btn btn-undo btn-circle ${!isUndoable ? ' btn-inactive' : ''}`}
            onclick={isUndoable && (() => stakeableModel.undo())}><i className="icon-undo"></i></button>;
    }
};

// Clear all stakes
export const ClearButton = {
    view: () => {
        const isClearable = stakeableModel.state.isClearable;
        return <button type="submit" class={`btn btn-clear-all btn-pill ${!isClearable ? ' btn-inactive' : ''}`}
            onclick={isClearable && (() => stakeableModel.clearAll())}>Clear</button>;
    }
};

