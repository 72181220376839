import api from 'legacy/util/api';
import throttle from 'util/events/throttle';
import publish from 'legacy/util/api/publish';
import router from 'uav-router';
import assetModel from 'models/asset-model';
import store from 'util/data/store';
import formModel from 'models/form-model';

const limit = 20;

class TabModel {

    _onscroll(e) {

        if (!this.loadedAll) {

            const scrollBox = e.target;

            if (scrollBox && scrollBox.scrollTop && scrollBox.scrollTop + scrollBox.offsetHeight > scrollBox.scrollHeight - 100) {

                this.getNextPage();

            }

        }

    }

    constructor(filter) {

        this.assetId = formModel.assetId;

        this.filter = filter;

        this.assetIds = [];

        this.onscroll = throttle(this._onscroll.bind(this));

        this.getNextPage();

        this.loading = true;

        this.awaitChanges();

    }

    getNextPage(opts = {}) {

        const pageSize = opts.limit || limit;

        api.rpc.list('Content', Object.assign({
            offset: this.assetIds.length,
            limit: pageSize,
            order: 'createdDateTime desc',
            include: ['media'],
            projectId: router.params.projectId
        }, this.filter)).then(assets => {

            const assetIds = assets.forEach(asset => {

                if (asset.contentId !== this.assetId) {
                    assetModel.addToStore(asset);
                    this.assetIds.push(asset.contentId);
                }

                return asset.contentId;

            });

            m.redraw();

            if (assets.length < pageSize) {

                this.loadedAll = true;

            }

            this.loading = false;

            if (this.onResults) {

                this.onResults(assetIds);

            }

        });

    }

    awaitChanges() {
        publish.await({
            changeType: 'new',
            recordType: 'content',
            test: asset => this.isForThisTab(asset),
            callback: asset => {

                assetModel.addToStore(asset);

                this.assetIds.unshift(asset.contentId);

                this.onContentChange('new', asset.contentId);

                m.redraw();

            },
            persist: true
        });

        publish.await({
            changeType: 'modified',
            recordType: 'content',
            test: asset => this.isForThisTab(asset),
            callback: asset => {
                assetModel.addToStore(asset, true);

                const index = this.assetIds.indexOf(asset.contentId);

                if (index === -1) {

                    this.assetIds.unshift(asset.contentId);

                }

                m.redraw();

                this.onContentChange('modified', asset.contentId);

            },
            persist: true
        });

        publish.await({
            changeType: 'deleted',
            recordType: 'content',
            test: asset => this.isForThisTab(asset),
            callback: asset => {
                const index = this.assetIds.indexOf(asset.contentId);

                delete store.assets[asset.contentId];

                if (index !== -1) {

                    this.assetIds.splice(index, 1);

                    m.redraw();

                }

                this.onContentChange('deleted', asset.contentId);
            },
            persist: true
        });

    }

    // If applicable, handle based on subclass method.
    onContentChange() {}

}

export default TabModel;
