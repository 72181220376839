import uav from 'uav';
import validatedField from 'legacy/components/validated-field';
import {wsUrl}  from 'util/data/env';
import auth from 'legacy/components/auth';
import login from 'legacy/components/auth/login';

function forgotPassword(email) {

    function submit(e) {

        if (e) {

            e.preventDefault();

        }

        if (!component.email.validate()) {

            return;

        }

        const socket = new WebSocket(wsUrl);

        socket.addEventListener('open', () => {

            if (socket.readyState === WebSocket.OPEN) {

                socket.send(JSON.stringify({
                    type: 'resetPassword',
                    payload: {
                        userId: component.email.value
                    }
                }));

                component.submitted = true;

            }

        });

    }

    function oninput() {

        component.disabled = !component.email.isValid();

    }

    const component = uav.component(`
    <div class="login">
        <h1>Forgot your password?</h1>
        <form u-class="form new-form {submitted ? 'hidden' : ''}" u-onsubmit="{submit}">
            <p>No problem. Enter your email address and we'll send you instructions to reset your password.</p>
            {email}
            <div class="form-bottom">
                <a u-class="btn btn-primary btn-pill {disabled}" u-onclick="{submit}">Send</a>
                <div class="login-footer">
                    <a class="cancel-btn" u-onclick="{login}">Cancel</a>
                </div>
            </div>
        </form>
        <form u-class="form {submitted ? '' : 'hidden'}" u-onsubmit="{submit}">
            <h3>We've sent reset instructions.</h3>
            <p>Check your email for a link to set your new password.</p>
            <div class="form-bottom no-link">
                <a class="btn btn-primary btn-pill" u-onclick="{login}"><span class="btn-label">Return to Sign In</span></a>
            </div>
        </form>
    </div>`, {
        login: () => {

            auth.component.view = login();

        },
        submitted: false,
        submit,
        disabled: true,
        email: validatedField({
            value: email,
            label: 'Email Address',
            validationType: 'email',
            autofocus: true,
            oninput,
            validateOnBlur: true
        }),
        classname: 'forgot-password'
    }, oninput);

    return component;

}

export default forgotPassword;
