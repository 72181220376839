import mediaViewerModel from 'models/media-viewer-model';
import MediaOptions from 'views/media-options';
import store from 'util/data/store';
import mediaModel from 'models/media-model';
import formModel from 'models/form-model';
import helpers from 'legacy/util/api/helpers';
import formatDate from 'legacy/util/date/format-date';

const ThumbnailItem = {
    oninit: ({attrs: {mediaId}, state}) => {
        const media = store.media[mediaId];
        media
            ? state.thumbnail = mediaModel.getThumbnail(mediaId, media.mimeType, media.label)
            : mediaModel.getMetadata(mediaId)
                .then(data => {
                    state.thumbnail = mediaModel.getThumbnail(mediaId, data.mimeType, data.name);
                }).catch(() => {
                    state.thumbnail = undefined;
                });
    },

    view: ({attrs: {index, showOptions, previewMode}, state}) => {
        const thumbnail = state.thumbnail || {};
        return <div style={thumbnail.src && `background-image:url(${thumbnail.src})`}
            class={'media-thumbnail-single file-gallery-item' + (!previewMode && index === mediaViewerModel.state.index ? ' selected' : '') + (thumbnail.isPhoto ? ' is-photo' : '')}
            key={thumbnail.mediaId + (thumbnail.src ? '1' : '')}
            onclick={() => {
                if (previewMode) {
                    const asset = store.assets[formModel.assetId];
                    const mediaIds = helpers.list(asset.mediaIds);
                    mediaViewerModel.open(mediaIds, mediaIds.indexOf(thumbnail.mediaId));
                } else if (mediaViewerModel.state.index !== index) {
                    mediaViewerModel.selectIndex(index);
                }
            }}>
            {!!showOptions && <MediaOptions mediaId={thumbnail.mediaId} />}
            {!previewMode && <div class="file-gallery-name">{thumbnail.name}</div> }
        </div>;
    }
};

const ThumbnailGallery = {
    view: ({attrs: {mediaIds, showOptions, previewMode}}) => {
        const isSingle = mediaIds.length === 1;
        const selectedMediaId = mediaIds[mediaViewerModel.state.index];
        const media = store.media[selectedMediaId];
        return <><div class={'file-gallery' + (isSingle ? ' single' : '')} onwheel={e => {
            e.currentTarget.scrollLeft += e.deltaY;
            e.redraw = false;
        }}>
            {mediaIds.map((mediaId, i) =>
                <ThumbnailItem mediaId={mediaId} index={i} key={i} showOptions={showOptions} previewMode={previewMode} />
            )}
        </div>
        {media && media.captureDateTime && previewMode &&
            <div class="capture-container">
                Capture Date: {formatDate.ddmmyyyy(media.captureDateTime)}
            </div>
        }
        </>;
    }
};

export default ThumbnailGallery;
