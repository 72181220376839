import message from 'legacy/components/message';
import dialogModel from 'models/dialog-model';
import api from 'legacy/util/api';
import pointMenu from 'legacy/components/point-menu';
import formModel from 'models/form-model';
import popup from 'util/popup';
import constants from 'util/data/constants';
import store from 'util/data/store';
import userModel from 'models/user-model';
import getAssetName from 'util/data/get-asset-name';

function deleteContent(assetId, type = store.assets[assetId].contentType) {

    pointMenu.close();

    if (type === 'beacon') {
        type = 'Task';
    }

    const textParts = [type.toLowerCase()];

    const name = getAssetName(assetId);

    if (name && name !== type) {
        textParts.push(name);
    }

    return new Promise(resolve => {

        dialogModel.open({
            headline: `Delete ${textParts.join(': ')}?`,
            text: 'Please note that this operation cannot be undone.',
            yesText: 'Delete',
            onYes: () => deleteContent.force(assetId, type).then(resolve),
            yesClass: 'btn btn-pill btn-red',
            noText: 'Cancel',
            noClass: 'btn btn-pill btn-secondary'
        });

    });

}

deleteContent.force = (assetId, type = store.assets[assetId].contentType) => new Promise(resolve => {

    const requests = [];

    const asset = store.assets[assetId];

    if (asset.assetTypeId === constants.commentAssetTypeId) {

        requests.push(['deleteContent', {contentId: asset.contentId}]);

    } else {

        requests.push(['deleteThread', {threadId: asset.threadId}]);

        // If this asset represents any projects, delete them too.
        const assetFormId = store.assetTypeToFormId[asset.assetTypeId],
            assetForm = store.assetForms[assetFormId],
            projectControlTypeId = constants.controlTypeNameToId.project;

        assetForm.controls.forEach(control => {
            if (control.controlTypeId === projectControlTypeId) {
                const projectId = asset.properties[control.label];
                if (projectId) {
                    requests.push(['deleteProject', {projectId}]);
                }
            }
        });

    }

    api.rpc.requests(requests);

    formModel.getAssetFeatures(assetId).then(features =>
        api.rpc.requests(features.map(feature => ['deleteFeature', {featureId: feature.id}]))
    );

    if (type === 'beacon') {

        type = 'Task';

    }

    message.show(`${type[0].toUpperCase()}${type.substring(1)} deleted.`);

    popup.remove();

    resolve(true);

});

deleteContent.isAllowed = assetId => {

    const asset = store.assets[assetId];

    if (userModel.isViewOnly) {

        return asset.authorId === userModel.userId && asset.assetTypeId === constants.commentAssetTypeId;

    }

    return asset.authorId === userModel.userId || userModel.isAccountAdmin;
};

export default deleteContent;
