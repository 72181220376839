export default {

    hide: (el, callback) => {

        setTimeout(() => {

            if (el) {

                el.style.display = 'none';

            }

            if (callback) {

                callback();

            }

        }, 200);

    },

    show: (el, callback) => {

        if (el) {

            el.style.display = '';

            if (callback) {

                setTimeout(callback);

            }

        }

    }

};
