import uav from 'uav';
import router from 'uav-router';
import submitButton from 'legacy/components/submit-button';
import socket from 'util/network/socket';
import auth from 'legacy/components/auth';
import forgotPassword from 'legacy/components/auth/forgot-password';
import validatedField from 'legacy/components/validated-field';

let loggedOut;

function login() {

    function submit() {

        component.notice = false;

        const validEmail = component.email.validate();

        const validPassword = component.password.validate();

        if (validEmail && validPassword) {

            component.submit.spinning = true;

            socket.connect({
                username: component.email.value.toLowerCase().trim(),
                password: component.password.value.trim()
            },
            // success
            () => router.load(), 
            // failure
            () => {

                component.notice = {
                    type: 'error',
                    text: 'The information you entered does not match our records. Please try again.'
                };

                component.submit.spinning = false;

            });

        } else {

            component.submit.spinning = false;

        }

    }

    const component = uav.component(`
    <div class="login">
        <h1>Sign In</h1>
        <div u-class="form-msg {notice.type}">{notice.text}</div>
        <form class="form sign-in-form new-form">
            {email}
            {password}
            <div class="form-bottom">
            {submit}
            </div>
        </form>
    </div>`, {
        notice: loggedOut ? {
            type: 'success',
            text: 'You have been signed out.'
        } : false,
        submit: submitButton({
            onclick: submit,
            text: 'Continue'
        }),
        email: validatedField({
            type: 'email',
            label: 'Email Address',
            validationType: 'email',
            autofocus: true,
            validateOnBlur: false
        }),
        password: validatedField({
            type: 'password',
            label: 'Password',
            validationType: 'name',
            prompt: uav.component('<a class="forgot-link" u-onclick={resetPass}>Forgot Password?</a>', {
                resetPass: () => {

                    auth.component.view = forgotPassword(component.email.value);

                }
            }),
            validateOnBlur: false
        }),
        classname: 'sign-in'
    }, () => {

        loggedOut = false;

    });

    return component;

}

login.logout = () => {

    loggedOut = true;

};

export default login;
