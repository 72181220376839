import router from 'uav-router';
import socket from 'util/network/socket';
import helpers from 'legacy/util/api/helpers';
import rpc from 'legacy/util/api/rpc';
import userModel from 'models/user-model';

const api = {

    rpc,

    apiSafeContent: helpers.apiSafeContent,

    apiSafeFeature: helpers.apiSafeFeature,

    // Generic request to search API
    simpleSearch: data => new Promise((resolve, reject) => {
        data.userId = userModel.userId;
        data.platform = 'web';
        data.version = '1.6';

        socket.send('search', {
            data,
            resolve: res => data.searchType === 'search' ? resolve(helpers.list(res.results)) : resolve(res),
            reject
        });
    }),

    // Common search request to API for assets ('content') specifically
    search: data => {
        data.type = 'content';
        data.filter.projectId = router.params.projectId;
        data.filter.siteId = router.params.siteId;

        return api.simpleSearch(data);
    },

    get: {

        thread: threadId => new Promise((resolve, reject) => {

            socket.get(`/threads/${threadId}`, {
                resolve: t => resolve(t || {}),
                reject
            });

        }),

        volumeDiff: (boundary, baseline, comparison) => new Promise((resolve, reject) => {

            if (comparison === 'best-fit' || comparison === 'lowest-point') {

                socket.get(`/surveys/${baseline}/volume`, {
                    resolve,
                    reject,
                    args: {
                        boundary,
                        volumeCalculationType: comparison
                    }
                });

            } else {

                socket.get(`/surveys/${comparison}/volume`, {
                    resolve,
                    reject,
                    args: {
                        boundary,
                        baselineSurveyId: baseline,
                        volumeCalculationType: 'baseline'
                    }
                });

            }

        }),

        plan: planId => new Promise(resolve => {

            socket.get(`/plans/${planId}`, {
                resolve
            });

        }),

        plans: () => new Promise(resolve => {

            socket.get('/plans', {
                resolve: plans => resolve(helpers.list(plans)),
                args: {
                    projectId: router.params.projectId,
                    siteId: router.params.siteId,
                    limit: 0,
                    isVisible: true
                }
            });

        }),

        planSection: planSectionId => new Promise((resolve, reject) => {

            socket.get(`/planSections/${planSectionId}`, {
                resolve,
                reject
            });

        }),

        tileset: tilesetId => new Promise((resolve, reject) => {

            socket.get(`/tilesets/${tilesetId}`, {
                resolve,
                reject
            });

        }),

        users: () => new Promise(resolve => {

            socket.get(`/projects/${router.params.projectId}/users`, {
                args: {
                    isVisible: true
                },
                resolve: _users => resolve(helpers.list(_users))
            });

        }),

        media: args => new Promise((resolve, reject) => {

            args.limit = args.limit || 0;

            args.isVisible = true;

            socket.get('/media', {
                args,
                resolve,
                reject
            });

        })

    },

    post: {

        planSection: data => new Promise((resolve, reject) => {

            socket.post('/planSections', {
                resolve,
                reject,
                data
            });

        }),

        planStakes: (planId, stakes) => new Promise((resolve, reject) => {

            socket.post(`/plans/${planId}/stake`, {
                resolve,
                reject,
                data: stakes
            });

        }),

        project: data => new Promise((resolve, reject) => {

            socket.post('/projects', {
                resolve,
                reject,
                data
            });

        }),

        site: data => new Promise((resolve, reject) => {

            socket.post('/sites', {
                resolve,
                reject,
                data
            });

        }),

        projectSite: data => new Promise(resolve => {

            socket.post(`/projects/${data.projectId}/sites/${data.siteId}`, {
                resolve,
                data
            });

        }),

        user: (data, reject) => new Promise(resolve => {

            socket.post('/users', {
                resolve,
                reject,
                data
            });

        }),

        surveyStakes: (surveyId, stakes) => new Promise((resolve, reject) => {

            socket.post(`/surveys/${surveyId}/stake`, {
                data: stakes,
                resolve,
                reject
            });

        }),

        crop: (sectionId, boundary) => new Promise((resolve, reject) => {

            socket.post(`/planSections/${sectionId}/crop`, {
                data: boundary,
                resolve,
                reject
            });

        })

    },

    delete: {

        thread(threadId, resolve, reject) {

            socket.delete(`/threads/${threadId}`, {
                resolve,
                reject
            });

        },

        content(contentId, resolve, reject) {

            socket.delete(`/content/${contentId}`, {
                resolve,
                reject
            });

        },

        survey: surveyId => new Promise(resolve => {

            socket.delete(`/surveys/${surveyId}`, {
                resolve
            });
        }),

        plan: id => new Promise(resolve => {

            socket.delete(`/plans/${id}`, {resolve});

        }),

        tileset: id => new Promise(resolve => {

            socket.delete(`/tilesets/${id}`, {resolve});

        })

    },

    put: {

        content: content => new Promise((resolve, reject) => {

            socket.put(`/content/${content.contentId}`, {
                data: helpers.apiSafeContent(content),
                resolve: c => {
                    c.levelIds = helpers.list(c.levelIds);
                    c.placeIds = helpers.list(c.placeIds);
                    c.mediaIds = helpers.list(c.mediaIds);
                    resolve(c);
                },
                reject
            });

        }),

        media: media => new Promise((resolve, reject) => {

            socket.put(`/media/${media.mediaId}`, {
                data: media,
                resolve,
                reject
            });

        }),

        thread: (threadId, thread) => new Promise((resolve, reject) => {

            socket.put(`/threads/${threadId}`, {
                data: thread,
                resolve: t => resolve(t || {}),
                reject
            });

        }),

        recipients: (contentId, recipientIds) => new Promise(resolve =>

            socket.put(`/content/${contentId}/recipients`, {
                data: {
                    recipientIds
                },
                resolve
            })

        ),

        plan: data => new Promise((resolve, reject) => {

            socket.put(`/plans/${data.planId}`, {
                resolve,
                reject,
                data
            });

        }),

        site: site => new Promise((resolve, reject) => {

            socket.put(`/projects/${site.projectId}/sites/${site.siteId}`, {
                resolve,
                reject,
                data: site
            });

        })

    },

    patch: {

        tileset: data => new Promise(resolve => {

            socket.patch(`/tilesets/${data.tilesetId}`, {
                resolve,
                data
            });

        }),

        user: data => new Promise((resolve, reject) => {

            socket.patch(`/users/${data.userId}`, {
                data,
                resolve,
                reject
            });

        })

    }

};

export default api;
