import api from 'legacy/util/api';
import randomId from 'util/numbers/random-id';
import constants from 'util/data/constants';
import router from 'uav-router';
import userModel from 'models/user-model';
import TabModel from 'models/tab-model';
import store from 'util/data/store';
import formModel from 'models/form-model';
import peopleModel from 'models/people/people-model';
import {tokenizer} from 'util/data/tokenizer';
import Cached from 'util/data/cached';
import TextEditorModel from 'models/text-editor-model';
import modalModel from './modal-model';
import {EditCommentForm} from 'views/comments/comments';

//                   <--- @MENTIONS --->|<-----------------------            URLS            ----------------------->
const TOKEN_REGEX = /(<@[a-zA-Z0-9-_]*?>|(?:https?:\/\/)?(?:www\.)?(?:[a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}\.?[^\s.;(),:!]+)/gi;
const USER_ID_REGEX = /<@([a-zA-Z0-9-_]*?)>/;
const URL_START_REGEX = /^(https?:\/\/|\/\/)/i;

class CommentsModel extends TabModel {

    constructor() {

        super({
            assetTypeId: constants.commentAssetTypeId,
            threadId: store.assets[formModel.assetId].threadId
        });

        this.textEditor = new TextEditorModel();
        this.commentEditor = new TextEditorModel();


        peopleModel.getPeopleForProjectId().then(() => {
            this.textEditor.initAutoComplete(['users']);
            this.commentEditor.initAutoComplete(['users']);
        });

        this.cache = new Cached();
    }


    // Check the plaintext comment for any rich text embeds,
    // add the necessary markup and cache it.
    commentToRichText(commentId, commentText = '') {
        return this.cache.get(commentId, () => {
            const parts = commentText.split(TOKEN_REGEX);
            if (parts.length > 1) {
                return <>{parts.map((part, i) => {
                    // even parts did not match the token regex, odd parts did
                    if (i % 2) {
                        // if this is a mention:
                        if (part[0] === '<') {
                            const userId = part.match(USER_ID_REGEX)[1];
                            return <span class='user-mention'>{`@${peopleModel.displayUserControlOption(userId)}`}</span>;
                        }
                        // otherwise it's a URL:
                        let href = part;
                        if (!part.match(URL_START_REGEX)) {
                            href = '//' + part;
                        }
                        return <a href={href} target="_blank" rel="noopener noreferrer">{part}</a>;
                    }
                    return part;
                })}</>;
            }
            return commentText;
        });
    }

    clearCommentBox() {
        this.textEditor.clear();
    }

    submit(e) {
        e.preventDefault();
        
        const message = this.textEditor.getApiReadyContent();
        if (!message) {
            return;
        }

        this.textEditor.clear(); // Clear the saved comment from the text editor

        const parentAsset = store.assets[this.assetId];
        parentAsset.recipientIds = parentAsset.recipientIds || [];

        if (parentAsset.recipientIds.indexOf(userModel.userId) === -1) {
            parentAsset.recipientIds.push(userModel.userId);
        }

        const asset = {
            contentId: randomId(),
            assetTypeId: constants.commentAssetTypeId,
            authorId: userModel.userId,
            createdDateTime: new Date(),
            toolId: constants.commentToolId,
            threadId: parentAsset.threadId,
            recipientIds: parentAsset.recipientIds,
            projectId: router.params.projectId,
            properties: {
                Comment: message
            },
            linkIds: [this.assetId],
            featureIds: [],
            attributes: {
                toolId: constants.commentToolId
            }
        };

        this.isSaving = true;
        api.rpc.create('Content', api.apiSafeContent(asset)).then(() => {
            this.isSaving = false;
            m.redraw();
        });

    }

    // ------- Handling editing of comments previously created ------- 

    openEditComment(assetId) {
        this.editingCommentId = assetId;
        const asset = store.assets[assetId];

        modalModel.open({
            view: EditCommentForm
        });
        m.redraw();

        const tokens = tokenizer(asset.properties.Comment).mentions;
        this.commentEditor.initExistingHTML(asset.properties.Comment, tokens, false);
    }

    closeEditComment() {
        modalModel.close();
        this.commentEditor.clear();
    }

    saveCommentEdits() {
        const message = this.commentEditor.getApiReadyContent();
        const asset = store.assets[this.editingCommentId];
        asset.properties.Comment = message;
        this.cache.clear(this.editingCommentId);
        m.redraw();

        api.rpc.modify('Content', {
            contentId: asset.contentId,
            properties: asset.properties
        });

        this.closeEditComment();
    }

    isForThisTab(asset) {
        if (constants.commentAssetTypeId === asset.assetTypeId) {
            const parentAsset = store.assets[formModel.assetId];
            return parentAsset.threadId === asset.threadId;
        }
    }

}

export default CommentsModel;
