import convertMithril from 'util/dom/convert-mithril';
import NextButton from 'legacy/components/next-button';
import constants from 'util/data/constants';
import api from 'legacy/util/api';
import publish from 'legacy/util/api/publish';
import inspectorModel from 'models/inspector-model';
import router from 'uav-router';
import element from 'util/dom/element';
import message from 'legacy/components/message';
import store from 'util/data/store';
import featureToControl from 'util/interfaces/feature-to-control';
import stopPropagation from 'util/events/stop-propagation';

function pageSelector (documentId, asset) {

    const nextButton = new NextButton(),
        pages = [];

    let creatingPlan,
        closed;

    function sortPages() {

        pages.sort((a, b) => a.number - b.number);

    }

    const stopAwaitingNewPages = publish.await({
        changeType: 'new',
        recordType: 'page',
        persist: true,
        test: page => page.documentId === documentId,
        callback: page => {

            pages.push(page);

            sortPages();

            m.redraw();

        }
    });

    api.rpc.list('Pages', {
        documentId,
        limit: 0
    }).then(_pages => {

        if (_pages.length) {

            pages.length = 0;

            pages.push(..._pages);

            m.redraw();

        }

    });

    function makeLayer(page) {

        creatingPlan = true;

        nextButton.disable();

        message.show(element`<span><i class="spinner spinning"></i> Preparing new layer </span>`, 'success', true);


        api.rpc.create('Plan', {
            pageId: page.pageId,
            projectId: router.params.projectId
        }).then(plan => {

            store.plans[plan.planId] = plan;

            const planZOrder = store.project.attributes && store.project.attributes.planZOrder;

            if (planZOrder) {

                planZOrder.unshift(plan.planId);

            }

            publish.await({
                changeType: 'modified',
                recordType: 'plan',
                test: _plan => _plan.planId === plan.planId && _plan.status === 'assembling' || _plan.status === 'complete',
                callback: _plan => {

                    message.hide();

                    store.plans[plan.planId] = _plan;

                    if (!closed) {

                        router.merge({view: 'layer', planId: plan.planId, skipInitializer: true});

                    }

                }
            });

            if (asset && asset.attributes) {

                const tool = store.tools[asset.attributes.toolId],
                    featureType = tool.featureTypes.find(f => f.attributes.interface === 'plan');

                featureToControl.sync('plan', plan, asset.contentId, featureType);

                api.rpc.request([['modifyContent', {
                    contentId: asset.contentId,
                    properties: asset.properties
                }]]);

            }

        });

    }

    const NextButtonMithril = convertMithril.fromDom(nextButton);

    const PageSelector = {
        oninit: ({state}) => {
            state.pageSelected = null;
        },

        view: ({state}) => <div class="page-selector map-wrapper">
            <NextButtonMithril />
            <div class="page-list">
                {pages.map(page =>
                    <div key={page.thumbnailImageId || page.pageId} class={'page-wrap' + (state.pageSelected === page.pageId ? ' selected' : '')}>
                        <div class="page" onclick={() => {
                            if (!creatingPlan) {
                                state.pageSelected = page.pageId;
                                return state.pageSelected ? nextButton.show({
                                    onclick: () => makeLayer(page)
                                }) : nextButton.disable();
                            }
                        }}>
                            <div class="img-wrap">
                                <img src={page.thumbnailImageId ? constants.mediaURL + page.thumbnailImageId : null} class="page-thumb"/>
                            </div>
                            <i class="icon-add-layer" onclick={stopPropagation(() => creatingPlan || makeLayer(page))}></i>
                            <div class="page-num">{page.number}</div>
                        </div>
                    </div>
                )}
            </div>
            ${pages.length ? null : <i class="gray spinner spinning"></i>}
        </div>
    };

    Object.assign(inspectorModel, {
        title: 'Select a page to create a Plan Layer',
        view: PageSelector,
        className: 'doc-inspector-wrap is-plan',
        onClose: () => {

            closed = true;

            stopAwaitingNewPages();

        }
  
    });

    m.redraw();

}

export default pageSelector;
