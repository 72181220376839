import constants from 'util/data/constants';
import swap from 'legacy/util/swap';

const {awsProfile, appSocket, wsSocket} = constants;

const wsSubdomain = swap(awsProfile, {
    development: () => 'ws-dev',
    staging: () => 'ws-staging',
    production: () => 'ws'
});

let wsUrl = `wss://${wsSubdomain}.unearthlabs.com:443`;
if (awsProfile === 'local') {
    wsUrl = wsSocket;
}

const appSubdomain = {
    development: 'app-dev',
    staging: 'app-staging',
    production: 'app'
}[awsProfile];

let appUrl = `https://${appSubdomain}.unearthlabs.com`;
if (awsProfile === 'local') {
    appUrl = appSocket;
}

const tilesSubdomain = swap(awsProfile, {
    development: () => 'tiles-dev',
    staging: () => 'tiles-staging',
    production: () => 'tiles'
});

let tilesUrl = `https://${tilesSubdomain}.unearthlabs.com`;
if (awsProfile === 'local') {
    tilesUrl = appSocket;
}

export {
    wsUrl,
    appUrl,
    tilesUrl
};
