import * as colorPicker from 'a-color-picker';
import debounce from '../util/events/debounce';

const GREEN_HEX = '#149267';
const BLUE_HEX = '#1073b7';
const PURPLE_HEX = '#944095';
const PINK_HEX = '#e84495';
const RED_HEX = '#eb342e';
const ORANGE_HEX = '#f69831';
const YELLOW_HEX = '#fff036';
const SLATE_HEX = '#303840';
const GRAY_HEX = '#b7bec5';
const WHITE_HEX = '#ffffff';
const DEFAULT_PALETTE = [GREEN_HEX, BLUE_HEX, PURPLE_HEX, PINK_HEX, RED_HEX,
    ORANGE_HEX, YELLOW_HEX, SLATE_HEX, GRAY_HEX, WHITE_HEX];

/**
 * ColorPickerModel to be used globally, saving recent custom swatches to app state.
 * View attrs set particulars for starter color, containerId, and set color function.
 */
class ColorPickerModel {

    constructor() {
        this.picker = null;
        this.palette = [...DEFAULT_PALETTE];
        this.handleSwatchSelection = debounce(this._handleSwatchSelection.bind(this));
        this.handleSetColor = null; // Passed in from caller through init()

        this.state = {
            color: null,
            activeSwatch: null,
            wrapClass: ''
        };
    }

    init(opts) {
        this.state.color = opts.starterColor;
        this.handleSetColor = opts.setColorFn;
        this.containerId = opts.containerId;
        this.hideInputs = opts.hideInputs;
        if (opts.starterColor
            && !this.palette.find(swatch => swatch === opts.starterColor)) {
            this.palette.push(opts.starterColor);
        }
        this.create();
    }

    create() {
        this.picker = document.getElementById(this.containerId);
        this.picker.onclick = e => {
            e.stopPropagation();
        };

        colorPicker.createPicker(this.picker, {
            color: this.state.color,
            showHSL: false,
            showHEX: !this.hideInputs,
            showRGB: !this.hideInputs,
            showAlpha: false,
            palette: this.palette,
            paletteEditable: true,
            slBarSize: [234, 90]
        }).on('coloradd', (picker, color) => {
            if (!this.palette.find(swatch => swatch === color)) {
                this.palette.push(color);
                this.setActiveSwatch(color);
            }
        }).on('change', (picker, color) => {
            this.handleSwatchSelection(color);
        });
        // Prevent shift + click from removing swatches at this time (it is not intuitive).
        this.picker.addEventListener('click', (e) => {
            if (e.shiftKey) {
                e.stopPropagation();
            }
        }, true);
        // If the element has a custom color, add it to the default palette:
        if (this.state.color) {
            this.state.activeSwatch = this.getSwatchElemByHex(this.state.color);
            this.highlightSwatch(this.state.activeSwatch);
        }
    }

    onRemove() {
        // Make sure the current color is added to the palette swatches.
        if (!this.palette.find(color => color === this.state.color)) {
            this.palette.push(this.state.color);
        }
    }

    resetColor(hex) {
        this.state.color = hex;
        this.picker.childNodes[0].remove();
        this.create();
        m.redraw();
    }

    _handleSwatchSelection(rgbSelected) {
        const hex = colorPicker.parseColor(rgbSelected, 'hex');
        if (hex !== this.state.color) {
            this.selectSwatch(hex);
        }
    }

    selectSwatch(hexSelected) {
        this.setActiveSwatch(hexSelected);
        this.handleSetColor(hexSelected);
        this.state.color = hexSelected;
    }

    setActiveSwatch(hexSelected) {
        this.unhighlightSwatch(this.state.activeSwatch);

        // Find the swatch matching the hex (if it exists)
        this.state.activeSwatch = this.getSwatchElemByHex(hexSelected);

        // Swatch doesn't exist, so select the "plus" button swatch element, providing option to add swatch to recents:
        if (!this.state.activeSwatch) {
            this.state.activeSwatch = this.picker.getElementsByClassName('a-color-picker-palette-add')[0];
        }

        this.highlightSwatch(this.state.activeSwatch);
    }

    getSwatchElemByHex(hex) {
        return this.picker.querySelector(`[data-color='${hex}']`);
    }

    highlightSwatch(swatch) {
        if (swatch) {
            swatch.classList.add('selected');
        }
    }

    unhighlightSwatch(swatch) {
        if (swatch) {
            swatch.classList.remove('selected');
        }
    }

}

export default new ColorPickerModel();
