import mediaModel from 'models/media-model';
import store from 'util/data/store';
import ToolInterface from 'util/interfaces/tool-interface';
import constants from 'util/data/constants';
import oneUpModel from 'models/one-up-model';
import popup from 'util/popup';

class PlanInterface extends ToolInterface {

    constructor(...args) {

        super(...args);

        this.type = 'plan';

    }

    launch() {

        popup.remove();

        return oneUpModel.addUploadFlow({
            accept: constants.uploadOpts.plan.accept,
            close: this.onClose.bind(this),
            name: this.tool.name,
            maxFiles: constants.uploadOpts.plan.maxFiles
        }).then(([media]) => {

            const mediaId = media.mediaId;

            const asset = store.assets[this.assetId];

            asset.mediaIds = [mediaId];

            asset.mediaId = mediaId;

            store.media[mediaId] = media;

            // const coordinates = Filepicker.getMediaCoordinates(media);

            // if (coordinates) {

            //     this.feature.coordinates = coordinates ? {
            //         type: 'Point',
            //         coordinates
            //     } : undefined;

            //     this.asset.featureIds = [this.feature.id];

            // }

            mediaModel.createLayer(mediaId, this.assetId);

            return [asset];

        });

    }

}

export default PlanInterface;
