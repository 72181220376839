import convertMithril from 'util/dom/convert-mithril';

const HeaderHideMessage = {
    view: ({assetType}) => {
        return <div>
            Property columns for Type: {assetType.name} are now hidden. <a>Undo</a>
        </div>;
    },
    toDom: ({assetType, onClick}) => {
        let view = HeaderHideMessage.view({assetType});
        view = convertMithril.toDom(view);
        view.onclick = onClick;
        return view;
    }
};

export default HeaderHideMessage;
