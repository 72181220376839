import tableModel from 'models/table/table-model';
import filterModel from 'models/table/filter-model';
import {combinedPlacesFilterType, combinedTaggedRadio, combineFilterSelections} from './helpers';

/**
 * Determines handling of filter behaviors dependent upon shared column vs individual filter types
 */
class FilterTypeModel {

    init(opts) {
        this.type = opts.type;
        const index = filterModel.opts.assetType.fields.findIndex((field) => filterModel.opts.controlType.name === field.name);
        this.field = filterModel.opts.assetType.fields[index];
        const sharedColumnName = filterModel.opts.sharedColumnName;

        if (sharedColumnName) {
            this.sharedColumn = tableModel.getSharedColumn(sharedColumnName);
        } else {
            this.sharedColumn = false;
        }

        this.state = filterModel.getState(sharedColumnName);
        if (!this.state) {
            this.state = {
                fieldName: this.field.name,
                type: this.type
            };
            if (opts.specialProperties) {
                opts.specialProperties.forEach(property => {
                    this.state[property] = this.defaultValueForProperty(property);
                });
                if (sharedColumnName && this.state.hasOwnProperty('checkedOptions')) {
                    const combined = combineFilterSelections(this.state, this.sharedColumn);
                    this.state = combined;
                }
                if (sharedColumnName && this.state.type === 'placesFilter') {
                    this.state = combinedPlacesFilterType(this.state, this.sharedColumn);
                }
            }
            filterModel.setState(this.state, sharedColumnName);
        }
    }

    get fieldName() {
        return this.state.fieldName;
    }

    hasAnyMixedOptions(state = this.state) {
        return state.selectedRadioIndex === -1;
    }

    get isUserObjType() {
        return this.field.type && this.field.type.objType === 'user';
    }

    getDefaultRadioIndex() {
        return this.sharedColumn ? combinedTaggedRadio() : 0;
    }

    getDefaultCheckedOptions() {
        // Default to all checks "on"
        const checkedOptions = {};
        this.options.forEach(opt => {
            checkedOptions[opt] = true;
        });
        return checkedOptions;
    }

    onFilterUpdate(singleOption) {
        if (filterModel.opts.sharedColumnName) { // If the shared/combined column is the current controller, update the others:
            if (singleOption) {
                return filterModel.syncTaggedStatesSingleOption(singleOption, this);
            }
            return filterModel.syncFilterStates(this);
        }
        filterModel.filtersChanged();
    }

    defaultValueForProperty(property) {
        switch (property) {
        case 'selectedRadioIndex':
            return this.getDefaultRadioIndex();
        case 'checkedOptions':
            return this.getDefaultCheckedOptions();
        case 'isEmptyChecked':
            return true;
        case 'numberType':
            return this.field.type;
        case 'selectedSign':
            return 'lte';
        case 'range':
            return 50;
        case 'rangeLow':
            return 10;
        case 'rangeHigh':
            return 75;
        default:
            return {};
        }
    }

    selectRadio(i) {
        if (i === this.state.selectedRadioIndex) {
            return;
        }
        this.state.selectedRadioIndex = i;
        this.onFilterUpdate();
    }

}

export default FilterTypeModel;
