import modalModel from 'models/modal-model';

const Modal = {
    view: () => {
        return <div class={`main-modal ${modalModel.cssClass} ${modalModel.styleClass}-style`}>
            {modalModel.views && modalModel.views.map(modalView => m(modalView))}
        </div>;
    }
};

export default Modal;
