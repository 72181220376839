import hubspot from 'util/hubspot/hubspot';

export default {

    view: () => <div class="side-nav-popup help-box">
        <div class="side-nav-popup-title">Support Options</div>
        <div class="support-links left">
            <div class="side-nav-popup-row"><span class="support-link-label">Wiki</span> <a href="https://support.unearthlabs.com/oneplace-v2" target="_blank" rel="noopener noreferrer">support.unearthlabs.com</a></div>
            <div class="side-nav-popup-row"><span class="support-link-label">Call</span> <a href="tel:+12064871166">(206) 487-1166</a></div>
            <div class="side-nav-popup-row"><span class="support-link-label">Email</span> <a href="mailto:support@unearthlabs.com">support@unearthlabs.com</a></div>
        </div>
        <div class="right help-highlight" onclick={() => hubspot.loadHubspotChat()}>
            <i class="icon-unearth support-logo" />
            <a class="btn btn-pill btn-smallest btn-chat">Start Chat</a>
        </div>
    </div>

};
