import stopPropagation from 'util/events/stop-propagation';

const PlaceList = {
    oninit: ({attrs: {model}}) => {
        model.index = 0;
    },

    view: ({attrs: {model, places, openUp}}) => {
        const areAllItemsSelected = model.places.length === Object.values(model.selectedMap).length;
        return <div className={`place-list-wrap ${openUp ? 'up' : ''}`}>
            <div className="place-list">
                <i class="icon-search"></i>
                {!openUp && <input className="place-search-input"
                    id="place-search"
                    oninput={model.onInput}
                    onkeydown={model.onKeyDown}
                    onclick={stopPropagation()}
                />}
                {areAllItemsSelected && 'All available tags have been added.'}
                {places.length === 0 && !areAllItemsSelected ?
                    'No matches found.' :
                    places.map((place, i) =>
                        <div className={`place-item${i === model.index ? ' active' : ''}`}
                            onclick={(e) => model.onSelect(e, place)}>
                            {place.name}
                        </div>
                    )}
                {openUp && <input className="place-search-input"
                    id="place-search"
                    oninput={model.onInput}
                    onkeydown={model.onKeyDown}
                    onclick={stopPropagation()}
                />}
            </div>
        </div>;
    }
};

export default PlaceList;
