import toolboxModel from 'models/toolbox-model';
import screenHelper from 'legacy/util/device/screen-helper';
import importModel from 'models/import-model';
import constants from 'util/data/constants';
import store from 'util/data/store';
import stopPropagation from 'util/events/stop-propagation';

const ImportHelp = {
    oninit({state, attrs: {tool}}) {
        state.geomType = tool.featureTypes[0].geometry.type;
        state.controlDescriptions = [];
        state.controls = [];
        tool.assetForm.controls.forEach(control => {
            if (!importModel.hiddenControlTypes[control.controlTypeId]) {
                const field = store.assetTypeFields[tool.assetForm.assetType.assetTypeId][control.label];
                if (field) {
                    state.controls.push(control);
                    state.controlDescriptions.push({
                        control,
                        description: importModel.fieldDescription(field)
                    });
                }
            }
        });
    },
    view: ({attrs: {tool}, state: {controls, controlDescriptions, geomType}}) => {
        return <div class="import-help">
            <div class="center">
                <a class="btn btn-pill btn-primary btn-small" onclick={() => importModel.upload(tool, controls)}>
                    <span class="btn-label">Select File for Import <i class="icon-arrow-right" /></span>
                </a>
            </div>
            <p class="import-intro">When importing a dataset, please note the following to avoid errors:</p>
            <p>For each record {geomType ? `with ${geomType} geometry` : 'in your import file'}, we will create one {tool.name} record in Unearth.</p>
            <p>We currently support importing files with up to {importModel.maxAssets} records.</p>
            {!!geomType && <>
                <p>For datasets that do not contain geometry, you will be able to:</p>
                <ul class="import-detail-list">
                    <li>Add the records without mapping</li>
                    <li>Or select a different geometry source</li>
                </ul>
            </>}
            <h3>{tool.name}: {controls.length} Propert{controls.length === 1 ? 'y' : 'ies'}</h3>
            <p>Each field in the {tool.name} Properties Form can be matched to a column in your dataset. Please check your import source for compatibility.</p>
            <table>
                <thead>
                    <td>Property Name</td>
                    <td>Matchable Column</td>
                </thead>
                <tbody>
                    {controlDescriptions.map(cd => {
                        return <tr>
                            <td>
                                <div class="property-label">{cd.control.label}</div>
                                <code>{constants.controlTypeIdToName[cd.control.controlTypeId]}</code>
                            </td>
                            <td>{cd.description}</td>
                        </tr>;
                    })}
                </tbody>
            </table>
        </div>;
    }
};

const ToolLauncher = {

    view: () => {

        const toolInterface = toolboxModel.toolInterface;

        if (!toolInterface) {
            return null;
        }

        const tool = toolboxModel.tool,
            ToolUI = toolInterface.ToolUI,
            coordinates = toolInterface.feature.geometry.coordinates,
            hasCoordinates = coordinates.length,
            isFeatureReady = screenHelper.small() && (toolInterface.type === 'polyline' && coordinates.length > 2 || toolInterface.type === 'polygon' && coordinates.length > 3),
            Prompt = toolInterface.prompt;

        return <div>
            <div class="tool-launcher">
                <div class="asset-title-wrap">
                    <img class="asset-icon" src={`https://media.unearthlabs.com/${tool.assetForm.assetType.attributes.icon.mediaId}`} />
                    <div class="asset-title">{tool.name}</div>
                    <div class="btn btn-smallest btn-secondary btn-pill asset-cancel" onclick={() => toolInterface.close()}>
                        <span class="btn-label">Cancel</span>
                    </div>
                </div>
                <div class="tool-launcher-prompt">
                    {toolboxModel.importMode 
                        ? <ImportHelp tool={tool} />
                        : <>
                            <div class="prompt-text"><Prompt /></div>
                            {toolInterface.done && !hasCoordinates && !toolInterface.requireFeature &&
                                <div class="btn btn-smaller btn-secondary btn-pill" onclick={() => toolInterface.done()}>
                                    <span class="btn-label">Skip Mapping</span>
                                </div>}
                            {toolInterface.done && isFeatureReady &&
                                <div class="btn btn-smaller btn-secondary btn-pill" onclick={stopPropagation(() => toolInterface.completeFeature())}>
                                    <span class="btn-label">Done Drawing</span>
                                </div>}
                        </>}
                </div>
            </div>
            {ToolUI && !toolboxModel.importMode && <ToolUI />}
        </div>;

    }
};

export default ToolLauncher;
