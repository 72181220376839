import dialogModel from 'models/dialog-model';

const Dialog = {
    view: () =>
        <div class={`block-wrap dialog-wrap ${dialogModel.cssClass} ${dialogModel.warning ? 'warning' : ''}`}>
            <div class="content-wrap">
                <div class={dialogModel.headline ? 'dialog-head' : 'hidden'}>{dialogModel.headline}</div>
                <div class="dialog-text">{dialogModel.text}</div>
                <div class={`dialog-btns ${dialogModel.okayClass}`}>
                    <div class="btn-pair">
                        <a class={`dialog-option-btn ${dialogModel.noClass}`} onclick={dialogModel.handleNo} tabIndex="2">
                            <span class="btn-label">{dialogModel.noText}</span>
                        </a>
                        <a class={`dialog-option-btn ${dialogModel.yesClass}`} onclick={dialogModel.handleYes} tabindex="1">
                            <span class="btn-label">{dialogModel.yesText}</span>
                        </a>
                    </div>
                    <div class="dialog-btn dialog-okay" onclick={dialogModel.handleOkay}>
                        <span class="btn-label">{dialogModel.okayText}</span>
                    </div>
                </div>
                <i class={dialogModel.quitClass} onclick={dialogModel.quit}></i>
            </div>
        </div>
};

export default Dialog;
