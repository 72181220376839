import DataImport from 'views/data-import';
import siteModel from 'models/site-model';
import toolboxModel from 'models/toolbox-model';
import api from 'legacy/util/api';
import publish from 'legacy/util/api/publish';
import deepClone from 'util/data/deep-clone-object';
import Table from 'views/table';
import tableModel from 'models/table/table-model';
import modalModel from 'models/modal-model';
import CancelImport from 'views/data-import/cancel-import';
import featureModel from 'models/feature-model';
import screenHelper from 'legacy/util/device/screen-helper';
import message from 'legacy/components/message';
import randomId from 'util/numbers/random-id';
import constants from 'util/data/constants';
import download from 'legacy/util/device/download';
import store from 'util/data/store';
import deepCloneObject from 'util/data/deep-clone-object';
import oneUpModel from 'models/one-up-model';

const GEO_FILE_TYPES = ['application/zip', '.zip', '.kml', '.kmz', '.json', '.geojson', '.sqlite', '.sqlite3', '.gpkg', 'text/plain'],
    OTHER_FILE_TYPES = ['.csv', '.xls', '.xlsx', 'text/plain'];

class ImportModel {

    constructor() {
        this.maxAssets = 2500;
        this.NO_GEOMETRY = 'Add records but skip mapping';
        this.JOIN_GEOMETRY = 'Join with another geometry source';
        const controlTypeNameToId = constants.controlTypeNameToId;
        this.hiddenControlTypes = {
            [controlTypeNameToId.comments]: 1,
            [controlTypeNameToId.links]: 1,
            [controlTypeNameToId.file]: 1,
            [controlTypeNameToId.coordinates]: 1,
            [controlTypeNameToId.embed]: 1,
            [controlTypeNameToId.place]: 1
        };
        this.geoFileTypes = {
            'application/json': 'GeoJSON',
            'json': 'GeoJSON',
            'geojson': 'GeoJSON',
            'application/zip': 'ESRI Shapefile',
            'zip': 'ESRI Shapefile',
            'kml': 'KML',
            'kmz': 'KML'
        };
    }

    init(tool, media) {
        this.maxExceeded = false;
        this.tool = tool;
        this.media = media;
        this.preview = null;
        this.step = 0;
        this.failed = false;
        this.totalCount = 0;
        this.hasGeometry = false;
        this.geometryMatches = 0;
        this.geo = {};
        this.joinOn = null;
        this.validCount = null;
        this.invalidCount = null;
        this.columns = [];
        const featureType = tool.featureTypes[0];
        this.toolGeomType = featureType.geometry ? featureType.geometry.type || 'NoGeometry' : 'NoGeometry';
        this.fileIcon = toolboxModel.linkTools.file.assetForm.assetType.attributes.icon.mediaId;
        this.args = {
            toolId: tool.toolId,
            featureTypeId: featureType.featureTypeId,
            formDefaults: {},   // asset_form_field: <string>
            formOverrides: {},  // asset_form_field: <string>
            formMappings: {}    // source_property: asset_form_field
        };
        this.rowCount = {};
        this.validating = {};
        this.isExporting = false;
        this.isImporting = false;
        this.skip = {};
        siteModel.sidebar = DataImport;
        this.defaultAsset = store.assets[toolboxModel.toolInterface.assetId];
        this.defaultAsset.properties = this.args.formDefaults;
        this.overrideAsset = deepClone(this.defaultAsset);
        this.overrideAsset.properties = this.args.formOverrides;
        const draw = toolboxModel.toolInterface.draw;
        // Clean up any feature the user may have started creating
        // before deciding to click the import button
        if (draw) {
            if (draw.feature) {
                const source = featureModel.deleteFeature(draw.feature);
                source.setData(source._data);
            }
            draw.stop();
        }
        this.geometryStrategy = this.toolGeomType === 'NoGeometry' ? this.NO_GEOMETRY : this.JOIN_GEOMETRY;
        this.removeResize = screenHelper.onResize(() => {
            if (document.body.offsetWidth < 1024) {
                this.done();
                message.show('Import aborted. Screen width must be at least 1024px.', 'warning');
            }
        });
    }

    cleanup() {
        this.removeResize();
        toolboxModel.toolInterface = null;
        if (!this.isImporting) {
            publish.clearCallbacks('modified', 'vectorProcessingJob');
        }
    }

    done() {

        oneUpModel.clearActiveFlow();

        modalModel.close();

        siteModel.view = null;

        siteModel.sidebar = Table;

        m.redraw();

    }

    close() {

        oneUpModel.clearActiveFlow();

        modalModel.open({view: CancelImport});

    }

    back() {

        if (this.step) {

            this.step--;

        }

        if (!this.step) {

            oneUpModel.clearActiveFlow();

            this.upload(this.tool, this.controls, this.done.bind(this));

        }

    }

    next() {

        if (this.canMoveForward()) {

            this.step++;

        }

    }

    getStrategyOpts() {

        return [this.NO_GEOMETRY, this.JOIN_GEOMETRY];

    }

    canMoveForward() {

        if (this.failed) {
            return false;
        }

        switch (this.step) {
        case 0:
        case 1:
        case 2:
            if (this.hasGeometry) {
                return this.geometryMatches;
            }
            if (this.geometryStrategy === this.JOIN_GEOMETRY) {
                return this.geo.media && this.geometryMatches;
            }
            return this.totalCount;
        }

        return false;

    }

    toggleSkip(controlLabel, doSkip) {

        if (doSkip) {
            delete this.args.formMappings[controlLabel];
            this.skip[controlLabel] = true;
        } else {
            delete this.skip[controlLabel];
        }

    }

    resetDefault(control, doLeaveEmpty) {
        if (doLeaveEmpty) {
            delete this.defaultAsset.properties[control.label];
        }
    }

    changeMatchStrategy(control, doInsertValue) {
        const label = control.label;
        if (doInsertValue) { // "Insert value" is selected
            delete this.defaultAsset.properties[label];
            delete this.rowCount[label];
            delete this.args.formMappings[label];
            this.overrideAsset.properties[label] = deepCloneObject(control.attributes.default);
        } else { // "Match to" is selected
            delete this.overrideAsset.properties[label];
        }
    }

    matchColumn(label, col) {

        delete this.rowCount[label];

        delete this.args.formMappings[label];

        if (col) {

            this.args.formMappings[label] = col;

            this.validateGeometry({
                [label]: col
            });

        }

    }

    countGeomType(type, geomTypes) {

        // The API automatically flattens Multi* features into
        // individual features, since the clients don't support
        // Multi geometry at this time.
        return (geomTypes[type] || 0) + (geomTypes['Multi' + type] || 0);

    }

    parseGeometry(preview) {

        const geometry = preview.attributes && preview.attributes.geometry;

        if (geometry) {

            this.geo.columnNames = geometry.columnNames;

            const joinOnMatch = geometry.columnNames.find(col => col.toLowerCase() === this.joinOn);

            if (joinOnMatch) {
                this.geo.match = joinOnMatch;
            } else {
                this.geo.match = geometry.columnNames[0];
            }

            this.geometryMatches = this.countGeomType(this.toolGeomType, geometry.geometryTypes);

        } else {

            this.geometryMatches = 0;

            this.failed = true;

        }

        this.validateGeometry();

        this.getValidCount();

        m.redraw();

    }

    getValidCount() {

        if (this.geo.media) {

            this.validCount = null;
            this.invalidCount = null;

            const promise = this.pendingQuery = Promise.all([
                api.rpc.request([['queryGeometry', {
                    // mediaId: this.media.mediaId,
                    sources: this.getSources([[{
                        geometryType: {
                            'eq': this.toolGeomType
                        }
                    }]]),
                    mapping: this.getMapping(),
                    featureLimit: 0
                }]]),
                api.rpc.request([['queryGeometry', {
                    // mediaId: this.media.mediaId,
                    sources: this.getSources([[{
                        geometryType: {
                            '~eq': this.toolGeomType
                        }
                    }]]),
                    mapping: this.getMapping(),
                    featureLimit: 0
                }]])
            ]).then(([valid, invalid]) => {
                if (this.pendingQuery === promise) {
                    this.totalCount = valid.mappings.totalCount;
                    this.validCount = valid.mappings.resultCount;
                    this.invalidCount = invalid.mappings.resultCount;
                    m.redraw();
                }
            });

        } else if (this.hasGeometry) {

            this.validCount = this.geometryMatches;
            this.invalidCount = this.totalCount - this.geometryMatches;

        } else if (this.geometryStrategy === this.NO_GEOMETRY) {

            this.validCount = this.totalCount;
            this.invalidCount = 0;

        } else {

            this.validCount = 0;
            this.invalidCount = this.totalCount;

        }

    }

    previewGeometry() {

        this.processMedia(this.media.mediaId, preview => {
            if (preview.status !== 'complete' || preview.attributes && preview.attributes.error) {
                this.failed = true;
            } else {
                const geometry = this.hasGeometry = preview.attributes && preview.attributes.geometry;
                if (geometry) {
                    this.geometryMatches = this.toolGeomType === 'NoGeometry' 
                        ? geometry.totalCount 
                        : this.countGeomType(this.toolGeomType, geometry.geometryTypes);
                    this.totalCount = geometry.totalCount;
                    this.columns = geometry.columnNames;
                } else {
                    this.geometryMatches = 0;
                }
                const mappings = preview.attributes && preview.attributes.mappings;
                if (mappings) {
                    this.columns = mappings.columnNames;
                    this.totalCount = mappings.totalCount;
                } else if (!geometry && this.toolGeomType !== 'NoGeometry') {
                    this.failed = true;
                }
                const lowerCaseControls = {};
                this.tool.assetForm.controls.forEach(control => {
                    lowerCaseControls[control.label.toLowerCase()] = control;
                });
                this.joinOn = this.columns[0];
                this.columns.forEach(col => {
                    if (col.toLowerCase() === 'address') {
                        this.joinOn = col;
                    }
                    const matchingControl = lowerCaseControls[col.toLowerCase()];
                    if (matchingControl && !this.hiddenControlTypes[matchingControl.controlTypeId]) {
                        this.args.formMappings[matchingControl.label] = col;
                    }
                });
                if (this.totalCount > this.maxAssets) {
                    this.maxExceeded = true;
                    this.failed = true;
                } else {
                    this.step = 1;
                    this.validateGeometry();
                }
                this.getValidCount();
            }
            m.redraw();
        });

    }

    processMedia(mediaId, callback) {

        const jobId = randomId();

        publish.await({
            changeType: 'modified',
            recordType: 'vectorProcessingJob',
            test: change => change.jobId === jobId,
            callback
        });

        api.rpc.request([['previewGeometry', {
            mediaId,
            jobId
        }]]);

    }

    getSources(filters) {
        return this.geo.media ? {
            filters,
            match: this.geo.match ? [this.geo.match] : undefined,
            mediaId: this.geo.media.mediaId
        } : undefined;
    }

    getMapping() {
        return {
            mediaId: this.media.mediaId,
            match: this.geo.media ? [this.joinOn] : undefined
        };
    }

    parseValidation(attributes, mapping = this.args.formMappings) {

        const errors = attributes.errors || {};
        const mismatchErrors = errors.PropertyTypeMismatch || {};
        const validationFailed = attributes.mappings && attributes.mappings[0] && attributes.mappings[0].error;

        this.controls.forEach(({label}) => {

            if (mapping[label]) {

                const errorCount = mismatchErrors[label] || 0;

                if (validationFailed) {

                    delete this.rowCount[label];

                } else {

                    this.rowCount[label] = {
                        matches: this.totalCount - errorCount,
                        errors: errorCount
                    };

                }

            }

        });

        m.redraw();

    }

    getGeomType() {
        if (!this.hasGeometry && !this.geo.media) {
            return 'NoGeometry';
        }
        return this.toolGeomType;
    }

    validateGeometry(mapping = this.args.formMappings) {

        Object.keys(mapping).forEach(label => {
            this.validating[label] = this.validating[label] || 0;
            this.validating[label] += 1;
        });

        const jobId = randomId();

        publish.await({
            changeType: 'modified',
            recordType: 'vectorProcessingJob',
            test: change => change.jobId === jobId,
            callback: preview => {
                if (preview.status === 'failed') {
                    this.failed = true;
                    this.step = 0;
                } else {
                    this.parseValidation(preview.attributes, mapping);
                }

                Object.keys(mapping).forEach(label => {
                    this.validating[label] = Math.max(0, this.validating[label] - 1);
                });

                m.redraw();
            }
        });

        api.rpc.request([['validateV2Geometry', {
            jobId,
            projectId: siteModel.projectId,
            sources: this.getSources(),
            mapping: this.getMapping(),
            assetMappings: {
                [this.getGeomType()]: Object.assign({}, this.args, {
                    formMappings: mapping || this.args.formMappings
                })
            }
        }]]);

    }

    import() {

        // Cancel pending validation requests
        this.validating = {};
        publish.clearCallbacks('modified', 'vectorProcessingJob');

        this.isImporting = true;

        const jobId = randomId();

        const importCount = this.validCount;

        publish.await({
            changeType: 'modified',
            recordType: 'vectorProcessingJob',
            test: change => change.jobId === jobId,
            callback: change => {
                if (change.status !== 'complete') {
                    this.failed = true;
                    this.step = 0;
                } else {

                    // TODO: remove the delay here once
                    // we fix the BE replica read issue
                    setTimeout(() => {

                        if (siteModel.sidebar !== DataImport) {
                            message.show(`We have imported ${importCount} record${importCount === 1 ? '' : 's'} of type ${this.tool.name} from ${this.media.label}.
New records may not be visible due to current filter conditions.`);
                        }

                        this.isImporting = false;

                        m.redraw();

                    }, 5000);
                }
            }
        });

        api.rpc.request([['createProjectGeometry', {
            jobId,
            projectId: siteModel.projectId,
            sources: this.getSources(),
            mapping: this.getMapping(),
            assetMappings: {
                [this.getGeomType()]: this.args
            }
        }]]);

    }

    clickDone() {
        tableModel.fetch();
        this.done();
    }

    upload(tool = this.tool, controls = this.controls, close) {

        this.controls = controls;
        oneUpModel.addUploadFlow({
            boundary: siteModel.boundary,
            accept: GEO_FILE_TYPES.concat(OTHER_FILE_TYPES),
            maxFiles: 1,
            name: 'Import',
            isImport: true,
            toolId: tool.toolId,
            close
        }).then(([media]) => {
            const isThirdPartyData = !media;
            if (isThirdPartyData) {
                toolboxModel.closeActiveTool();
                message.show('We have imported your records successfully.');
                tableModel.fetchAllAssetsCount().then(() => tableModel.renderWithDefaultFilters());
                return;
            }

            this.init(tool, media);
            this.previewGeometry();
        }).catch(error => {
            console.error(error);
            message.show('There was a problem importing your records. Please contact our Customer Success team for help importing records.', 'error');
        });

    }

    clearGeoMedia() {
        if (this.geo.media) {
            this.geo = {};
            this.geometryMatches = 0;
            this.getValidCount();
        }
    }

    uploadGeometry() {

        oneUpModel.addUploadFlow({
            accept: GEO_FILE_TYPES,
            maxFiles: 1
        }).then(([media]) => {

            this.geo.media = media;

            this.processMedia(media.mediaId, preview => this.parseGeometry(preview));

            m.redraw();

        });

    }

    fieldDescription(field) {

        if (!field) {
            return null;
        }

        switch (field.type) {
        case 'text':
        case 'paragraph':
        case 'name':
            return 'Any string';
        case 'number':
            return 'Any number';
        case 'length':
            return 'Any number (meters)';
        case 'area':
            return 'Any number (square meters)';
        case 'volume':
            return 'Any number (cubic meters)';
        case 'dateTime':
        case 'date':
            return 'Any date';
        case 'color':
            return 'A hex color string like #FFFFFF';
        case 'boolean':
            return 'True or false';
        }

        const values = field.type.values || field.type.items && field.type.items.values;

        if (values) {

            return `One of the defined options:\n${values.map(v => '- ' + v).join('\n')}`;

        } else if (field.type.type === 'object') {

            return `An object with these properties:\n${Object.keys(field.type.properties).map(p => '- ' + p).join('\n')}`;

        } else if (field.type.type === 'objRef') {
            // Can't import projects
            if (field.type.objType === 'project') {
                return null;
            }

            return `An Unearth ${field.type.objType} ID string`;

        } else if (field.type.type === 'geometry') {

            return `Any ${field.type.geomType.toLowerCase()} geometry`;

        }

    }

    getFileInfo(media) {

        const labelParts = media.label.split('.');
        const extension = labelParts.pop();
        const fileLabel = labelParts.join('.') + '-excluded';

        let exportFormat;

        if (this.geo.media || !this.hasGeometry) {
            exportFormat = 'CSV';
        } else {
            exportFormat = this.geoFileTypes[media.mimeType] || this.geoFileTypes[extension] || 'GeoJSON';
        }

        return {
            fileLabel,
            exportFormat
        };

    }

    exportGeometry(media, args) {

        const jobId = randomId();

        const fileInfo = this.getFileInfo(media);

        Object.assign(args, fileInfo, {
            jobId
        });

        const fail = () => {
            this.isExporting = false;
            message.show('Export failed.', 'error');
            m.redraw();
        };

        publish.await({
            changeType: 'modified',
            recordType: 'vectorProcessingJob',
            test: change => change.jobId === jobId,
            callback: result => {
                this.isExporting = false;
                if (result.status === 'failed') {
                    fail();
                } else {
                    download(constants.mediaURL + result.mediaId, fileInfo.fileLabel + '.zip');
                }
                m.redraw();
            }
        });

        api.rpc.request([['exportGeometryQuery', args]])
            .catch(fail);

    }

    downloadAll() {

        download.force(this.media);

    }

    downloadUnmatched() {

        if (this.geo.media) {

            this.isExporting = 'unmatched';

            this.exportGeometry(this.media, {
                joinType: 'outer',
                mediaId: this.media.mediaId,
                sources: this.getSources(),
                mapping: this.getMapping()
            });

        } else {

            this.downloadAll();

        }

    }

    downloadInvalid() {

        const filters = [[{
            geometryType: {
                '~eq': this.toolGeomType
            }
        }]];

        if (this.geo.media) {

            this.isExporting = 'invalid';

            this.exportGeometry(this.media, {
                mediaId: this.media.mediaId,
                sources: this.getSources(filters),
                mapping: this.getMapping()
            });

        } else if (this.hasGeometry) {

            this.isExporting = 'invalid';

            this.exportGeometry(this.media, {
                mediaId: this.media.mediaId,
                sources: {
                    filters
                },
                mapping: null
            });

        } else {

            this.downloadAll();

        }

    }

}

export default new ImportModel();
