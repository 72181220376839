import FilterSelectModel from 'models/filter-select-model';

/**
 Required args:
 * @param options - Array of options available for the select dropdown
 * @param onselect - Function to run when an option has been selected
 Optional args:
 * @param displayOptionAs - Callback to format option data for display, e.g, userId displays as user's name
 * @param selected - Currently selected option
 */
const FilterSelect = {

    oninit: ({state, attrs: {types, onselect, selected}}) => {
        state.model = new FilterSelectModel(types, onselect, selected);
    },

    oncreate({state, dom}) {
        // Allows us to update the displayed input value if a selection is made
        state.model.input = dom.querySelector('input');
    },

    view: ({state, attrs: {selected, displayOptionAs}}) => {
        const model = state.model;
        return <div class="filter-select">
            <input type="text" defaultValue={selected ? displayOptionAs(selected) : ''}
                placeholder={selected ? '' : 'Tap for list. Type to filter ...'}
                onfocus={() => model.showOptions()}
                onblur={() => model.resetInput()}
                onkeydown={(e) => model.handleKeyDown(e)}/>
            {model && model.optionsVisible && <div class="options">
                {selected && model.filteredOptions.length ?
                    <div class={'option option-clear' + (model.focusedIndex < 0 ? ' focus' : '')}
                        onmousedown={() => model.clearSelection()}
                        ontouchstart={() => model.clearSelection()}>Clear selection</div> : null}
                {model.filteredOptions.map((option, index) =>
                    <div class={'option' + (model.focusedIndex === index ? ' focus' : '')}
                        onmousedown={() => model.selectIndex(index)}
                        ontouchstart={() => model.selectIndex(index)}>
                        {option.text}
                    </div>)}
                {!model.filteredOptions.length && <div class='option none-found'>No matches found.</div>}
            </div>}
        </div>;
    }

};

export default FilterSelect;
