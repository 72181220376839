import ajax from 'legacy/util/api/ajax';
import deepMerge from 'util/data/deep-merge';
import userModel from 'models/user-model';
import socket from 'util/network/socket';
import {appUrl} from 'util/data/env';

const authorization = () => 'Basic ' + btoa(`${userModel.userId}@${localStorage.getItem('clientId')}:${socket.getSessionId()}`);

const proxyRequest = (url, opts = {}) => new Promise((resolve, reject) =>
    ajax(appUrl + '/proxy/?url=' + url, deepMerge(opts, {
        headers: {
            authorization: authorization()
        },
        resolve,
        reject
    }))
);

proxyRequest.authorization = authorization;

export default proxyRequest;
