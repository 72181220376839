import formModel from 'models/form-model';
import Card from 'views/card/card';
import popup from 'util/popup';
import siteModel from 'models/site-model';
import mediaModel from 'models/media-model';
import {copyToClipboard} from 'util/dom/clipboard';
import isMetric from 'util/numbers/is-metric';
import measure from 'legacy/util/numbers/measure';
import getAssetName from 'util/data/get-asset-name';
import router from 'uav-router';
import constants from 'util/data/constants';
import store from 'util/data/store';
import userModel from 'models/user-model';
import placeModel from 'models/place-model';

const NoFeatureMenu = {
    view: ({attrs: {lngLat, elevation}}) =>
        <div class="feature-menu">
            <div className="lat-lng-menu">
                <div className="lat-lng-title">Lat-Lng</div>
                <span className="lat-lng-copy" onclick={() => copyToClipboard(`${lngLat.lat},${lngLat.lng}`)}>
                    <div className="copy-text">Copy</div>
                </span>
                <div>{lngLat.lat}</div>
                <div>{lngLat.lng}</div>
                {elevation &&
                    <div>
                        <div className='lat-lng-title'>Elevation</div>
                        {isMetric() ? elevation.toFixed(2) + 'm' : measure.metersToFeet(elevation).toFixed(2) + 'ft'}</div>}
            </div>
        </div>
};

const EditFeatureLineItem = {
    view: ({attrs: {assetId, feature}}) => {
        const disableEditing = feature.properties[constants.trimbleDataKey];
        return <li class={`menu-option${disableEditing ? ' disabled' : ''}`} onclick={() => formModel.validateThenRun(() => {
            formModel.viewAsset(assetId, 'Properties');
            formModel.editFeature(feature);
        })}>
            <i class={`icon-${disableEditing ? 'locked' : 'edit'}`} /> Edit on Map
            {!!disableEditing && <div class="gps-tip"><img class="trimble" src="/images/trimble.png" /> Positioned with Trimble</div>}
        </li>;
    }
};

const SingleAsset = {
    view: ({attrs: {assetId, features}}) => {
        const asset = store.assets[assetId],
            tool = store.tools[asset.attributes.toolId];
        let isProjectFeature,
            projectId;
        if (siteModel.isMetaProject) {
            const projectControlTypeId = constants.controlTypeNameToId.project;
            isProjectFeature = features.find(f => {
                const featureType = store.featureTypes[f.properties.featureTypeId];
                if (featureType.attributes.interface === 'project') {
                    const label = tool.assetForm.controls.find(control => control.controlTypeId === projectControlTypeId).label;
                    projectId = asset.properties[label];
                    return true;
                }
                return false;
            });
        }
        return <div class="feature-menu">
            <Card assetId={assetId}/>
            <div class="link-list">
                <ul>
                    {formModel.assetId !== assetId &&
                        <li class="menu-option" onclick={() => formModel.validateThenRun(() => formModel.viewAsset(assetId, 'Properties'))}>
                            <i class="icon-asset-form"/> View Properties
                        </li>}
                    {features.length === 1 && !userModel.isViewOnly &&
                        <EditFeatureLineItem assetId={assetId} feature={features[0]} />}
                    {!!isProjectFeature && <li class="menu-option go-to-project-option" onclick={() => router.set({projectId})}>
                        <span class="btn btn-pill btn-secondary btn-small"><span class="btn-label">Go to {tool.name}</span></span>
                    </li>}

                </ul>
            </div>
        </div>;
    }
};

const MultiAsset = {

    chooseAsset: (assetId, features, lngLat) => {

        siteModel.map.getContainer().click();

        popup.add({
            className: 'feature-menu-popup',
            content: <SingleAsset assetId={assetId} features={features.filter(f => f.properties.assetId === assetId)} />,
            lngLat,
            maxWidth: 'none'
        });

    },

    view: ({attrs: {assetIds = [], placeIds = [], features, lngLat}}) => {
        return <div class="feature-menu select-feature">
            <p>Select a feature:</p>
            <div class="link-list">
                <ul>
                    {[assetIds.map(assetId => {
                        return <li key={assetId} class="menu-option"
                            onclick={() => MultiAsset.chooseAsset(assetId, features, lngLat)}>
                            {mediaModel.getIcon(assetId)} <span
                                class="asset-title">{getAssetName(assetId)}</span>
                        </li>;
                    }),
                    placeIds.map((placeId) => {
                        const place = store.places[placeId];
                        return <li
                            key={placeId}
                            class="menu-option"
                            onclick={() => placeModel.showPlacePopups([placeId], lngLat)}>
                            <i class="icon-place"/> <span
                                class="asset-title">{place.name}</span>
                        </li>;
                    })].flat()}
                </ul>
            </div>
        </div>;
    }
};

const FeatureMenu = {
    view: ({attrs: {assetIds = [], placeIds = [], features, lngLat, elevation}}) => {
        switch (assetIds.length + placeIds.length) {
        case 0:
            return <NoFeatureMenu lngLat={lngLat} elevation={elevation} />;
        case 1:
            return <SingleAsset assetId={assetIds[0]} features={features.filter(f => f.properties.assetId === assetIds[0])} />;
        default:
            return <MultiAsset assetIds={assetIds} placeIds={placeIds} features={features} lngLat={lngLat} />;
        }
    }
};

export default FeatureMenu;
