import featureToControl from 'util/interfaces/feature-to-control';
import NextButton from 'legacy/components/next-button';
import siteModel from 'models/site-model';
import LineString from 'util/draw/linestring';
import featureModel from 'models/feature-model';
import isMetric from 'util/numbers/is-metric';
import store from 'util/data/store';
import formModel from 'models/form-model';
import ToolInterface from 'util/interfaces/tool-interface';
import GeometryDetail from 'views/geometry-detail';
import controlToFeature from 'util/interfaces/control-to-feature';
import volumeModel from 'models/volume-model';
import helpers from 'legacy/util/api/helpers';

class PolylineInterface extends ToolInterface {

    init() {

        this.color = this.featureType.properties._lineColor || this.featureType.properties._fillColor;

        if (!this.color) {

            const colorStyles = ['line-color', 'fill-color', 'fill-outline-color'];

            this.tool.featureStyles.find(f => {

                const paint = f.style.paint;

                if (paint) {

                    const colorStyle = colorStyles.find(c => typeof paint[c] === 'string');

                    if (colorStyle) {

                        this.color = paint[colorStyle];

                        return true;

                    }

                }

                return false;

            });

        }

        this.source = siteModel.map.getSource(this.featureType.featureTypeId);

    }

    constructor(...args) {

        super(...args);

        this.type = 'polyline';

        this.DrawClass = LineString;

        this.init();

    }

    close() {

        if (this.draw) {

            this.draw.stop();

        }

        if (!this.isSaved) {

            featureModel.deleteFeature(this.feature, this.source);

            this.source.setData(this.source._data);

        }

        this.onClose();

    }

    setPrompt() {
        this.prompt = {view: () => 'Tap on the map to draw the ' + this.tool.name};
    }

    updatePrompt() {

        if (this.feature.geometry.coordinates.length > 2) {

            this.prompt = {view: () => 'Tap the last point to complete the ' + this.tool.name};

        }
    }

    launch() {

        this.setPrompt();

        this.showToolLauncher();

        return new Promise(resolve => {

            const asset = store.assets[this.assetId];

            const saveAndClose = () => {
                this.isSaved = true;
                this.close();
                resolve([asset]);
            };

            this.done = () => {

                if (this.verifyDone && !this.verifyDone()) {
                    return;
                }

                if (this.beforeDone) {

                    this.beforeDone().then(() => saveAndClose());

                } else {

                    saveAndClose();

                }

            };

            featureModel.setupFeatureProperties(this.feature).then(() => {

                this.draw = new this.DrawClass({
                    map: siteModel.map,
                    source: this.source,
                    metric: isMetric(),
                    color: this.color
                }).create(this.feature.properties);

                this.draw.onVertexAdded = _feature => {

                    this.feature = _feature;

                    featureModel.addLatitudeToFeature(_feature);

                    if (this.updatePrompt) {

                        this.updatePrompt();

                    }

                    m.redraw(); // hide "skip mapping" button

                };

                if (this.featureType.attributes.repeat) {

                    const nextButton = new NextButton();

                    this.draw.onComplete = _feature => {

                        this.feature = _feature;

                        store.features[_feature.id] = _feature;

                        asset.featureIds = helpers.list(asset.featureIds);
                        asset.featureIds.push(_feature.id);

                        this.draw.create();

                        nextButton.show({
                            onclick: () => {

                                featureToControl.sync(this.type, _feature, this.assetId);

                                this.done();

                            }
                        });

                    };

                } else {

                    this.draw.onComplete = _feature => {

                        this.feature = _feature;

                        store.features[_feature.id] = _feature;

                        asset.featureIds = helpers.list(asset.featureIds);
                        asset.featureIds.push(_feature.id);

                        featureToControl.sync(this.type, _feature, this.assetId);

                        controlToFeature.syncAllFeatureProperties(this.assetId, _feature);

                        this.done();

                    };

                }

            });

        });

    }

    edit(_feature) {

        this.isSaved = true;

        this.feature = this.source._data.features.find(f => f.id === _feature.id);

        if (!this.feature) {

            return;

        }

        this.draw = new this.DrawClass({
            map: siteModel.map,
            source: this.source,
            metric: isMetric(),
            color: this.color
        }).edit(this.feature);

        this.draw.onVertexChanged = () => {

            featureModel.addLatitudeToFeature(this.feature);

            featureToControl.sync(this.type, this.feature, this.assetId);

            controlToFeature.syncAllFeatureProperties(this.assetId, this.feature);

            this.source.setData(this.source._data);

            GeometryDetail.update();

            volumeModel.vertexChanged();

            this.autosave(this.feature);

        };

        this.draw.onStop = formModel.onEditStop;

        m.redraw();

    }

    completeFeature() {
        this.draw.completeFeature();
    }

}

export default PolylineInterface;
