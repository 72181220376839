class SortableModel {

    createDropZone() {

        const dropZone = document.createElement('div');

        dropZone.setAttribute('class', this.dropZoneClass);

        dropZone.classList.add('drop-zone');

        window.ondrop = e => {

            e.preventDefault();

            if (!this.draggedItem) {

                return;

            }

            const parent = this.draggedItem.parentNode;

            parent.insertBefore(this.draggedItem, dropZone);

            dropZone.remove();

            const newIndex = Array.from(parent.childNodes).indexOf(this.draggedItem);

            const [value] = this.collection.splice(this.startingIndex, 1);

            this.collection.splice(newIndex, 0, value);

            if (this.onDrop) {

                this.onDrop(value, this.collection);

            }

            this.dropZone = null;

            this.draggedItem = null;

            this.collection = null;

            this.objKey = null;

            this.onDrop = null;

            this.dropZoneClass = '';

            window.ondragover = null;

            window.ondrop = null;

        };

        window.ondragover = e => e.preventDefault();

        this.dropZone = dropZone;

    }

    onDragStart(e, opts) {

        e.redraw = false;

        e.stopPropagation();

        const draggedItem = e.target;

        this.draggedItem = draggedItem;
        this.collection = opts.collection;
        this.dropZoneClass = opts.dropZoneClass || '';
        this.startingIndex = Array.from(draggedItem.parentNode.childNodes).indexOf(draggedItem);

        this.onDrop = opts.onDrop;

        this.createDropZone();

    }

    onDragOver(e) {

        e.redraw = false;

        const draggedItem = this.draggedItem,
            target = e.currentTarget || e.target,
            parentNode = draggedItem.parentNode;

        if (parentNode === target.parentNode) {

            if (e.pageY > target.getBoundingClientRect().top) {

                if (target.nextElementSibling !== draggedItem || this.dropZone.parentNode) {

                    parentNode.insertBefore(this.dropZone, target.nextElementSibling);

                }

            } else {

                parentNode.insertBefore(this.dropZone, target);

            }

        } else if (e.pageY < parentNode.getBoundingClientRect().top) {

            parentNode.insertBefore(this.dropZone, parentNode.firstElementChild);

        }

    }

}

export default new SortableModel();
