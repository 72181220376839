import selectRange from 'legacy/util/dom/select-range';

let preventSave;

class EditableTextModel {

    constructor(attrs) {
        Object.assign(this, attrs);
        this.untitled = !attrs.text;
        this.isEditing = false;
    }

    allowSave() {
        requestAnimationFrame(() => {
            preventSave = false;
        });
    }

    catchEnter(e) {

        e.stopPropagation();

        if (e.which === 13) {

            e.preventDefault();

            e.target.blur();

        }

    }

    catchEscape(e) {

        e.stopPropagation();

        if (e.which === 27) {

            this.cancel(e.target);

        }
    }

    edit(e) {

        if (e) {

            e.stopPropagation();

        }

        if (!this.spinning) {

            const input = e.target.previousElementSibling;

            input.setAttribute('contenteditable', 'true');

            selectRange(input);

            this.untitled = false;

            this.isEditing = true;
            this.changeEditingClass('add', input);

            m.redraw();

        }
    }

    changeEditingClass(action = 'toggle', input) {

        if (this.parentSelector) {

            document.querySelector(this.parentSelector).classList[action]('editing');

        } else {

            input.parentNode.classList[action]('editing');

        }

    }

    cancel(input) {

        preventSave = true;

        input.removeAttribute('contenteditable');

        this.isEditing = false;
        this.changeEditingClass('remove', input);

        this.text = this.text || 'Add a title';

        input.textContent = this.text;

        this.untitled = input.textContent === 'Add a title';

        this.allowSave();

    }

    rename(e) {

        e.preventDefault();

        e.stopPropagation();

        selectRange.clear();

        if (preventSave) {

            return;

        }

        const input = e.target;

        if (input.textContent === this.text || input.textContent === '') {

            this.cancel(input);

        } else {

            input.removeAttribute('contenteditable');

            this.untitled = false;

            this.text = input.textContent;

            this.spinning = true;

            this.save(this.text, () => {

                this.spinning = false;

                this.isEditing = false;
                this.changeEditingClass('remove', input);

                m.redraw();

            });

        }
    }
}

export default EditableTextModel;
