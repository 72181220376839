import constants from 'util/data/constants';
import layerModel from 'models/layer-model';
import stopPropagation from 'util/events/stop-propagation';
import router from 'uav-router';
import capitalize from 'util/data/capitalize';
import store from 'util/data/store';
import pointMenu from 'legacy/components/point-menu';
import convertMithril from 'util/dom/convert-mithril';
import PlanDetails from 'views/plan-details';
import modal from 'legacy/components/modal';
import userModel from 'models/user-model';
import api from 'legacy/util/api';
import Sortable from 'views/sortable';
import stakeableModel from 'models/stakeable-model';
import tableModel from 'models/table/table-model';
import formModel from 'models/form-model';
import formatDate from 'legacy/util/date/format-date';
import siteModel from 'models/site-model';
import sideNavModel from 'models/side-nav-model';

const PlansLayer = {
    view: () => {
        const planIds = store.project.attributes && store.project.attributes.planZOrder || [];
        return planIds.length
            ? <div class={layerModel.expanded.plans ? 'expanded' : ''}>
                <div class="layer-picker-item layer-picker-item-plans" onclick={() => layerModel.togglePlans()}>
                    <i class={`toggle-switch toggle-is-${layerModel.state.doShowPlans ? 'on' : 'off'}`}/>
                    <span class="layer-picker-item-name">Plans</span>
                    <i class={'icon-down icon'} onclick={e => layerModel.expandContract(e, 'plans')}/>
                </div>
                <Sortable
                    className="layer-picker-subitems layer-picker-plans"
                    collection={planIds}
                    onDrop={planId => layerModel.savePlanOrder(planId)}
                    dropZoneClass="layer-picker-item"
                    view={planId => {
                        const plan = store.plans[planId];
                        return plan && !plan.isEmpty
                            ? <div class="layer-picker-item" onclick={() => layerModel.togglePlan(plan)}>
                                <i class="icon-ellipsis drag-handle"/>
                                <i class={'toggle-switch ' + layerModel.getPlanIcon(plan)}/>
                                <i class="icon-plan asset-icon"/>
                                <span class="layer-picker-item-name asset-title">{plan.title}</span>
                                {userModel.isContentEditable(plan) && !(router.params.view === 'layer' && router.params.planId === planId)
                                    ? <i class="icon-right" onclick={e => layerModel.togglePlanDetails(plan, e)}/>
                                    : null}
                            </div>
                            : null;
                    }}/>
            </div>
            : <div/>;
    }
};

const PlaceLayer = {
    view: ({attrs: {place}}) =>
        <div class="layer-picker-item" onclick={() => layerModel.togglePlace(place)}>
            <i class={`toggle-switch toggle-is-${layerModel.state.placeIds.has(place.placeId) ? 'on' : 'off'}`}/>
            <i class="icon-place asset-icon"/>
            <span class="layer-picker-item-name asset-title">{place.name}</span>
        </div>
};

const PlacesLayer = {
    view: () =>
        <div class={layerModel.expanded.places ? 'expanded' : ''}>
            <div class="layer-picker-item" onclick={() => layerModel.togglePlaces()}>
                <i class={`toggle-switch toggle-is-${layerModel.state.doShowPlaces ? 'on' : 'off'}`}/>
                <span class="layer-picker-item-name">Place Boundaries</span>
                <i class={'icon-down icon'} onclick={e => layerModel.expandContract(e, 'places')}/>
            </div>
            <div class="layer-picker-subitems">
                {Object.values(store.places).map(place => <PlaceLayer place={place} key={place.placeId}/>)}
                <div className="layer-picker-manage-places" onclick={() => sideNavModel.openMetaAsset('Places/Levels')}>
                    <i className='icon-add-place'/> Manage Places/Levels
                </div>
            </div>
        </div>
};

const LinksLayer = {
    view: () => <div class="layer-picker-item layer-picker-links" onclick={() => layerModel.toggleLinks()}>
        <i class={`toggle-switch toggle-is-${layerModel.state.doShowLinks ? 'on' : 'off'}`}/>
        <span class="layer-picker-item-name">Links</span>
        <div class="layer-picker-info">Show assets linked to the current dataset on the map.</div>
    </div>
};

const SurveyPopover = {

    renameSurvey: survey =>
        <div>
            <div class="dialog-text">
                <label>Survey Name</label>
                <p></p>
                <input type="text" value={survey.title || formatDate.formatDay(survey.surveyDateTime, true)} oninput={e => {
                    survey.title = e.target.value;
                }}/>
            </div>
            <div class="warning">
                <a class="dialog-btn dialog-yes" onclick={modal.hide}>Cancel</a>
                <a class="dialog-btn dialog-no" onclick={() => {
                    api.rpc.modify('Survey', {
                        surveyId: survey.surveyId,
                        title: survey.title
                    });
                    if (survey.surveyId === router.params.survey) {
                        stakeableModel.recordTitle = survey.title || formatDate.formatDay(survey.surveyDateTime, true);
                    }
                    m.redraw();
                    modal.hide();
                }}>Save</a>
            </div>
        </div>,

    view: ({attrs: {survey, canEdit}}) =>
        <div class="link-list">
            <div class="menu-option show-on-large" onclick={() => formModel.validateThenRun(() => router.merge({view: 'survey', survey: layerModel.state.surveyId}))}>
                <i class="icon-marker-outline"/>
                GeoCorrect Survey
            </div>
            {canEdit ? <div class="menu-option" onclick={() => modal.show({view: convertMithril.toDom(SurveyPopover.renameSurvey(survey))})}>
                <i class="icon-edit"/>
                Rename Survey
            </div> : null}
            {canEdit ? <div class="menu-option remove-link" onclick={() => layerModel.deleteSurveyDialog(survey)}>
                <i class="icon-trash"/>
                Delete Survey
            </div> : null}
        </div>

};

const SurveyMenu = {

    view: () => {

        if (!layerModel.state.surveyId) {
            return null;
        }

        const survey = store.surveys[layerModel.state.surveyId],
            canEdit = survey && userModel.userId === survey.authorId;

        if (canEdit || window.innerWidth >= 1024) {

            return <i class="icon-ellipsis" onclick={e => pointMenu({
                e,
                content: convertMithril.toDom(<SurveyPopover survey={survey} canEdit={canEdit} />),
                forceDesktop: true,
                noLine: true
            })}/>;

        }

        return null;

    }

};

const SurveyPicker = {
    view: () =>
        <div class="clear">
            <div class={'select-wrapper select-survey' + (layerModel.state.surveyId ? ' active' : '')}>
                <select class="layer-picker-survey-select" onchange={e => layerModel.setSurvey(e.target.value)} value={layerModel.state.surveyId || ''}>
                    <option value="">No Survey</option>
                    {Object.values(store.surveys).map(survey => survey.status === 'ready'
                        ? <option value={survey.surveyId}>{survey.title || formatDate.formatDay(survey.surveyDateTime, true)}</option>
                        : null
                    )}
                </select>
            </div>
            <SurveyMenu />
        </div>
};

const LayerPicker = {

    onremove: () => {

        layerModel.focusedPlan = null;

    },

    view: ({attrs}) =>
        <div class="layer-picker" onclick={stopPropagation()}>
            {layerModel.focusedPlan ? <PlanDetails /> : null}
            <div class="layer-picker-head"><i class="icon-layers"/>Map Layers</div>
            <div class="layer-picker-layers">
                <PlansLayer/>
                {!siteModel.isMetaProject && <PlacesLayer/>}
                {!attrs.hideLinks && !tableModel.loadingTable && <LinksLayer/>}
            </div>
            <div class="layer-picker-basemaps">
                {Object.keys(store.surveys).length ? <SurveyPicker/> : null}
                <div class="select-wrapper select-basemap">
                    <select class="layer-picker-basemap-select" onchange={e => layerModel.setBasemap(e.target.value)} value={layerModel.state.basemapId}>
                        {Object.keys(constants.basemaps).map(basemapId =>
                            <option key={basemapId} value={basemapId}>{capitalize(basemapId)} Basemap</option>
                        )}
                        <option value="0">No Basemap</option>
                    </select>
                </div>
            </div>
        </div>
};

export default LayerPicker;
