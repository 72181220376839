import store from 'util/data/store';
import router from 'uav-router';
import initializer from 'util/initializer';
import api from 'legacy/util/api/api';
import publish from 'legacy/util/api/publish';
import dialogModel from 'models/dialog-model';
import message from 'legacy/components/message';
import modalModel from 'models/modal-model';
import PersonModel from 'models/people/person-model';
import userModel from 'models/user-model';
import {Profile} from 'views/people/profile';
import constants from 'util/data/constants';
import {apiPhoneNumber} from 'util/data/phone-number';
import helpers from 'legacy/util/api/helpers';
import ProjectPeopleModel from 'models/people/project-people-model';
import toolboxModel from 'models/toolbox-model';
import sideNavModel from 'models/side-nav-model';
import siteModel from 'models/site-model';
import formModel from 'models/form-model';
import appModel from 'models/app-model';
import debouncedAPICall from 'util/network/debounced-api-call';

const DEFAULT_ACCOUNT_ROLE = 'general';
const UNKNOWN_USER = 'Unknown User';
const UNKNOWN_USER_NAME = 'New User';
const MAX_NUM_CACHED_PROJECTS = 5;

// Handles data and logic for list of account and project users
// (See user-model for code related to the logged-in user)
class peopleModel {

    constructor() {
        this.reset();
        initializer.add(this.awaitChanges.bind(this));
    }

    reset() {
        this.all = {}; // Mapping of visible accountUser userIds:PersonModel
        this.hidden = {}; // Mapping of hidden accountUser userIds:PersonModel
        this.projectPeople = {}; // Mapping of all account projectIds:ProjectPeopleModel
        this.currentlyViewing = undefined;
        this._list = [];
    }

    /**
     * To run with each new site-model fetch().
     */
    onSiteFetch() {
        this.getPeopleForProjectId(appModel.getState('editingProjectId'));
    }

    // --------------- Getters ---------------

    get list() {
        return this._list;
    }

    getPerson(userId) {
        return this.all[userId] || this.hidden[userId];
    }

    displayName(user) {
        if (!user) {
            return UNKNOWN_USER;
        }
        return user.givenName && user.familyName ? `${user.givenName} ${user.familyName}` : user.givenName || UNKNOWN_USER_NAME;
    }

    displayNameOrEmail(userId) {
        const user = this.getPerson(userId);
        if (!user) {
            return UNKNOWN_USER;
        }
        return user.givenName && user.familyName ? `${user.givenName} ${user.familyName}` : user.givenName || user.emailAddress;
    }

    isInPeopleList(userId) {
        return this.all[userId] || this.hidden[userId];
    }
    
    getControlOptions() {
        const projectId = appModel.getState('editingProjectId');
        if (this.projectPeople[projectId]) {
            return this.projectPeople[projectId].controlOptions;
        }
        this.getPeopleForProjectId(projectId).then(projectPeople => {
            m.redraw();
            return projectPeople.controlOptions;
        });
    }

    get controlOptionFilter() {
        return this._list;
    }

    displayUserControlOption(userId) {
        return this.displayNameOrEmail(userId);
    }

    permissionLevel(role) {
        return constants.permissionsRanks[role] || -1;
    }

    hasAdminPrivileges(user) {
        return this.permissionLevel(user.role) >= 25 || user.isSuperAdmin;
    }

    // --------------- Loading initial data ---------------

    /*
     * Load each account user from store to model.
     */
    initAccount() {
        this.reset();
        const users = [...store.account.users];
        // Sort users by first name, last name when first loaded
        users.sort((a, b) => `${a.givenName} ${a.familyName}`.localeCompare(`${b.givenName} ${b.familyName}`));
        users.forEach(user => this.addAccountUser(user));
    }

    /*
     * Fetches or returns cached project people. Returns them sorted by project access level and first and last name.
     */
    getPeopleForProjectId(projectId = appModel.getState('editingProjectId')) {
        // If the user list doesn't exist for the project yet, fetch it:
        if (this.projectPeople[projectId]) {
            this.projectPeople[projectId].refreshListOrder();
            return Promise.resolve(this.projectPeople[projectId]);
        }
        return this.fetchProjectUsers(projectId).then(projectUsers => this.loadProjectUsers(projectId, projectUsers));
    }

    /*
     * Fetch users from api
     */
    fetchProjectUsers(projectId) {
        return debouncedAPICall('getProjectUsers' + projectId, ['getProject', {projectId}]).then((project) => helpers.list(project.users));
    }

    /*
     * Load project users into cache as model
     */
    loadProjectUsers(projectId, projectUserList) {
        this.clearCacheIfMaxed();
        this.projectPeople[projectId] = new ProjectPeopleModel(projectId, projectUserList, this._list);
        m.redraw();
        return this.projectPeople[projectId];
    }

    clearCacheIfMaxed() {
        if (Object.keys(this.projectPeople).length >= MAX_NUM_CACHED_PROJECTS) {
            this.projectPeople = {};
        }
    }

    // --------------- Modifying front end data ---------------

    /* Add account person to front end */
    addAccountUser(user) {
        let person;
        if (user.userId !== userModel.userId) {
            person = new PersonModel(user);
        } else {
            person = userModel; // This is the logged-in user
        }
        if (user.isVisible) {
            this.all[person.userId] = person;
            // Maintain order based on created date for now.
            this._list.push(user.userId);
        } else {
            this.addHiddenUser(person);
        }
    }

    addHiddenUser(user) {
        this.hidden[user.userId] = new PersonModel(user);
    }

    /* Remove account person from front end */
    removeAccountUser(userId) {
        Object.keys(this.projectPeople).forEach(projectId =>
            this.projectPeople[projectId].removePerson(userId));
        this._list = this._list.filter(id => id !== userId);
        delete this.all[userId];
    }

    // --------------- UI Changes ---------------

    openProfile(user) {
        this.currentlyViewing = user;
        modalModel.open({view: Profile});
    }

    openNewPerson() {
        const newUser = new PersonModel({
            accountId: store.account.accountId,
            role: 'viewer'
        });
        this.currentlyViewing = newUser;
        modalModel.open({view: Profile});
    }

    routeToPeopleTab(assetId = router.params.assetId) {
        if (siteModel.isMetaProject) {
            if (router.params.assetId) {
                router.url.mergeReplace({tab: 'People', assetId});
            } else {
                formModel.viewAsset(assetId, 'People');
            }
        } else {
            sideNavModel.openMetaAsset('People');
        }
    }

    // --------------- Saving data to back end ---------------

    // If person has access to project, remove it, else, add it.
    // Update front end and save to back end.
    toggleProjectAccess(userId, projectId) {
        const person = this.getPerson(userId);
        person.savingClass = 'modifying'; // Freezes toggle access until save is complete
        this.projectPeople[projectId].toggleAccess(person).then((action) => {
            message.show(`Person ${action}.`, 'success');
            person.savingClass = '';
            m.redraw();
        });
    }

    submitNewPersonForm(person) {
        person.formState.isSavingNew = person.formState.highlightMissing = true;
        // Check if form is valid and handle accordingly:
        if (!this.userFormIsValid(person)) {
            dialogModel.append({
                headline: 'Required fields incomplete.',
                text: 'Please check that all required fields are complete and resubmit.',
                onOkay: () => {
                    person.formState.isSavingNew = false;
                }
            });
            m.redraw();
            return;
        }
        // If valid, save new person:
        this.saveNewPerson(person);
    }

    // Verify that all required fields have data
    userFormIsValid(person) {
        return person.givenName && person.familyName
            && person.emailAddress && person.phoneNumber;
    }

    // Check if the creation date difference is less than 60 seconds from now
    // TODO Remove and handle according to api response once API-691 is complete
    userAlreadyInSystem(userRecord) {
        return new Date() - new Date(userRecord.createdDateTime) > 60000;
    }

    // Sends new user data to API
    saveNewPerson(person) {
        const accountId = store.account.accountId;
        message.show('Saving new user...', 'warning');
        api.rpc.create('User',
            {
                userBody: {
                    accounts: [{id: accountId, addProjects: false}],
                    phoneNumber: apiPhoneNumber(person.phoneNumber),
                    emailAddress: person.emailAddress,
                    givenName: person.givenName,
                    familyName: person.familyName
                }
            }).then(user => {
            if (this.userAlreadyInSystem(user)) {
                message.hide();
                dialogModel.append({
                    headline: 'Duplicate information found',
                    text: 'The contact information supplied matches an existing user. Please check your form input and try again or contact support for additional assistance.',
                    onOkay: () => {
                        person.formState.isSavingNew = person.formState.highlightMissing = false;
                        m.redraw();
                    }
                });
            } else {
                person.userId = user.userId;
                person.status = user.status;
                // Save account role to API if not default
                if (person.role !== DEFAULT_ACCOUNT_ROLE) {
                    person.saveUserRole();
                }
                // Save user to front end
                this.onSaveComplete(person, accountId);
                m.redraw();
            }
        }).catch(() => {
            message.hide();
            dialogModel.append({
                headline: 'Unable to create new user',
                text: 'There was an error trying to create the new user. Please check your form input and try again or contact support for additional assistance.',
                onOkay: () => {
                    person.formState.isSavingNew = person.formState.highlightMissing = false;
                    m.redraw();
                }
            });
        });
    }

    /*
     * Callback to run when new user is saved successfully to API.
     */
    onSaveComplete(person, accountId) {
        // Make sure we're still on the account
        if (accountId === store.account.accountId) {
            message.show('New user added to account.', 'success');
            this.addAccountUser(person);
            // Add to any project access lists we have cached (default to no project access)
            Object.keys(this.projectPeople).forEach(projectId =>
                this.projectPeople[projectId].addPerson(person.userId)
            );
        }
        modalModel.close();
    }

    deletePerson(userId) {
        const person = this.getPerson(userId);
        dialogModel.open({
            headline: `Remove this user's access to ${store.account.name}?`,
            text: 'Please note that this operation cannot be undone.',
            yesClass: 'btn btn-pill btn-red',
            noText: 'Cancel',
            noClass: 'btn btn-pill btn-secondary',
            onYes: () => {
                message.show('Removing user from account...', 'warning');
                person.savingClass = 'removing';
                api.rpc.request([['removeAccountUser', {
                    userId,
                    accountId: store.account.accountId
                }]]).then(() => {
                    this.removeAccountUser(userId);
                    message.show('User removed from account.', 'success');
                    m.redraw();
                });
            }
        });
    }

    getRoleDescriptions(role) {
        const {projectPlural, projectSingular} = toolboxModel;
        return {
            viewer: [
                `Can view all content in ${projectPlural} to which they are added`,
                'Cannot add, edit, or delete any content'
            ],
            guest: [
                `Must be added to each ${projectSingular} to view content`,
                `Can view any content in that ${projectSingular}`,
                'Cannot add, edit, or delete any content'
            ],
            general: [
                `Can create ${projectPlural}`,
                `Can edit ${projectSingular} properties for ${projectPlural} they create`,
                `Can view, edit, and delete all content in ${projectPlural} to which they are added`
            ],
            admin: [
                `Can create new ${projectPlural} and add new people to the account`,
                `Full access and permissions on all ${projectSingular} properties and ${projectSingular} content`
            ],
            owner: [
                `Can create new ${projectPlural} and add new people to the account`,
                `Full access and permissions on all ${projectSingular} properties and ${projectSingular} content`,
                'Full access and permissions to manage account details'
            ],
            limited: [
                `Can view all content in ${projectPlural} to which they are added`,
                `Can create new content in ${projectPlural} to which they are added`,
                'Can update content only if assigned to them'
            ],
            superadmin: []
        }[role];
    }

    // ---------- Publishing back end updates ----------

    awaitChanges() {
        // TODO: API-671 work required, new users not publishing with associated accountId.
        // publish.await({
        //     changeType: 'new',
        //     recordType: 'user',
        //     test: (change) => true,
        //     callback: user => {
        //         if (user.accountId === store.account.accountId) {
        //             this.addAccountUser(user);
        //             let projectId = router.params.projectId;
        //             // If we're viewing a project asset, add it to (the bottom of) that list as well:
        //             if (siteModel.isMetaProject) {
        //                 projectId = assetIdToProjectId(router.params.assetId);
        //                 this.projectListOrder.push(user.userId);
        //             }
        //             if (user.projectId === projectId) {
        //                 const person = this.getPerson(user.userId);
        //                 this.addPersonToProject(user.projectId, person);
        //             }
        //         }
        //         m.redraw();
        //     },
        //     persist: true
        // });

        publish.await({
            changeType: 'modified',
            recordType: 'user',
            test: change => this.isInPeopleList(change.userId),
            callback: user => {
                const person = this.getPerson(user.userId);
                person.syncUserData(user);
                m.redraw();
            },
            persist: true
        });

        // TODO: API-671 work required, removed users not publishing.
        // publish.await({
        //     changeType: 'deleted',
        //     recordType: 'user',
        //     test: change => this.isInPeopleList(change.userId),
        //     callback: user => {
        //         this.removeAccountUser(user.userId);
        //         m.redraw();
        //     },
        //     persist: true
        // });

    }
}


export default new peopleModel();
