import socket from 'util/network/socket';
import siteModel from 'models/site-model';
import initializer from 'util/initializer';
import publish from 'legacy/util/api/publish';
import store from 'util/data/store';
import bbox from '@turf/bbox';
import difference from '@turf/difference/index.js';
import union from '@turf/union';
import {length, bounds} from 'util/geo';
import validate from 'util/geo/validate';
import formModel from 'models/form-model';
import constants from 'util/data/constants';
import filterModel from 'models/table/filter-model';
import userModel from 'models/user-model';
import api from 'legacy/util/api/api';
import dialogModel from 'models/dialog-model';
import getAssetName from 'util/data/get-asset-name';
import commonFilterModel from 'models/table/common-filter-model';

initializer.add(() => featureModel.cleanup());

const MAX_IMAGE_WIDTH = 256,
    TRIMBLE_DATA_KEY = constants.trimbleDataKey;

let isFirstLoadOfSession = true;

const featureModel = {

    cleanup() {

        featureModel.imageWidths = {};

        if (featureModel.streams) {

            featureModel.streams.forEach(abort => abort());

        }

        featureModel.streams = [];

        delete featureModel.args;

    },

    getPlaceFeature(place) {

        return featureModel.addLatitudeToFeature({
            type: 'Feature',
            id: place.placeId,
            geometry: {
                type: 'Polygon',
                coordinates: place.boundary.coordinates
            },
            properties: {
                _placeId: place.placeId === 'siteBounds' ? false : place.placeId
            }
        });

    },

    addImage(id, src, feature) {

        let sourceWidthPx = featureModel.imageWidths[id];

        if (sourceWidthPx !== undefined) {

            if (feature && sourceWidthPx !== 'loading') {

                if (sourceWidthPx.then) {

                    sourceWidthPx = sourceWidthPx.then(() => {

                        feature.properties._sourceWidthPx = featureModel.imageWidths[id];

                    });

                } else {

                    feature.properties._sourceWidthPx = sourceWidthPx;

                }

            }

            return sourceWidthPx.then ? sourceWidthPx : Promise.resolve();

        }

        featureModel.imageWidths[id] = new Promise((resolve) => {

            const img = new Image();

            img.crossOrigin = 'Anonymous';

            img.onerror = () => {

                img.onerror = () => resolve();

                img.src += '?d=' + new Date().getTime();

            };

            img.onload = () => {

                if (img.width > MAX_IMAGE_WIDTH) {

                    const aspectRatio = img.width / img.height;

                    img.width = MAX_IMAGE_WIDTH;
                    img.height = MAX_IMAGE_WIDTH / aspectRatio;

                }

                featureModel.imageWidths[id] = img.width;

                if (feature) {

                    feature.properties._sourceWidthPx = img.width;

                }

                siteModel.map.addImage(id, img);

                resolve(true);

            };

            img.src = src;

        });

        return featureModel.imageWidths[id];

    },

    addLatitudeToFeature(feature) {

        const geometryType = feature.geometry.type,
            coords = feature.geometry.coordinates;

        if (coords && coords.length) {

            let latitude;

            if (geometryType === 'Polygon' || geometryType === 'MultiLineString') {

                latitude = coords[0][0][1];

            } else if (geometryType === 'LineString' || geometryType === 'MultiPoint') {

                latitude = coords[0][1];

            } else if (geometryType === 'Point') {

                latitude = coords[1];

            } else if (geometryType === 'MultiPolygon') {

                latitude = coords[0][0][0][1];

            }

            feature.properties._latitude = latitude * Math.PI / 180; // to radians

        }

        return feature;

    },

    setupFeatureProperties(feature) {
        const featureTypeId = feature.properties.featureTypeId,
            featureType = store.featureTypes[featureTypeId],
            featureStyleIds = store.featureTypeToStyles[featureTypeId],
            promises = [];

        featureModel.addLatitudeToFeature(feature);

        feature.properties = Object.assign({}, featureType.properties, feature.properties);

        featureStyleIds.forEach(featureStyleId => {

            const style = store.featureStyles[featureStyleId].style;

            const iconImage = feature.properties._iconImage || style.layout && style.layout['icon-image'];

            if (iconImage) {

                if (typeof iconImage === 'string') {

                    promises.push(featureModel.addImage(iconImage, constants.mediaURL + iconImage, feature));

                } else if (iconImage[0] === 'match') {

                    const propertyName = iconImage[1][1],
                        value = feature.properties[propertyName],
                        currentStopIndex = iconImage.slice(2).findIndex(item => item === value),
                        mediaId = currentStopIndex === -1
                            ? iconImage[iconImage.length - 1]
                            : iconImage[currentStopIndex + 3];

                    promises.push(featureModel.addImage(mediaId, constants.mediaURL + mediaId, feature));

                } else if (iconImage.stops) {

                    let mediaId;

                    iconImage.stops.find(stop => {

                        if (feature.properties[iconImage.property] === stop[0]) {

                            mediaId = stop[1];

                            promises.push(featureModel.addImage(mediaId, constants.mediaURL + mediaId, feature));

                        }

                        return mediaId;

                    });

                    if (!mediaId && iconImage.default) {

                        mediaId = iconImage.default;

                        promises.push(featureModel.addImage(mediaId, constants.mediaURL + mediaId, feature));

                    }

                }

            }

        });

        return Promise.all(promises);

    },

    addFeature(feature) {

        return featureModel.setupFeatureProperties(feature).then(() => {

            const source = siteModel.map.getSource(feature.properties.featureTypeId);

            source._data.features.push(feature);

        });

    },

    setupFeatureObject(apiFeature, featureId) {
        const properties = apiFeature.properties || {};
        if (!apiFeature.properties) {
            apiFeature.properties = properties;
        }
        // Trimble location data is stored at properties[TRIMBLE_DATA_KEY].
        // It is a large blob that we don't use on web,
        // so we can replace it with a small flag.
        if (properties[TRIMBLE_DATA_KEY]) {
            properties[TRIMBLE_DATA_KEY] = true;
        }
        return {
            type: 'Feature',
            id: featureId,
            geometry: apiFeature.geometry,
            properties: Object.assign({}, properties, {
                _id: featureId,
                assetId: apiFeature.assetId || properties.assetId,
                featureTypeId: apiFeature.featureTypeId || properties.featureTypeId
            })
        };
    },

    addFeatures(features) {

        const map = siteModel.map,
            sourceIds = {};

        const promises = features.map(feature => {

            const properties = feature.properties,
                featureId = feature.featureId || feature.id || properties._id;

            if (store.features[featureId]) {

                return Promise.resolve();

            }

            feature = featureModel.setupFeatureObject(feature, featureId);

            store.features[featureId] = feature;

            sourceIds[feature.properties.featureTypeId] = 1;

            return featureModel.addFeature(feature);

        });

        return Promise.all(promises).then(() => {

            Object.keys(sourceIds).forEach(sourceId => {

                const source = map.getSource(sourceId);

                // Sort features by size so that larger features don't obstruct smaller ones.
                // We're using the diagonal of the bounding box as a proxy for size
                // because it's faster to calculate than exact area.

                const unsortedFeatures = source._data.features;

                source._data.features = unsortedFeatures.map((feature, i) => {

                    const box = bbox(feature);

                    return {
                        size: length([
                            [box[0], box[1]],
                            [box[2], box[3]]
                        ]),
                        i
                    };

                }).sort((a, b) => b.size - a.size)
                    .map(result => unsortedFeatures[result.i]);

                source.setData(source._data);

            });

            return features;

        }).catch(err => console.error(err));

    },

    clearAllFeatures() {

        const sourcesToClear = {};

        Object.values(store.features).forEach(feature => {

            sourcesToClear[feature.properties.featureTypeId] = true;

        });

        Object.keys(sourcesToClear).forEach(featureTypeId => {

            const source = siteModel.map.getSource(featureTypeId);

            source._data.features = [];

            source.setData(source._data);

        });

        store.features = {};

    },

    onMove() {

        if (!featureModel.args) {
            return;
        }

        if (!filterModel.searchArea && commonFilterModel.selectedPlacesLevelsIndex !== 1) {

            const mapBounds = siteModel.map.getBounds(),
                nw = mapBounds.getNorthWest().toArray();

            let boundary = {
                type: 'Polygon',
                coordinates: [[
                    nw,
                    mapBounds.getNorthEast().toArray(),
                    mapBounds.getSouthEast().toArray(),
                    mapBounds.getSouthWest().toArray(),
                    nw
                ]]
            };

            if (featureModel.loadedBoundary) {
                try {
                    boundary = difference(boundary, featureModel.loadedBoundary);
                } catch (e) {
                    return;
                }
                boundary = boundary && boundary.geometry;
            }

            if (!boundary || !validate(boundary)) {
                return;
            }

            featureModel.args.geometry = {
                intersects: boundary
            };

        }

        featureModel.streamFeatures();

    },

    search: leaveExistingFeatures => {

        featureModel.streams.forEach(abort => abort());

        featureModel.args = Object.assign({}, filterModel.getArgs(), {
            limit: undefined,
            include: undefined,
            offset: undefined
        });

        if (!featureModel.args.geometry) {

            const mapBounds = siteModel.map.getBounds(),
                nw = mapBounds.getNorthWest().toArray();

            featureModel.args.geometry = {
                intersects: {
                    type: 'Polygon',
                    coordinates: [[
                        nw,
                        mapBounds.getNorthEast().toArray(),
                        mapBounds.getSouthEast().toArray(),
                        mapBounds.getSouthWest().toArray(),
                        nw
                    ]]
                }
            };

        }

        featureModel.loadedBoundary = null;

        if (!leaveExistingFeatures) {

            featureModel.clearAllFeatures();

        }

        featureModel.streamFeatures();

    },

    streamCallback(features) {

        featureModel.addFeatures(features.geojson.features);

    },

    streamFeatures() {

        const args = featureModel.args;

        let boundary;

        if (!(isFirstLoadOfSession && siteModel.isMetaProject)) {
            boundary = args.geometry.intersects;
        }

        if (featureModel.loadedBoundary) {

            try {

                featureModel.loadedBoundary = union(boundary, featureModel.loadedBoundary).geometry;

            } catch (e) {

                return;

            }

        } else {

            featureModel.loadedBoundary = boundary;

        }

        // Uncomment to see the boundary being loaded:

        // const feature = {
        //     type: 'Feature',
        //     id: 'thing',
        //     geometry: boundary,
        //     properties: {
        //         featureTypeId: Object.values(store.featureTypes).find(f => f.name === 'Shape').featureTypeId,
        //         _fillOpacity: 0.2,
        //         _fillColor: '#ff0000'
        //     }
        // };

        // featureModel.deleteFeature(feature);

        // featureModel.addFeatures([feature]);

        featureModel.featureCount = undefined;

        const stream = socket.stream('geojson', args, featureModel.streamCallback, featureCount => {

            featureModel.featureCount = featureCount;

            featureModel.streams.splice(featureModel.streams.indexOf(stream), 1);

            siteModel.removeLoadingClass();

            // All of the following is to fit the map bounds to the feature bounds in the following case:
            // - this is a meta project
            // - this is the first map load of the session
            // - the user doesn't have a saved map camera
            if (isFirstLoadOfSession) {

                isFirstLoadOfSession = false;

                if (siteModel.isMetaProject) {

                    const prefs = userModel.preferences;

                    const sitePrefs = prefs
                        && prefs.sitePreferences
                        && prefs.sitePreferences[siteModel.siteId];

                    if (!sitePrefs || !sitePrefs.camera) {

                        const features = Object.values(store.features);

                        if (features.length) {

                            const featureBounds = bounds({
                                type: 'FeatureCollection',
                                features
                            });

                            siteModel.focusOnBounds(featureBounds);

                        }

                    }

                }

            }

        });

        featureModel.streams.push(stream);

    },

    deleteFeaturesFromAsset(assetId) {
        dialogModel.open({
            headline: `Remove ${getAssetName(assetId)} from map?`,
            text: 'Please note that this operation cannot be undone.',
            onYes: () => featureModel.doDeleteFeaturesFromAsset(assetId),
            yesText: 'Remove',
            noText: 'Cancel',
            yesClass: 'btn btn-pill btn-red',
            noClass: 'btn btn-pill btn-secondary'
        });
    },

    doDeleteFeaturesFromAsset(assetId) {
        if (formModel.toolInterface) {
            formModel.toolInterface.close();
        }

        formModel.getAssetFeatures(assetId).then(features => {
            const requests = [];
            let source;

            features.forEach((feature) => {
                requests.push(['deleteFeature', {featureId: feature.id}]);
                source = featureModel.deleteFeature(feature);
            });

            if (source) {
                source.setData(source._data);
            }

            api.rpc.requests(requests);
            store.assets[assetId].featureIds = [];
        });
    },

    deleteFeature(feature, source) {

        source = source || siteModel.map.getSource(feature.properties.featureTypeId);

        const index = source._data.features.findIndex(f => f.id === feature.id);

        if (index !== -1) {

            delete store.features[feature.id];

            source._data.features.splice(index, 1);

        }

        return source;

    },

    removeFilteredFeatures(featureIds) {

        if (!featureIds || !featureIds.length > 0) {

            return;

        }

        const features = featureIds.map(featureId => store.features[featureId]);

        if (features.length) {

            const source = siteModel.map.getSource(features[0].properties.featureTypeId);

            for (const feature of features) {

                if (source) {

                    featureModel.deleteFeature(feature, source);

                }

            }

            source.setData(source._data);

        }

    },

    panToFeatures(features) {
        siteModel.focusOnBounds(bounds({type: 'FeatureCollection', features}));
    },

    awaitChanges() {

        publish.await({
            changeType: 'new',
            recordType: 'feature',
            callback: feature => {

                const assetId = feature.assetId,
                    source = siteModel.map && siteModel.map.getSource(feature.properties.featureTypeId);

                let asset = store.assets[assetId];

                function maybeRender() {

                    if (filterModel.doesMatchFilters(assetId)) {

                        featureModel.addFeatures([feature]);

                    }

                }

                if (asset) {

                    maybeRender();

                } else {

                    publish.await({
                        changeType: 'new',
                        recordType: 'content',
                        test: content => content.contentId === assetId,
                        callback: content => {

                            asset = content;

                            maybeRender();

                        }
                    });

                }

                if (source) {

                    source.setData(source._data);

                }

            },
            persist: true
        });

        publish.await({
            changeType: 'deleted',
            recordType: 'feature',
            test: feature => store.features[feature.featureId],
            callback: feature => {

                if (formModel.editingFeatureId !== feature.id && formModel.assetId !== feature.properties.assetId) {

                    const source = siteModel.map && siteModel.map.getSource(feature.properties.featureTypeId);

                    if (source) {

                        featureModel.deleteFeature(feature, source);

                        source.setData(source._data);

                    }

                }

            },
            persist: true
        });

        publish.await({
            changeType: 'modified',
            recordType: 'feature',
            test: feature => store.features[feature.featureId] && (!feature.changedBy || feature.changedBy.sessionId !== socket.getSessionId()),
            callback: f => {

                const featureId = f.featureId || f.id;

                const feature = store.features[featureId];

                // handle any changes to the presence of
                // trimble location data
                if (f.properties[TRIMBLE_DATA_KEY]) {
                    f.properties[TRIMBLE_DATA_KEY] = true;
                } else if (feature.properties[TRIMBLE_DATA_KEY]) {
                    delete feature.properties[TRIMBLE_DATA_KEY];
                }

                if (formModel.editingFeatureId !== featureId) {

                    feature.geometry = f.geometry;

                    Object.assign(feature.properties, f.properties);

                    featureModel.addLatitudeToFeature(feature);

                    const source = siteModel.map && siteModel.map.getSource(feature.properties.featureTypeId);

                    if (source) {

                        source.setData(source._data);

                    }

                }

            },
            persist: true
        });

    }

};

export default featureModel;
