import 'babel-polyfill';
import 'util/polyfills';
import 'util/log';
import uav from 'uav';
import router from 'uav-router';
import App from 'views/app';
import cache from 'legacy/util/data/cache';
import initializer from 'util/initializer';
import auth from 'legacy/components/auth';
import socket from 'util/network/socket';
import Site from 'views/site';
import hubspot from 'util/hubspot/hubspot';
import PlaceEditor from 'views/place-editor';
import dialogModel from 'models/dialog-model';
import mapboxgl from 'mapbox-gl';
import Survey from 'views/survey';
import Plan from 'views/plan';
import PDF from 'views/pdf';
import OneUp from 'views/one-up/one-up';
import oneUpModel from 'models/one-up-model';
import store from 'util/data/store';

OneUp.init();

if (!mapboxgl.supported()) {
    dialogModel.create('app', {
        warning: true,
        text: 'Your browser is not supported. Please upgrade and try again.',
        onOkay: () => {
            location.href = 'https://bestvpn.org/outdatedbrowser/en';
        },
        okayText: 'Upgrade browser'
    });

} else {

    cache.prep();

    const $app = document.getElementById('app');

    const views = {
        layer: () => Plan,
        survey: () => Survey,
        pdf: () => PDF,
        editplace: () => PlaceEditor,
        forgotpassword: () => auth('forgotPassword'),
        changepassword: () => auth('changePassword')
    };

    function render(view) {

        uav.unbind($app); // unbind previous uav view

        m.mount($app, null); // unmount previous mithril view

        $app.innerHTML = '';

        m.mount($app, App(view));

    }

    router.init(params => {

        document.title = 'Unearth';

        if (params.access_token) {
            store.accessToken = params.access_token;
        }

        if (!params.skipInitializer) {
            initializer.run();
        }

        /* UNAUTHENTICATED ROUTES */

        if (params.code && params.userId) {

            render(auth('resetPassword'));

        } else if (params.signup) {

            render(auth('signUp'));

        } else if (params.view && params.view === 'forgotpassword') {

            render(auth('forgotPassword'));

        } else {

            socket.connect(null, () => {

                try {

                    /* AUTHENTICATED ROUTES */

                    params = router.params;

                    if (views[params.view]) {

                        render(views[params.view]());

                    } else if (params.projectId) {

                        render(Site);

                    } else {

                        dialogModel.create('app', {
                            warning: false,
                            text: 'There is a problem with this account. Please contact support for assistance.',
                            onYes: () => hubspot.loadHubspotChat(),
                            yesText: 'Support',
                            noText: 'Sign Out',
                            noClass: 'btn btn-pill btn-primary',
                            yesClass: 'btn btn-pill btn-secondary',
                            onNo: () => {
                                dialogModel.close();
                                socket.logout();
                            }
                        });

                    }

                } catch (e) {

                    console.error(e);

                }

            }, () => render(auth('login')));

        }

    });

    router.canNavigate = retry => oneUpModel.canNavigate(retry);

    window.addEventListener('beforeunload', (e) => {
        if (oneUpModel.showBeforeUnload()) {
            e.returnValue = '';
            return '';
        }
    });

}
