import store from 'util/data/store';
import FilterMenus from 'views/table/filter-menu/filter-menus';
import commonFilterModel from 'models/table/common-filter-model';
import siteModel from 'models/site-model';
import formatDate from 'legacy/util/date/format-date';
import tableModel from 'models/table/table-model';
import throttle from 'util/events/throttle';
import initializer from 'util/initializer';
import router from 'uav-router';
import api from 'legacy/util/api';
import userModel from 'models/user-model';
import definedOptionsModel from 'models/table/filter-types/defined-options-model';
import popupModel from 'models/popover-model';
import Polygon from 'util/draw/polygon';
import isMetric from 'util/numbers/is-metric';
import featureModel from 'models/feature-model';
import pointInPoly from '@turf/boolean-point-in-polygon';
import activeCell from 'models/table/active-cell-model';
import measure from 'legacy/util/numbers/measure';
import deepCloneObject from 'util/data/deep-clone-object';
import cellMenus from 'views/table/filter-menu/cell-menus';
import appModel from '../app-model';
import popup from 'util/popup';

const ASC = 'asc';
const DESC = 'desc';


class FilterModel {

    constructor() {
        this.reset();
        this.redraw = throttle(() => {
            m.redraw();
        }, 1000);
        this.itemsPerPage = 400;
        initializer.add(() => this.reset());
    }

    reset() {
        this.coordinates = {x: 0, y: 0};
        this.isOpen = false;
        this.isDirty = false;
        this.filterStates = {};
        this.sharedFilterStates = {};
        this.searchString = undefined;
        this.sortField = undefined;
        this.lastViewedProjectViewId = undefined;
        this.searchArea = undefined;
    }

    open(element, opts) {
        this.opts = opts;
        const shouldForceInit = popupModel.isOpen;
        const view = this.opts.isCellMenu
            ? cellMenus[opts.viewKey]
            : FilterMenus[opts.viewKey];
        if (shouldForceInit) {
            if (view && view.oninit) {
                view.oninit();
            }
        }
        popupModel.open(element, {view, wrapClass: 'filter-menu'});
    }

    resetAllFilters() {
        this.filterStates = {};
        this.sharedFilterStates = {};
        this.searchString = undefined;
        this.sortField = undefined;
        commonFilterModel.selectedPlacesLevelsIndex = undefined;
        tableModel.resetCache();
    }

    getFilterStates() {
        return {
            commonFilters: commonFilterModel.getState(),
            filterStates: this.filterStates,
            sortField: this.sortField,
            searchString: this.searchString,
            tableState: tableModel.getState()
        };
    }

    setupLoadedStates(currentView) {
        this.filterStates = currentView.filterStates;
        commonFilterModel.setState(currentView.commonFilters);
        this.sortField = currentView.sortField;
        this.searchString = currentView.searchString;
        tableModel.setState(currentView.tableState);
    }

    // This is the api request for table view
    loadFilterStates() {
        this.filterStates = {};
        this.sharedFilterStates = {};
        return api.rpc.request([['listProjectViews', {
            projectId: router.params.projectId,
            platform: 'web',
            creatorId: userModel.userId,
            isVisible: true
        }]]).then((results) => {
            if (results.length === 0) {
                tableModel.initDefaultVisibility();
                commonFilterModel.initializeDefaults();
                return api.rpc.request([['createProjectView', {
                    projectId: router.params.projectId,
                    name: 'table-filter',
                    type: 'last-viewed',
                    attributes: this.getFilterStates(),
                    platform: 'web'
                }]]).then((result) => {
                    const currentView = result.attributes;
                    this.setupLoadedStates(currentView);
                    this.lastViewedProjectViewId = result.projectViewId;
                });
            }
            const currentView = results[0].attributes;
            this.setupLoadedStates(currentView);
            this.lastViewedProjectViewId = results[0].projectViewId;
        });
    }

    filtersChanged() {
        this.isDirty = true;
        popupModel.disableCloseFor();
        if (tableModel.inEditMode) {
            tableModel.updateVisibleCellRange();
            activeCell.resetState();
        }
        this.sharedFilterStates = {};
        tableModel.fetch();
        popup.remove();
    }

    saveFilterStates(makeDirty) {
        if (!this.lastViewedProjectViewId || !this.isDirty && !makeDirty) {
            return;
        }

        if (this.cancelSave) {
            this.cancelSave();
            delete this.cancelSave;
        }

        this.cancelSave = api.rpc.cancelableRequest([['modifyProjectView', {
            projectViewId: this.lastViewedProjectViewId,
            attributes: this.getFilterStates()
        }]], () => {
            delete this.cancelSave();
        });
        this.isDirty = false;
    }

    initCurrentState() {
        if (!this.opts.assetType || !this.opts.controlType) {
            console.error(`Cannot get the filter state of: ${this.opts.assetType} and ${this.opts.controlType}`);
            return;
        }
        if (!this.filterStates[this.opts.assetType.assetTypeId]) {
            this.filterStates[this.opts.assetType.assetTypeId] = {};
        }
    }

    hasCellMenu(controlTypeId) {
        return !!cellMenus[controlTypeId];
    }

    hasFilterMenu(controlTypeId) {
        return !!FilterMenus[controlTypeId];
    }

    getState(sharedColumnName) {
        this.initCurrentState();
        if (sharedColumnName) {
            return this.sharedFilterStates[sharedColumnName];
        }
        return this.filterStates[this.opts.assetType.assetTypeId][this.opts.controlType.name];
    }

    setState(state, sharedColumnName) {
        if (sharedColumnName) {
            this.sharedFilterStates[sharedColumnName] = state;
        } else {
            this.initCurrentState();
            this.filterStates[this.opts.assetType.assetTypeId][this.opts.controlType.name] = state;
        }
    }

    /**
     * Sync the passed assetTypeIds filter states to match the passed state object.
     */
    syncFilterStates(sharedFilter) {
        const state = sharedFilter.state;
        const sharedColumn = sharedFilter.sharedColumn;
        const name = sharedColumn.controlType.name;
        const normalizedState = deepCloneObject(state);
        if (normalizedState.checkedOptions) {
            Object.keys(normalizedState.checkedOptions).forEach(opt => {
                const val = normalizedState.checkedOptions[opt];
                // Make sure the values are boolean, exclude "mixed" option from standard filter
                normalizedState.checkedOptions[opt] = val ? true : false;
            });

            normalizedState.isEmptyChecked = state.isEmptyChecked ? true : false;
        }

        sharedColumn.assetTypeIds.forEach(id => {
            this.filterStates[id] = this.filterStates[id] || {}; // If no filter applied yet, create it
            this.filterStates[id][name] = deepCloneObject(normalizedState); // Copy all filter settings
            this.filterStates[id][name].fieldName = name; // Don't overwrite the fieldName, it's used for API filter request
        });

        this.filtersChanged();
    }

    /**
     * Runs after shared column filter is applied: Updates all the other properties matching that tag to select the same *single option*
     * (Does not sync entire state of filter — for example, only will sync the checkedOption passed)
     */
    syncTaggedStatesSingleOption(option, sharedFilter) {
        const state = sharedFilter.state;
        const sharedColumn = sharedFilter.sharedColumn;
        const name = sharedColumn.controlType.name;

        sharedColumn.assetTypeIds.forEach(id => {
            this.filterStates[id] = this.filterStates[id] || {};

            // If the filter doesn't exist yet, duplicate the shared filter
            if (!this.filterStates[id][name]) {
                this.filterStates[id][name] = deepCloneObject(state);
                Object.keys(state.checkedOptions).forEach(opt => {
                    const val = state.checkedOptions[opt];
                    // Make sure the values are boolean, exclude "mixed" option from standard filter
                    this.filterStates[id][name].checkedOptions[opt] = val ? true : false;
                });
                this.filterStates[id][name].fieldName = name;
            }
            // Update the value for the option passed:
            const value = state.checkedOptions[option];
            if (option !== 'isEmptyCheckedOption') {
                this.filterStates[id][name].checkedOptions[option] = value ? true : false;
            } else {
                this.filterStates[id][name].isEmptyChecked = state.isEmptyChecked ? true : false;
            }
        });

        this.filtersChanged();
    }

    get toolGroups() {
        return Object.values(store.toolGroups);
    }


    /**
     * Returns unique string with assetTypeId, controlType name, and (if applicable) share tag.
     * Used to ensure view is not recycled for like filter menus.
     **/
    get filterColumnKey() {
        if (!this.opts) {
            return '';
        }
        return this.opts.assetType.assetTypeId + this.opts.controlType.name + this.opts.sharedColumnName;
    }

    get currentFilterDisplayName() {
        if (!this.opts) {
            return '';
        }
        return this.opts.controlType.name;
    }

    getInUseFilters(assetTypeId) {
        const filterStates = this.filterStates[assetTypeId];
        const inUseFilters = [];
        if (!filterStates) {
            return inUseFilters;
        }
        for (const controlTypeName in filterStates) {
            if (this.isFilterInUse(assetTypeId, controlTypeName)) {
                inUseFilters.push(filterStates[controlTypeName]);
            }
        }
        return inUseFilters;
    }

    isFilterInUse(assetTypeId, controlTypeName) {
        if (tableModel.loadingTable) {
            return false;
        }

        if (!this.filterStates[assetTypeId] || !this.filterStates[assetTypeId][controlTypeName]) {
            return false;
        }

        const filterState = this.filterStates[assetTypeId][controlTypeName];

        let isFiltered = false;
        // Clean up any empty / redundant filters
        if (filterState) {

            if (filterState.type === 'definedOptions') {
                isFiltered = !definedOptionsModel.isAllSelected(filterState).isAllSelected;
            } else if (filterState.type) { // Checks that the filter has been initted (may be added as empty object for sorting request only)
                isFiltered = filterState.selectedRadioIndex !== 0;
            }
            if (!isFiltered) {
                delete this.filterStates[assetTypeId][controlTypeName];
                if (!Object.keys(this.filterStates[assetTypeId]).length) {
                    delete this.filterStates[assetTypeId];
                }
            }
        }
        return isFiltered;
    }

    // This isn't a real intersects query because it only checks whether
    // vertices fall within the polygon (not edges), but the bugginess factor
    // of a false negative in this case is low, so it's not worth adding
    // a new dependency and doing more expensive intersects queries.
    // I'm leaving this method within filterModel instead of moving it to
    // util/geo, because that would open it up to new use cases where the
    // bugginess factor might be higher.
    intersects(geometry, polygon) {
        let coordinates = geometry.coordinates;
        let geomType = geometry.type;
        if (geomType.startsWith('Multi')) {
            coordinates = coordinates[0];
            geomType = geomType.replace('Multi', '');
        }
        if (geomType === 'Polygon') {
            coordinates = coordinates[0];
        } else if (geomType === 'Point') {
            coordinates = [coordinates];
        }
        return coordinates.find(ll => pointInPoly({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: ll
            }
        }, polygon));
    }

    doesMatchDateFilter(assetId, selectedDateRangeIndex, rangeModel) {
        if (selectedDateRangeIndex !== 0) {
            const createdDate = new Date(store.assets[assetId].createdDateTime);
            if (selectedDateRangeIndex < commonFilterModel.daysByIndex.length) {
                const minDate = formatDate.getDaysAgo(commonFilterModel.daysByIndex[selectedDateRangeIndex]);
                if (createdDate < minDate) {
                    return false;
                }
            } else if (createdDate < rangeModel.fromDate || createdDate > rangeModel.toDate) {
                return false;
            }
        }
        return true;
    }

    doesMatchFilters(assetId) {
        const asset = store.assets[assetId];
        // Type filter
        if (!commonFilterModel.checkedAssetTypes[asset.assetTypeId]
            // Added By filter
            || !commonFilterModel.checkedUsers[asset.authorId]) {
            return false;
        }
        // Category filter
        if (asset.attributes.toolGroupIds && !commonFilterModel.checkedToolGroups[asset.attributes.toolGroupIds[0]]) {
            return false;
        }
        // Added Date filter
        if (!this.doesMatchDateFilter(assetId, commonFilterModel.selectedDateRangeIndex, commonFilterModel.addedDateRange)) {
            return false;
        }
        // Last Updated Date filter
        if (!this.doesMatchDateFilter(assetId, commonFilterModel.updatedDateRangeIndex, commonFilterModel.updatedDateRange)) {
            return false;
        }

        if (commonFilterModel.assetIdMatch && assetId !== commonFilterModel.assetIdMatch) {
            return false;
        }

        // Location
        if (commonFilterModel.selectedPlacesLevelsIndex) {
            const featureGeometry = asset.geometry;
            // In View filter
            if (commonFilterModel.selectedPlacesLevelsIndex === 1) {
                if (!featureGeometry || !this.intersects(featureGeometry, this.getSitePoly())) {
                    return false;
                }
            // Draw Search Area filter
            } else if (this.searchArea) {
                if (!featureGeometry || !this.intersects(featureGeometry, this.searchArea)) {
                    return false;
                }
            }
        }
        // Query filter
        // This is a case-insensitive exact match, so there will be some inconsistency
        // between the results of this check and the fuzzy back end text search.
        if (this.searchString) {
            const query = this.searchString.toLowerCase();
            const fields = store.assetTypeFields[asset.assetTypeId];
            const match = Object.keys(fields).find(label => {
                const value = asset.properties[label];
                return fields[label].index && typeof value === 'string' && value.toLowerCase().indexOf(query) !== -1;
            });
            if (!match) {
                return false;
            }
        }
        // Filter out meta project assets that this user is not authorized to view
        if (siteModel.isMetaProject && userModel.projectIds) {
            const projectId = asset.properties._projectId;
            if (projectId && userModel.projectIds.indexOf(projectId) === -1) {
                return false;
            }
        }
        // Asset property filters
        const filtersForThisAssetType = this.filterStates[asset.assetTypeId];
        const isFilteredOut = filtersForThisAssetType && Object.values(filtersForThisAssetType).find(filter => {
            const index = filter.selectedRadioIndex,
                fieldValue = asset.properties[filter.fieldName];
            switch (filter.type) {
            case 'text':
                if (index === 1 && fieldValue !== undefined && fieldValue !== '') {
                    return true;
                } else if (index === 2 && (fieldValue === undefined || fieldValue === '')) {
                    return true;
                } else if (index === 3 && (!fieldValue || fieldValue.indexOf(filter.queryParm) === -1)) {
                    return true;
                }
                break;
            case 'numberRange':
                if (index === 1 && fieldValue !== undefined) {
                    return true;
                } else if (index === 2) {
                    if (filter.selectedSign === 'lte' && fieldValue > filter.range
                        || filter.selectedSign === 'eq' && fieldValue !== filter.range
                        || filter.selectedSign === 'gte' && fieldValue < filter.range) {
                        return true;
                    }
                } else if (index === 3 && (fieldValue < filter.rangeLow || fieldValue > filter.rangeHigh)) {
                    return true;
                }
                break;
            case 'definedOptions':
                const checkedOptions = Object.keys(filter.checkedOptions).filter(key => filter.checkedOptions[key]);
                if (!checkedOptions.find(key => fieldValue === key)) {
                    if (fieldValue !== undefined) {
                        return true;
                    } else if (!filter.isEmptyChecked) {
                        return true;
                    }
                }
                break;
            case 'dateRange':
                if (!this.doesMatchDateFilter(assetId, filter.selectedRadioIndex, filter.rangeModel)) {
                    return true;
                }
                break;
            case 'boolean':
                if (index === 1 && !fieldValue || index === 2 && fieldValue) {
                    return true;
                }
                break;
            case 'placesFilter':
                if (index === 0) {
                    return false;
                } else if (index === 1) {
                    return fieldValue && fieldValue.placeIds;
                }
                if (!fieldValue) {
                    return false;
                }
                if (fieldValue.placeIds) {
                    const selected = {};
                    filter.selectedItems.forEach((id) => {
                        selected[id] = true;
                    });
                    for ( let i = 0; i < fieldValue.placeIds.length; i++) {
                        const placeId = fieldValue.placeIds[i];
                        if (selected[placeId]) {
                            return false;
                        }
                    }
                    return true;
                }
            }
            return false;
        });

        return !isFilteredOut;

    }
      
    search(searchString) {
        this.searchString = searchString;
        this.filtersChanged();
    }

    // Taken directly from feed.js.
    // Now uses table args instead.
    suggest(query) {
        if (tableModel.isCollapsed) {
            tableModel.sideBarToggle();
        }
        const args = this.getArgs();
        delete args.limit;
        delete args.order;
        return api.search({
            searchType: 'suggest',
            query,
            filter: args,
            limit: 10
        }).then(data => {
            const suggestions = {};
            // if this.searchString exists, then the user pressed enter
            // while the suggest request was in flight, so we do not
            // want to display the autosuggest results.
            if (data && data.results && !this.searchString) {
                data.results.forEach(result => {
                    suggestions[result.suggestion] = 1;
                });
            }
            return Object.keys(suggestions);
        });
    }

    isCommonColumnSorted(viewKey) {
        return this.sortField && this.sortField.type === viewKey;
    }

    _ascDesc(order) {
        switch (order) {
        case ASC:
            this.sortField.order = DESC;
            return;
        case DESC:
            this.sortField = undefined;
            return;
        default:
            this.sortField.order = ASC;
            return;
        }
    }

    toggleCommonSortField(value, type) {
        if (type === 'unearthIdFilter') {
            value = 'contentId';
        }
        const sortField = this.sortField;
        if (sortField && sortField.value === value) {
            this._ascDesc(sortField.order);
            this.filtersChanged();
            return;
        }
        this.sortField = {order: ASC, isCommon: true, value, type, sharedColumnName: this.opts.sharedColumnName};
        this.filtersChanged();
    }

    toggleSortField() {
        const assetTypeId = this.opts.assetType.assetTypeId;
        const controlName = this.opts.controlType.name;
        const sortField = this.sortField;

        if (sortField && !this.sortField.isCommon && this.sortField.assetTypeId === assetTypeId && this.sortField.controlName === this.opts.controlType.name) {
            const sortedColumnName = this.sortField.sharedColumnName;
            const sharedColumnName = this.opts ? this.opts.sharedColumnName : null;
            // Check for special case of tag column with matching assetTypeId of a regular column
            if (sortedColumnName === sharedColumnName) {
                this._ascDesc(sortField.order);
                this.filtersChanged();
                return;
            }
        }
        this.sortField = {assetTypeId, controlName, order: ASC, isCommon: false, sharedColumnName: this.opts.sharedColumnName};
        this.filtersChanged();
    }

    getSitePoly() {
        const siteBounds = siteModel.map.getBounds(),
            nw = siteBounds.getNorthWest().toArray();
        return {
            type: 'Polygon',
            coordinates: [
                [
                    nw,
                    siteBounds.getNorthEast().toArray(),
                    siteBounds.getSouthEast().toArray(),
                    siteBounds.getSouthWest().toArray(),
                    nw
                ]
            ]
        };
    }

    getDateArg(dateRangeIndex, dateType, rangeModel, args) {
        const dateGte = dateType + 'Gte',
            dateLte = dateType + 'Lte';
        if (dateRangeIndex === undefined) {
            args[dateGte] = undefined;
            args[dateLte] = undefined;
        } else if (dateRangeIndex === 0) {
            args[dateGte] = undefined;
        } else if (dateRangeIndex < commonFilterModel.daysByIndex.length) {
            args[dateGte] = formatDate.getDaysAgoForPython(commonFilterModel.daysByIndex[dateRangeIndex]);
        } else if (rangeModel.fromDate && rangeModel.toDate) {
            args[dateGte] = formatDate.forPython(new Date(rangeModel.fromDate));
            args[dateLte] = formatDate.forPython(new Date(rangeModel.toDate));
        }
    }

    // We need to filter out assets that represent projects
    // that the user is not authorized to view
    addProjectAuthFilters(args) {
        if (siteModel.isMetaProject && userModel.projectIds) {
            args['properties._projectId'] = {in: userModel.projectIds};
        }
        return args;
    }

    getArgs(offset = 0) {

        /**
         * args: These are the top level arguments.
         *      The logic here is args AND (filter OR filter OR filter)
         */

        const args = {
            limit: this.itemsPerPage,
            order: `createdDateTime ${DESC}`,
            isVisible: true,
            projectId: siteModel.projectId,
            siteId: siteModel.siteId,
            geometry: undefined,
            assetTypeIdIn: undefined,
            query: this.searchString || undefined,
            include: ['media'],
            offset,
            authorIdIn: []
        };

        if (commonFilterModel.assetIdMatch) {
            args.contentId = commonFilterModel.assetIdMatch;
        }

        if (commonFilterModel.checkedUsers) {
            args.authorIdIn = Object.keys(commonFilterModel.users).filter((key) => commonFilterModel.checkedUsers[key]);
        }

        switch (commonFilterModel.selectedPlacesLevelsIndex) {
        case 1:
            args.geometry = {
                intersects: this.getSitePoly()
            };
            break;
        case 2:
            if (this.searchArea) {
                args.geometry = {
                    intersects: this.searchArea.geometry
                };
            }
            break;
        }

        // Clean up the searchArea feature if the searchArea filter was deselected
        if (this.searchArea && commonFilterModel.selectedPlacesLevelsIndex !== 4) {
            const source = siteModel.map.getSource('searchArea');
            featureModel.deleteFeature(this.searchArea, source);
            source.setData(source._data);
        }

        const notFiltered = {};

        notFiltered.assetTypeIdIn = Object.keys(commonFilterModel.checkedAssetTypes).filter((assetTypeId) =>
            commonFilterModel.checkedAssetTypes[assetTypeId] && commonFilterModel.checkedToolGroups[store.assetTypeIdToToolGroupId[assetTypeId]]
        );

        this.getDateArg(commonFilterModel.selectedDateRangeIndex, 'captureDateTime', commonFilterModel.addedDateRange, args);
        this.getDateArg(commonFilterModel.updatedDateRangeIndex, 'updatedDateTime', commonFilterModel.updatedDateRange, args);

        /**
         * Sort order: This is where the table ordering lives:
         * In the case where we are requesting to sort a custom field that is NOT filtered, we must manually include
         * that assetTypeId as a separate filter item for the rpc request to work (so sorting should be checked
         * before filtering):
         */
        if (this.sortField) {
            if (this.sortField.isCommon) {
                args.order = `${this.sortField.value} ${this.sortField.order}`;
            } else  {
                const state = this.filterStates[this.sortField.assetTypeId] ?
                    this.filterStates[this.sortField.assetTypeId][this.sortField.controlName] : '';
                if (!state) {
                    this.filterStates[this.sortField.assetTypeId] = {
                        [this.sortField.controlName]: {}
                    };
                }
                args.order = `properties.${this.sortField.controlName} ${this.sortField.order}`;
            }
        }

        /**
         * Filters: Logic for build the OR portion of the rpc request.
         */
        const filters = [];
        let isFilteredOnPlace = false;
        
        Object.keys(this.filterStates).forEach((assetTypeIdKey) => {
            const filterAssetTypes = this.filterStates[assetTypeIdKey];
            const index = notFiltered.assetTypeIdIn.indexOf(assetTypeIdKey);
            if (index === -1) {
                return;
            }
            notFiltered.assetTypeIdIn.splice(index, 1);
            const filter = {};
            filters.push(filter);
            filter.assetTypeId = assetTypeIdKey;
            const emptyList = [];

            Object.keys(filterAssetTypes).forEach((filterControlTypeKeys) => {
                const filterControlTypes = filterAssetTypes[filterControlTypeKeys];
                const selectedRadioIndex = filterControlTypes.selectedRadioIndex;
                switch (filterControlTypes.type) {
                case 'numberRange' : {
                    if (selectedRadioIndex === 1) {
                        filter[`properties.${filterControlTypes.fieldName}`] = {eq: null};
                    } else if (selectedRadioIndex === 2) {
                        const range = this.apiSafeNumber(filterControlTypes.numberType, filterControlTypes.range);
                        const lessOrGreater = {};
                        lessOrGreater[`${filterControlTypes.selectedSign}`] = range;
                        filter[`properties.${filterControlTypes.fieldName}`] = lessOrGreater;
                    } else if (selectedRadioIndex === 3) {
                        const rangeHigh = this.apiSafeNumber(filterControlTypes.numberType, filterControlTypes.rangeHigh);
                        const rangeLow = this.apiSafeNumber(filterControlTypes.numberType, filterControlTypes.rangeLow);
                        filter[`properties.${filterControlTypes.fieldName}`] = {lte: rangeHigh, gte: rangeLow};
                    }
                    break;
                }
                case 'definedOptions': {
                    const contains = [];
                    if (filterControlTypes.isEmptyChecked) {
                        contains.push(null);
                        emptyList.push({[`properties.${filterControlTypes.fieldName}`]: {eq: null}});
                    }
                    Object.keys(filterControlTypes.checkedOptions).forEach((checkedOptionsKeys) => {
                        const filterCheckedValues = filterControlTypes.checkedOptions;
                        if (filterCheckedValues[checkedOptionsKeys]) {
                            contains.push(checkedOptionsKeys);
                        }
                    });
                    filter[`properties.${filterControlTypes.fieldName}`] = {'in': contains};
                    break;
                }
                case 'text' : {
                    if (selectedRadioIndex === 1) {
                        filter[`properties.${filterControlTypes.fieldName}`] = {eq: null};
                    } else if (selectedRadioIndex === 2) {
                        filter[`properties.${filterControlTypes.fieldName}`] = {ne: null};
                    } else if (selectedRadioIndex === 3) {
                        filter[`properties.${filterControlTypes.fieldName}`] = {ilike: `%${filterControlTypes.queryParam}%`};
                    }
                    break;
                }
                case 'dateRange': {
                    if (selectedRadioIndex < commonFilterModel.daysByIndex.length && selectedRadioIndex !== 0) {
                        const gte = {gte: formatDate.getDaysAgo(commonFilterModel.daysByIndex[selectedRadioIndex]).getTime()};
                        filter[`properties.${filterControlTypes.fieldName}`] = gte;
                    } else if (selectedRadioIndex !== 0) {
                        const ltGte = {lte: new Date(filterControlTypes.rangeModel.toDate).getTime(), gte: new Date(filterControlTypes.rangeModel.fromDate).getTime()};
                        filter[`properties.${filterControlTypes.fieldName}`] = ltGte;
                    }
                    break;
                }
                case 'boolean': {
                    if (selectedRadioIndex) {
                        filter[`properties.${filterControlTypes.fieldName}`] = selectedRadioIndex === 1 ? {eq: true} : {ne: true};
                    }
                    break;
                }
                case 'placesFilter': {
                    if (selectedRadioIndex === 1) {
                        filter[`properties.${filterControlTypes.fieldName}.placeIds`] = {eq: null};
                    } else if (selectedRadioIndex === 2) {
                        isFilteredOnPlace = true;
                        if (filterControlTypes.selectedItems.length) {
                            filter[`properties.${filterControlTypes.fieldName}.placeIds`] = {ov: filterControlTypes.selectedItems};
                        }
                    }
                }
                }
            });

            for (const emptyProp of emptyList) {
                const emptyFilter = Object.assign({}, filter, emptyProp);
                filters.push(emptyFilter);
            }
        });
        
        // If an asset name query provided, include as OR filters based on name control and asset type.
        if (commonFilterModel.nameQueryString) {    
            commonFilterModel.setNameQueryFilters(filters);
        }

        this.addProjectAuthFilters(args);

        if (isFilteredOnPlace === false && !commonFilterModel.nameQueryString && (notFiltered.assetTypeIdIn.length || !filters.length)) {
            filters.push(notFiltered);
        }

        args.filters = filters;
        return args;
    }

    cancelDrawSearchArea() {
        this.draw.stop();
        appModel.stopDrawing();
        commonFilterModel.selectedPlacesLevelsIndex = 0;
        this.filtersChanged();
        if (tableModel.isCollapsed) {
            tableModel.sideBarToggle();
        }
    }

    drawSearchArea() {
        appModel.startDrawing();
        popupModel.close();
        tableModel.sideBarToggle();
        const color = '#70bfbf',
            map = siteModel.map;
        let source = map.getSource('searchArea');
        if (!source) {
            map.addSource('searchArea', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: []
                }
            });
            source = map.getSource('searchArea');
            map.addLayer({
                id: 'searchArea',
                source: 'searchArea',
                type: 'line',
                paint: {
                    'line-width': 3,
                    'line-color': color
                }
            });
        }
        this.draw = new Polygon({
            map,
            source,
            metric: isMetric(),
            color
        });
        this.draw.create();
        this.draw.onComplete = feature => {
            this.searchArea = feature;
            source._data.features = [feature];
            source.setData(source._data);
            tableModel.sideBarToggle();
            this.filtersChanged();
            appModel.stopDrawing();
        };
        this.draw.onVertexChanged = () => this.filtersChanged();
    }

    /**
     * If the value should be passed as metric (and the site is not already in metric) return the metric conversion. Otherwise just return the value.
     */
    apiSafeNumber(numberType, value) {
        if (Number.isNaN(value)) {
            // Stops error from being thrown for empty number input
            return 'None';
        }
        if (isMetric()) {
            return value;
        }
        switch (numberType) {
        case 'length':
            return measure.feetToMeters(value);
        case 'area':
            return measure.squareFeetToSquareMeters(value);
        case 'volume':
            return measure.cubicFeetToCubicMeters(value);
        default:
            return value;
        }
    }

}

export default new FilterModel();
