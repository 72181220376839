import convertMithril from 'util/dom/convert-mithril';
import stopPropagation from 'util/events/stop-propagation';
import Popover from 'legacy/components/popover';
import constants from 'util/data/constants';
import api from 'legacy/util/api';
import store from 'util/data/store';

let toolboxId = Object.values(constants.baseToolboxes)[0].toolboxId,
    popupContent;

const select = toolbox => {
    toolboxId = toolbox.toolboxId;
    popupContent.redraw();
};

function toolboxSelector(e) {

    popupContent = convertMithril.withRedraw({
        oninit() {
            toolboxId = null;
        },
        view: () => {
            const toolboxes = toolboxSelector.accountToolboxes;
            toolboxId = toolboxId || (toolboxes ? toolboxes[0].toolboxId : '');
            return <div onclick={stopPropagation()}>
                <div class="fixed-header"><p>Select a Toolkit:</p></div>
                <div class="scroll-wrapper">
                    {toolboxes
                        ? toolboxes.map(toolbox => {
                            const selected = toolboxId === toolbox.toolboxId;
                            return <div class={`menu-option ${selected ? 'selected' : ''}`} onclick={stopPropagation(() => select(toolbox))} key={toolbox.toolboxId}>
                                <img class="icon left" src={`${toolbox.attributes.icon ? constants.mediaURL + toolbox.attributes.icon.mediaId : ''}`} />
                                {toolbox.name}
                            </div>;
                        }) : <i class="gray spinner spinning"></i>}
                </div>
                <div class="fixed-footer"><a class="btn btn-primary btn-small btn-pill" onclick={() => {
                    location.href = `#view=addsite&accountId=${store.account.accountId}&toolboxId=${toolboxId}`;
                }}><span class="btn-label">Next: Map the Site <i class="icon-right" /></span></a>
                </div>
            </div>;
        }
    });

    new Popover({
        e,
        content: popupContent,
        cssClass: 'popover active has-close ellipsis-menu create-site',
        verticalAlign: true,
        offset: {top: 22, left: -110}
    });

}

toolboxSelector.fetchAccountToolboxes = () => api.rpc.request([
    ['listAccountToolboxes', {
        accountId: store.account.accountId
    }]
]).then(toolboxes => {

    const filteredToolboxes = {};

    Object.assign(filteredToolboxes, constants.baseToolboxes);

    toolboxes.forEach(toolbox => {
        if (toolbox.base && toolbox.toolboxId !== store.toolboxId) {
            filteredToolboxes[toolbox.name] = toolbox;
        }
    });

    toolboxSelector.accountToolboxes = Object.values(filteredToolboxes);

    if (popupContent) {

        popupContent.redraw();

    }

});

export default toolboxSelector;
