import router from 'uav-router';
import api from 'legacy/util/api';
import store from 'util/data/store';
import publish from 'legacy/util/api/publish';
import message from 'legacy/components/message';
import dialogModel from 'models/dialog-model';
import layerModel from 'models/layer-model';
import siteModel from 'models/site-model';
import layerColorModel from 'models/layer-color-model';
import stakeableModel from 'models/stakeable-model';
import StakeStatic from 'components/stake/stake-static';
import {LngLat} from 'mapbox-gl';
import lngLatsToBounds from '../util/geo/lnglats-to-bounds';
import latLngsToLngLats from '../util/geo/latlngs-to-lnglats';
import helpers from 'legacy/util/api/helpers';
import loaderModel from 'models/loader-model';

const LAT_INDEX_API = 0;   // Unearth's API stores: lat, lng
const LNG_INDEX_API = 1;  //         MapBox stores: lng, lat

/**
 * Handles logic and state for plan layer editing view.
 * (/views/plan/plan.js
 */
class PlanModel {

    constructor() {
        this.reviewAnimateClass = 'fade-in';
        this.cropTileset = null;
        this.stakeTileset = null;
    }

    init() {
        loaderModel.load('Loading your plan...');
        this.recordId = router.params.planId;
        return stakeableModel.init({
            storeKey: 'plans',
            recordType: 'plan',
            recordId: this.recordId,
            withElevation: false,
            lightOriginBg: true
        }).then(() => {
            this.recordTitle = stakeableModel.recordTitle = store.plans[this.recordId].title;
            this.stakeInit().then(() => {
                if (router.params.step === 'review' && store.plans[this.recordId].stakes) {
                    this.reviewInit();
                }
            }).finally(() => loaderModel.hide());
        });
    }

    // ------------------------- Initialize for the current step --------------------------

    /**
     * Initialize for "staking" step.
     * Sets up the staking view with the plan being edited, stakes, and initial map bounds.
     */
    stakeInit() {
        return stakeableModel.origin.mapLoaded().then(() => {
            return this.getTilesets().then(() => {
                stakeableModel.origin.setUpEditingLayer(this.stakeTileset);
                stakeableModel.preloadSavedStakes(this.getInitialBounds());
                stakeableModel.state.isLoading = false;
                layerModel.hideAllLayers();
                m.redraw();
            });
        });
    }

    /**
     * Initialize for "review" step.
     * Repurpose the layer control to work with our review mapbox object.
     */
    reviewInit() {
        this.isReviewing = true;
        layerModel.isOpen = false; // Make sure layer menu is closed in case it was open on prior screen.
        layerModel.layerControl._container.classList.remove('active');
        siteModel.map.removeControl(layerModel.layerControl);
        m.redraw();
    }

    /**
     * Initialize the layerColorModel for the "review" step and load static stakes on the map.
     */
    colorInit() {
        layerColorModel.init({
            planId: this.recordId,
            tilesetId: store.plans[this.recordId].tilesetId,
            container: 'mapbox-review',
            basemap: this.currentBasemap
        }).then(() => this.loadStaticStakes());
    }

    /**
     * Reassigns the layer control to the target map (left side) for staking.
     */
    stakeReinit() {
        layerModel.hideAllLayers();
        siteModel.map = stakeableModel.target;
        siteModel.map.addControl(layerModel.layerControl, 'bottom-right');
        this.currentBasemap ? layerModel.setBasemap(this.currentBasemap) : null; // Maintain the basemap in use on the review step, in case it was changed.
    }

    // ------------------------- Loading saved stakes --------------------------

    /**
     * Create the non-draggable stakes for the plan being reviewed.
     */
    loadStaticStakes() {
        const data = store.plans[this.recordId].stakes;
        const bounds = this.getInitialBounds();
        data.forEach((pair, index) => {
            new StakeStatic({
                coords: new LngLat(pair.target[LNG_INDEX_API], pair.target[LAT_INDEX_API]),
                stakeNumber: index + 1,
                map: layerColorModel.map
            });
            // Extend site bounds to include stakes.
            bounds.extend(new LngLat(pair.target[LNG_INDEX_API], pair.target[LAT_INDEX_API]));
        });
        layerColorModel.map.fitBounds(bounds,  {
            animate: false,
            padding: {top: 50, bottom: 50, left: 50, right: 50}
        });
    }

    /**
     * If the plan is assembled, gets the initial bounds for it.
     * If not assembled or bounds don't exist, return siteModel map bounds.
     */
    getInitialBounds() {
        if (store.plans[this.recordId].status !== 'assembling') {
            const tilesetId = store.plans[this.recordId].tilesetId;
            const bounds = store.tilesets[tilesetId].bounds;
            if (bounds) {
                return lngLatsToBounds(latLngsToLngLats(bounds));
            }
        }
        return siteModel.map.getBounds();
    }

    // ------------------------- Navigating between steps (crop, stake, and review) --------------------------

    /**
     * Opens the cropping step map from the stake step.
     */
    goToCropStep() {
        this.isCropping = true;
        m.redraw();
    }

    /**
     * Opens the reviewing step map from the stake step.
     */
    goToReviewStep() {
        layerModel.hideAllLayers();
        router.url.mergeReplace({step: 'review'});
        this.currentBasemap = layerModel.state.basemapId;
        this.reviewInit();
    }

    /**
     * Returns to the staking step from the review step.
     */
    goToStakeStep() {
        this.reviewAnimateClass = 'fade-body-out';
        this.currentBasemap = layerModel.state.basemapId;
        this.stakeReinit();
        setTimeout(() => {
            this.isReviewing = false;
            this.reviewAnimateClass = 'fade-in';
            router.url.mergeReplace({step: 'stake'});
            m.redraw();
        }, 500);
    }


    /**
     * From the staking step, checks if there are changes to submit
     * (and if so, sends them) before routing to the review step.
     */
    nextFromStaking() {
        if (stakeableModel.state.isUndoable) { // Means there are staking changes to submit.
            dialogModel.open({
                headline: 'Ready to Save?',
                text: 'It may take a few minutes to align your layer; we’ll email you when it’s ready for review. ' +
                    'Leaving this page won’t affect processing.',
                yesText: 'Save',
                yesClass: 'btn btn-pill btn-primary',
                noText: 'Cancel',
                noClass: 'btn btn-pill btn-red',
                onYes: () => {
                    this.savePins(stakeableModel.stakes.getStakeData());
                }
            });
        } else {
            // No unsaved staking changes to submit, just move on to next step:
            this.goToReviewStep();
        }
    }

    /**
     * Returns to the site view from the review step.
     */
    exitFromReviewing() {
        this.isReviewing = false;
        router.url.removeReplace('step', 'review');
        router.remove('view', 'layer');
    }

    /**
     * Returns to the site view from the staking step.
     */
    exitFromStaking() {
        router.url.removeReplace('step', 'stake');
        router.remove('view', 'layer');
    }

    /**
     * Sends the current stake/pin data to the API and routes to the review step.
     */
    savePins(data) {
        const planId = this.recordId;
        const plan = store.plans[planId];
        const tilesetId = plan.tilesetId;
        message.show('<span><i class=\'spinner spinning\'></i><span>It may take a few minutes to align your layer; we’ll email you when it’s ready for review. Leaving this page won’t affect processing.</span></span>', 'success', true);
        publish.clearCallbacks('modified', 'tileset');
        publish.await({
            changeType: 'modified',
            recordType: 'tileset',
            test: change => change.geoImageIds && change.tilesetId === tilesetId,
            callback: () => {
                message.hide();
                stakeableModel.stakes.clearHistory();
                this.goToReviewStep();
            }
        });
        api.post.planStakes(planId, data);
    }


    /**
     * If our stakeable plan tileset is cropped, we should reset it on the map with the new (cropped)
     * version and adjust the max bounds to account for the modified size.
     */
    awaitCropChanges() {
        publish.clearCallbacks('modified', ['tileset', 'planSection']);
        publish.await({
            changeType: 'modified',
            recordType: 'planSection',
            callback: tileset => {
                if (this.recordId === tileset.planId) {
                    api.get.tileset(tileset.baseTilesetId).then((baseTileset) => {
                        const originalLayer = stakeableModel.origin.getStyle().layers[0];
                        originalLayer ? stakeableModel.origin.removeLayer(originalLayer.id) : null;
                        const initialBounds = stakeableModel.origin.getBounds();
                        stakeableModel.origin.setUpEditingLayer(baseTileset);
                        stakeableModel.boundOriginByStakes(initialBounds);
                    });
                }
            }
        });
    }


    /**
     * Get (and set) the special plan tilesets for cropping and staking.
     */
    getTilesets() {
        const planSections = helpers.list(store.plans[this.recordId].sectionIds);
        const planSectionId = planSections[0];
        const tilesetId = store.plans[this.recordId].tilesetId;
        return api.rpc.get('Tileset', tilesetId)
            .then(tileset => api.get.tileset(tileset.baseTilesetId)
                .then(baseTileset => {
                    this.stakeTileset = baseTileset;
                })
                .then(() => api.get.planSection(planSectionId))
                .then(planSection => api.get.tileset(planSection.cropBaseTilesetId))
                .then(cropBaseTileset => {
                    this.cropTileset = cropBaseTileset;
                })
                .catch(() => message.show('Something went wrong. Please try refreshing or contact support.', 'error')));
    }

    /**
     * Tear down mapbox resources on exit.
     */
    onRemove() {
        if (siteModel.map) {
            siteModel.map.remove();
        }
        if (stakeableModel.origin) {
            stakeableModel.origin.remove();
        }
        this.isReviewing = this.isCropping = false;
    }

}


export default new PlanModel();
