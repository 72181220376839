import constants from 'util/data/constants';
import store from 'util/data/store';
import userModel from 'models/user-model';
import {displayPhoneNumber} from 'util/data/phone-number';
import debouncedAPICall from 'util/network/debounced-api-call';
import peopleModel from 'models/people/people-model';
import debounce from 'util/events/debounce';

const MISSING_DATA = 'Not provided';

class PersonModel {

    constructor(user = {}) {
        this.syncUserData(user);
        this.role = user.role;
        this.isVisible = Object.hasOwnProperty(user.isVisible) ? user.isVisible : true;
        this.isNewUser = user.userId === undefined;
        this.status = user.status || 'new';
        this.projects = {}; // ProjectIds the user has access to
        this.formState = {
            autoSaving: {},
            isSavingNew: false,
            highlightInvalid: false
        };
        this.savingClass = ''; // If we need to freeze the user for removing, modifying, updating, etc
        this.saveToApi = debounce(this.saveToApi.bind(this), 400);
    }

    get isSelf() {
        if (!this.userId) {
            return false;
        }
        return userModel.userId === this.userId;
    }

    syncUserData(user) {
        const givenName = user.givenName || this.givenName;
        const familyName = user.familyName || this.familyName;
        const emailAddress = user.emailAddress || this.emailAddress;
        const phoneNumber = user.phoneNumber || this.phoneNumber;

        Object.assign(this, {
            userId: user.userId,
            createdDateTime: user.createdDateTime,
            updatedDateTime: user.updatedDateTime,
            givenName,
            familyName,
            emailAddress,
            phoneNumber
        });
    }

    userCanEdit(property = '', user = userModel) {
        // The person is currently being removed from the account, no further changes can occur
        if (this.savingClass === 'removing') {
            return false;
        }
        // Cant remove project access from project owners
        if (property === 'access' && (this.role === 'owner' || this.role === 'admin')) {
            return false;
        }
        // Role and access are editable by admins, personal details editable only by self (unless new user)
        if (property !== 'role' && property !== 'access') {
            return this.isSelf || this.isNewUser;
        }
        if (user.isSuperAdmin) {
            return true;
        }
        const userRank = peopleModel.permissionLevel(user.role);
        const personRank = peopleModel.permissionLevel(this.role);
        return userRank >= constants.permissionsRanks.WRITABLE_MIN && userRank >= personRank;
    }

    saveToApi(value, property) {
        if (property === 'role') {
            return this.saveUserRole(value);
        }
        return this.saveUserProperty(value, property);
    }

    autosave(value, property) {
        this[property] = value;
        if (this.isNewUser) {
            m.redraw();
            return; // Don't autosave data for new users.
        }
        this.saveToApi(value, property);
    }

    // ---------- Saving data to back end -----------

    saveUserRole(value = this.role) {
        const userId = this.userId;
        // Used to show spinner and temporarily disable add'l changes until role is updated (further prevents deadlocks)
        this.formState.autoSaving.role = true;
        m.redraw();
        debouncedAPICall('modifyAccountUser' + userId, ['modifyAccountUser', {
            userId,
            accountId: store.account.accountId,
            role: value
        }]).then(() => {
            this.formState.autoSaving.role = false;
            m.redraw();
        });
    }

    saveUserProperty(value, property) {
        const userId = this.userId;
        this.formState.autoSaving[property] = true;
        m.redraw();
        debouncedAPICall('modifyUser' + userId, ['modifyUser', {
            userId,
            [property]: value
        }]).then(() => {
            this.formState.autoSaving[property] = false;
            m.redraw();
        });
    }

    // ---------- Displaying (formatted) user data -----------

    // Depending on data available, will return: First Last OR First OR ''
    displayName(user = userModel) {
        return user.givenName && user.familyName ? `${user.givenName} ${user.familyName}` : user.givenName || '';
    }

    // Depending on data available, will return: First Last OR First OR Passed arg
    displayNameOr(string = '') {
        return this.givenName && this.familyName ? `${this.givenName} ${this.familyName}` : this.givenName || string;
    }

    initials() {
        return this.givenName && this.familyName ? `${this.givenName[0]}${this.familyName[0]}` : 'NU';
    }

    displayProperty(property) {
        let value = this[property];
        if (property === 'phoneNumber' && value) {
            value = displayPhoneNumber(value);
        } else if (property === 'role' && value) {
            value = constants.accountUserRoles[value].display;
        }
        return value || MISSING_DATA;
    }
}

export default PersonModel;
