
const ToggleColorSwitch = {

    view: ({attrs: {tileset, toggleFn}}) => {
        return <div class="plan-details-header toggle-color-row">
            <span class="btn-label">Plan Color</span>
            <span class="toggle-text-wrap">
                <i class={'toggle-switch ' + (tileset.color !== 'None' ? 'toggle-is-on' : 'toggle-is-off')} onclick={() => {
                    toggleFn(tileset.color, tileset.tilesetId);
                }}/>
                {tileset.color !== 'None' ? <span>On</span> : <span>Off</span>}
            </span>
        </div>;
    }

};

export default ToggleColorSwitch;
