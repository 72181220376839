import toolboxSelector from 'views/toolbox-selector';
import SearchBox from 'views/search-box/search-box';
import toolboxModel from 'models/toolbox-model';

const ProjectPrompt = {
    checkToolboxes(state, toolboxes) {
        const toolInterface = toolboxModel.toolInterface,
            toolName = toolInterface.tool && toolInterface.tool.name;
        let matchingToolbox;
        if (state.presetToolboxId) {
            matchingToolbox = toolboxes.find(toolbox => toolbox.base && toolbox.base.toolboxId === state.presetToolboxId);
            if (matchingToolbox) {
                toolInterface.setToolboxId(matchingToolbox.toolboxId);
            }
        }
        if (!matchingToolbox) {
            matchingToolbox = toolboxes.find(toolbox => toolbox.name === toolName);
            if (matchingToolbox) {
                toolInterface.toolboxId = matchingToolbox.toolboxId;
            } else {
                toolInterface.toolboxId = toolboxes[0].toolboxId;
            }
            m.redraw();
        }
    },
    oninit: ({state}) => {
        state.presetToolboxId = toolboxModel.toolInterface.tool.featureTypes[0].attributes.toolbox;
        if (toolboxSelector.accountToolboxes) {
            ProjectPrompt.checkToolboxes(state, toolboxSelector.accountToolboxes);
        } else {
            toolboxSelector.fetchAccountToolboxes().then(() => ProjectPrompt.checkToolboxes(state, toolboxSelector.accountToolboxes));
        }
        toolboxModel.toolInterface.initGoogle();
    },
    view: ({state: {presetToolboxId}}) => {
        const toolboxes = toolboxSelector.accountToolboxes;
        const toolInterface = toolboxModel.toolInterface;
        return <>
            {!presetToolboxId && <>
                <div class="project-prompt-header">Select a Toolkit for this {toolInterface.tool.name}</div>
                {toolboxes
                    ? <div class="select-wrapper project-prompt">
                        <select onchange={e => toolInterface.setToolboxId(e.target.value)} value={toolInterface.toolboxId}>
                            <option value="default" disabled>Select Toolkit</option>
                            {toolboxes.map(toolbox =><option value={toolbox.toolboxId}>{toolbox.name}</option>)}
                        </select>
                    </div>
                    : <div class="select-placeholder project-prompt"><i class="gray spinner spinning left" /> Loading Toolkits</div>
                }
            </>}
            <div class="project-prompt-header">Locate the {toolInterface.tool.name}</div>
            <div class="project-prompt-search"><SearchBox onSuggest={toolInterface.suggest.bind(toolInterface)} placeholder="Enter an address, city, lat/long…" /></div>
            <div class="project-prompt-tip"><i class="icon-polygon" /><span class="project-prompt-tip-text">Tap on the map to outline a polygon to define the bounds of this {toolInterface.tool.name}.</span></div>
        </>;
    }
};

export default ProjectPrompt;
