import constants from 'util/data/constants';
import modalModel from 'models/modal-model';
import CloseButton from 'views/close-button';
import peopleModel from 'models/people/people-model';
import {Avatar, AccountInfo, ContactInfo, TextField, PersonMenu} from 'views/people/profile/components';
import panelModel from 'models/panel-model';
import popupModel from 'models/popover-model';

class Profile {

    oninit({attrs: {person}}) {
        this.person = person || peopleModel.currentlyViewing;
        this.viewingSelf = this.person.isSelf;
        this.creatingNew = this.person.isNewUser;
    }

    view({attrs: {person}}) {
        person = this.person;
        return <div class={`user-profile modal-wrap ${person.formState.isSavingNew ? 'freeze-editing' : ''}`}>
            <div class="modal-header-row">
                <span class="modal-title">Profile</span>
                <CloseButton onclick={() => modalModel.close()} />
            </div>
            <span class="full-width-label">Personal Details</span>
            {this.viewingSelf || this.creatingNew ? <div class="user-header two-col">
                <TextField person={person} isRequired={true} property={'givenName'} editable={this.person.userCanEdit()} />
                <TextField person={person} isRequired={true} property={'familyName'} editable={this.person.userCanEdit()} />
            </div> :
                <div class="user-header viewing-profile">
                    <Avatar person={person} size={'medium'}/>
                    <span class="user-full-name">{person.displayNameOr('New User')}</span>
                </div>}
            <div class={`user-body ${this.viewingSelf || this.creatingNew ? 'editable-profile' : 'viewing-profile'}`}>
                <ContactInfo person={person} isEditable={true} />
            </div>
            <div class={`user-role ${this.viewingSelf || this.creatingNew ? 'editable-profile' : 'viewing-profile'}`}>
                <span class="full-width-label">Platform Access</span>
                <AccountInfo person={person} includeDescription={true} isEditable={true} />
            </div>
            <div class="footer">
                {this.creatingNew ? <button class={`btn btn-pill btn-smaller btn-primary ${person.formState.isSavingNew ? 'saving' : ''}`} type="submit" onclick={() => peopleModel.submitNewPersonForm(person)}><span class="spinner"></span><span class="button-text">Add to Account</span></button>
                    : <button class="btn btn-pill btn-smaller btn-primary" type="submit" onclick={() => modalModel.close()}>Done</button> }
            </div>
        </div>;
    }
}

class ProfilePreview extends Profile {
    
    view({attrs: {person, handleClick}}) {
        const role = constants.accountUserRoles[person.role].display;
        return <div class={`user-profile preview ${person.status} ${person.savingClass} ${!handleClick ? 'click-disabled' : ''} `} onclick={handleClick ? handleClick : null}>
            <Avatar person={person} size={'small'}/>
            <div className="user-body">
                <i class='icon-ellipsis toggle-option-menu'
                    onclick={function (e) {
                        e.stopPropagation();
                        peopleModel.currentlyViewing = person;
                        popupModel.open(this, {
                            view: PersonMenu,
                            wrapClass: 'profile-preview-card-menu'
                        });
                    }}/>
                <span class="user-full-name"><span>{person.displayNameOr('New User')}{person.isSelf && ' (You)'}</span></span>
                <div class="user-role">{role}</div>
            </div>
        </div>;
    }
}

const closeMenu = (state) => {
    state.menuOpen = false;
};

class ProfileCard extends Profile {
    oninit({state}) {
        state.menuOpen = false;
    }

    view({state, attrs: {person}}) {
        return <div class={`user-profile card ${person.status} ${person.savingClass} ${state.menuOpen ? 'menu-open' : ''}`} onclick={() => peopleModel.openProfile(person)}>
            {person.savingClass ?
                <span className="teal spinner spinning"></span>
                : <i class='icon-ellipsis toggle-option-menu'
                    onclick={function (e) {
                        e.stopPropagation();
                        peopleModel.currentlyViewing = person;
                        state.menuOpen = true;
                        panelModel.popup.open(this, {
                            view: PersonMenu,
                            onClose: () => closeMenu(state)
                        });
                    }}/> }
            <div class="user-header decorated">
                <Avatar person={person}/>
                <span class="user-full-name"><span>{person.displayNameOr('New User')}{person.isSelf && ' (You)'}</span>
                </span>
            </div>
            <div class="user-body">
                <ContactInfo person={person} isEditable={false} />
            </div>
            <div class="user-role">
                <AccountInfo person={person} includeDescription={false} isEditable={false} />
            </div>
        </div>;
    }
}

export {Profile, ProfilePreview, ProfileCard};

