import siteModel from 'models/site-model';
import layerModel from 'models/layer-model';
import colorPickerModel from 'models/color-picker-model';
import api from 'legacy/util/api';
import store from 'util/data/store';
import MapModel from './map-model';
import message from '../legacy/components/message/message';
import onBodyClick from '../legacy/util/dom/on-body-click';

/**
 * Handles logic and state for plan color editing view.
 * (/views/plan/review.js
 */
class LayerColorModel {

    constructor() {
        this.map = null;
        this.prevColor = '#ffffff';

        this.state = {
            layerColor: null,
            colorOn: false,
            colorPanelOpen: false,
            isProcessing: false,
            isLoading: true
        };

    }

    init(opts) {
        this.planId = opts.planId;
        this.tilesetId = opts.tilesetId;
        const basemap = opts.basemap || layerModel.state.basemapId;
        this.state.layerColor = store.tilesets[this.tilesetId].color || store.tilesets[this.tilesetId].defaultColor;
        this.state.colorOn = this.state.layerColor !== 'None';
        this.map = siteModel.map = new MapModel({container: opts.container}); // siteModel.map for layer menu to function
        return this.map.mapLoaded().then(() => {
            layerModel.setBasemap(basemap);
            layerModel.hideAllLayers();
            layerModel.showPlan(store.plans[this.planId]);
            layerModel.awaitLayerChanges();
            this.toggleColorPicker(); // Toggle on upon load
            this.state.isLoading = false;
            m.redraw();
        });
    }

    /**
     * Shows and hides the color picker panel.
     */
    toggleColorPicker() {
        if (!this.state.colorPanelOpen) {
            onBodyClick.once(this.toggleColorPicker.bind(this));
        }
        this.state.colorPanelOpen = !this.state.colorPanelOpen;
        m.redraw();
    }

    /**
     * Updates the color picker preview swatch and ensure the "done" button
     * is made active after layer update is published.
     */
    handleUpdatedLayer() {
        this.state.layerColor = store.tilesets[this.tilesetId].defaultColor;
        this.state.isProcessing = false;
        m.redraw();
    }

    /**
     * Set the color of the tileset to the passed hex value via API patch.
     */
    setColor(colorHex, tilesetId) {
        this.state.layerColor = colorHex;
        this.state.isProcessing = true;
        m.redraw();
        api.patch.tileset({
            tilesetId: tilesetId ? tilesetId : this.tilesetId,
            color: this.state.layerColor,
            defaultColor: this.state.layerColor
        }).catch(() => message.show('Something went wrong. Please try again or contact support.', 'error'));
    }

    /**
     * Sets the layer color to "None" and stores prev color for toggling back on, or sets the color to the prevColor
     * (default #fff if color not stored)
     */
    toggleColor(currentColor, tilesetId) {
        if (currentColor === 'None') {
            this.setColor(this.prevColor, tilesetId);
            if (this.state.colorPanelOpen) {
                colorPickerModel.resetColor(this.prevColor);
            }
        } else {
            this.prevColor = currentColor;
            this.setColor('None', tilesetId);
        }
        m.redraw();
    }

}

export default new LayerColorModel();
