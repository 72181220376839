import LinksModel from 'models/links-model';
import userModel from 'models/user-model';
import pointMenu from 'legacy/components/point-menu';
import formModel from 'models/form-model';
import constants from 'util/data/constants';
import store from 'util/data/store';
import router from 'uav-router';
import TableRow from 'views/table/table-row';
import filterModel from 'models/table/filter-model';

let linksModel;

const Links = {

    oninit({state}) {

        linksModel = formModel.linksModel = new LinksModel();

        const asset = store.assets[formModel.assetId],
            assetFormId = store.assetTypeToFormId[asset.assetTypeId],
            assetForm = store.assetForms[assetFormId],
            linksControlTypeId = constants.controlTypeNameToId.links,
            linkControl = assetForm.controls.find(c => c.controlTypeId === linksControlTypeId);

        state.upload = () => {
            formModel.uploadFiles(linkControl);
        };

        if (userModel.isInvited) {

            state.actionUI = <p style="text-align: center;">
                <a class="btn btn-primary btn-pill btn-small" onclick={() => router.merge({signup: 'fromguest'})}>
                    Sign up to add links
                </a>
            </p>;

        } else if (userModel.isViewOnly) {

            state.actionUI = null;

        } else {

            state.actionUI = <div class="links-control-input" onclick={state.upload}>
                <span class="link-control-tip">Link other assets or media to this {asset.contentType}</span> <span class="btn btn-pill btn-secondary btn-smaller"><span class="btn-label"><i class="icon-plus"/><i class="icon-link icon"/></span></span>
            </div>;

        }

        state.onScroll = e => {
            e.redraw = false;
            pointMenu.onScroll();
            linksModel.onscroll(e);
        };

    },

    onremove() {
        formModel.linksModel = undefined;
    },

    list() {
        return linksModel.assetIds.length
            ? <>
                {linksModel.hasLinkedMedia &&
                    <div class="view-all-linked-media" onclick={() => linksModel.viewLinkedMedia()}>
                        <i class="icon-expand icon"/> View Media
                    </div>}
                {linksModel.assetIds.map((assetId, i) => <TableRow assetId={assetId} index={i + 1} key={assetId}/>)}
            </>
            : <div class="empty-state-message"><img src="/images/no-attachments.png" class="empty-state-image"/><span class="message-intro">No links just yet.</span>Tap above to add to this record.</div>;
    },

    view: ({state}) => <div className="tab-content link-content" onscroll={state.onScroll}>
        <div className="config-menu-control">
            <div onclick={function () {
                filterModel.open(this.parentElement, {viewKey: 'configMenu'});
            }}>
                <div class="config-menu-tab">
                    <span class="config-menu-tab-btn"><i className='icon-properties-table'/></span>
                    <span className="config-menu-tab-label">Properties</span>
                </div>
            </div>
        </div>
        {state.actionUI}
        {linksModel.loading
            ? <i class="gray spinner spinning"/>
            : <div class="list-links">{Links.list()}</div>
        }
    </div>
};

export default Links;
