import Stack from 'util/data/stack';
import stakeableModel from 'models/stakeable-model';
import StakePair from './stake-pair';
import measure from 'legacy/util/numbers/measure';

/**
 * Stores both the stake coordinate data and the stake objects themselves.
 * Controls behavior regarding creating, updating, and deleting within stored states for undo button option.
 */
class StakeList {
    constructor() {
        this.stakes = []; // Actual list of stake objects (Note: These are mutable).
        this.activePair = null;
        this.steps = new Stack(); // For tracing history w/undo button.
    }

    /**
     * Creates all the stakes from the current this.data variable, activates the last one added,
     * saves to undo history, and updates button states.
     */
    initStakes(coords) {
        this.createAll(coords);
        this.activatePair(this.stakes[this.stakes.length - 1]);
        this.saveStep();
        stakeableModel.updateButtonStates();
    }

    /**
     * Creates and adds a pair of Stakes to the target and origin map coordinates passed.
     *
     * Note: Assumes coords are formatted in conjunction with Unearth API.
     * Method exists within stakeableModel to format them before passing in.
     *
     * @param {*} formattedCoords - Stake coordinates formatted to save to Unearth API:
       [
            {
               origin: [LAT, LNG],
               target: [LAT, LNG, ELEV]
           },
           {
               origin: [LAT, LNG],
               target: [LAT, LNG]
           }, ...
        ]
     */
    addPair(formattedCoords) {
        const newPair = new StakePair(formattedCoords, this.stakes.length);
        this.stakes.push(newPair);
        this.activatePair(newPair);
        this.saveStep();
        stakeableModel.updateButtonStates();
    }

    /**
     * Given a StakePair object, deactivates it (if needed), removes it from the maps and stake list.
     */
    deletePair(pairToDelete) {
        if (this.activePair === pairToDelete) {
            this.deactivatePair(pairToDelete);
        }
        pairToDelete.removePair();

        this.stakes = this.stakes.filter(pair => pair !== pairToDelete);

        this.refreshStakeNumbers(); // Update the numbers in case a lower number was deleted.
        this.saveStep();
        stakeableModel.updateButtonStates();
    }

    /**
     * Deactivates the current active pair (if any) and activates (i.e., highlights) the pair passed.
     */
    activatePair(pair) {
        if (this.activePair) {
            this.activePair.stopHighlightPair();
        }
        pair.doHighlightPair();
        this.activePair = pair;
        stakeableModel.updateActivePairClass();
    }

    /**
     * Dehighlights the passed pair and sets the current active pair to null.
     */
    deactivatePair(pair) {
        if (pair) {
            this.activePair = null;
            pair.stopHighlightPair();
            stakeableModel.updateActivePairClass();
        }
    }

    /**
     * Saves the current stakes as a step and calls method to update button states.
     */
    updatePairData() {
        this.saveStep();
        stakeableModel.updateButtonStates();
    }

    /**
     * Given a data object, generates all the stakes for that data in one 'step' (for a single undo).
     * Used for adding all stakes from a previously staked asset.
     */
    bulkAddPairs(data) {
        data.forEach(datum => {
            const newPair = new StakePair(datum, this.stakes.length);
            this.stakes.push(newPair);
        });

        this.activatePair(this.stakes[this.stakes.length - 1]);
        this.saveStep();
        stakeableModel.updateButtonStates();
    }

    /**
     * Removes all current stakes and data in one 'step' (for a single undo).
     * Used for clearing all stakes with Clear button.
     */
    bulkDeletePairs() {
        this.removeAll();
        this.saveStep();
        stakeableModel.updateButtonStates();
    }

    /**
     * Runs through all the stake data elements and generates StakePairs with them.
     * Returns the list of StakePair objects.
     */
    createAll(data) {
        this.stakes = data.map((coordPair, index) => new StakePair(coordPair, index));
    }

    /**
     * Removes all the current stakes on the maps before calling createAll to create all the stakes from the stake data.
     */
    recreateAll(data) {
        this.stakes ? this.removeAll() : null;
        this.createAll(data);
    }

    /**
     * Removes all the stakes stored in the stake object list from the maps and then empties the list.
     */
    removeAll() {
        this.stakes.forEach(pair => pair.removePair());
        this.stakes = [];
    }

    /**
     * Saves the current state to the stack for undo history.
     * State data saved includes: Number of stakes, locations of stakes, and which StakePair was active.
     */
    saveStep() {
        const activeIndex = this.activePair ? this.activePair.position : null;
        this.steps.push({data: this.getStakeData(), activeIndex: activeIndex});
    }

    /**
     * Clears all existing steps, saves the current stake state and update the button states.
     */
    clearHistory() {
        this.steps = new Stack();
        this.saveStep();
        stakeableModel.updateButtonStates();
    }

    /**
     * Rolls back the state to the most recently added step (top of stack).
     * State data saved includes: Number of stakes, locations of stakes, and which StakePair was active.
     */
    undoStep() {
        this.deactivatePair(this.activePair);
        this.steps.pop();
        const step = this.steps.pop();
        this.recreateAll(step.data);
        if (step.activeIndex !== null && this.stakes[step.activeIndex]) {
            this.activatePair(this.stakes[step.activeIndex]);
        }
        this.saveStep();
        stakeableModel.updateButtonStates();
    }

    /**
     * Runs through each stake object in the stake list and re-renders them with their current indices as the number displayed.
     */
    refreshStakeNumbers() {
        let i = 0;
        this.stakes.forEach(pair => pair.renderPair(i++));
    }

    /**
     * Returns an array of StakePair objects that have been converted to API-ready data points.
     */
    getStakeData() {
        return this.stakes.map(stakePair => this.stakePairToApiDataPoint(stakePair));
    }

    /**
     * Takes a StakePair object and returns the coordinate data in an object ready to be sent to the API.
     */
    stakePairToApiDataPoint(stakePair) {
        const targetCoords = stakePair.targetStake.getLngLat();
        const originCoords = stakePair.originStake.getLngLat();
        const targetData =  [targetCoords.lat, targetCoords.lng];
        if (stakePair.targetStake.elevation) {
            const elevation = stakeableModel.isMetric ? stakePair.targetStake.elevation : measure.feetToMeters(stakePair.targetStake.elevation);
            targetData.push(elevation);
        }

        return {
            origin: [originCoords.lat, originCoords.lng],
            target: targetData
        };
    }

}

export default StakeList;
