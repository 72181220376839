import uav from 'uav';
import validation from 'legacy/util/data/validation';

function validatedField(opts) {

    const isCheckbox = opts.type === 'checkbox';

    if (opts.validateOnBlur !== false) {

        opts.validateOnBlur = true;

    }

    const isValid = () => {

        if (opts.validationType === 'match') {

            return component.value === opts.match();

        }

        if (isCheckbox) {

            return validation[opts.validationType].test(input.checked);

        }

        return component.value.match(validation[opts.validationType].regex);

    };

    function checkInput() {

        requestAnimationFrame(() => {

            component.value = input.value;

            if (isValid()) {

                component.error = false;

            }

            if (opts.oninput) {

                opts.oninput(component.value);

            }

        });

    }

    const id = opts.id || opts.type + 'Field';

    const model = {
        error: false,
        type: opts.type || 'text',
        checkInput,
        validate: () => {

            component.error = !isValid();

            if (isCheckbox) {

                checkInput();

            }

            return !component.error;

        },
        disabled: !!opts.disabled
    };

    if (opts.prompt) {

        model.prompt = opts.prompt;

    }

    if (opts.type === 'password') {

        model.togglePass = () => {

            if (component.type === 'password') {

                component.type = 'text';

            } else {

                component.type = 'password';

            }

        };

    }

    opts.value = opts.value || '';

    opts.cssClass = opts.cssClass || '';

    const validationEvent = isCheckbox ? 'change' : 'blur';

    const component = uav.component(`
    <fieldset u-class="{error} ${opts.cssClass} validated">
        ${opts.label ? `<label for="${id}">${opts.label}</label>` : ''}
        <input id="${id}"
            value="${opts.value}"
            u-type="{type}"
            u-oninput="{checkInput}"
            u-attr="{disabled}"
            ${opts.validateOnBlur ? `u-on${validationEvent}="{validate}"` : ''}
            ${opts.autofocus ? 'autofocus' : ''}/>
        <div class="error-msg">${validation[opts.validationType].errorMsg}</div>
        ${opts.prompt ? '<div class="form-prompt">{prompt}</div>' : ''}
        ${opts.type === 'password' ? '<span class="pwd-toggle" u-onclick="{togglePass}">{type === \'password\' ? \'show\' : \'hide\'}</span>' : ''}
    </fieldset>`, model);

    component.value = opts.value;

    component.isValid = isValid;

    const input = component._el.querySelector('input');

    return component;

}

export default validatedField;
