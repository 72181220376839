import popupModel from 'models/popover-model';
import activeCell from 'models/table/active-cell-model';
import formModel from 'models/form-model';
import store from 'util/data/store';
import peopleModel from 'models/people/people-model';
import tableModel from 'models/table/table-model';
import formControls from 'views/form-controls';
import placeModel from 'models/place-model';
import constants from 'util/data/constants';

const derivedField = 'Derived value cannot be directly edited.';
const readonlyField = 'This is a read-only field and cannot be edited.';
const userPermissions = 'You do not have permission to edit this property.';
const idOnly = 'The ID for this asset type is not editable.';

const ErrorPopup = {
    view: ({attrs: {message}}) =>
        <div class="asset-form-popup form-control-popup-wrap">
            <div class="form-control-popup-body">
                <i onclick={() => {
                    popupModel.close();
                    formModel.viewAsset(activeCell.assetId, 'Properties');
                }} class="icon-asset-form"/>
                <span class="popup-message">{message}</span>
            </div>
        </div>
};

// Wrapper for editing a form control within table view popup.
const AssetEditorPopup = {
    oninit: ({state}) => {
        state.control = activeCell.cell.controlType;
        state.assetId = activeCell.assetId;
        if (state.control !== 'places') {
            state.headerLabel = state.control.label;
            state.wrapClass = `form-control-popup-form ${constants.controlTypeIdToName[state.control.controlTypeId]}-form-control`;
            state.formControl = formControls[state.control.controlTypeId];
        }
    },

    view: ({state}) => {
        if (!activeCell.state.isActive || !state.formControl) {
            return;
        }
        const asset = store.assets[state.assetId];
        const isSaving = state.control === 'places' ? placeModel.isLoading : formModel.saving[state.headerLabel];
        const recentEditMessage = asset.recentlyEdited ? `${peopleModel.displayNameOrEmail(asset.changedBy.userId)} has recently edited this ${store.assetTypes[asset.assetTypeId].name}` : '';
        const recentBatchModifyMessage = tableModel.showBatchModifyNotice ? <span>A batch of asset modifications was recently processed. <span class='linked-text' onclick={tableModel.reload}>Reload table data<i class='icon-undo'/></span></span> : '';
        return <div class={`asset-form-popup form-control-popup-wrap ${isSaving ? ' saving' : ''}`}>
            {!!recentEditMessage && <div class='recently-edited-wrap'><div class='recently-edited-message'>{recentEditMessage}</div></div>}
            {!!recentBatchModifyMessage && <div class='recently-edited-wrap recent-batch-modify-wrap'><div class='recently-edited-message'>{recentBatchModifyMessage}</div></div>}
            <div class="form-control-popup-header">
                <div class="form-popup-title-wrap">
                    <i class="spinner spinning"/><label>{state.headerLabel}</label>
                </div>
                <i onclick={() => {
                    popupModel.close();
                    formModel.viewAsset(state.assetId, 'Properties');
                }} class="icon-asset-form" />
            </div>
            <div class={state.wrapClass}>
                {state.formControl(state.control, state.assetId, activeCell.changeUndone, true)}
            </div>
            <div class="form-control-popup-footer">
                {activeCell.isUndoable && <div class="sublabel form-control-popup-undo" onclick={() => activeCell.undoAssetChange()}>undo</div> }
                <div class="btn btn-primary btn-tiny btn-pill form-control-popup-done" onclick={() => activeCell.assetRow.validateThenClose(state.assetId, state.control)}>Done</div>
            </div>
        </div>;
    }
};

export const popups = {
    derived: {view: () => m(ErrorPopup, {message: derivedField})},
    readonly: {view: () => m(ErrorPopup, {message: readonlyField})},
    disabled: {view: () => m(ErrorPopup, {message: userPermissions})},
    id: {view: () => m(ErrorPopup, {message: idOnly})},
    assetEditor: {view: () => m(AssetEditorPopup)}
};

class Popover {
    oncreate() {
        window.addEventListener('resize', popupModel.close);
    }

    onclose() {
        window.removeEventListener('resize', popupModel.close);
    }

    view() {
        if (!popupModel.isOpen) {
            return undefined;
        }
        const View = popupModel.view;
        const coordinates = `top:${popupModel.coordinates.y}px; left:${popupModel.coordinates.x}px; width:${popupModel.coordinates.width}px`;
        return <div onclick={(e) => e.stopPropagation()}
            className={`table-popup ${popupModel.wrapClass}`}
            style={coordinates}>
            <View />
        </div>;
    }
}

export default Popover;
