import store from 'util/data/store';
import api from 'legacy/util/api';
import helpers from 'legacy/util/api/helpers';
import router from 'uav-router';
import userModel from 'models/user-model';
import peopleModel from 'models/people/people-model';
import PaginatedFetch from 'util/network/paginated-fetch';
import constants from 'util/data/constants';
import toolboxSelector from 'views/toolbox-selector/toolbox-selector';
import publish from 'legacy/util/api/publish';
import initializer from 'util/initializer';
import MenuModel from 'models/menu-model';
import formModel from 'models/form-model';
import panelModel from 'models/panel-model';
import PeopleList from 'views/people/people-list';
import modalModel from 'models/modal-model';
import AccountInfo from 'views/side-nav/account-menu/account-info';
import debounce from 'util/events/debounce';
import tableModel from 'models/table/table-model';
import sideNavModel from 'models/side-nav-model';

const LIST_API_REQUEST = (args) => api.rpc.request([['listAccounts', args]], false, true);
const LIST_API_ARGS = {include: ['users']};

class accountModel {

    constructor() {
        this.accounts = [];
        this.loadedAll = false;
        this.isSearching = false;
        this.searchQuery = '';
        this.paginator = new PaginatedFetch(LIST_API_REQUEST,
            {
                args: LIST_API_ARGS,
                onResults: this.handleResults.bind(this),
                onComplete: this.onComplete.bind(this)
            });
        this.menu = new MenuModel({
            onAllMenuClicks: () => m.redraw(),
            items: [{
                itemId: 'portfolio',
                text: 'Portfolio',
                onClick: this.showPortfolio.bind(this)
            }, {
                itemId: 'people',
                text: 'People',
                onClick: this.showPeoplePanel
            }, {
                itemId: 'account-info',
                text: 'Account Info',
                onClick: this.showAccountInfo
            }]
        });
        initializer.add(this.awaitChanges.bind(this));
        this.search = debounce(this.search.bind(this), 1000);
    }

    get hasSeatsAvailable() {
        return this.usedLicenseCount < store.account.seats;
    }

    select(account) {
        sideNavModel.close();
        // The sequence of these steps is important because
        // the setAccount function can result in a redirection
        // between the V1 and V2 apps
        router.url.set({
            accountId: account.accountId,
            projectId: account.attributes.metaProjectId
        });
        requestAnimationFrame(() => {
            this.setAccount(account);
            router.load();
        });
    }

    setAccount(account) {
        if (account) {
            if (account.attributes.appVersion === 1) {
                if (!constants.isDeployed) {
                    console.error('You\'ve loaded a V1 account using the V2 codebase. If this weren\'t localhost, you would be redirected to /1/');
                } else {
                    location.pathname = '/1/';
                    return;
                }
            }
            if (!store.account || store.account.accountId !== account.accountId) {
                toolboxSelector.accountToolboxes = null;
            }
            this.usedLicenseCount = account.users.filter(u => u.role !== 'guest' && u.role !== 'viewer').length;
            store.setObject('account', account);
            userModel.setAccountUserData(account); // Load logged in account user's data
            peopleModel.initAccount(); // Load other account users' data
        }
    }

    // Alternative fetch without pagination
    loadAll() {
        return LIST_API_REQUEST(LIST_API_ARGS).then(results => {
            this.handleResults(results);
            this.loadedAll = true;
            this.paginator.isComplete = true;
        });
    }

    search(query) {
        if (!query) {
            if (this.isSearching) {
                return this.clearSearch();
            }
            // Empty query provided but we didnt have an existing query, so do nothing.
            return;
        }
        this.isSearching = true;
        this.loadedAll = false;
        this.accounts = [];
        m.redraw();
        // Restart paginator using a simple search with our passed args.
        this.paginator.apiRequest = (args) => api.simpleSearch(args);
        this.paginator.args = {
            type: 'account',
            searchType: 'search',
            query,
            include: ['users']
        };
        this.paginator.restart();
    }

    // Clears the current search results and resumes normal pagination of accounts
    clearSearch() {
        this.accounts = [];
        this.loadedAll = false;
        this.isSearching = false;
        this.paginator.apiRequest = LIST_API_REQUEST;
        this.paginator.args = LIST_API_ARGS;
        this.searchInput.value = '';
        m.redraw();
        this.paginator.restart();
    }

    handleResults(results) {
        const allAccounts = store.accounts;
        let gotUsableResults;
        results.forEach((accountTemp) => {
            accountTemp.users = helpers.list(accountTemp.users);
            accountTemp.projectIds = helpers.list(accountTemp.projectIds);
            allAccounts[accountTemp.accountId] = accountTemp;

            if (accountTemp.status !== 'expired' && accountTemp.status !== 'suspended') {
                gotUsableResults = true;
                this.accounts.push(accountTemp);
            }
        });
        store.setMutableContainer('accounts', allAccounts);
        if (!this.paginator.isComplete && (!gotUsableResults || this.accounts.length < this.paginator.pageLimit)) {
            this.paginator.next();
        }
        m.redraw();
    }

    onComplete() {
        this.loadedAll = true;
    }

    // ---- Account Menu Actions ------

    showPortfolio() {
        panelModel.close();
        this.menu.activeItemId = 'portfolio';
        if (router.params.assetId) {
            formModel.validateThenRun(() => formModel.close());
        } else {
            m.redraw();
        }
    }

    showPeoplePanel() {
        if (router.params.assetId) {
            formModel.validateThenRun(() => {
                formModel.close();
                panelModel.open({view: PeopleList, onclose: tableModel.recallTable});
            });
        } else {
            panelModel.open({view: PeopleList, onclose: tableModel.recallTable});
        }
        tableModel.dismissTable();
    }

    showAccountInfo() {
        panelModel.close();
        modalModel.open({view: AccountInfo});
    }

    // If nothing is active, default to portfolio as active — unless we're viewing an asset.
    updateActiveMenuItem() {
        if (!router.params.assetId) {
            if (!this.menu.activeItemId) {
                this.menu.activeItemId = 'portfolio';
            }
        } else if (this.menu.activeItemId === 'portfolio') {
            this.menu.resetActive();
        }
    }

    useAccount(account) {

        const metaProjectId = account.attributes.metaProjectId;

        if (metaProjectId) {
            if (!router.params.projectId) {
                router.url.mergeReplace({projectId: metaProjectId});
            }
        }

        if (!store.accounts) {
            store.setMutableContainer('accounts', {});
        }

        if (!store.accounts[account.accountId]) {
            store.setContainerValue(store.accounts, account.accountId, account);
        }

        if (!store.account || store.account.accountId !== account.accountId) {
            store.setObject('account', account);
        }

        this.setAccount(account);

    }

    loadAccount(userId) {

        return userModel.init(userId).then(() => {

            const preferences = userModel.preferences;
            const lastVisitedProjectId = preferences.lastVisitedSite && preferences.lastVisitedSite.projectId;

            let projectId;

            if (!router.params.accountId) {
                projectId = router.params.projectId || lastVisitedProjectId;
            }

            return api.rpc.requests([
                ['listAccounts', {
                    limit: 1,
                    accountId: router.params.accountId,
                    projectId,
                    statusIn: router.params.accountId || router.params.projectId ? undefined : ['active', 'trial'],
                    include: ['users']
                }]
            ], true).then(([[account]]) => {

                const accounts = {};

                if (account) {

                    account.attributes = account.attributes || {};

                    account.users = helpers.list(account.users);

                    account.projectIds = helpers.list(account.projectIds);

                    this.useAccount(account);

                } else {

                    return api.rpc.request([['listAccounts', {
                        limit: 1
                    }]], true, true).then(([fallbackAccount]) => {

                        if (!fallbackAccount) {

                            return userModel.showAccessDeniedMessage();

                        }

                        fallbackAccount.attributes = fallbackAccount.attributes || {};

                        fallbackAccount.users = helpers.list(fallbackAccount.users);

                        fallbackAccount.projectIds = helpers.list(fallbackAccount.projectIds);

                        accounts[fallbackAccount.accountId] = fallbackAccount;

                        store.setObject('accounts', accounts);

                        store.setObject('account', fallbackAccount);

                        setTimeout(() => {

                            if (['active', 'trial'].includes(userModel.checkAccountStatus())) {

                                this.useAccount(fallbackAccount);

                            }
                        }, 1000);


                    });

                }

            });
        });
    }

    // ---- Set up publish handling ----

    awaitChanges() {
        publish.await({
            changeType: 'modified',
            recordType: 'account',
            test: change => change.accountId === store.account.accountId,
            callback: account => {
                account.users = helpers.list(account.users).filter(user => user && (!user.hasOwnProperty('isVisible') || user.isVisible));
                account.projectIds = helpers.list(account.projectIds);
                this.setAccount(account);
            },
            persist: true
        });
    }
}


export default new accountModel();
