import isMetric from 'util/numbers/is-metric';
import store from 'util/data/store';
import constants from 'util/data/constants';
import formatDate from 'legacy/util/date/format-date';
import round from 'util/numbers/round';
import measure from 'legacy/util/numbers/measure';
import peopleModel from 'models/people/people-model';

export function cellValueToText(value, controlTypeId) {
    const metric = isMetric();
    switch (controlTypeId) {
    case constants.controlTypeNameToId.date:
        return `${formatDate.ddmmyyyy(value)}`;
    case constants.controlTypeNameToId.length:
        return `${round(metric ? value : measure.metersToFeet(value))} ${metric ? 'meters' : 'feet'}`;
    case constants.controlTypeNameToId.area:
        return `${round(metric ? value : measure.squareMetersToSquareFeet(value))} ${metric ? 'meters²' : 'feet²'}`;
    case constants.controlTypeNameToId.volume:
        return `${round(metric ? value : measure.cubicMetersToCubicYards(value))} ${metric ? 'meters³' : 'yards³'}`;
    case constants.controlTypeNameToId.user:
        return value ? peopleModel.displayUserControlOption(value) : '';
    case constants.controlTypeNameToId.toggle:
        return value;
    case constants.controlTypeNameToId.multitext:
        // workaround for bad data import WEB-2156
        if (value && value.join) {
            return value.join(', ');
        }
        return value;
    case constants.controlTypeNameToId.place:
        const {placeIds} = value;
        if (placeIds && placeIds.length > 0) {
            const jsx = [];
            for (let i = 0; i < placeIds.length; i++) {
                if (i === 3) {
                    break;
                }
                jsx.push(<span onclick={(e) => {
                    // Pass data up to the cell click handler.
                    e.clickData = placeIds[i];
                }}>{store.places[placeIds[i]].name} </span>);
            }
            return jsx;
        }
        return '';
    default:
        return value;
    }
}

// Ensure "false" values display as "off" rather than empty for toggles. Undefined properties display as empty.
export function toggleValueToCellText(assetId, toggleControlType) {
    const asset = store.assets[assetId];
    const value = asset.properties[toggleControlType.label];
    return asset.properties.hasOwnProperty(toggleControlType.label) ? value === false ? 'Off' : 'On' : undefined;
}
