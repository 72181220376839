import simpleSearch from 'util/data/simple-search';
import onBodyClick from 'legacy/util/dom/on-body-click';
import debounce from 'util/events/debounce';
import {getNormalizedKey} from 'util/events/get-normalized-key';
import store from 'util/data/store';

class PlaceSearchModel {
    constructor(opts) {
        this.selectedMap = {};
        this.opts = opts;
        const {selectedItems} = this.opts;
        selectedItems.forEach((placeId) => {
            this.selectedMap[placeId] = true;
        });
        this.places = Object.values(store.places)
            .filter((place) => place.placeId !== 'siteBounds');
        this.active = false;
        this.onInput = debounce(this.onInput.bind(this));
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.toggleActive = this.toggleActive.bind(this);
        this.deactivate = this.deactivate.bind(this);
    }

    onInput(e) {
        const text = e.target.value;
        if (!text) {
            delete this.searchedPlaces;
            m.redraw();
            return;
        }

        this.searchedPlaces = simpleSearch({
            text,
            list: this.places,
            split: ' ',
            maxResults: 5,
            getValueFn: (place) => place.name
        }).filter(({placeId}) => !this.selectedMap[placeId]);

        if (this.searchedPlaces.length !== 0) {
            if (this.index >= this.searchedPlaces.length) {
                this.index = this.searchedPlaces.length - 1;
            } else if (this.index < 0) {
                this.index = 0;
            }
        }
    }

    onDelete(e, place) {
        e.stopPropagation();
        this.deactivate();
        const {onDelete} = this.opts;
        if (onDelete) {
            delete this.selectedMap[place.placeId];
            onDelete(place, this.opts);
        }
    }

    onSelect(e, place) {
        e.stopPropagation();
        this.deactivate();
        const {onSelect} = this.opts;
        if (onSelect) {
            this.selectedMap[place.placeId] = true;
            onSelect(place, this.opts);
        }
    }

    toggleActive() {
        this.active = !this.active;
        if (this.active) {
            this.firstFocus = true;
            onBodyClick.once(this.deactivate);
        } else {
            delete this.searchedPlaces;
            onBodyClick.clear(this.deactivate);
        }
    }

    deactivate() {
        if (this.active) {
            this.active = false;
            onBodyClick.clear(this.deactivate);
            delete this.searchedPlaces;
            m.redraw();
        }
    }

    onKeyDown(e) {
        const places = this.searchedPlaces || this.places.filter(({placeId}) => !this.selectedMap[placeId]);
        const key = getNormalizedKey(e.key);
        if (places.length === 0) {
            return;
        }
        switch (key) {
        case 'ArrowDown':
            e.preventDefault(); // Dont arrow through text input
            e.stopPropagation();
            this.index++;
            if (this.index >= places.length) {
                this.index = 0;
            }
            break;
        case 'ArrowUp':
            e.preventDefault();
            e.stopPropagation();
            this.index--;
            if (this.index < 0) {
                this.index = places.length - 1;
            }
            break;
        case 'Enter':
            e.preventDefault();
            e.stopPropagation();
            this.onSelect(e, places[this.index]);
            break;
        }
    }
}

export default PlaceSearchModel;
