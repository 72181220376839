import store from 'util/data/store';

function getAssetName(assetId) {

    const asset = store.assets[assetId];

    const nameControlLabel = store.assetTypeIdToNameControl[asset.assetTypeId];

    return asset.properties[nameControlLabel] || store.assetTypes[asset.assetTypeId].name;

}

export default getAssetName;
