import closeButton from 'legacy/components/close-button';
import element from 'util/dom/element';
import convertMithril from 'util/dom/convert-mithril';
import inspectorModel from 'models/inspector-model';

const Inspector = {

    onbeforeremove: ({dom}) => new Promise(resolve => {

        dom.classList.remove('visible');

        setTimeout(resolve, 200);

    }),

    oninit({state}) {

        // For now, we need to convert some legacy components when the inspector is initialized.
        // Once those are refactored, we can clean up this code, and hopefully stop using state.
        state.CloseButton = convertMithril.fromDom(closeButton(inspectorModel.close));

        if (inspectorModel.tip) {

            state.Tip = inspectorModel.tip;

        }

        let title = inspectorModel.title;

        title = title ? title._el || title : '';

        if (!title.setAttribute) {

            title = element`<div>${title}</div>`;

        }

        title.classList.add('inspector-title');

        state.Title = convertMithril.fromDom(title);

        state.View = inspectorModel.view;

    },

    oncreate: ({dom}) => requestAnimationFrame(() => dom.classList.add('visible')),

    view: ({state: {View, CloseButton, Tip, Title}}) => {
        
        return <div class={'inspector ' + (inspectorModel.className || '')}>
            <div class="sidebar-header">
                <CloseButton />
                <Title />
                {Tip && <Tip />}
            </div>
            <View />
        </div>;
    }
};

export default Inspector;
