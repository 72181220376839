import api from 'legacy/util/api';
import store from 'util/data/store';
import debouncedAPICall from 'util/network/debounced-api-call';
import formModel from 'models/form-model';
import constants from 'util/data/constants';
import siteModel from 'models/site-model';
import userModel from 'models/user-model';
import screenHelper from 'legacy/util/device/screen-helper';
import getToolInterface from '../util/interfaces/get-tool-interface';
import helpers from 'legacy/util/api/helpers';
import getAssetName from 'util/data/get-asset-name';
import featureModel from 'models/feature-model';
import deepCloneObject from 'util/data/deep-clone-object';
import tableModel from 'models/table/table-model';
import {bounds, latLngsToLngLats} from 'util/geo';
import centroid from '@turf/centroid';
import placeModel from 'models/place-model';

const assetModel = {

    fetch: (contentId, forceSync = false) => {
        const asset = store.assets[contentId];

        if (asset && !forceSync) {

            return Promise.resolve(asset);

        }

        return api.rpc.request([['listContent', {
            include: ['media'],
            contentId,
            limit: 1
        }]]).then(([_asset]) => {

            if (_asset) {

                assetModel.addToStore(_asset, forceSync);

            }

            return _asset;

        });

    },

    addToStore(asset, doUpdate) {
        if (doUpdate || !store.assets[asset.contentId]) {
            asset.placeIds = helpers.list(asset.placeIds);
            asset.levelIds = helpers.list(asset.levelIds);
            asset.mediaIds = helpers.list(asset.mediaIds);
            asset.media = helpers.list(asset.media);
            asset.media.forEach((media) => {
                store.setContainerValue(store.media, media.mediaId, media);
            });
            store.assets[asset.contentId] = asset;
        }
        return store.assets[asset.contentId];
    },

    autosave(assetId) {

        const asset = store.assets[assetId];

        if (!asset.updatedDateTime) { // asset isn't saved

            formModel.saving = {};

            return Promise.resolve();

        }
        return debouncedAPICall('modifyAsset' + assetId, ['modifyContent', {
            contentId: assetId,
            properties: asset.properties,
            mediaIds: asset.mediaIds,
            isVisible: true
        }]).then(() => {
            formModel.saving = {};
            tableModel.activeCell.onAssetSave(assetId);
            m.redraw();
        });

    },

    supportsFileFeatures(assetId) {
        const asset = store.assets[assetId],
            tool = store.tools[asset.attributes.toolId],
            fileControlTypeId = constants.controlTypeNameToId.file;

        const fileControlLabels = {};

        tool.assetForm.controls.forEach(control => {

            if (control.controlTypeId === fileControlTypeId) {

                fileControlLabels[control.label] = true;

            }

        });

        // To create a feature, we need a feature type.
        // We can pick a suitable feature type for media
        // by making sure it meets these criteria:
        // - has geometry
        // - uses the filepicker interface
        // - is linked to a file control
        return tool.featureTypes.find(f => f.geometry
            && f.attributes.interface === 'filepicker'
            && f.attributes.linkedControls
            && f.attributes.linkedControls.find(label => fileControlLabels[label])
        );
    },

    supportsGeometry(assetId) {

        const asset = store.assets[assetId],
            tool = store.tools[asset.attributes.toolId];

        return tool.featureTypes.find(featureType => featureType.geometry && featureType.attributes.interface !== 'action');

    },

    supportsCloning(assetId) {

        const asset = store.assets[assetId];

        if (asset.contentType !== 'Plan' && asset.contentType !== 'Survey') {

            const assetFormId = store.assetTypeToFormId[asset.assetTypeId],
                assetForm = store.assetForms[assetFormId],
                projectControlId = constants.controlTypeNameToId.project;

            if (!assetForm.controls.find(c => c.controlTypeId === projectControlId)) {
                return true;
            }

        }

    },

    getLayer(assetId, layerType) {

        const asset = store.assets[assetId],
            tool = store.tools[asset.attributes.toolId],
            specifiedControlTypeId = layerType && constants.controlTypeNameToId[layerType];

        const controlTypeIds = specifiedControlTypeId
            ? {
                [specifiedControlTypeId]: 1
            } : {
                [constants.controlTypeNameToId.plan]: 1,
                [constants.controlTypeNameToId.survey]: 1
            };

        for (const control of tool.assetForm.controls) {

            const layerId = controlTypeIds[control.controlTypeId] && asset.properties[control.label];

            if (layerId) {

                return store.surveys && store.surveys[layerId] || store.plans && store.plans[layerId];

            }

        }

    },

    getSurveyId(assetId) {

        const asset = store.assets[assetId],
            tool = store.tools[asset.attributes.toolId],
            surveyControlTypeId = constants.controlTypeNameToId.survey,
            surveyControl = tool.assetForm.controls.find(c => c.controlTypeId === surveyControlTypeId);

        if (surveyControl) {

            return asset.properties[surveyControl.label];

        }

    },

    getCompleteLayerId(assetId, layerType) {

        const layer = assetModel.getLayer(assetId, layerType);

        return layer && layer.status === 'complete' && (layer.planId || layer.surveyId);

    },

    getLayerBounds(layerId, layerType) {

        const layer = store[layerType + 's'][layerId];

        const tilesetId = layerType === 'survey'
            ? layer.visibleTilesetId
            : layer.tilesetId;

        const tileset = layer && store.tilesets[tilesetId];

        return tileset && latLngsToLngLats(tileset.bounds);

    },

    panToAsset(assetId) {

        formModel.getAssetFeatures(assetId).then(features => {

            if (features.length) {

                featureModel.panToFeatures(features);

                if (assetId === this.openAssetId) {

                    return;

                }

                this.openAssetId = assetId;

                setTimeout(() => {

                    const latLngs = features.map((feature) => centroid(feature).geometry.coordinates);

                    siteModel.leftClick(features, latLngs[0])
                        .then((featurePopup) => {

                            if (featurePopup && !formModel.editingFeatureId) {

                                const id = 'pan-to-asset';

                                siteModel.mapModel.addColorCircle(id, latLngs, {
                                    opacity: 0.6,
                                    radius: 40
                                });

                                featurePopup.once('close', () => {

                                    siteModel.mapModel.safeRemoveSource(id);

                                    delete this.openAssetId;

                                });

                            }

                        });

                }, 500);

            } else {

                const plan = assetModel.getLayer(assetId, 'plan');

                let layerBounds = plan && bounds(assetModel.getLayerBounds(plan.planId, 'plan'));

                if (!layerBounds) {

                    const surveyId = assetModel.getCompleteLayerId(assetId, 'survey');

                    if (surveyId) {

                        layerBounds = bounds(assetModel.getLayerBounds(surveyId, 'survey'));

                    }

                    const asset = store.assets[assetId];

                    if (asset && asset.placeIds && asset.placeIds.length) {

                        placeModel.panToPlaces(asset.placeIds);

                    }

                }

                if (layerBounds) {

                    formModel.turnOnLayer(assetId);

                    siteModel.focusOnBounds(layerBounds);

                }

            }

        });

    },

    canAddToMap(assetId) {

        if (formModel.editingFeatureId || !userModel.isContentEditable(assetId)) {

            return false;

        }

        if (assetModel.supportsGeometry(assetId)) {

            return true;

        }

        const asset = store.assets[assetId],
            tool = store.tools[asset.attributes.toolId];
        const isAction = tool.featureTypes.find(featureType => featureType.attributes.interface === 'action');

        return !assetModel.getCompleteLayerId(assetId) && screenHelper.canEditLayers() && !assetModel.getSurveyId(assetId) && !isAction;

    },

    /**
     * Given an asset, clone it and return the clone
     * (currently does not support cloning of features)
     */
    createClone(assetId) {

        // Create starter asset of correct type
        const asset = store.assets[assetId],
            tool = store.tools[asset.attributes.toolId],
            assetClone = store.assets[getToolInterface(tool).assetId];

        // Update name of clone
        const name = 'Copy of ' + getAssetName(assetId);

        // Copy asset properties & attributes
        assetClone.properties = Object.assign({}, asset.properties);
        assetClone.attributes = asset.attributes;

        // Update name control, and check for unclonable controls
        tool.assetForm.controls.forEach(control => {
            if (control.controlTypeId === constants.controlTypeNameToId.name) {
                assetClone.properties[control.label] = name;
            }
            if (control.attributes.resetOnClone) {
                if (control.attributes.default === undefined) {
                    delete assetClone.properties[control.label];
                } else {
                    assetClone.properties[control.label] = deepCloneObject(control.attributes.default);
                }
            }
        });

        // Find any attached media and clone them
        const allPromises = [];

        assetClone.mediaIds = asset.mediaIds;

        tool.assetForm.controls.forEach(control => {

            const controlValue = asset.properties[control.label];

            // Don't clone user assignments
            if (control.controlTypeId === constants.controlTypeNameToId.user && controlValue) {

                delete assetClone.properties[control.label];

            }

            // COMMENTED OUT UNTIL WE GET https://unearth.atlassian.net/browse/API-579

            // if (control.controlTypeId === constants.controlTypeNameToId.file && controlValue) {

            //     allPromises.push(

            //         Promise.all(controlValue.map(mediaModel.getMedia)).then(mediaRecords =>

            //             Promise.all(mediaRecords.map(mediaRecord => mediaModel.createClone(mediaRecord)))

            //                 .then(clonedMediaRecords => {

            //                     const clonedMediaIds = clonedMediaRecords.map(media => media.mediaId);

            //                     assetClone.mediaIds.push(...clonedMediaIds);

            //                     assetClone.properties[control.label] = clonedMediaIds;

            //                 })

            //         ));

            // }

        });

        // Copy linked asset references.
        assetClone.linkIds = asset.linkIds || [];
        const linkedAssetUpdates = [];

        // Add clone to linked assets link list (skip assets not found in store)
        assetClone.linkIds.forEach((linkedAssetId) => {
            const linkedAsset = store.assets[linkedAssetId];
            if (linkedAsset) {
                const linkIds = linkedAsset.linkIds || [];
                linkIds.push(assetClone.contentId);
                linkedAssetUpdates.push(['modifyContent', {
                    contentId: linkedAssetId,
                    linkIds
                }]);
            }
        });

        // Publish clone and associated changes to DB
        Promise.all(allPromises)
            .then(() => api.rpc.create('Content', api.apiSafeContent(assetClone)))
            .then(() => api.rpc.requests(linkedAssetUpdates));

        return assetClone;
    },

    isProjectAsset(asset) {
        const {assetTypeId} = asset;
        const assetFormId = store.assetTypeToFormId[assetTypeId],
            assetForm = store.assetForms[assetFormId];
        const index = assetForm.controls.findIndex((control) =>  control.controlTypeId === constants.controlTypeNameToId.project);
        return index !== -1;
    }


};

export default assetModel;
