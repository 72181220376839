import SearchBoxModel from 'models/search-box-model';

const SuggestionsDropdown = {
    view: ({attrs: {model, onSearch}}) => <div class="suggestions">
        {model.suggestionTip && <div class='suggestion-tip'><i class='icon-center-on-map' />{model.suggestionTip}</div>}
        {model.suggestions.map((suggestion, i) =>
            <div key={suggestion.text}
                class={'suggestion' + (model.focusedSuggestionIndex === i ? ' focus' : '')}
                onmousedown={e => model.useSuggestion(i, e, onSearch)}
                ontouchstart={e => model.useSuggestion(i, e, onSearch)}>
                {suggestion.iconClass && <i class={suggestion.iconClass} />}
                {suggestion.text}
                {suggestion.secondLine && <span class='second-line'>{suggestion.secondLine}</span>}
            </div>
        )}
        {model.displayNoResultsFound && <div class='suggestion disabled-suggestion'>No results found.</div>}
    </div>
};

class SearchBox {

    oninit({state, attrs: {value, includeGeoSearch}}) {
        state.model = new SearchBoxModel();
        state.model.includeGeoSearch = includeGeoSearch || false;
        state.model.query = value || '';
    }

    // htmlId : Unique id for the input html element used for this search box instance. 
    oncreate({attrs: {htmlId, value}}) {
        document.getElementById(htmlId).value = value || '';
    }

    onupdate({attrs}) {
        if (attrs.sync) {
            document.getElementById(attrs.htmlId).value = attrs.value || '';
        }
    }

    view(vnode) {
        const attrs = vnode.attrs, state = vnode.state,
            placeholder = attrs.placeholder,
            focusedPlaceholder = attrs.focusedPlaceholder || attrs.placeholder,
            label = attrs.label,
            focusedLabel = attrs.focusedLabel || attrs.label,
            htmlId = attrs.htmlId,
            model = state.model;

        model.includeGeoSearch = attrs.includeGeoSearch || false;

        let onKeyDown;
        if (attrs.onKeyDown) {
            onKeyDown = (query) => attrs.onKeyDown(query, vnode);
        }
        return <div class={`search-box ${model.isEmpty ? 'empty' : 'active'} ${state.isFocused ? 'focus' : 'blur'} ${label ? 'with-label' : 'no-label'}`}>
            <div class='search-input-wrap'>
                {label && <div class='search-box-label'>{state.isFocused || !model.isEmpty ? focusedLabel : label}</div>}
                <input id={htmlId} type='text' class={'search-box-input ' + (model.isLoading ? 'loading' : 'loaded')}
                    placeholder={state.isFocused ? focusedPlaceholder : placeholder}
                    onkeydown={(e) => model.search(e, attrs.onSearch, attrs.onSuggest, onKeyDown)}
                    onfocus={() => {
                        state.isFocused = true;
                    }}
                    onblur={() => {
                        state.isFocused = false;
                    }}/>
            </div>
            <i class={model.isLoading ? 'spinner spinning' : model.isEmpty ? 'icon-search' : 'icon-close'}
                onclick={!model.isLoading && (model.isEmpty ? model.focus : (e) => model.clear(e, attrs.onSearch))}/>
            <SuggestionsDropdown model={model} onSearch={attrs.onSearch}/>
        </div>;
    }
}

export default SearchBox;
