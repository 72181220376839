import Draw from './draw';

class Point extends Draw {

    constructor(opts) {

        super(opts);

        this.addVertex = this._addVertex.bind(this);

        this.type = 'Point';

    }

    getCoordinates() {

        return [this.feature.geometry.coordinates];

    }

    render() {

        if (this.feature.geometry.coordinates.length) {

            this.source.setData(this.source._data);

        }

    }

    _addVertex(e) {

        this.feature.geometry.coordinates = e.lngLat.toArray();

        this.source._data.features.push(this.feature);

        if (this.onVertexAdded) {

            this.onVertexAdded(this.feature);

        }

        this.render();

        this.stop();

        if (this.onComplete) {

            this.onComplete(this.feature);

        }

    }

    newFeature() {

        const id = this.randomId();

        this.feature = {
            type: 'Feature',
            id,
            geometry: {
                type: 'Point',
                coordinates: []
            },
            properties: Object.assign(this.properties, {_id: id})
        };

        this.map.on('click', this.addVertex);

        return this;

    }

    editFeature() {

        const vertex = this.makeVertex(this.feature.geometry.coordinates, 0)
            .on('drag', () => {

                this.feature.geometry.coordinates = vertex.getLngLat().toArray();

                this.render();

            }).on('dragend', () => {

                if (this.onVertexChanged) {

                    this.onVertexChanged(this.feature);

                    this.render();

                }

            });

        return this;

    }

    removeEventListeners() {

        this.map.off('click', this.addVertex);

    }

}

export default Point;
