import {datadogRum} from '@datadog/browser-rum';
import constants from 'util/data/constants';

if (constants.isDeployed) {

    const env = {
        development: 'dev',
        staging: 'staging',
        production: 'prod'
    }[constants.awsProfile];

    datadogRum.init({
        applicationId: '81a7b931-e6f8-4f2b-be26-8693b484c4f3',
        clientToken: 'pub8b13d42bdef2d1f9cd1594251f2bc8b5',
        site: 'datadoghq.com',
        service: 'OnePlace',
        env,
        version: constants.release,
        sampleRate: 100,
        trackInteractions: true,
        beforeSend: e => {
            // Ignore all network errors related to loading tiles
            const resource = e.resource || e.error && e.error.resource;
            if (resource && resource.url.startsWith('https://api.mapbox.com/styles')) {
                return false;
            }
        }
    });

}
