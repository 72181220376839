import formatDate from 'legacy/util/date/format-date';
import peopleModel from 'models/people/people-model';
import formModel from 'models/form-model';
import stopPropagation from 'util/events/stop-propagation';
import AssetOptions from 'views/asset-options';
import mediaModel from 'models/media-model';
import store from 'util/data/store';
import getAssetName from 'util/data/get-asset-name';

class Card {

    oninit({attrs: {assetId}, state}) {

        const asset = store.assets[assetId];

        state.timestamp = formatDate.timestamp(asset.createdDateTime);

        state.thumbnail = mediaModel.getIcon(assetId);

        state.assetType = store.assetTypes[asset.assetTypeId];

    }

    view({attrs: {assetId}, state: {thumbnail, assetType, timestamp}}) {

        const asset = store.assets[assetId];

        if (!asset.assetTypeId) {

            return console.error('Invalid asset:', asset);

        }

        const author = peopleModel.getPerson(asset.authorId);

        return <div class="card" onclick={() => formModel.viewAsset(assetId, 'Properties')} key={assetId}>
            <AssetOptions assetId={assetId}/>
            {thumbnail}
            <span class="asset-title">{getAssetName(assetId)}</span>
            <div class="asset-type-wrap">
                <span class="asset-type">{assetType.name}</span>
                <span class="asset-tablink-btn" onclick={stopPropagation(() => formModel.viewAsset(assetId, 'Comments'))}>
                    <i class="icon-plus"/>
                    <i class="icon-comment"/>
                </span>
                <span class="asset-tablink-btn" onclick={stopPropagation(() => formModel.viewAsset(assetId, 'Links'))}>
                    <i class="icon-plus"/>
                    <i class="icon-link"/>
                </span>
            </div>
            <div class="asset-added">{timestamp}{author ? ` / ${peopleModel.displayName(author)}` : ''}</div>
        </div>;

    }
}

export default Card;
