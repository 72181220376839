import store from 'util/data/store';
import sideNavModel from 'models/side-nav-model';
import userModel from 'models/user-model';

const ProjectMenu = {
    view: () => {
        const metaProjectId = store.account.attributes.metaProjectId,
            projectName = store.project.name;
        return <div class="project-menu side-nav-popup">
            <div class="padding-wrap">
                {!!projectName && <div class="side-nav-popup-title">{projectName}</div>}
                <ul class="side-nav-popup-links">
                    <li class="side-nav-popup-item">
                        <a onclick={() => sideNavModel.openMetaAsset('Properties')}>
                            <i class="icon-asset-form" /> Properties
                        </a>
                    </li>
                    <li class="side-nav-popup-item">
                        <a onclick={() => sideNavModel.openMetaAsset('Comments')}>
                            <i class="icon-discussion" /> Comments
                        </a>
                    </li>
                    <li class="side-nav-popup-item">
                        <a onclick={() => sideNavModel.openMetaAsset('Links')}>
                            <i class="icon-link" /> Links
                        </a>
                    </li>
                    {!!userModel.isAccountAdmin && <>
                        <li class="side-nav-popup-item">
                            <a onclick={() => sideNavModel.openMetaAsset('People')}>
                                <i class="icon-user" /> People
                            </a>
                        </li>
                        <li class="side-nav-popup-item">
                            <a onclick={() => sideNavModel.openMetaAsset('Places/Levels')}>
                                <i class="icon-place-with-levels" /> Places/Levels
                            </a>
                        </li>
                    </>}
                </ul>
            </div>
            <div class="project-menu-footer">
                <a class="btn btn-pill btn-secondary btn-smaller" href={`/#projectId=${metaProjectId}`}><span class="btn-label">View Portfolio</span></a>
            </div>
        </div>;
    }

};

export default ProjectMenu;
