import filterModel from 'models/table/filter-model';
import SearchBox from 'views/search-box/search-box';
import tableModel from 'models/table/table-model';
import siteModel from 'models/site-model';
import TableRow from 'views/table/table-row';
import pointMenu from 'legacy/components/point-menu/point-menu';
import SidebarToggle from 'views/sidebar-toggle/sidebar-toggle';
import addCommas from 'util/numbers/add-commas';
import popoverModel from 'models/popover-model';
import batchModifyModel from 'models/batch-modify-model';
import LayoutControl from 'views/table/layout-control';
import toolboxModel from 'models/toolbox-model';
import capitalize from 'util/data/capitalize';
import store from 'util/data/store';
import commonFilterModel from '../../models/table/common-filter-model';


class Table {

    constructor() {
        this.searchBox = new SearchBox();
    }

    oncreate() {
        tableModel.rememberScroll();
        tableModel.editingAllowed = tableModel.tableMode === 'table-bottom' || tableModel.tableMode === 'table-full';
        tableModel.checkScrollArrows();
    }

    onremove() {
        popoverModel.close();
        tableModel.editingAllowed = false;
        tableModel.activeCell.exitTable();
        filterModel.saveFilterStates();
    }

    renderState(width) {
        const assetCount = tableModel.assetIds.length;
        if (assetCount) {
            return this.rowsState(width);
        } else if (tableModel.loadingTable) {
            return this.loadingState(width);
        } else if (tableModel.loadingFresh) {
            return this.filteredLoadingState(width);
        } else if (assetCount === 0 && tableModel.totalAssetCount === 0) {
            return this.emptyState(width);
        } else if (assetCount === 0 && tableModel.totalAssetCount > 0) {
            return this.filteredState(width);
        }
        return this.filteredLoadingState(width);
    }

    /**
     *  This is the state when we have filters selected, loading is done and no assets.
     */

    filteredState(width) {
        return <div class="table-placeholder" style={tableModel.tableMode !== 'list-left' ? `width:${width}px;` : ''}>
            <div class="placeholder-message">
                <img src="/images/no-results.png" class="placeholder-image" />
                <div class="placeholder-headline">No Results</div>
                <div class="placeholder-subtext">Try picking a different filter, or <a onclick={() => tableModel.renderWithDefaultFilters()} class="no-results-reset">reset all filters</a> to start fresh.</div>
            </div>
        </div>;
    }

    /**
     *  This is the loading state without filters.
     *  This will be the initial load and if you deselect all filters.
     */
    loadingState(width) {
        return <div class="table-placeholder" style={tableModel.tableMode !== 'list-left' ? `width:${width}px;` : ''}><i class="loader-v2 block border"/>
            <div class="placeholder-message">
                <img src="/images/loading-assets.png" class="placeholder-image" />
                <div class="placeholder-headline">Loading Data</div>
                <div class="placeholder-subtext">Hang tight - it should only take a moment.</div>
            </div>
        </div>;
    }

    /**
     *  This is the loading state with filters.
     */

    filteredLoadingState(width) {
        return <div class="table-placeholder" style={tableModel.tableMode !== 'list-left' ? `width:${width}px;` : ''}><i class="loader-v2 block border"/>
            <div class="placeholder-message">
                <img src="/images/updating-filters.png" class="placeholder-image" />
                <div class="placeholder-headline">Updating Filters</div>
                <div class="placeholder-subtext">We'll be right back with your results.</div>
            </div>
        </div>;
    }

    /**
     *  This is the state when we have no filters selected and no assets.
     */
    emptyState(width) {
        return <div class="table-placeholder" style={tableModel.tableMode !== 'list-left' ? `width:${width}px;` : ''}>
            <div class="placeholder-message"><img src="/images/no-assets.png" class="placeholder-image"/>
                <div class="placeholder-headline">Getting started is easy.</div>
                <div class="placeholder-subtext">No assets here just yet. To add to your site, tap + in the left sidebar.</div>
            </div>
        </div>;
    }

    /**
     * This is the state when the table has assets to render.
     */
    rowsState(width) {
        const className = `asset-rows${tableModel.loadingFresh ? ' outgoing-rows' : ''}`;
        return <div className={className} style={tableModel.tableMode !== 'list-left' ? `width:${width}px;` : ''}>
            {tableModel.assetIds.map((assetId, i) => {
                if (i >= tableModel.loadRange.startRow && i <= tableModel.loadRange.endRow) {
                    return <TableRow key={assetId + i} assetId={assetId} index={i} />;
                }
                return <div key={assetId + i} className='mock-table-row' />;
            })}
        </div>;
    }

    view() {    
        const tableCssClass = `asset-table ${tableModel.tableMode}${tableModel.transition}${tableModel.isCollapsed ? ' collapsed' : ''}${tableModel.loadingTable ? ' loading' : ''}${tableModel.inEditMode ? ' edit-mode' : ' view-mode'}${tableModel.shouldDisplayTableFooter() ? ' show-table-footer' : ''}`;
        const tableHeaderSections = tableModel.getVisibleTableHeaderSections();
        const styles = tableModel.styles;
        const commonHeaderStyles = styles.allCommonColumnHeaderStyles;
        const assetCount = Math.max(0, Math.min(tableModel.assetCount, tableModel.totalAssetCount));
        const viewKeys = commonFilterModel.nameToViewKey;
        const AssetSearchBox = this.searchBox;
        return <div>
            <div class={tableCssClass}>
                <SidebarToggle onclick={() => tableModel.sideBarToggle()}/>
                <div className="asset-table-fill">
                    <div className="search-box-wrap">
                        {!tableModel.loadingTable && <AssetSearchBox value={filterModel.searchString} htmlId="table-asset-search" 
                            label={siteModel.isMetaProject ? 'Portfolio' : `Current ${capitalize(toolboxModel.projectSingular)}`}
                            placeholder={siteModel.name}
                            focusedPlaceholder='Term, Value, or Location'
                            focusedLabel={`Search ${siteModel.name} or Map`}
                            onSearch={q => filterModel.search(q)} onSuggest={q => filterModel.suggest(q)} sync={filterModel.isOpen || tableModel.loadingFresh} includeGeoSearch={true}/>}
                    </div>
                    {tableModel.loadingFresh ?
                        <div class="results-count"><i class="loader-v2 small"/></div> :
                        <div class="results-count"><span class="results-count-text">{`${addCommas(assetCount) || 0} of ${addCommas(tableModel.totalAssetCount) || 0}`}</span>
                            <div class="reset-link">
                                {assetCount !== tableModel.totalAssetCount && <span class="reset-link-inner" onclick={() => tableModel.renderWithDefaultFilters()}><i class="icon-configure"/> <span class="btn-label">Reset Filters</span></span>}
                            </div>
                        </div>}
                    <div className="config-menu-control">
                        <div onclick={function () {
                            filterModel.open(this.parentElement, {viewKey: 'configMenu'});
                        }}>
                            <div class="config-menu-tab">
                                <span class="config-menu-tab-btn"><i className='icon-properties-table'/></span>
                                <span className="config-menu-tab-label">Properties</span>
                            </div>
                        </div>
                    </div>
                    <div class='editing-control'>
                        <span class="editing-control-btn view-btn" onclick={() => tableModel.toggleEditMode(false)}>
                            <i className="icon-asset-form"/> <span class="btn-label">View</span></span>
                        <span className="editing-control-btn edit-btn" onclick={() => tableModel.toggleEditMode(true)}>
                            <i className="icon-edit"/> <span class="btn-label">Edit</span></span>
                    </div>
                    <LayoutControl />
                    <div className="asset-table-header">
                        <div className={tableModel.COLUMN_GROUP_HEADERS}>
                            {tableHeaderSections.length > 0 && <div className="column-group-span" style={`width: ${tableModel.getWidthCommon()}px`}><span>Common Properties</span></div>}
                            {tableHeaderSections.map(({assetType, count}) => {
                                return <div className="column-group-span" style={`width:${tableModel.COL_WIDTH * count}px`}><i className="toggle-switch toggle-is-on" onclick={function () {
                                    tableModel.hideAssetType(assetType, true);
                                }}/><span className="span-label">{assetType.name} Properties</span></div>;
                            })}
                        </div>
                        <div className="asset-rows-header" onscroll={e => popoverModel.close(e)}>
                            <div className={`${commonHeaderStyles.name} asset-primary-label`}>
                                <div className="primary-label-wrap" onclick={function () {
                                    filterModel.open(this.parentElement, {viewKey: viewKeys.name});
                                }}>
                                    <span className="row-number"/><span className="property-label">Name</span><i className="icon-configure"/>
                                </div>
                                <span className="map-status">
                                    <i className="icon-map-square"/>
                                    <span className="map-status-label">Map</span>
                                </span>
                                <span className="asset-options-menu">
                                    <i className="icon-ellipsis"/>
                                </span>
                            </div>
                            <ul className="asset-properties properties-list">   
                                {tableModel.visibleHeaders.unearthId && <li className={`${commonHeaderStyles.unearthId} asset-id`} onclick={function () {
                                    filterModel.open(this, {viewKey: viewKeys.unearthId});
                                }}> <span className="property-label">Unearth ID</span><i className="icon-configure"/></li>}
                                {tableModel.visibleHeaders.category && <li className={`${commonHeaderStyles.category} asset-category`} onclick={function () {
                                    filterModel.open(this, {viewKey: viewKeys.category});
                                }}><span className="property-label">Category</span><i className="icon-configure"/></li>}
                                {tableModel.visibleHeaders.type && <li className={`${commonHeaderStyles.type} asset-type`} onclick={function () {
                                    filterModel.open(this, {viewKey: viewKeys.contentType});
                                }}><span className='property-label'>Type</span><i className="icon-configure"/></li>}
                                {tableModel.visibleHeaders.addedDateTime && <li className={`${commonHeaderStyles.addedDateTime} asset-added`} onclick={function () {
                                    filterModel.open(this, {viewKey: viewKeys.createdDateTime});
                                }}><span className="property-label">Added Date/Time</span><i className="icon-configure"/></li>}
                                {tableModel.visibleHeaders.lastUpdated && <li className={`${commonHeaderStyles.lastUpdated} asset-added`} onclick={function () {
                                    filterModel.open(this, {viewKey: viewKeys.captureDateTime});
                                }}><span className="property-label">Last Updated</span><i className="icon-configure"/></li>}
                                {tableModel.visibleHeaders.addedBy && <li className={`${commonHeaderStyles.addedBy} asset-author`} onclick={function () {
                                    filterModel.open(this, {viewKey: viewKeys.addedBy});
                                }}><span className='property-label'>Added By</span><i className="icon-configure"/></li>}
                                {tableModel.visibleHeaders.places && <li className={`${commonHeaderStyles.places} asset-places`} onclick={function () {
                                    filterModel.open(this, {viewKey: viewKeys.location});
                                }}><span className="property-label">Location</span><i className="icon-configure"/></li>}
                                {tableModel.getVisibleSharedColumnHeaders().map(sharedColumnName => {
                                    const sharedColumn = tableModel.getSharedColumn(sharedColumnName);
                                    const sharedHeaderStyles = styles.getSharedColumnHeaderStyles(sharedColumnName);
                                    if (filterModel.hasFilterMenu(sharedColumn.controlType.controlTypeId)) {
                                        return <li className={`${sharedHeaderStyles} type-specific shared-property`} onclick={function () {
                                            filterModel.open(this, {
                                                sharedColumnName,
                                                viewKey: sharedColumn.controlType.controlTypeId,
                                                assetTypeIds: sharedColumn.assetTypeIds,
                                                controlType: sharedColumn.controlType,
                                                assetType: store.assetTypes[sharedColumn.assetTypeIds[0]]
                                            });
                                        }}><span className="property-label">{sharedColumn.name}</span><i className="icon-configure"/></li>;
                                    }
                                    return <li className="type-specific">
                                        <span className="property-label">{sharedColumn.name}</span>
                                    </li>;
                                })}
                                {tableModel.getVisibleTableHeaders().map(({controlType, assetType}) => {
                                    const assetTypeId = assetType.assetTypeId;
                                    if (filterModel.hasFilterMenu(controlType.controlTypeId)) {
                                        const typeSpecificStyles = styles.getTypeSpecificHeaderStyles(assetTypeId, controlType.name);
                                        return <li className={`${typeSpecificStyles} asset-type type-specific`}
                                            onclick={function () {
                                                filterModel.open(this, {
                                                    controlType,
                                                    assetType,
                                                    viewKey: controlType.controlTypeId
                                                });
                                            }}>
                                            <span className="property-label">{controlType.label}</span>
                                            <i className="icon-configure"/>
                                        </li>;
                                    }
                                    return <li className="asset-type type-specific">
                                        <span className="property-label">{controlType.label}</span>
                                    </li>;
                                })}
                            </ul>
                        </div>
                    </div>
                    <div class="y-scroll-container" onscroll={(e) => {
                        popoverModel.close();
                        pointMenu.close();
                        tableModel.onVerticalScroll(e);
                    }}>
                        <div class={tableModel.X_SCROLL} onscroll={(e) => {
                            popoverModel.close();
                            pointMenu.close();
                            tableModel.onHorizontalScroll(e);
                        }}>
                            <div class={`scroll-right ${!tableModel.hideArrows && tableModel.horizontalScrollPercent >= 100 ? ' disabled' : ''}`} onclick={() => tableModel.scrollRight()}><i class="icon-arrow-right"/></div>
                            <div class={`scroll-left ${!tableModel.hideArrows && tableModel.horizontalScrollPercent <= 0 ? ' disabled' : ''}`} onclick={() => tableModel.scrollLeft()}><i class="icon-arrow-left"/></div>
                            {this.renderState(tableModel.width)}
                        </div>
                        <div/>
                        <div class="add-assets-prompt"><span class="plus-sign">+</span>Select a tool from the toolkit to add assets to your site.</div>
                    </div>
                    {tableModel.shouldDisplayTableFooter() && <div class="table-footer"><button type="submit" class="btn btn-small btn-primary btn-pill" onclick={batchModifyModel.start}>Batch Modify</button>
                    </div> }
                </div>
            </div>
        </div>;
    }
}

export default Table;
