import uav from 'uav';
import toggle from 'legacy/util/dom/toggle-element';

let timeout,
    component,
    onclose;

function close() {

    if (component) {

        clearTimeout(timeout);

        toggle.hide(component._el);

        component._el.classList.remove('active');

    }

    if (onclose) {

        onclose();

    }

    onclose = null;

}

function message() {

    component = uav.component(`
    <div u-class="message {klass}" style="display:none">
        {content}
        <a class="icon-close" u-onclick={close}></i>
    </div>`, {
        klass: false,
        content: document.createElement('span'),
        close
    });

    return component;
}

function show(view, klass, persist, _onclose, timoutTick = 8000) { // eslint-disable-line

    onclose = _onclose;

    if (component) {

        toggle.show(component._el, () => component._el.classList.add('active'));

        if (typeof view === 'string') {

            if (view.trim()[0] !== '<') {

                view = `<span>${view}</span>`;

            }

            view = uav.component(view);

        }

        component.content = view;

        component.klass = klass || 'success';

        clearTimeout(timeout);

        if (!persist) {

            timeout = setTimeout(close, timoutTick);

        }

    }

}

message.hide = close;

message.show = show;

export default message;
