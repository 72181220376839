import popup from 'util/popup';
import PointMenu from 'views/point-menu/point-menu';
import placesTabModel from 'models/places-tab-model';
import getAssetName from 'util/data/get-asset-name';
import PlaceProjectMenu from 'views/places-tab/place-project-menu';

class PlaceFeaturesMenu {

    static open(features, lngLat) {
        popup.remove();
        requestAnimationFrame(()=> {
            popup.add({
                className: 'feature-menu-popup',
                content: <PlaceFeaturesMenu features={features} lngLat={lngLat}/>,
                lngLat,
                maxWidth: 'none'
            });
        });
    }

    view({attrs: {features, lngLat}}) {
        if (features.length > 1) {
            const featureId = placesTabModel.feature.id;
            return <PointMenu options={features
                .sort((fa, fb) => fb.id === featureId || fa.id === featureId ? -1 : 0)
                .map(feature => {
                    if (feature.id === featureId) {
                        const projectAsset = placesTabModel.asset;

                        return {
                            label: `${getAssetName(projectAsset.contentId)}`,
                            icon: 'icon-place',
                            callback: () => PlaceProjectMenu.open(lngLat)
                        };
                    }
                    const place = placesTabModel.places[feature.properties._placeId];
                    return {
                        label: `${place.name} Details`,
                        icon: 'icon-place',
                        callback: () => PlaceFeaturesMenu.open([feature], lngLat)
                    };
                })}/>;
        }

        const feature = features[0];
        if (feature.id === placesTabModel.feature.id) {
            return <PlaceProjectMenu/>;
        }

        const place = placesTabModel.places[feature.properties._placeId];
        return <PointMenu
            headerEl={<div className="point-header">{place.name}</div>}
            options={[{
                label: 'Edit Place',
                icon: 'icon-edit',
                callback: () => placesTabModel.openPlaceForEditing(placesTabModel.projectId, place.placeId)
            },
            {
                label: <div className="remove-link"><i className="icon-trash"/>Delete Place</div>,
                callback: () => {
                    placesTabModel.deletePlace(place);
                    popup.remove();
                }
            }]}/>;
    }
}

export default PlaceFeaturesMenu;
