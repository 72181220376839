import Stakeable from '../stakeable/stakeable';
import surveyModel from 'models/survey-model';
import SurveyHeader from './survey-header';

/**
 * Survey staking view.
 */
class Survey {

    oninit() {
        surveyModel.init();
    }

    onremove() {
        surveyModel.onRemove();
    }

    view() {
        return  <div id='stakeable-view' class='stakeable survey'>
            <SurveyHeader />
            <Stakeable />
        </div>;
    }

}

export default Survey;
