import tableModel from 'models/table/table-model';
import filterModel from 'models/table/filter-model';
import store from 'util/data/store';
import constants from 'util/data/constants';

const NA_CELL = 'N/A';
const EMPTY_CELL = null;
const ASSET_LABEL_COLUMN = 0;

const CellInner = {
    view: ({attrs: {value, controlType, assetRow, sharedColumnName}}) => {
        if (value === NA_CELL || value === EMPTY_CELL) {
            return <Empty value={value}/>;
        }
        return <Value value={value} controlType={controlType} assetRow={assetRow} sharedColumnName={sharedColumnName} />;
    }
};

const Empty = {
    view: ({attrs: {value}}) => {
        if (value === NA_CELL) {
            return <span class="asset-type type-specific">N/A</span>;
        }
        return <span class="is-null"/>;
    }
};

const Value = {
    view: ({attrs: {value, controlType, assetRow, sharedColumnName}}) => {
        const assetType = store.assetTypes[assetRow.assetTypeId];
        const asset = store.assets[assetRow.assetId];
        const controlValue = constants.controlTypeIdToName[controlType.controlTypeId] === 'user' ? asset.properties[controlType.label] : value;
        if (!tableModel.inEditMode && filterModel.hasCellMenu(controlType.controlTypeId)) {
            return <span class="asset-type type-specific"><span class="list-label">{controlType.label}:</span>
                {Array.isArray(value) ?
                    value.map((listValue) => <span className="property-value shortcut-filter-link"
                        onclick={function (e) {
                            e.stopPropagation();
                            if (!e.clickData) {
                                return;
                            }

                            filterModel.open(this, {
                                controlType,
                                asset,
                                assetType,
                                sharedColumnName,
                                isCellMenu: true,
                                viewKey: controlType.controlTypeId,
                                clickData: e.clickData,
                                selectedControlName: controlValue
                            });
                        }}>{listValue || ''}
                    </span>) :
                    <span class="property-value shortcut-filter-link" onclick={function (e) {
                        e.stopPropagation();
                        filterModel.open(this, {
                            controlType,
                            asset,
                            assetType,
                            sharedColumnName,
                            isCellMenu: true,
                            viewKey: controlType.controlTypeId,
                            selectedControlName: controlValue
                        });
                    }}>{value || ''}
                    </span>
                }
            </span>;
        }
        return <span class="asset-type type-specific"><span class="list-label">{controlType.label}:</span> <span class="property-value">{value || ''}</span></span>;
    }
};


class Cell {

    oninit({attrs: {assetRow, cellKey, controlType}}) {
        assetRow.cells[cellKey] = this;
        this.controlType = controlType;
        this.cellKey = cellKey;
    }

    oncreate({state, dom, attrs: {assetRow, columnNA, columnIndex}}) {
        columnNA ? assetRow.addNaCell(this) : assetRow.addCell(this);
        state.element = columnIndex > ASSET_LABEL_COLUMN ? dom : dom.firstElementChild;
    }

    onremove({attrs: {assetRow, cellKey}}) {
        delete assetRow.cells[cellKey];
    }

    view({state, attrs: {assetRow, controlType, columnNA, columnIndex, sharedColumnName}}) {
        const wrapClass = state.wrapClass || (columnNA ? 'na-cell' : '');
        state.cssClasses = `asset-cell ${wrapClass} ${state.activeClass}`;
        const cellValue = columnNA ? NA_CELL : assetRow.getCellValue(controlType);
        return <li onclick={(e) => tableModel.handleCellClick(e, assetRow.rowIndex, columnIndex)} class={state.cssClasses}>
            <CellInner
                value={cellValue}
                controlType={controlType}
                assetRow={assetRow}
                sharedColumnName={sharedColumnName}
                viewKey='definedOptionsCellMenu'
            />
        </li>;
    }

}

export default Cell;
