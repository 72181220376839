import uav from 'uav';
import validatedField from 'legacy/components/validated-field';
import {wsUrl} from 'util/data/env';
import auth from 'legacy/components/auth';
import login from 'legacy/components/auth/login';
import router from 'uav-router';
import message from 'legacy/components/message';
import submitButton from 'legacy/components/submit-button';

function resetPassword() {

    function formIsValid() {

        return component.newPassword.isValid() && component.confirmPassword.isValid();

    }

    function submit(e) {

        if (e) {

            e.preventDefault();

        }

        if (!component.newPassword.validate() || !component.confirmPassword.validate()) {

            return;

        }

        component.submit.spinning = true;

        const socket = new WebSocket(wsUrl);

        socket.addEventListener('open', () => {

            if (socket.readyState === WebSocket.OPEN) {

                socket.send(JSON.stringify({
                    type: 'confirmresetpassword',
                    payload: {
                        code: router.params.code,
                        userId: router.params.userId,
                        newPassword: component.newPassword.value
                    }
                }));

            }

        });

        socket.addEventListener('message', msg => {

            msg = JSON.parse(msg.data);

            component.submit.spinning = false;

            component._el.scrollTop = 0;

            if (msg.success) {

                component.login();

            } else {

                message.show(msg.errorMsg || 'Invalid or expired reset code.', 'error');

            }

        });

        socket.addEventListener('error', () => {

            message.show('Something went wrong. Please try again or contact support.', 'error');

        });

    }

    function oninput() {

        component.submit.disabled = !formIsValid();

    }

    const component = uav.component(`
    <div class="login">
        <h1>Reset Password</h1>
        <form class="form new-form reset-password" u-onsubmit="{submit}">
            <p>You will be prompted to sign in after setting your new password.</p>
            {newPassword}
            {confirmPassword}
            <div class="form-bottom">
                {submit}
                <div class="login-footer">
                    <a class="cancel-btn" u-onclick="{login}">Cancel</a>
                </div>
            </div>
        </form>
    </div>`, {
        login: () => {

            router.url.removeReplace('code', 'userId');

            auth.component.view = login();

        },
        submit: submitButton({
            disabled: true,
            onclick: submit,
            text: 'Reset Password'
        }),
        newPassword: validatedField({
            type: 'password',
            label: 'New Password',
            validationType: 'password',
            prompt: 'Minimum of 8 characters, including 1 number and 1 uppercase letter.',
            autofocus: true,
            oninput
        }),
        confirmPassword: validatedField({
            type: 'password',
            label: 'Confirm New Password',
            validationType: 'match',
            match: () => component.newPassword.value,
            oninput
        }),
        classname: 'reset-password'
    });

    return component;

}

export default resetPassword;
