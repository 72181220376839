import constants from 'util/data/constants';
import MediaOptions from 'views/media-options';

const audioViewer = {
    view: ({attrs: {media}}) =>
        <div class="audio-viewer">
            <MediaOptions mediaId={media.mediaId} isFullScreen={true} key="opts" />
            <audio controls class="audio-viewer-stage" src={constants.mediaURL + media.mediaId} key={media.mediaId} />
        </div>
};

export default audioViewer;
