import store from 'util/data/store';
import tableModel from 'models/table/table-model';
import siteModel from 'models/site-model';
import controlToFeature from 'util/interfaces/control-to-feature';
import tablePopup from 'models/popover-model';
import formModel from 'models/form-model';
import {popups} from 'views/popover/popover';
import onBodyClick from 'legacy/util/dom/on-body-click';
import debounce from 'util/events/debounce';
import {getNormalizedKey} from 'util/events/get-normalized-key';
import {assetIdToProjectId} from 'util/data/asset-id-to-project-id';
import appModel from 'models/app-model';

class ActiveCellModel {

    constructor() {
        this.row = 0;
        this.column = 0;
        this.assetId = 0;
        this.debouncedOpeningCell = debounce(() => activeCell.openCellPopup(), 300);
    }

    resetState() {
        this.state = {
            isActive: false,
            isAwaitingKeyUp: false,
            isEditingForm: false
        };
        this.deactivateCell();
    }

    get isActive() {
        return activeCell.state && activeCell.state.isActive;
    }

    get assetRow() {
        return tableModel.assetRows[this.assetId];
    }

    get cell() {
        const row = this.assetRow;
        if (!row) {
            return undefined;
        }
        return row.getCell(this.column);
    }

    /* ------- Event Handling (Keyboard Controls) -------   */

    // Activate clicked cell, and if no cell is active yet, kick off the table cell navigating flow.
    onCellClick(row, column) {
        if (!activeCell.state.isActive) {
            return activeCell.enterTable(row, column);
        } else if (activeCell.row === row && activeCell.column === column) {
            return tablePopup.isOpen ? activeCell.closeCellPopup() : activeCell.openCellPopup();
        }
        activeCell.activate(row, column);
    }

    // Enters the table cell navigating state for keyboard control
    enterTable(row = activeCell.row, column = activeCell.column) {
        onBodyClick.once(activeCell.exitTable);
        activeCell.activate(row, column);
        window.addEventListener('keydown', activeCell.handleKeyInput);
        siteModel.map.keyboard.disable();
    }

    // Exits the table cell navigating state for keyboard control
    exitTable() {
        activeCell.resetState();
        window.removeEventListener('keydown', activeCell.handleKeyInput);
        window.removeEventListener('keyup', activeCell.keyJumpThroughCells);
        if (siteModel.map) {
            siteModel.map.keyboard.enable();
        }
        m.redraw();
    }

    // Responds based on input for navigating cells with keyboard.
    handleKeyInput(e) {
        // If the user has clicked into the form input, pause the navigating flow:
        if (activeCell.state.isEditingForm) {
            return activeCell.keyInputWhileEditing(e);
        }
        // If long pressing down on an arrow, just scroll until release.
        if (e.repeat) {
            return activeCell.awaitKeyUp(e);
        }
        // Normal key input navigation through cells
        return activeCell.keyInputWhileNavigating(e);
    }

    // If a user has clicked into the input form, let them exit via tab or escape
    keyInputWhileEditing(e) {
        const key = getNormalizedKey(e.key);
        if (key === 'Tab' || key === 'Escape') {
            const position = activeCell.getAdjacentCell(activeCell.row, activeCell.column, e.shiftKey);
            e.preventDefault();
            return activeCell.activate(...position);
        }
    }

    // Navigating between cells w/arrows and tab
    keyInputWhileNavigating(e) {
        let row = activeCell.row;
        let column = activeCell.column;
        const key = getNormalizedKey(e.key);
        switch (key) {
        case 'ArrowLeft':
            if (column <= 0) {
                return; // Reached first col
            }
            column--;
            break;
        case 'ArrowUp':
            if (row <= 0) {
                return; // Reached first row
            }
            row--;
            break;
        case 'ArrowRight':
            if (column >= tableModel.lastColumnIndex || column >= tableModel.loadRange.endCol) {
                return; // Reached last col
            }
            column++;
            break;
        case 'ArrowDown':
            if (row >= tableModel.lastRowIndex || row >= tableModel.loadRange.endRow) {
                return;
            }
            row++;
            break;
        case 'Enter': // Toggle popup on enter/return
            e.preventDefault();
            if (tablePopup.isOpen) {
                return activeCell.closeCellPopup();
            }
            return activeCell.openCellPopup();
        case 'Escape': // Close popup on escape
            e.preventDefault();
            return activeCell.closeCellPopup();
        case 'Tab': // Exit popup and tab to adjacent cell
            [row, column] = activeCell.getAdjacentCell(row, column, e.shiftKey);
            break;
        default: // Any other key, start editing the form.
            return;
        }
        e.preventDefault();
        activeCell.activate(row, column);
    }

    // Open the current cell or the first cell in view (after a jump in position)
    keyJumpThroughCells(e) {
        let row = activeCell.row;
        let column = activeCell.column;
        const key = getNormalizedKey(e.key);
        switch (key) {
        case 'ArrowLeft':
            column = column <= tableModel.visibleRange.endCol ? column : tableModel.visibleRange.endCol;
            break;
        case 'ArrowUp':
            row = row <= tableModel.visibleRange.endRow ? row : tableModel.visibleRange.endRow;
            break;
        case 'ArrowRight':
            column = column >= tableModel.visibleRange.startCol ? column : tableModel.visibleRange.startCol;
            break;
        case 'ArrowDown':
            row = row >= tableModel.visibleRange.startRow ? row : tableModel.visibleRange.startRow;
            break;
        }
        activeCell.activate(row, column);
        if (activeCell.state.isAwaitingKeyUp)  {
            window.removeEventListener('keyup', activeCell.keyJumpThroughCells);
            activeCell.state.isAwaitingKeyUp = false;
        }
    }

    awaitKeyUp(e) {
        const key = getNormalizedKey(e.key);
        if (key === 'ArrowRight') {
            tableModel.scrollRight();
        } else if (key === 'ArrowLeft') {
            tableModel.scrollLeft();
        }
        if (!activeCell.state.isAwaitingKeyUp) {
            window.addEventListener('keyup', activeCell.keyJumpThroughCells);
            activeCell.state.isAwaitingKeyUp = true;
        }
    }

    getAdjacentCell(row, column, goLeft = false) {
        if (goLeft) {
            if (column > 0) {
                return [row, column - 1];
            } else if (row > 0) {
                return [row - 1, tableModel.lastColumnIndex];
            }
            return [tableModel.lastRowIndex, column];
        }
        if (column < tableModel.lastColumnIndex) {
            return [row, column + 1];
        } else if (row < tableModel.lastRowIndex) {
            return [row + 1, 0];
        }
        return [0, column];
    }

    /* ------- Activating cell and opening its popup ------- */

    // Given a cell row and column position, activates (highlights) the cell and opens the popup.
    activate(row, column) {
        activeCell.deactivateCell(); // Deactivate current active cell if it exists
        Object.assign(activeCell, {
            state: {isActive: true},
            assetId: tableModel.assetIds[row],
            row,
            column
        });
        if (activeCell.cell) {
            activeCell.cell.activeClass = 'active-cell';
            m.redraw();
        }
        activeCell.openCellAfterScrolling(row, column);
    }

    // Removes the active cell class from a cell if it exists and closes the popup.
    deactivateCell() {
        if (activeCell.cell) {
            activeCell.cell.activeClass = '';
        }
        tablePopup.close();
    }

    // Scroll the cell into view and open its popup. (Debounce the popup opening in case a user is keying quickly through the cells.)
    openCellAfterScrolling(row, column) {
        if (tableModel.scrollIfOutOfView(row, column)) {
            clearTimeout(this.scrollTimeout);
            this.scrollTimeout = setTimeout(() => this.debouncedOpeningCell(), 500);
        } else {
            this.debouncedOpeningCell();
        }
    }

    openCellPopup() {
        const cell = this.cell;
        if (cell && cell.hasPopup) {
            this.saveCellValue();
            this.initAssetRow(this.assetId);
            const boundingBox = cell.element.getBoundingClientRect();
            tablePopup.open(cell.element, {
                view: popups[cell.permissionsErrorType || 'assetEditor'],
                wrapClass: cell.permissionsErrorType ? 'disabled-popup' : 'form-control-popup',
                alignRight: tableModel.visibleRange.middleX >= boundingBox.left,
                alignBottom: tableModel.visibleRange.middleY >= boundingBox.top,
                yPadding: 15,
                forceInit: tablePopup.isOpen,
                onBodyClickClose: activeCell.exitTable,
                onPopupOpen: activeCell.awaitCellFormInput
            });
            // In rare cases (big jumps in pagination) the cell won't have rendered in time to receive active class
            if (activeCell.cell && activeCell.cell.activeClass === 'inactive-cell') {
                activeCell.cell.activeClass = 'active-cell';
                m.redraw();
            }
        } else {
            tablePopup.close();
            onBodyClick.once(activeCell.exitTable);
        }
    }

    closeCellPopup() {
        tablePopup.close();
        activeCell.state.isEditingForm = false;
        onBodyClick.once(activeCell.exitTable);
        m.redraw();
    }

    initAssetRow(assetId) {
        const asset = store.assets[assetId];
        if (!asset || !asset.assetTypeId) {
            return;
        }
        const assetFormId = store.assetTypeToFormId[asset.assetTypeId];
        const tool = store.tools[asset.attributes.toolId];
        let childProjectId;
        if (siteModel.isMetaProject) {
            childProjectId = assetIdToProjectId(assetId);
            appModel.setState('editingProjectId', childProjectId);
        }
        Object.assign(formModel, {
            assetForm: store.assetForms[assetFormId],
            assetId: assetId,
            controls: tool.assetForm.controls || [],
            childProjectId
        });

    }

    // Auto focus the input if applicable and if popup is clicked, enter editing state.
    awaitCellFormInput() {
        const input = document.querySelector('[data-focus-id="1"]');
        if (input) {
            input.focus();
            input.addEventListener('click', (e) => {
                e.preventDefault();
                activeCell.state.isEditingForm = true;
            });
        }
    }

    /* ------- Storing/updating active cell value for undoing -------   */

    saveCellValue() {
        this.isUndoable = false;
        const cell = this.cell;
        if (!cell || cell.permissionsErrorType) {
            this.originalValue = '';
            return;
        }
        this.originalValue = this.getValueFor(cell.controlType);
    }

    getValueFor(controlType) {
        const asset = store.assets[this.assetId];
        if (controlType.controlTypeId) {
            const val = asset.properties[controlType.name];
            if (val && Array.isArray(val)) {
                return [...val];
            }
            return val;
        } else if (controlType === 'places') {
            return  {
                placeIds: [...asset.placeIds],
                levelIds: [...asset.levelIds]
            };
        }
    }

    setValueFor(controlType, value) {
        const asset = store.assets[this.assetId];
        if (controlType.controlTypeId) {
            if (value && Array.isArray(value)) {
                asset.properties[controlType.name] = [...value];
            } else {
                asset.properties[controlType.name] = value;
            }
        }
    }

    undoAssetChange() {
        this.setValueFor(this.cell.controlType, this.originalValue);
        if (this.cell.controlType !== 'places') {
            const {updateAssetFeatures} = controlToFeature;
            updateAssetFeatures(this.cell.controlType, this.assetId);
        }
        formModel.triggerEval();
        this.isUndoable = false;
        this.changeUndone = true;
        m.redraw();
    }

    cellValueIsUnchanged(controlTypeOrKey) {
        const currentValue = this.getValueFor(controlTypeOrKey);
        if (Array.isArray(this.originalValue)) {
            if (Array.isArray(currentValue)) {
                return currentValue.length === this.originalValue.length &&
                    currentValue.every((val, index) => val === this.originalValue[index]);
            }
            return false;
        }
        return this.originalValue === currentValue;
    }

    // Callback, called when an asset has finished autosaving
    onAssetSave(assetId) {
        if (!this.isActive || assetId !== this.assetId || this.cell.controlType === 'places' || !this.cell.controlType) {
            return;
        }
        const controlTypeOrKey = this.cell.controlType;
        if (this.cellValueIsUnchanged(controlTypeOrKey)) {
            this.isUndoable = false;
        } else {
            this.isUndoable = true;
            this.changeUndone = false;
        }
    }

}

const activeCell = new ActiveCellModel();

export default activeCell;
