import download from 'legacy/util/device/download';
import MediaOptions from 'views/media-options';

const FileViewer = {

    notSupported: media => <div class="not-supported">
        <MediaOptions mediaId={media.mediaId} isFullScreen={true}/>
        <div><strong>{media.label || 'This file'} cannot be previewed.</strong></div>
        <p class="btn btn-primary btn-pill" onclick={() => download.force(media)}>
            <i class="icon-download icon"/>
            Download
        </p>
    </div>,

    view: ({attrs: {media}}) =>
        <div class="file-viewer">
            <MediaOptions mediaId={media.mediaId} isFullScreen={true} key="opts"/>
            <div id="file-viewer-stage" key="file">
                <iframe src={`lib/viewer/#https://media.unearthlabs.com/${media.mediaId}`} />
            </div>
        </div>
};

export default FileViewer;
