import layerModel from 'models/layer-model';
import LayerPicker from 'views/layer-picker';
import stakeableModel from 'models/stakeable-model';
import planModel from 'models/plan-model';

// Navigate back to site bounds
const ReturnToSiteButton = {
    view: () =>  {
        return <div class="button-container-centered">
            <button class="btn btn-clear-all btn-pill" type="submit" onclick={() => stakeableModel.boundTargetByStakes()}>Return to Site</button>
        </div>;
    }
};

// Left mapbox container
const LeftMap = {
    view: () =>  <div class='splitview-left stake-view basemap'>
        {stakeableModel.state.isOutOfBounds ? <ReturnToSiteButton /> : null}
        {!planModel.isReviewing && layerModel.isOpen ? <LayerPicker hideLinks={true}/> : null}
        <div id="mapbox" class="mapbox">
        </div>
    </div>
};

// Right mapbox container
const RightMap = {
    view: () => <div class='splitview-right stake-view basemap'>
        {stakeableModel.stakesMenu.isOpen ? <StakesMenu /> : null}
        <div id="mapboxRight" class="mapbox">
        </div>
    </div>
};

// Control menu appearing on right mapbox object with list of previously pinned surveys/plans
const StakesMenu = {
    view: () =>
        <div class="pins-picker">
            <div class="pins-picker-head">
                <i class={'icon-' + stakeableModel.recordType}></i>
                <span class="head-text-inner-wrap">Previous {stakeableModel.recordType}s
                    <span class="head-subtitle">Use pins from a previously pinned {stakeableModel.recordType} to align with this {stakeableModel.recordType}.</span>
                </span>
            </div>
            <div class="pins-picker-layers">
                <ul class="pins-picker-select">
                    { stakeableModel.stakesMenuList.map((menuOption, index) =>
                        <li class="option" key={index}><button type="submit" onclick={() => stakeableModel.handleAddingPreviousPins(index)} value={index}>{menuOption.title}</button></li>
                    )}
                </ul>
            </div>
        </div>

};


class Stakeable {

    onremove() {
        // Clear data from model on remove.
        stakeableModel.stakes = null;
        stakeableModel.stakesMenuList = [];
    }

    view() {
        return <div className="splitview split">
            <LeftMap/>
            <RightMap/>
        </div>;
    }

}

export default Stakeable;
