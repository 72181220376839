import colorPickerModel from 'models/color-picker-model';

class ColorPicker {

    oncreate({attrs: {containerId, starterColor, setColorFn, hideInputs}}) {
        colorPickerModel.init({containerId, starterColor, setColorFn, hideInputs});
    }

    onremove() {
        colorPickerModel.onRemove();
    }

    view({attrs: {containerId}}) {
        return <div class="color-picker-wrap unearth-color-picker-outer-wrap fade-in">
            <div id={containerId} class="color-picker unearth-color-picker"></div>
        </div>;
    }
}

export default ColorPicker;
