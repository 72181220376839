import router from 'uav-router';
import store from 'util/data/store';
import peopleModel from 'models/people/people-model';
import formModel from 'models/form-model';
import constants from 'util/data/constants';
import controlToFeature from 'util/interfaces/control-to-feature';
import formatDate from 'legacy/util/date/format-date';
import measure from 'legacy/util/numbers/measure';
import isMetric from 'util/numbers/is-metric';
import round from 'util/numbers/round';
import siteModel from 'models/site-model';
import Toggle from 'views/toggle';
import Radio from 'views/radio';
import mediaModel from 'models/media-model';
import DatePicker from 'views/date-picker';
import Multiselect from 'views/multiselect';
import ThumbnailGallery from '../media-viewer/thumbnails/thumbnail-gallery';
import FilterSelect from 'views/filter-select/filter-select';
import userModel from 'models/user-model';
import PlaceSearch from 'views/place-search/place-search';
import tableModel from 'models/table/table-model';
import placeModel from 'models/place-model';

const LNG_RANGE = 180;
const LAT_RANGE = 90;

const surveyStatuses = {
    created: 'Processing',
    loaded: 'Processing',
    processing: 'Processing',
    ready: 'Complete'
};

const updateAssetFeatures = (control, assetId) => controlToFeature.updateAssetFeatures(control, assetId);

const formControls = {

    file(control, assetId = formModel.assetId) {
        const asset = store.assets[assetId];
        const mediaIds = asset.properties[control.label] || [];
        return <div class="file-control">
            {mediaIds.length
                ? <ThumbnailGallery mediaIds={mediaIds} showOptions={true} previewMode={true} />
                : formModel.isReadOnlyControl(asset.assetTypeId, control)
                    ? null
                    : <div class="btn btn-secondary btn-pill btn-smallest" onclick={() => {
                        formModel.uploadFiles(control);
                    }}>Upload {control.label}</div>}
        </div>;
    },

    name(control, assetId = formModel.assetId) {
        const asset = store.assets[assetId],
            properties = asset.properties,
            text = properties[control.label] || '';
        return <div class="name-control">
            <input data-focus-id="1" type="text" value={text} oninput={e => {
                properties[control.label] = e.target.value;
                updateAssetFeatures(control, assetId);
            }}/>
        </div>;
    },

    text(control, assetId = formModel.assetId) {
        const text = store.assets[assetId].properties[control.label] || '';
        return <div class="text-control">
            <input data-focus-id="1" type="text" value={text} oninput={e => {
                store.assets[assetId].properties[control.label] = e.target.value;
                updateAssetFeatures(control, assetId);
            }}/>
        </div>;
    },

    paragraph(control, assetId = formModel.assetId) {
        const text = store.assets[assetId].properties[control.label] || '';
        return <div class="paragraph-control">
            <textarea data-focus-id="1" oninput={e => {
                store.assets[assetId].properties[control.label] = e.target.value;
                updateAssetFeatures(control, assetId);
            }}>{text}</textarea>
        </div>;
    },

    coordinates(control, assetId = formModel.assetId) {
        const asset = store.assets[assetId];
        const properties = asset.properties;
        const point = properties[control.label] = properties[control.label] || {
            type: 'Point',
            coordinates: []
        };
        const hasTrimbleFeatures = asset.featureIds && asset.featureIds
            .find(featureId => {
                const feature = store.features[featureId];
                return feature && feature.properties && feature.properties[constants.trimbleDataKey];
            });
        return <div class={`coordinates-control${hasTrimbleFeatures ? ' disabled' : ''}`}>
            <div class="latitude-row">
                <label class="sublabel">Latitude</label>
                <input type="text" value={point.coordinates[1]} oninput={e => {
                    formModel.handleCoordinate(e, 1, point, control);
                }}/>
                <div class="error-msg relative error-coord">Latitude must be between -{LAT_RANGE} and +{LAT_RANGE}.</div>
            </div>
            <div class="longitude-row">
                <label class="sublabel">Longitude</label>
                <input type="text" value={point.coordinates[0]} oninput={e => {
                    formModel.handleCoordinate(e, 0, point, control);
                }}/>
                <div class="error-msg relative error-coord">Longitude must be between -{LNG_RANGE} and +{LNG_RANGE}.</div>
            </div>
            {!!hasTrimbleFeatures && <div class="gps-tip"><img class="trimble" src="/images/trimble.png" /> Positioned with Trimble</div>}
        </div>;
    },

    links(control, assetId = formModel.assetId) {
        const asset = store.assets[assetId];
        return <div class="links-control">
            <div class="links-control-input" onclick={() => {
                formModel.uploadFiles(control);
            }}><span class="link-control-tip">Link other assets or media to this {asset.contentType}</span> <span class="btn btn-pill btn-secondary btn-smaller"><span class="btn-label"><i class="icon-plus"/><i class="icon-link icon"/></span></span></div>
        </div>;
    },

    dropdown(control, assetId = formModel.assetId) {
        const asset = store.assets[assetId],
            selectedValue = asset.properties[control.label] || '',
            field = store.assetTypeFields[asset.assetTypeId][control.label];
        return <div class="dropdown-control select-wrapper">
            <select value={selectedValue} onkeydown={(e) => {
                return e.stopPropagation();
            }} onchange={e => {
                store.assets[assetId].properties[control.label] = e.target.value || undefined;
                updateAssetFeatures(control, assetId);
            }}>
                <option value="">Select an option</option>}
                {field && field.type.values.map(value =>
                    <option key={value} value={value}>{value}</option>
                )}
            </select>
        </div>;
    },

    radio(control, assetId = formModel.assetId, reset = false) {
        const asset = store.assets[assetId],
            properties = asset.properties,
            defaultOption = properties[control.label],
            field = store.assetTypeFields[asset.assetTypeId][control.label];
        return <Radio reset={reset} defaultOption={defaultOption} options={field.type.values} onchange={value => {
            properties[control.label] = value;
            updateAssetFeatures(control, assetId);
        }}/>;
    },

    // This should never be hit because readOnly will always
    // be true on request controls; but just in case:
    request(control, assetId = formModel.assetId) {
        const properties = store.assets[assetId].properties;
        return <div class="text-control">{properties[control.label] || ''}</div>;
    },

    toggle(control, assetId = formModel.assetId, reset = false) {
        const properties = store.assets[assetId].properties;
        return <Toggle reset={reset} active={properties[control.label]} onchange={trueOrFalse => {
            store.assets[assetId].properties[control.label] = trueOrFalse;
            updateAssetFeatures(control, assetId);
        }}/>;
    },

    plan(control, assetId = formModel.assetId) {
        const asset = store.assets[assetId],
            planId = asset.properties[control.label],
            plan = store.plans[planId],
            mediaId = plan && plan.document ? plan.document.mediaId : asset.mediaId;
        return <div class="plan-control">
            {plan
                ? <div>
                    <ThumbnailGallery mediaIds={[mediaId]} showOptions={true} previewMode={true} />
                    <div class="control-data-row"><span class="row-label">Title</span><span class="row-value">{plan.title}</span></div>
                    <div class="control-data-row"><span class="row-label">Status</span><span class="row-value">{plan.status}</span></div>
                    {plan.status === 'complete'
                        && !formModel.isReadOnlyControl(asset.assetTypeId, control)
                        && <div class="layer-actions clear">
                            <div class="btn btn-secondary btn-pill btn-smallest show-on-large" onclick={() =>
                                router.merge({view: 'layer', planId: plan.planId})
                            }><span class="btn-label">Edit Layer</span></div>
                        </div>
                    }
                </div>
                : <div>
                    <ThumbnailGallery mediaIds={[mediaId]} showOptions={true} previewMode={true} />
                    {asset.mediaId && !formModel.isReadOnlyControl(asset.assetTypeId, control)
                        ? <div class="layer-actions clear">
                            <div class="btn btn-secondary btn-pill btn-smallest show-on-large" onclick={() =>
                                mediaModel.createLayer(asset.mediaId, assetId)
                            }><span class="btn-label">Create New Layer</span></div>
                        </div>
                        : null}
                </div>
            }
        </div>;
    },

    survey(control, assetId = formModel.assetId) {
        const asset = store.assets[assetId],
            surveyId = asset.properties[control.label],
            survey = store.surveys[surveyId];
        return <div class="survey-control">
            {survey && survey.status === 'ready'
                ? <div>
                    <div class="control-data-row"><span class="row-label">Title</span><span class="row-value">{survey.title}</span></div>
                    <div class="control-data-row"><span class="row-label">Date</span><span class="row-value">{formatDate.formatDay(survey.surveyDateTime, true)}</span></div>
                    <div class="control-data-row"><span class="row-label">Status</span><span class="row-value">{surveyStatuses[survey.status] || survey.status}</span></div>
                    <div class="layer-actions clear">
                        {!formModel.isReadOnlyControl(asset.assetTypeId, control) &&
                            <div class="btn btn-secondary btn-pill btn-smallest left show-on-large" onclick={() =>
                                router.merge({view: 'survey', survey: surveyId, skipInitializer: true})
                            }><span class="btn-label">GeoCorrect Survey</span></div>}
                        <div class="btn btn-secondary btn-pill btn-smallest right" onclick={() => formModel.viewSurveyFiles(surveyId)}>
                            <span class="btn-label">View Source Files</span>
                        </div>
                    </div>
                </div>
                : <div>
                    <p><i class="spinner spinning"/> Creating survey...</p>
                    <p>This can take minutes or hours depending on the type and size of the imagery.</p>
                    <div class="layer-actions">
                        <div class="btn btn-secondary btn-pill btn-smallest" onclick={() => formModel.viewSurveyFiles(surveyId)}>
                            <span class="btn-label">View Source Files</span>
                        </div>
                    </div>
                </div>
            }
        </div>;
    },


    length(control, assetId = formModel.assetId) {
        const asset = store.assets[assetId],
            properties = asset.properties,
            metric = isMetric();
        let value = properties[control.label];
        value = !!value || control.attributes.required
            ? round(metric ? value : measure.metersToFeet(value))
            : value;
        if (formModel.isReadOnlyControl(asset.assetTypeId, control)) {
            return <div class="eval-value">{value} {metric ? 'meters' : 'feet'}</div>;
        }
        return <div class="length-control">
            <input data-focus-id="1" type="number" class="number-input" value={value} step="any" oninput={e => {
                formModel.handleLength(e, control, assetId);
            }}/>
            <span class="number-metric">{metric ? 'meters' : 'feet'}</span>
        </div>;
    },

    area(control, assetId = formModel.assetId) {
        const properties = store.assets[assetId].properties,
            metric = isMetric(),
            asset = store.assets[assetId];
        let value = properties[control.label];
        value = !!value || control.attributes.required
            ? round(metric ? value : measure.squareMetersToSquareFeet(value))
            : value;
        if (formModel.isReadOnlyControl(asset.assetTypeId, control)) {
            return <div class="eval-value">{value} {metric ? 'meters²' : 'feet²'}</div>;
        }
        return <div class="area-control">
            <input data-focus-id="1"  type="number" class="number-input" value={value} step="any" oninput={e => {
                formModel.handleArea(e, control, assetId);
            }}/>
            <span class="number-metric">{metric ? 'meters²' : 'feet²'}</span>
        </div>;
    },

    volume(control, assetId = formModel.assetId) {
        const properties = store.assets[assetId].properties,
            metric = isMetric(),
            asset = store.assets[assetId];
        let value = properties[control.label];
        value = round(metric ? value : measure.cubicMetersToCubicYards(value));
        if (formModel.isReadOnlyControl(asset.assetTypeId, control)) {
            return <div class="eval-value">{value} {metric ? 'meters³' : 'feet³'}</div>;
        }
        return <div class="area-control">
            <input data-focus-id="1"  type="number" class="number-input" value={value} step="any" oninput={e => {
                formModel.handleVolume(e, control, assetId);
            }}/>
            <span class="number-metric">{metric ? 'meters³' : 'yards³'}</span>
        </div>;
    },

    number(control, assetId = formModel.assetId) {
        const value = store.assets[assetId].properties[control.label];
        return <div class="length-control">
            <input data-focus-id="1"  type="number" class="number-input" value={value} step="any" oninput={e => {
                formModel.handleNumber(e, control, assetId);
            }}/>
        </div>;
    },

    date(control, assetId = formModel.assetId) {
        const date = store.assets[assetId].properties[control.label];
        return <DatePicker
            date={date && new Date(date)}
            required={control.attributes.required}
            min={control.attributes.min && new Date(control.attributes.min)}
            max={control.attributes.max && new Date(control.attributes.max)}
            onchange={newDate => {
                const properties = store.assets[assetId].properties;
                if (newDate) {
                    properties[control.label] = newDate.getTime();
                } else {
                    delete properties[control.label];
                }
                updateAssetFeatures(control, assetId);
            }}/>;
    },

    multiselect(control, assetId = formModel.assetId) {
        const asset = store.assets[assetId],
            field = store.assetTypeFields[asset.assetTypeId][control.label];
        return <Multiselect defaultOptions={asset.properties[control.label]} onchange={options => {
            store.assets[assetId].properties[control.label] = options;
            updateAssetFeatures(control, assetId);
        }} options={field.type.items.values}/>;
    },

    multitext(control, assetId = formModel.assetId) {
        const getValues = () => store.assets[assetId].properties[control.label];
        let properties = store.assets[assetId].properties,
            values = getValues() || [''];
        properties[control.label] = values;
        const canDelete = values.length > 1 || values[0];
        return <div class="multitext">
            {values.map((value, i) => <div class="text-control">
                <input data-focus-id="1" type="text" value={value} oninput={e => {
                    getValues()[i] = e.target.value;
                    updateAssetFeatures(control, assetId);
                }}/>
                {canDelete && <i class="icon-close" onclick={() => {
                    properties = store.assets[assetId].properties;
                    values = properties[control.label];
                    values.splice(i, 1);
                    if (!values.length) {
                        delete properties[control.label];
                    }
                    updateAssetFeatures(control, assetId);
                }} />}
            </div>)}
            <div class="btn btn-secondary btn-pill btn-smallest" onclick={() => getValues().push('')}><i class="icon-plus" /></div>
        </div>;
    },

    user(control, assetId = formModel.assetId) {
        const options = peopleModel.getControlOptions();
        const selected = store.assets[assetId].properties[control.label] || undefined;
        return <div class="user-control">
            {options ? <div class="select-wrapper searchable-select">
                <FilterSelect
                    types={['users']}
                    selected={selected}
                    displayOptionAs={() => peopleModel.displayUserControlOption(selected)}
                    onselect={selection => {
                        store.assets[assetId].properties[control.label] = selection || undefined;
                        updateAssetFeatures(control, assetId);
                    }} /></div> : options === null ?
                <div class="no-options-exist">No people have been added to this project yet.</div> :
                <div class="loading-options"><i class="spinner always-show spinning"/><input disabled type="text" class="loading-text" placeholder={'Loading...'} />
                </div>}
            {userModel.isAccountAdmin && <div class="linked-text" onclick={() => peopleModel.routeToPeopleTab(assetId)}><i class="icon-plus"/> Add a person</div> }
        </div>;
    },

    place(control, assetId = formModel.assetId, undo, isPopupMenu) {
        return <div class="place-control">
            <PlaceSearch
                openUp={isPopupMenu && tableModel.tableMode === 'table-bottom'}
                selectedItems={store.assets[assetId].properties[control.label]
                && store.assets[assetId].properties[control.label].placeIds || []}
                control={control}
                onSelect={(place, opts) => {
                    placeModel.addPlaceToFormControl(place, assetId, opts.control.label);
                    updateAssetFeatures(control, assetId).then(() => {
                        placeModel.showPlacePopups([place.placeId]);
                    });
                    placeModel.panToPlace(place, false);
                }}
                onDelete={(place, opts) => {
                    placeModel.removePlaceToFormControl(place, assetId, opts.control.label);
                    updateAssetFeatures(control, assetId);
                    m.redraw();
                }}
            />
        </div>;
    }
};

const controlTypeNameToId = constants.controlTypeNameToId;

formControls.volume.suffix = () => siteModel.hasElevationData()
    ? <span>(<a onclick={() => formModel.recalculateVolume()}>Recalculate</a>)</span>
    : null;

export default {
    [controlTypeNameToId.name]: formControls.name,
    [controlTypeNameToId.file]: formControls.file,
    [controlTypeNameToId.text]: formControls.text,
    [controlTypeNameToId.paragraph]: formControls.paragraph,
    [controlTypeNameToId.coordinates]: formControls.coordinates,
    [controlTypeNameToId.links]: formControls.links,
    [controlTypeNameToId.dropdown]: formControls.dropdown,
    [controlTypeNameToId.radio]: formControls.radio,
    [controlTypeNameToId.toggle]: formControls.toggle,
    [controlTypeNameToId.plan]: formControls.plan,
    [controlTypeNameToId.survey]: formControls.survey,
    [controlTypeNameToId.length]: formControls.length,
    [controlTypeNameToId.area]: formControls.area,
    [controlTypeNameToId.volume]: formControls.volume,
    [controlTypeNameToId.number]: formControls.number,
    [controlTypeNameToId.date]: formControls.date,
    [controlTypeNameToId.multiselect]: formControls.multiselect,
    [controlTypeNameToId.multitext]: formControls.multitext,
    [controlTypeNameToId.user]: formControls.user,
    [controlTypeNameToId.request]: formControls.request,
    [controlTypeNameToId.place]: formControls.place
};
