// Simple model for logic related to clickable menus
class MenuModel {
    constructor(opts) {
        this.items = opts.items;
        this.onAllMenuClicks = opts.onAllMenuClicks;
        this.activeItemId = opts.activeItemId || undefined;
    }

    selectItem(e, index) {
        const itemClicked = this.items[index];
        this.activeItemId = itemClicked.itemId;
        itemClicked.onClick(e);
        this.onAllMenuClicks(e);
    }

    resetActive() {
        this.activeItemId = undefined;
        m.redraw();
    }
}

export default MenuModel;
