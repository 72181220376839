import MediaViewer from 'views/media-viewer/media-viewer';
import siteModel from 'models/site-model';

class MediaViewerModel {

    constructor() {
        this.cleanup();
    }

    cleanup() {
        this.mediaIds = [];
        this.state = {
            index: 0,
            isWaiting: false,
            isOpen: false
        };
    }

    wait() {
        this.state.isWaiting = true;
        siteModel.view = MediaViewer;
        m.redraw();
    }

    open(mediaIds, index) {
        this.state.isWaiting = false;
        this.state.isOpen = true;
        this.mediaIds = mediaIds;
        if (index) {
            this.state.index = index;
        }
        siteModel.view = MediaViewer;
        m.redraw();
    }

    close() {
        siteModel.view = null;
        m.redraw();
        this.cleanup();
    }

    hasPrev() {
        return this.state.index > 0;
    }

    hasNext() {
        return this.state.index < this.mediaIds.length - 1;
    }

    selectIndex(index) {
        this.state.index = index;
    }


}

export default new MediaViewerModel();
