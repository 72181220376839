import loaderModel from 'models/loader-model';
import siteModel from 'models/site-model';
import router from 'uav-router';

const SPACE = '\u00A0';

const Loader = {
    view: () => {
        const noAcctYet = !router.params || !router.params.projectId;
        return <div
            class={`loader ${loaderModel.loading ? 'loading' : 'hidden'} ${loaderModel.fade ? 'fade' : ''} ${noAcctYet || siteModel.isMetaProject ? 'dark-theme' : 'light-theme'}`}>
            <div class="loader-inner">
                <i class="icon-unearth"></i>
                <span class={`loading-message${loaderModel.message ? ' visible' : ''}`}>{loaderModel.message || SPACE}</span>
            </div>
        </div>;
    }
};

export default Loader;
