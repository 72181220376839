import toolboxModel from 'models/toolbox-model';
import formModel from 'models/form-model';
import store from 'util/data/store';
import stopPropagation from 'util/events/stop-propagation';
import siteModel from 'models/site-model';
import userModel from 'models/user-model';
import peopleModel from 'models/people/people-model';
import sideNavModel from 'models/side-nav-model';
import capitalize from 'util/data/capitalize';

const Toolbox = {

    oninit: ({state}) => {
        state.headerLabel = `Add to Your ${siteModel.isMetaProject ? 'Account' : capitalize(toolboxModel.projectSingular)}`;
    },

    view: ({state}) => {
        return <div class="toolbox side-nav-popup" onclick={stopPropagation()}>
            <div class="side-nav-popup-title">
                {state.headerLabel}
                {!siteModel.isMetaProject && <div class="toolbox-mode" onclick={toolboxModel.toggleMode}>
                    <div class={`toolbox-mode-opt ${toolboxModel.importMode ? '' : 'active'}`}>Create</div>
                    <div class={`toolbox-mode-opt ${toolboxModel.importMode ? 'active' : ''}`}>Import</div>
                </div>}
            </div>
            <div class="padding-wrap">
                {Object.values(store.toolGroups).map(toolGroup => {
                    return !!toolGroup.tools.length && <div class={'toolbox-group' + (toolGroup.collapsed ? ' collapsed' : '')}>
                        <div class="toolbox-group-name" onclick={e => toolboxModel.toggleGroup(toolGroup, e)}>
                            {toolGroup.name}
                            <i class={`icon-${toolGroup.collapsed ? 'down' : 'up'} right`} />
                        </div>
                        <div class="toolbox-tools" style={`max-height: ${Math.ceil(toolGroup.tools.length / 3) * 72}px`}>
                            {toolGroup.tools.map(tool =>
                                <div class="toolbox-tool" onclick={(e) => formModel.validateThenRun(() => toolboxModel.selectTool(toolGroup, tool, e))} key={tool.toolId}>
                                    <img class="toolbox-icon" src={`https://media.unearthlabs.com/${tool.attributes.icon
                                        ? tool.attributes.icon.mediaId
                                        : tool.assetForm.assetType.attributes.icon.mediaId}`} />
                                    <div class="toolbox-tool-name-wrap"><span class="toolbox-tool-name">{tool.name}</span></div>
                                </div>
                            )}
                        </div>
                    </div>;
                })}
                {userModel.isAccountAdmin && siteModel.isMetaProject && <><div class="toolbox-group-name no-click">People</div>
                    <div class="toolbox-tool" onclick={() => {
                        sideNavModel.close();
                        peopleModel.openNewPerson();
                    }}>
                        <i class="icon-user toolbox-icon" />
                        <div class="toolbox-tool-name-wrap">
                            <span class="toolbox-tool-name">Person</span>
                        </div>
                    </div> 
                </>}
            </div>
        </div>;

    }

};

export default Toolbox;
