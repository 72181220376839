import initializer from 'util/initializer';
import deep from 'util/data/deep-freeze';
import deepCloneObject from 'util/data/deep-clone-object';

initializer.add(() => store.cleanup());

const store = window.store = {
    cleanup() {
        Object.assign(store, {
            featureTypes: {},
            toolGroups: {},
            tools: {},
            assetForms: {},
            assetTypes: {},
            assetTypeToFormId: {},
            assetTypeFields: {},
            media: {},
            assets: {}, /** Fully mutable **/
            features: {}, /** Fully mutable **/
            plans: {},
            places: {},
            surveys: {},
            tilesets: {},
            toolboxId: null,
            featureStyles: {},
            featureTypeToStyles: {},
            project: store.project,
            accounts: store.accounts,
            account: store.account,
            users: {},
            assetTypeIdToToolGroupId: {},
            assetTypeIdToNameControl: {}
        });
        Object.preventExtensions(store);
    }
};

/**
 *  setObject: Creates an immutable object and saves it to the store. Key value pair.
 */

store.setObject = (key, value) => {
    deep.freeze(value);
    store[key] = value;
};

/**
 * setContainerValue: Used for swapping container values.
 *                      This creates an immutable version on tileset that can be safely stored in our storage.
 *
 *      example: store.tilesets[tileset.tilesetId] = tileset could be written as
 *                  store.setContainerValue(store.tilesets, tilesetId, tileset);
 *
 */
store.setContainerValue = (container, id, value) => {
    deep.freeze(value);
    container[id] = value;
};


/**
 * setMutableContainer: Used for swapping container objects from the store.
 *                      This creates a container object with all of its child objects immutable.
 *
 *      examples:    store.tilesets = {tilesets...}
 *
 *                  //should be written as
 *                  store.setMutableContainer('tilesets', {tilesets});
 *
 *                  // Old way
 *                  store.tilesets[tilesetId].color = '#936829';
 *
 *                  // New way
 *                  const newTileSet = deepCloneObject(store.tilesets[tilesetId]);
 *                  const newTileSet.color = '#936829';
 *                  store.setContainerValue(store.tilesets, tilesetId, newTileSet);
 *
 *                  // Even newer way
 *                  store.updateImmutableProp('tilesets', tilesetId, 'color', '#936829');
 *
 */

store.setMutableContainer = (key, value) => {
    store[key] = value;
    Object.keys(value).forEach(id => deep.freeze(value[id]));
};

/**
 * updateImmutableProp: Provides shorthand for mutating immutable objects in the store.
 *                      We do need to update immutable records when users change their data.
 *
 *      examples:   // Old way
 *                  store.tilesets[tilesetId].color = '#936829';
 *
 *                  // New way
 *                  store.updateImmutableProp('tilesets', tilesetId, 'color', '#936829');
 *
 */
store.updateImmutableProp = (containerType, recordId, key, value) => {
    const container = store[containerType];
    const record = deepCloneObject(container[recordId]);
    record[key] = value;
    store.setContainerValue(container, recordId, record);
    return record;
};

export default store;
