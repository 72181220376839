import Point from 'util/draw/point';
import siteModel from 'models/site-model';
import featureToControl from 'util/interfaces/feature-to-control';
import NextButton from 'legacy/components/next-button';
import featureModel from 'models/feature-model';
import isMetric from 'util/numbers/is-metric';
import store from 'util/data/store';
import formModel from 'models/form-model';
import ToolInterface from 'util/interfaces/tool-interface';
import controlToFeature from 'util/interfaces/control-to-feature';
import helpers from 'legacy/util/api/helpers';

class SymbolInterface extends ToolInterface {

    constructor(...args) {

        super(...args);

        this.type = 'symbol';

    }

    launch() {

        const map = siteModel.map;

        this.prompt = {view: () => `Tap on the map to place the ${this.featureType.name}.`};

        this.showToolLauncher();

        let nextButton;

        if (this.featureType.attributes.repeat) {

            nextButton = new NextButton();

        }

        return new Promise(resolve => {

            const asset = store.assets[this.assetId];

            this.done = () => {

                if (this.draw) {

                    this.draw.stop();

                }

                resolve([asset]);

            };

            featureModel.setupFeatureProperties(this.feature).then(() => {

                this.draw = new Point({
                    map,
                    source: map.getSource(this.featureType.featureTypeId),
                    metric: isMetric()
                }).create(this.feature.properties);

                this.draw.onVertexAdded = _feature => featureModel.addLatitudeToFeature(_feature);

                this.draw.onComplete = _feature => {

                    this.feature = _feature;

                    store.features[_feature.id] = _feature;

                    asset.featureIds = helpers.list(asset.featureIds);
                    asset.featureIds.push(_feature.id);

                    featureToControl.sync('symbol', _feature, this.assetId);

                    controlToFeature.syncAllFeatureProperties(this.assetId, _feature);

                    if (this.featureType.attributes.repeat) {

                        this.draw.create(_feature.properties);

                        nextButton.show({
                            onClick: this.done
                        });

                    } else {

                        this.done();

                    }

                };

            });

        });

    }

    edit(_feature) {

        const map = siteModel.map,
            source = map.getSource(this.featureType.featureTypeId);

        this.feature = source._data.features.find(f => f.id === _feature.id);

        if (!this.feature) {

            return;

        }

        this.draw = new Point({
            map,
            source,
            metric: isMetric()
        }).edit(this.feature);

        this.draw.onVertexChanged = () => {

            featureModel.addLatitudeToFeature(this.feature);

            featureToControl.sync('symbol', this.feature, this.assetId);

            controlToFeature.syncAllFeatureProperties(this.assetId, this.feature);

            source.setData(source._data);

            m.redraw();

            this.autosave(this.feature);

        };

        this.draw.onStop = formModel.onEditStop;

    }

}

export default SymbolInterface;
