import router from 'uav-router';
import oneUpModel from 'models/one-up-model';
import constants from 'util/data/constants';
import cache from 'legacy/util/data/cache';

class OneUp {
    static init() {
        const isLoggedIn = cache.get('clientId');
        if (oneUpModel.mounted || !isLoggedIn) {
            // Must be logged in to init one-up.
            return;
        }
        oneUpModel.mounted = true;
        const el = document.getElementById('one-up');
        m.mount(el, OneUp);
        oneUpModel.awaitInit();
    }

    oncreate(vnode) {
        oneUpModel.init(vnode);
    }

    view() {
        const {projectId} = router.params;
        const cssClass = oneUpModel.window ? oneUpModel.cssClass : undefined;
        const clientIdString = constants.oneUpOrigin === window.location.origin ? '' : `clientId=${localStorage.getItem('clientId')}`;
        return <div class={`one-up ${cssClass || ''} ${projectId === undefined ? 'hidden' : ''}`}>
            <iframe
                src={`${constants.oneUpOrigin}/one-up/#/?${clientIdString}&parentOrigin=${window.location.origin}`}
                allow="camera;microphone"
            />
        </div>;
    }
}

export default OneUp;
