import CropModel from 'models/crop-model';
import CropHeader from './crop-header';

/**
 * Plan cropping view.
 */
class Crop {

    oncreate() {
        CropModel.init();
    }

    onremove() {
        CropModel.onRemove();
    }

    view() {
        return <>
            <CropHeader />
            <div id="crop-view" class={'crop ' + CropModel.animateClass}>
                <div id="mapbox-crop" class="mapbox"></div>
            </div>
        </>;
    }

}

export default Crop;
