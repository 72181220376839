import convertMithril from 'util/dom/convert-mithril';
import pointMenu from 'legacy/components/point-menu/point-menu';
import popupModel from 'models/popup-model';

/**
 *  options: [{
 *      label: The display name.
 *      icon: An optional icon.
 *      callback: callback for option.
 *      isDelete: defaults to false
 *  }]
 *
 */

const OptionsPopup = {
    view: ({attrs: {options, pointMenuOpts}}) => {
        return <i className="icon-ellipsis asset-options" onclick={e => {
            popupModel.close();
            return pointMenu({
                e,
                content: () => convertMithril.toDom(<div>
                    {
                        options.map(({
                            label,
                            icon,
                            callback,
                            isDelete
                        }) => <div
                            onclick={(...args)=>{
                                callback(...args);
                                pointMenu.close();
                            }}
                            className={`menu-option${isDelete ? ' remove-link' : ''}`}>
                            {icon && <i className={icon}/>}{label}
                        </div>)
                    }
                </div>),
                cssClass: 'link-list asset-options',
                forceDesktop: true,
                noLine: true,
                ...pointMenuOpts
            });
        }}/>;
    }
};

export default OptionsPopup;
