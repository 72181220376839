import xyzToQuadkey from 'legacy/util/numbers/xyz-to-quadkey';

const constants = JSON.parse(document.getElementById('constants').innerHTML);

constants.isDeployed = location.hostname.match(/unearthlabs/);

constants.mapboxAccessToken = 'pk.eyJ1IjoibjhtbHIiLCJhIjoiY2pxdGxqeTBmMGF5bjQycmM4bm1md2YyZyJ9.UF03MZ6WbNQFAcHN_ohgew';

constants.baseTileURL = 'https://api.mapbox.com/styles/v1/n8mlr/ck3rhmolg2hsi1cow67u19pwi/tiles/256/{z}/{x}/{y}@2x?access_token=' + constants.mapboxAccessToken;

constants.basemaps = {
    satellite: {
        type: 'raster',
        tiles: ['https://api.mapbox.com/styles/v1/n8mlr/ck3rhmolg2hsi1cow67u19pwi/tiles/256/{z}/{x}/{y}@2x?access_token=' + constants.mapboxAccessToken],
        maxzoom: 20,
        tileSize: 256
    },
    light: {
        type: 'raster',
        tiles: ['https://api.mapbox.com/styles/v1/n8mlr/cjsmfs9bz17lg1fsea9kniryl/tiles/256/{z}/{x}/{y}@2x?access_token=' + constants.mapboxAccessToken],
        maxzoom: 20,
        tileSize: 256
    },
    dark: {
        type: 'raster',
        tiles: ['https://api.mapbox.com/styles/v1/n8mlr/cjuk4q9ag1lba1fo5qeanaoql/tiles/256/{z}/{x}/{y}@2x?access_token=' + constants.mapboxAccessToken],
        maxzoom: 20,
        tileSize: 256
    },
    topo: {
        type: 'raster',
        tiles: ['https://api.mapbox.com/styles/v1/n8mlr/ckhky1l03028m19ptwj16wvq2/tiles/256/{z}/{x}/{y}@2x?access_token=' + constants.mapboxAccessToken],
        maxzoom: 20,
        tileSize: 256
    }
};

constants.apiVersion = '3.9';

constants.googleAPIKey = 'AIzaSyBNywOzvO7EaPnZG50cPH93XzdxCEyOaeU';

constants.filestackApiKey = 'ACtlXnmeIRQWsIN8SLHeBz';

constants.mediaURL = 'https://media.unearthlabs.com/';

constants.unearthAccountId = 'iLZWUjsHJCNY6Pj-MKUY';

constants.baseTileOptions = {
    updateWhenZooming: false,
    maxNativeZoom: 24,
    maxZoom: 24,
    quadkey: xyzToQuadkey
};

constants.projectUserRoles = {
    suspended: {'key': 'suspended', ranking: 1, display: 'Suspended', writable: false},
    guest: {'key': 'guest', ranking: 2, display: 'Guest User', writable: false},
    general: {'key': 'general', ranking: 4, display: 'General User', writable: true},
    admin: {'key': 'admin', ranking: 5, display: 'Administrator', writable: true},
    owner: {'key': 'owner', ranking: 6, display: 'Owner', writable: true},
    superadmin: {key: 'superadmin', ranking: 7, display: 'Administrator', writable: false},
    viewer: {'key': 'viewer', ranking: 8, display: 'View Only', writable: true},
    limited: {'key': 'limited', ranking: 9, display: 'Limited edit access', writable: true}
};

constants.accountUserRoles = {
    viewer: {key: 'viewer', ranking: 1, display: 'View Only'},
    guest: {key: 'guest', ranking: 2, display: 'Guest User'},
    limited: {key: 'limited', ranking: 7, display: 'Limited Edit Access'},
    general: {key: 'general', ranking: 4, display: 'General User'},
    admin: {key: 'admin', ranking: 5, display: 'Administrator'},
    owner: {key: 'owner', ranking: 6, display: 'Owner'},
    superadmin: {key: 'superadmin', ranking: 8, display: 'Super Admin'}
};

constants.permissionsRanks = {
    guest: 5,
    viewer: 10,
    limited: 15,
    general: 20,
    admin: 25,
    owner: 30,
    superadmin: 30,
    WRITABLE_MIN: 20 // Does not account for limited user overrides
};

constants.controlTypeIdToName = {
    'kHP5E7ytbCXDobUEpGUJ': 'area',
    'KWvNr3goaEC8ZTq6Pcvx': 'asset',
    'JClHKXspGsXCulXTAyiI': 'links',
    '98e1iwaVDBAvaOOX8R9B': 'color',
    '7xgQOT7XONk9QoD_ETaj': 'comments',
    'BLIOUucM-vfNsdWoy1HL': 'coordinates',
    's2ZgKG5YJ-h0K7kS49YN': 'date',
    '9bqDYEh-w-ZnQIjrtgx-': 'dateTime',
    'ltEqAmuRhCLTpW-n0n4b': 'dropdown',
    'RpThORwku_8GADvB7qKE': 'embed',
    'InwvONjdUiDHp9MCn8Nu': 'file',
    'pa2idyDpPoN_oDLcD91k': 'length',
    'QFRFEjSlK9HCERyHgVLO': 'multiselect',
    'XHrOauni2ZhI0FB1GzL8': 'multitext',
    'cHOO50gwqyqTIiy24lxX': 'name',
    'aITN7NyIRXyGHXKvwMAv': 'number',
    '50joA2aJxXFI4gR8mvx7': 'paragraph',
    '8Y0hi3g6q6IZT9focY9B': 'plan',
    '1UOw3bdiOONotKjYrSj_': 'project',
    'upr8Czy0FSyzOhO-bN4R': 'radio',
    'Nvhz7WVx2nYMO7Uzm7Dw': 'request',
    'hW0itVu6pbBIXF16G4SA': 'survey',
    '0K3uA1hBYt5PWb4-pGqb': 'text',
    'OR2gCMYvD9vQTu1nFBM1': 'toggle',
    'TsL5Wj4sSMTq6NRg8F4I': 'URL',
    'EbAuM-i0vYuBEtSGVKcR': 'user',
    'owM1UO03S9ju7B_DntXK': 'volume',
    'ewmvVR6NZFCDcYY4x1YT': 'place'
};

constants.controlTypeNameToId = {
    'area': 'kHP5E7ytbCXDobUEpGUJ',
    'asset': 'KWvNr3goaEC8ZTq6Pcvx',
    'links': 'JClHKXspGsXCulXTAyiI',
    'color': '98e1iwaVDBAvaOOX8R9B',
    'comments': '7xgQOT7XONk9QoD_ETaj',
    'coordinates': 'BLIOUucM-vfNsdWoy1HL',
    'date': 's2ZgKG5YJ-h0K7kS49YN',
    'dateTime': '9bqDYEh-w-ZnQIjrtgx-',
    'dropdown': 'ltEqAmuRhCLTpW-n0n4b',
    'embed': 'RpThORwku_8GADvB7qKE',
    'file': 'InwvONjdUiDHp9MCn8Nu',
    'length': 'pa2idyDpPoN_oDLcD91k',
    'multiselect': 'QFRFEjSlK9HCERyHgVLO',
    'multitext': 'XHrOauni2ZhI0FB1GzL8',
    'name': 'cHOO50gwqyqTIiy24lxX',
    'number': 'aITN7NyIRXyGHXKvwMAv',
    'paragraph': '50joA2aJxXFI4gR8mvx7',
    'plan': '8Y0hi3g6q6IZT9focY9B',
    'project': '1UOw3bdiOONotKjYrSj_',
    'radio': 'upr8Czy0FSyzOhO-bN4R',
    'request': 'Nvhz7WVx2nYMO7Uzm7Dw',
    'survey': 'hW0itVu6pbBIXF16G4SA',
    'text': '0K3uA1hBYt5PWb4-pGqb',
    'toggle': 'OR2gCMYvD9vQTu1nFBM1',
    'URL': 'TsL5Wj4sSMTq6NRg8F4I',
    'user': 'EbAuM-i0vYuBEtSGVKcR',
    'volume': 'owM1UO03S9ju7B_DntXK',
    'place': 'ewmvVR6NZFCDcYY4x1YT'
};

constants.colors = {
    nameToHex: {
        green: '#07b050',
        blue: '#02b0f0',
        purple: '#944095',
        pink: '#e84495',
        red: '#d0021b',
        yellow: '#fff036',
        orange: '#eb7826',
        black: '#303840',
        gray: '#b7bec5',
        white: '#ffffff',
        teal: '#70BFBF'
    },
    hexToName: {
        '#07b050': 'green',
        '#02b0f0': 'blue',
        '#944095': 'purple',
        '#e84495': 'pink',
        '#d0021b': 'red',
        '#fff036': 'yellow',
        '#eb7826': 'orange',
        '#303840': 'black',
        '#b7bec5': 'gray',
        '#ffffff': 'white',
        '#70BFBF': 'teal'
    }
};

constants.baseToolboxes = {
    'AEC': {
        name: 'AEC',
        toolboxId: 'YMxl4hgY4cg1HN3VFuMz',
        attributes: {
            icon: {
                mediaId: 'Iqd46Ojx1scFcttk_IaL'
            }
        }
    },
    'Gas': {
        name: 'Gas',
        toolboxId: '2p88gH1nW8cN4Wlb8KYX',
        attributes: {
            icon: {
                mediaId: 'xEVi-o2qFrajw02WbEBr'
            }
        }
    },
    'Drone Services': {
        name: 'Drone Services',
        toolboxId: 'SU1OORTfgTxTPOecpN-f',
        attributes: {
            icon: {
                mediaId: 'EXFzkGPSb2EyQardkaE3'
            }
        }
    },
    'Sewer/Storm': {
        name: 'Sewer/Storm',
        toolboxId: 'yH5Wsry9VvqFimf8UBjt',
        attributes: {
            icon: {
                mediaId: '6le6S83bDWsAMr3hxBqr'
            }
        }
    }
};

constants.uploadOpts = {
    plan: {
        accept: ['image/bmp', 'image/gif', 'image/jpeg', 'image/pjpeg', 'image/png', 'image/tiff', 'image/x-tiff', 'application/pdf', '.zip'],
        maxFiles: 1,
        minFiles: 1
    },
    survey: {
        accept: ['image/tiff', 'image/x-tiff', '.zip', 'image/jpeg', 'image/pjpeg', '.jpg', '.jpeg', 'image/png'],
        maxFiles: 1000,
        minFiles: 1
    }
};

constants.oneUpOrigin = constants.isDeployed ? document.location.origin : 'http://localhost:8003';

if (constants.awsProfile === 'production') {
    constants.customExportUrl = 'http://custom-reporter.p';
} else {
    constants.customExportUrl = 'http://custom-reporter.d';
}

constants.customExportUrl += '/report';

constants.trimbleDataKey = '_locations';

constants.appURLs = {
    iOS: 'https://apps.apple.com/us/app/unearth-app/id1217649493',
    Android: 'https://play.google.com/store/apps/details?id=com.unearth.app'
};
export default constants;
