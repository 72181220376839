import initializer from 'util/initializer';
import Modal from 'views/modal';
import Loader from 'views/loader';
import message from 'legacy/components/message';
import MobileLauncher from 'views/mobile-launcher';


let updateCallbacks = [];

initializer.add(() => {

    updateCallbacks = [];

});

const messageComponent = message();

// Please forgive this code, it is quite cryptic.
// This is a temporary shim to allow us to use
// mithril components and Unearth V1 components
// interchangeably. We can remove it once we're
// fully moved over to mithril.
const App = view => ({

    onupdate() {

        updateCallbacks.forEach(fn => fn());

        updateCallbacks = [];

    },

    oncreate({dom}) {

        if (view._el) {

            dom.parentNode.replaceChild(view._el, dom);

            dom = view._el;

        }

        dom.parentNode.appendChild(messageComponent._el);

    },

    view: () => <>
        {view._el ? <div/> : m(view)}
        <Modal />
        <Loader />
        <MobileLauncher />
    </>

});

// Add a callback using App.onNextRender to run some code after
// the next Mithril redraw
App.onNextRender = callback => updateCallbacks.push(callback);

export default App;
