import constants from 'util/data/constants';
import router from 'uav-router';

let cache = {};

const storage = localStorage || sessionStorage;

function set(key, val) {

    cache[key] = val;

    try {

        storage.setItem(key, typeof val === 'string'
            ? val
            : JSON.stringify(val)
        );

    } catch (e) {
        
        // storage is disabled

    }

}

function get(key) {

    let val = cache[key];

    if (val === undefined) {

        try {

            val = storage.getItem(key);

        } catch (e) {

            // storage is disabled

        }

        if (typeof val === 'string') {

            try {

                val = JSON.parse(val);

            } catch (e) {

                // val is not an object

            }

            cache[key] = val;

        }

    }

    return val;

}

function remove(key) {

    delete cache[key];

    storage.removeItem(key);

}

function clear() {

    cache = {};

    storage.clear();

    set('env', constants.awsProfile);

}

function prep() {

    const env = get('env');

    if (constants.awsProfile !== env) {

        clear();

        if (env) {

            router.set();

        }

    }

    if (get('user') === 'anonymous' || router.params.secret && router.params.invitationId) {

        clear();

    }

    set('env', constants.awsProfile);

}

export default {
    set,
    get,
    prep,
    clear,
    remove
};
