import store from 'util/data/store';
import CloseButton from 'views/close-button';
import modalModel from 'models/modal-model';
import notificationsModel from 'models/notifications-model';
import ToolboxMenu from 'views/notifications/toolbox-menu';

const Preferences = {

    oninit: ({state}) => {
        notificationsModel.init().then(() => {
            state.viewingUserId = notificationsModel.userId + store.toolboxId; // Use as vnode key to ensure initted for this user + acct
        });
    },

    onremove: () => {
        notificationsModel.onRemove();
    },

    view: ({state}) => {
        return <div class='notification-preferences modal-wrap' key={state.viewingUserId}>
            <div class="modal-vertical-fixed"><CloseButton onclick={() => modalModel.close()} /></div>
            <div class="modal-vertical-scroll">
                <div class="modal-header-row">
                    <span class="modal-title">Notification Preferences</span>
                </div>
                <div class="modal-content-row">
                    {notificationsModel.getLabelFor('editingUserName') ? <div class="user-info-subtitle">Managing Preferences for <span>{notificationsModel.getLabelFor('editingUserName')}</span></div> : ''}
                    <span class="full-width-label">Subscriptions</span>
                    {notificationsModel.state.settingsFetched ? <> 
                        <div class="section-description">{notificationsModel.getLabelFor('subscriptionsDescription')}</div>
                        <div class="quick-radio-options"><div class="radio">
                            {notificationsModel.quickOpts.map((option, index) => {
                                return <div key={option} class={`radio-row${notificationsModel.isSaving('quickRadio', option)  ? ' saving' : ''}`}>
                                    <div class={`radio-opt${notificationsModel.selections.quickRadio === index ? ' active' : ''}`} onclick={() => notificationsModel.selectOption('quickRadio', index)}>
                                        <i class="spinner teal"/>
                                        <span class="radio-circle"></span>
                                        <span class="radio-opt-label">{option}</span>
                                    </div>
                                </div>;
                            }
                            )}
                        </div></div>
                        <div class={`subscription-options ${notificationsModel.allAreMuted() ? 'muted' : ''}`}>
                            {notificationsModel.triggerOpts.map((option, index) => 
                                <div key={index} class={`subscription-option ${notificationsModel.isSaving('switches', option) ? 'saving' : ''}`} 
                                    onclick={() => notificationsModel.toggleSwitch(option)}>
                                    <i class="spinner teal"/>
                                    <i class={`toggle-switch toggle-is-${notificationsModel.selections.switches[option] && !notificationsModel.allAreMuted() ? 'on' :  'off'}`}/>
                                    <span class="subscription-option-label">{notificationsModel.getLabelFor(option)}</span>
                                </div>)}
                            {!notificationsModel.allAreMuted() && notificationsModel.selections.switches.assetTypeActivity &&
                        <div class="subscription-options-toolbox-menus"> {notificationsModel.toolboxesFetched ? 
                            notificationsModel.toolboxes.map(toolbox => <ToolboxMenu toolbox={toolbox}/>)
                            : <div className="loading-options-text"><i className='spinner spinning teal'/>Loading options...</div>} </div> }
                        </div> 
                    </> : <div className="loading-options-text"><i className='spinner spinning teal'/>Loading options...</div> }
                </div>
            </div>
        
        </div>;
    }
};

export default Preferences;
