import popup from 'util/popup';
import GeometryDetail from 'views/geometry-detail/geometry-detail';
import placesTabModel from 'models/places-tab-model';
import toolboxModel from 'models/toolbox-model';
import capitalize from 'util/data/capitalize';

class PlaceProjectMenu {

    static open(lngLat) {
        popup.remove();
        popup.add({
            className: 'feature-menu-popup',
            content: <PlaceProjectMenu/>,
            lngLat,
            maxWidth: 'none'
        });
    }

    view() {
        if (!placesTabModel.feature) {
            return <div/>;
        }

        return <div className="place-project-menu">
            <GeometryDetail
                featureId={placesTabModel.feature.id}
                customElement={<>
                    <div className="left half">{'Avg Elevation'}</div>
                    <div className="left half">{placesTabModel.getTotalAverageElevation()}</div>
                </>}
                header={
                    <div className="place-project-menu-header">
                        <i className="icon-place"/>
                        {capitalize(toolboxModel.projectSingular)} Bounds
                    </div>
                }/>
        </div>;
    }
}

export default PlaceProjectMenu;
