import uav from 'uav';
import 'modernizr';
import Popover from 'legacy/components/popover';
import element from 'util/dom/element';

const escapeTip = element`<span>Close (ESC)</span>`;

export default (close, className = '') => {

    return uav.component('<i class="icon-close" u-onclick={close} u-onmouseover={show} u-onmouseout={hide}></i>', {
        close,
        hide: !Modernizr.touchevents ? Popover.hide : undefined,
        show: !Modernizr.touchevents ? e => new Popover({
            e,
            content: escapeTip,
            cssClass: `close-tip right ${className}`
        }) : undefined
    });

};
