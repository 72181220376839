function componentToHex(c) {
    
    const hex = c.toString(16);
    
    return hex.length === 1 ? '0' + hex : hex;

}

function rgbToHex(rgb) {

    if (rgb.startsWith('#') && rgb.length === 7) {

        return rgb;

    }

    rgb = rgb.replace(/^rgba?\(/, '').replace(/\)/, '').split(/, ?/).map(Number);

    return '#' + componentToHex(rgb[0]) + componentToHex(rgb[1]) + componentToHex(rgb[2]);
}

export default {
    names: ['white', 'pink', 'red', 'yellow', 'orange', 'blue', 'purple', 'green', 'none'],
    hexes: ['#ffffff', '#e84495', '#eb342e', '#fff036', '#f69831', '#1073b7', '#944095', '#149267', null],
    byName: {
        white: '#ffffff',
        pink: '#e84495',
        red: '#eb342e',
        yellow: '#fff036',
        orange: '#f69831',
        blue: '#1073b7',
        purple: '#944095',
        green: '#149267',
        none: null
    },
    byHex: {
        '#ffffff': 'white',
        '#e84495': 'pink',
        '#eb342e': 'red',
        '#fff036': 'yellow',
        '#f69831': 'orange',
        '#1073b7': 'blue',
        '#944095': 'purple',
        '#149267': 'green',
        none: null
    },
    rgbToHex
};
