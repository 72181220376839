import constants from 'util/data/constants';
import formModel from 'models/form-model';
import store from 'util/data/store';
import siteModel from 'models/site-model';
import api from 'legacy/util/api';
import debounce from 'util/events/debounce';
import mediaModel from 'models/media-model';
import debouncedAPICall from 'util/network/debounced-api-call';
import assetModel from 'models/asset-model';
import featureModel from 'models/feature-model';
import helpers from 'legacy/util/api/helpers';

const controlType = constants.controlTypeNameToId;

function saveFeature(feature) {
    debouncedAPICall('modifyFeature' + feature.id, ['modifyFeature', api.apiSafeFeature(feature, true)]);
}

function coordinatesToFeature(featureType, control, assetId) {
    const asset = store.assets[assetId];
    const features = asset.featureIds
            .map(id => store.features[id])
            .filter(f => f.properties.featureTypeId === featureType.featureTypeId),
        geometry = asset.properties[control.label],
        draw = formModel.toolInterface && formModel.toolInterface.draw,
        drawFeature = draw && draw.feature;
    features.forEach(f => {
        if (f.geometry.type.startsWith('Multi')) {
            f.geometry.coordinates[0] = geometry.coordinates;
        } else {
            f.geometry.coordinates = geometry.coordinates;
        }
        if (drawFeature && drawFeature.id === f.id && draw.vertices && draw.vertices.length) {
            draw.vertices[0].setLngLat(geometry.coordinates);
        }
        saveFeature(f);
    });
    return true; // return true to render source
}

function textToMedia(featureType, control, assetId) {
    const asset = store.assets[assetId],
        linkedControls = featureType.attributes.linkedControls,
        fileControlTypeId = constants.controlTypeNameToId.file,
        text = asset.properties[control.label],
        linkedFileControls = formModel.assetForm.controls.filter(
            c => c.controlTypeId === fileControlTypeId
                && linkedControls.indexOf(c.label) !== -1
        );
    linkedFileControls.forEach(fileControl => {
        const mediaIds = asset.properties[fileControl.label] || [];
        Promise.all(mediaIds.map(mediaModel.getMedia)).then(mediaRecords => mediaRecords.forEach(media => {
            media = store.updateImmutableProp('media', media.mediaId, 'label', text);
            debouncedAPICall('modifyMediaLabel', ['modifyMedia', {
                mediaId: media.mediaId,
                label: media.label
            }]);
        }));
    });
    return false;
}

function textToPlan(featureType, control, assetId) {
    const asset = store.assets[assetId],
        linkedControls = featureType.attributes.linkedControls,
        planControlTypeId = constants.controlTypeNameToId.plan,
        linkedPlanControls = formModel.assetForm.controls.filter(c =>
            c.controlTypeId === planControlTypeId
            && linkedControls.indexOf(c.label) !== -1
        ),
        text = asset.properties[control.label];
    linkedPlanControls.forEach(planControl => {

        const planId = asset.properties[planControl.label],
            plan = store.plans[planId];

        if (plan) {

            store.updateImmutableProp('plans', planId, 'title', text);

            m.redraw();

            debouncedAPICall('modifyPlan' + planId, ['modifyPlan', {
                planId,
                title: text
            }]);

        }

    });
    return false;
}

function textToProject(featureType, control, assetId) {
    const asset = store.assets[assetId],
        name = asset.properties[control.label],
        linkedControls = featureType.attributes.linkedControls,
        projectControlTypeId = constants.controlTypeNameToId.project,
        projectControls = formModel.assetForm.controls.filter(
            c => c.controlTypeId === projectControlTypeId
            && linkedControls.indexOf(c.label) !== -1
        );
    projectControls.forEach(projectControl => {

        const projectId = asset.properties[projectControl.label];

        debouncedAPICall('modifyProject' + projectId, ['modifyProject', {
            projectId,
            name
        }]).then(project =>
            api.rpc.request([['modifyProjectSite', {
                siteId: helpers.list(project.sites)[0].siteId,
                projectId,
                name
            }]])
        );

    });
    return false;
}

function textToSurvey(featureType, control, assetId) {
    const asset = store.assets[assetId],
        linkedControls = featureType.attributes.linkedControls,
        surveyControlTypeId = constants.controlTypeNameToId.survey,
        linkedSurveyControls = formModel.assetForm.controls.filter(c =>
            c.controlTypeId === surveyControlTypeId
            && linkedControls.indexOf(c.label) !== -1
        ),
        text = asset.properties[control.label];
    linkedSurveyControls.forEach(surveyControl => {

        const surveyId = asset.properties[surveyControl.label],
            survey = store.surveys[surveyId];

        if (survey) {

            store.updateImmutableProp('surveys', surveyId, 'title', text);

            m.redraw();

            debouncedAPICall('modify' + surveyId, ['modifySurvey', {
                surveyId,
                title: text
            }]);

        }

    });
    return false;
}

function textToText(featureType, control, assetId) {
    let doRender;
    const asset = store.assets[assetId],
        draw = formModel.toolInterface && formModel.toolInterface.draw,
        drawFeature = draw && draw.feature;
    asset.featureIds
        .map(id => store.features[id])
        .filter(f => f.properties.featureTypeId === featureType.featureTypeId)
        .forEach(f => {
            doRender = true;
            f.properties._textField = asset.properties[control.label];
            if (drawFeature && drawFeature.id === f.id) {
                draw.resetSize();
            }
            saveFeature(f);
        });
    return doRender;
}

function syncFeatureProperties(control, feature, assetId = formModel.assetId) {

    const featureType = store.featureTypes[feature.properties.featureTypeId],
        styledControls = featureType.attributes.styledControls,
        controlLabel = control.label,
        asset = store.assets[assetId];

    if (styledControls && styledControls.indexOf(controlLabel) !== -1) {

        if (asset.properties.hasOwnProperty(controlLabel)) {

            let value = asset.properties[controlLabel];

            if (Array.isArray(value)) {
                value = value[0];
            }

            feature.properties[controlLabel] = value;

            // If this property change might result in a new icon for the feature,
            // then we need to reset the _sourceWidthPx property
            const featureTypeId = feature.properties.featureTypeId,
                featureStyleIds = store.featureTypeToStyles[featureTypeId];

            featureStyleIds.forEach(featureStyleId => {

                const style = store.featureStyles[featureStyleId].style,
                    iconImage = style.layout && style.layout['icon-image'];

                if (iconImage) {

                    if (iconImage[0] === 'match' && iconImage[1][1] === controlLabel) {

                        const currentStopIndex = iconImage.slice(2).findIndex(item => item === value),
                            mediaId = currentStopIndex === -1
                                ? iconImage[iconImage.length - 1]
                                : iconImage[currentStopIndex + 3];

                        featureModel.addImage(mediaId, constants.mediaURL + mediaId, feature);

                    } else if (iconImage.stops && iconImage.property === controlLabel) {

                        const currentStop = iconImage.stops.find(s => s[0] === value),
                            mediaId = currentStop ? currentStop[1] : iconImage.default;

                        featureModel.addImage(mediaId, constants.mediaURL + mediaId, feature);

                    } else if (iconImage[0] === 'get') {

                        let mediaId = feature.properties[iconImage[1]];

                        if (mediaId) {

                            if (Array.isArray(mediaId)) {
                                mediaId = mediaId[0];
                            }

                            featureModel.addImage(mediaId, constants.mediaURL + mediaId, feature).then(needsRedraw => {

                                if (needsRedraw) {

                                    const source = siteModel.map.getSource(featureTypeId);

                                    source.setData(source._data);

                                }

                            });

                        }

                    }

                }

            });

        } else {

            delete feature.properties[controlLabel];

        }

        return true;

    }

}

function updateFeatureType(featureType, control, assetId) {

    const interfaceType = featureType.attributes.interface,
        syncLinkedFeature = controlToFeature[control.controlTypeId][interfaceType];

    if (syncLinkedFeature) {

        const linkedControls = featureType.attributes.linkedControls;

        if (linkedControls && linkedControls.indexOf(control.label) !== -1) {

            return syncLinkedFeature(featureType, control, assetId);

        }

    }

}

function syncAllFeatureProperties(assetId, feature) {

    const asset = store.assets[assetId],
        tool = store.tools[asset.attributes.toolId];

    tool.assetForm.controls.forEach(control => {

        syncFeatureProperties(control, feature, assetId);

    });

}

const updateAssetFeatures = (control, assetId = formModel.assetId) => new Promise((resolve) =>
    debounce(() => {

        const asset = store.assets[assetId];

        // If something is marked as invalid,
        // revalidate on input.
        if (formModel.invalid[control.label]) {

            formModel.validateControl(control);

        }

        formModel.saving[control.label] = true;

        m.redraw();

        const modifiedFeatureTypes = [];

        asset.featureIds = helpers.list(asset.featureIds); // In case asset was never mapped

        if (asset.featureIds && asset.featureIds.length > 0) {

            asset.featureIds.forEach(featureId => {

                const feature = store.features[featureId];

                if (feature && syncFeatureProperties(control, feature, assetId)) {

                    modifiedFeatureTypes[feature.properties.featureTypeId] = true;

                    saveFeature(feature);

                }

            });

        }

        if (asset.attributes) { // this should never be falsy, but sentry says it has been

            const tool = store.tools[asset.attributes.toolId];

            if (tool.featureTypes) {

                tool.featureTypes.forEach(featureType => {

                    if (updateFeatureType(featureType, control, assetId)) {

                        modifiedFeatureTypes[featureType.featureTypeId] = true;

                    }

                });
            }

        }

        Object.keys(modifiedFeatureTypes).forEach(featureTypeId => {

            const source = siteModel.map.getSource(featureTypeId);

            source.setData(source._data);

        });

        if (assetId === formModel.assetId) {

            formModel.debouncedTriggerEval(control.label);

        }

        assetModel.autosave(assetId).then(() => resolve(assetId));

    })()
);


const controlToFeature = {
    [controlType.area]: {
        text: textToText
    },
    [controlType.links]: {},
    [controlType.coordinates]: {
        filepicker: coordinatesToFeature,
        symbol: coordinatesToFeature,
        plan: coordinatesToFeature,
        survey: coordinatesToFeature
    },
    [controlType.dropdown]: {
        text: textToText
    },
    [controlType.radio]: {
        text: textToText
    },
    [controlType.file]: {},
    [controlType.length]: {
        text: textToText
    },
    [controlType.multiselect]: {
        text: textToText
    },
    [controlType.multitext]: {},
    [controlType.name]: {
        filepicker: textToMedia,
        plan: textToPlan,
        survey: textToSurvey,
        text: textToText,
        project: textToProject
    },
    [controlType.number]: {
        text: textToText
    },
    [controlType.paragraph]: {
        filepicker: textToMedia,
        plan: textToPlan,
        survey: textToSurvey,
        text: textToText,
        project: textToProject
    },
    [controlType.plan]: {},
    [controlType.survey]: {},
    [controlType.text]: {
        filepicker: textToMedia,
        plan: textToPlan,
        survey: textToSurvey,
        project: textToProject,
        text: textToText
    },
    [controlType.toggle]: {},
    [controlType.URL]: {
        text: textToText
    },
    [controlType.volume]: {
        text: textToText
    },
    [controlType.date]: {},
    [controlType.place]: {},
    [controlType.user]: {},
    syncAllFeatureProperties,
    syncFeatureProperties,
    updateAssetFeatures
};

export default controlToFeature;
