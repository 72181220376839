import socket from 'util/network/socket';
import router from 'uav-router';
import signUp from 'legacy/components/auth/sign-up';
import api from 'legacy/util/api';
import message from 'legacy/components/message';
import swap from 'legacy/util/swap';
import promoteUser from 'legacy/components/auth/sign-up/promote-user';
import constants from 'util/data/constants';
import hubspot from 'util/hubspot/hubspot';
import cookie from 'util/data/cookie';

function startTrial(id) {

    function showError(data) {

        if (data.payload && data.payload.error && (data.payload.error.indexOf('UsernameExistsException') !== -1 || data.payload.error.indexOf('Email address and phone number belong to different') !== -1)) {

            logOut();

        } else {

            socket.showError();

            signUp.component.form.submit.spinning = false;

        }

    }

    function logIn() {

        // This flag is set to true if the user is signing up
        // for a cold trial, i.e. they were not invited by another
        // user or created by Customer Service.
        // https://unearth.atlassian.net/browse/WEB-605
        startTrial.completedSelfSignUp = signUp.isTrial;

        socket.connect({
            username: id.email,
            password: id.password
        },
        () => router.set(),
        () => logOut());

    }

    function logOut(msg = '') {

        router.set();

        setTimeout(() => message.show(msg || 'An account with that contact information already exists. Please sign in or contact support.' + msg, 'warning'), 2000);

    }

    function createUser() {

        hubspot.load().then(() => {
            hubspot.postHubspotUser({
                fields: [{
                    name: 'firstname',
                    value: id.firstName
                },
                {
                    name: 'lastname',
                    value: id.lastName
                },
                {
                    name: 'email',
                    value: id.email
                },
                {
                    name: 'mobilephone',
                    value: id.phone
                },
                {
                    name: 'company',
                    value: id.accountName
                }],
                context: {
                    hutk: cookie.readCookie('hubspotutk'),
                    pageUri: window.location.href,
                    pageName: window.location.pathname
                },
                skipValidation: true
            });
        });

        api.post.user({
            emailAddress: id.email,
            phoneNumber: id.phone,
            givenName: id.firstName,
            familyName: id.lastName,
            password: id.password,
            chargebeeCustomerId: id.customerId,
            accountName: id.accountName,
            baseToolboxId: constants.baseToolboxes.Gas.toolboxId
        }, showError).then(user => {

            socket.reset();

            swap(user && user.status, {
                trial: logIn,
                invited: () => {

                    if (router.params.invitationId && router.params.secret && router.params.projectId) {

                        promoteUser(id);

                    } else {

                        logOut('If you were sent an invite link, please use it to sign up.');

                    }

                },
                default: () => logOut()
            });

        });

    }

    socket.connect({anonymous: true}, createUser, showError);

}

export default startTrial;
