import uav from 'uav';
import socket from 'util/network/socket';
import router from 'uav-router';
import message from 'legacy/components/message';
import signUp from 'legacy/components/auth/sign-up';
import api from 'legacy/util/api';
import userModel from 'models/user-model';

function promoteUser(id) {

    const params = router.params;

    function showError(msg) {

        if (msg) {

            const lower = msg.toLowerCase();

            if (lower.indexOf('user does not exist') !== -1 || lower.indexOf('failed to reset password') !== -1) {

                msg = 'We\'ve encountered an issue with your account. Please contact support@unearthlabs.com, and we\'ll get it fixed.';

            } else if (lower.indexOf('usernameexistsexception') !== -1 || lower.indexOf('email address and phone number belong to different users') !== -1) {

                msg = 'An account with that contact information already exists.';

            } else {

                msg = undefined;

            }

        }

        socket.reset();

        message.show(
            uav.component(`<span>${msg || 'Something went wrong.'}</span>`),
            'error',
            true
        );

        signUp.component.form.submit.spinning = false;

    }

    function makeAuthenticatedRequest() {

        api.patch.user({
            userId: userModel.userId,
            emailAddress: id.email,
            phoneNumber: id.phone,
            password: id.password,
            givenName: id.firstName,
            familyName: id.lastName
        }).then(user => {

            userModel.setCurrent(user);

            router.set({
                projectId: params.projectId,
                siteId: params.siteId,
                threadId: params.threadId,
                overlays: params.overlays,
                layers: params.layers
            });

        }).catch(data => {

            signUp.component.form.submit.spinning = false;

            showError(data.payload && data.payload.error);

        });

    }

    socket.connect(null, makeAuthenticatedRequest, showError);

}

export default promoteUser;
