const Radio = {

    oninit: ({attrs: {options, defaultOption}, state}) => {
        state.selected = options.indexOf(defaultOption);
    },

    onupdate: ({attrs: {options, defaultOption, reset}, state}) => {
        if (reset) {
            state.selected = options.indexOf(defaultOption);
        }
    },

    view: ({attrs: {options, onchange}, state}) =>
        <div class={`radio ${options.length > 3 ? 'over3' : ''}`}>
            {options.map((option, index) =>
                <div class="radio-row">
                    <div key={option} class={'radio-opt' + (index === state.selected ? ' active' : '')} onclick={() => {
                        state.selected = index;
                        if (onchange) {
                            onchange(option, index);
                        }
                    }}>
                        <span class="radio-circle"></span>
                        <span class="radio-opt-label">{
                            typeof option === 'string'
                                ? option
                                : option.tag && option.attrs ? option : m(option)
                        }</span>
                    </div>
                </div>
            )}
        </div>
};

export default Radio;
