import Text from 'util/draw/text';
import siteModel from 'models/site-model';
import featureToControl from 'util/interfaces/feature-to-control';
import NextButton from 'legacy/components/next-button';
import featureModel from 'models/feature-model';
import formModel from 'models/form-model';
import ToolInterface from 'util/interfaces/tool-interface';
import controlToFeature from 'util/interfaces/control-to-feature';
import store from 'util/data/store';
import helpers from 'legacy/util/api/helpers';

class TextInterface extends ToolInterface {

    constructor(...args) {

        super(...args);

        this.type = 'text';

    }

    launch() {

        const map = siteModel.map;

        this.prompt = {view: () => `Tap on the map to place the ${this.featureType.name}.`};

        this.showToolLauncher();

        let nextButton;

        if (this.featureType.attributes.repeat) {

            nextButton = new NextButton();

        }

        return new Promise(resolve => {

            const asset = store.assets[this.assetId];

            this.done = () => {

                if (this.draw) {

                    this.draw.stop();

                }

                resolve([asset]);

            };

            this.draw = new Text({
                map,
                source: map.getSource(this.featureType.featureTypeId)
            }).create(this.feature.properties);

            this.draw.onComplete = _feature => featureModel.setupFeatureProperties(_feature).then(() => {

                this.feature = _feature;

                featureModel.addFeatures([_feature]);

                asset.featureIds = helpers.list(asset.featureIds); // In case asset was never mapped
                asset.featureIds.push(_feature.id);

                featureToControl.sync('text', _feature, this.assetId);

                controlToFeature.syncAllFeatureProperties(this.assetId, _feature);

                if (this.featureType.attributes.repeat) {

                    this.draw.create(_feature.properties);

                    nextButton.show({
                        onClick: this.done
                    });

                } else {

                    this.done();

                }

            });

        });

    }

    edit(_feature) {

        const map = siteModel.map,
            source = map.getSource(this.featureType.featureTypeId);

        this.feature = source._data.features.find(f => f.id === _feature.id);

        if (!this.feature) {

            return;

        }

        this.draw = new Text({
            map,
            source
        }).edit(this.feature);

        this.draw.onVertexChanged = () => {

            featureToControl.sync('text', this.feature, this.assetId);

            controlToFeature.syncAllFeatureProperties(this.assetId, this.feature);

            source.setData(source._data);

            m.redraw();

            this.autosave(this.feature);

        };

        this.draw.onStop = feature => {

            formModel.onEditStop(feature);

            this.autosave(feature);

        };

    }

}

export default TextInterface;
