import uav from 'uav';
import router from 'uav-router';
import signUp from 'legacy/components/auth/sign-up';
import resetPassword from 'legacy/components/auth/reset-password';
import forgotPassword from 'legacy/components/auth/forgot-password';
import changePassword from 'legacy/components/auth/change-password';
import login from 'legacy/components/auth/login';
import constants from 'util/data/constants';
import hubspot from 'util/hubspot/hubspot';

function auth(view) {

    view = {
        signUp,
        resetPassword,
        forgotPassword,
        changePassword,
        login
    }[view]();

    const isSignUp = router.params.signup;

    auth.component = uav.component(`
    <div u-class="auth {view.classname}">
        <header class="header">
            <div class="header-left"><a class="home" u-onclick={signin}></a></div>
            <div class="header-right">
                <div class="login-prompt">${isSignUp ? 'Already have an account' : 'No account yet'}?</div>
                <a u-onclick={signup}" class="btn btn-small btn-outline-arctic btn-pill">${isSignUp ? 'Sign In' : 'Try it Free'}</a>
            </div>
        </header>
        <div class="auth-wrapper">
            <div class="map-dots">
                <span class="map-dot red-dot"></span>
                <span class="map-dot teal-dot"></span>
                <span class="map-dot red-dot"></span>
                <span class="map-dot red-dot"></span>
                <span class="map-dot red-dot"></span>
                <span class="map-dot teal-dot"></span>
                <span class="map-dot teal-dot"></span>
                <span class="map-dot teal-dot"></span>
            </div>
            {view}
            <div class="auth-form-links">
                <span class="unearth-help-link"><a u-onclick={askSupport}>Ask Unearth Support</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span><a u-onclick={privacy}>Privacy Policy</a>
            </div>
        </div>
    </div>`, {
        askSupport: () => {

            hubspot.loadHubspotChat();

        },
        privacy: () => {

            window.open('https://www.unearthlabs.com/privacy-policy');

        },
        signin: () => {

            router.set();

        },
        signup: () => {

            if (constants.awsProfile === 'staging' || constants.awsProfile === 'development') {

                window.location.href = `https://unearthlabs.com/free-trial/?sdkMode=${constants.awsProfile}`;

            } else {

                window.location.href = 'https://unearthlabs.com/request-a-demo/';

            }

        },
        view
    });

    return auth.component;

}

export default auth;
