import popupModel from 'models/popover-model';
import tableModel from 'models/table/table-model';
import formModel from 'models/form-model';
import Cell from 'views/table/cells/cell';
import CellID from 'views/table/cells/cell-id';
import CellCommon from 'views/table/cells/cell-common';
import siteModel from 'models/site-model';
import Table from 'views/table/table';
import AssetRowModel from 'models/table/asset-row-model';
import store from 'util/data/store';

class TableRow {

    oninit({attrs: {assetId}}) {
        tableModel.assetRows[assetId] = new AssetRowModel(assetId);
    }

    onremove({attrs: {assetId}}) {
        delete tableModel.assetRows[assetId];
    }

    view({attrs: {assetId, index}}) {
        const assetRow = tableModel.assetRows[assetId];
        if (!assetRow) {
            return;
        }
        assetRow.rowIndex = index;

        let columnIndex = 0;
        const activeClass = tableModel.activeCell.isActive && tableModel.activeCell.row === assetRow.rowIndex ? 'active-row' : 'inactive-row';

        return <div class={`asset-row ${activeClass} ${assetRow.rowSortingCss}`} onclick={() => {
            if (popupModel.isOpen) {
                popupModel.close();
                return;
            }
            if (!tableModel.inEditMode) {
                const asset = store.assets[assetId];
                const tool = store.tools[asset.attributes.toolId];
                if (tool && tool.featureTypes.find(ft => ft.attributes.interface === 'project')) {
                    tableModel.viewProject(assetId);
                } else {
                    formModel.viewAsset(assetId, 'Properties');
                }
            }
        }}>
            <CellID assetRow={assetRow} cellKey={'name'} controlType={assetRow.nameControl} columnNA={false} columnIndex={columnIndex} />
            <ul class="asset-properties">
                {tableModel.visibleHeaders.unearthId && <CellCommon assetRow={assetRow} columnIndex={++columnIndex} cellKey='unearthId'/> }
                <ul class="card-group">
                    {tableModel.visibleHeaders.category &&
                    <CellCommon assetRow={assetRow} cellKey='category' columnIndex={++columnIndex}/>}
                    {tableModel.visibleHeaders.type &&
                    <CellCommon assetRow={assetRow} cellKey='type' columnIndex={++columnIndex} /> }
                </ul>
                {tableModel.visibleHeaders.addedDateTime && <CellCommon assetRow={assetRow} columnIndex={++columnIndex} cellKey='addedDateTime'/> }
                {tableModel.visibleHeaders.lastUpdated && <CellCommon assetRow={assetRow} columnIndex={++columnIndex} cellKey='lastUpdated'/> }
                {tableModel.visibleHeaders.addedBy && <CellCommon assetRow={assetRow} columnIndex={++columnIndex} cellKey='addedBy'/> }
                {tableModel.visibleHeaders.places && <CellCommon assetRow={assetRow} columnIndex={++columnIndex} cellKey='places' controlType='places' /> }
                {tableModel.tableMode !== 'list-left' && siteModel.sidebar === Table && tableModel.getVisibleSharedColumnHeaders().map((sharedColumnName, i) => {
                    if (i >= tableModel.loadRange.startCol && i <= tableModel.loadRange.endCol) {
                        return <Cell assetRow={assetRow} columnIndex={++columnIndex} key={sharedColumnName + assetRow.assetId + assetRow.rowIndex + columnIndex} 
                            cellKey={sharedColumnName} sharedColumnName={sharedColumnName} controlType={tableModel.getSharedColumn(sharedColumnName).controlType} columnNA={!tableModel.getSharedColumn(sharedColumnName).assetTypeIds.includes(assetRow.assetTypeId)} />;
                    }
                    columnIndex++;
                    return <div key={sharedColumnName + assetRow.assetId + assetRow.rowIndex + columnIndex} class="mock-table-cell"/>;
                })}
                {tableModel.getVisibleTableHeaders().map((header, i) => {
                    if (tableModel.tableMode === 'list-left' || siteModel.sidebar !== Table || i + tableModel.getVisibleSharedColumnHeaders().length >= tableModel.loadRange.startCol && i + tableModel.getVisibleSharedColumnHeaders().length <= tableModel.loadRange.endCol) {
                        return <Cell 
                            key={assetRow.generateCellKey(columnIndex, header)} 
                            assetRow={assetRow} 
                            columnIndex={i + columnIndex + 1}
                            cellKey={header.assetType.assetTypeId + header.controlType.label} 
                            controlType={header.controlType} 
                            columnNA={header.assetType.assetTypeId !== assetRow.assetTypeId} />;
                    }
                    return <div key={assetRow.generateCellKey(columnIndex, header)} class="mock-table-cell"/>;
                })}
            </ul>
        </div>;
    }
}

export default TableRow;
