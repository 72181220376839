import uav from 'uav';

let _timer;

export default opts => {

    const model = uav.model({
        spinning: opts.spinning,
        onclick: e => {

            if (e) {

                e.preventDefault();

            }

            if (model.disabled) {

                if (opts.onDisabledClick) {

                    opts.onDisabledClick();

                }

            } else if (!model.spinning) {

                model.spinning = true;

                clearTimeout(_timer);

                _timer = setTimeout(() => {

                    model.spinning = false;

                }, opts.delay || 90000);

                opts.onclick();

            }

        },
        pressed: false,
        disabled: opts.disabled || false,
        text: opts.text,
        togglePressed: () => {

            model.pressed = !model.pressed;

        }
    });

    return uav.component(`<div u-class="submit-button {spinning}">
        <button type="submit" u-class="btn btn-primary btn-pill ${opts.klass || ''} {disabled} {pressed}" u-onclick={onclick} u-ontouchstart={togglePressed} u-ontouchend={togglePressed}>
            <div class="spinner"></div>
            <span class="btn-label">{text}</span>
        </button>
    </div>`, model);

};
