import mapboxgl from 'mapbox-gl';
import store from 'util/data/store';
import router from 'uav-router';
import constants from 'util/data/constants';
import styleModel from 'models/style-model';
import LayerControl from 'views/layer-control';
import layerModel from 'models/layer-model';
import {latLngsToLngLats, lngLatsToBounds} from 'util/geo';
import api from 'legacy/util/api';
import MagnifierModel from 'models/magnifier-model';
import helpers from 'legacy/util/api/helpers';
import popup from 'util/popup';

/**
 * Handles all logic and state for mapbox objects.
 */
class MapModel extends mapboxgl.Map {
    constructor(opts, settings) {

        opts = Object.assign({}, {
            style: styleModel.reset(),
            attributionControl: false
        }, opts);

        if (router.params.view === 'pdf') {
            opts.preserveDrawingBuffer = true;
            opts.fadeDuration = 0;
        }

        super(opts);
        this.siteId = router.params.siteId;
        this.settings = settings ? settings : {};
        this.setControls();
        this.mapLoaded().then(() => {
            !this.settings.basemapOff ? this.setBasemap() : null;
        });

        const zoomIn = this.zoomIn.bind(this);

        this.zoomIn = () => {
            const pop = popup.isOpen();
            if (pop) {
                const popXY = this.project(pop[0].getLngLat());
                const centerXY = this.project(this.getCenter());
                zoomIn({
                    offset: [popXY.x - centerXY.x, popXY.y - centerXY.y]
                });
            } else {
                zoomIn();
            }
        };

    }

    /**
     * Given a camera, sets the center and zoom of the map to its attributes.
     * @param camera
     */
    setCamera(camera) {
        this.setCenter(camera.center);
        this.setZoom(camera.zoom);
    }

    /**
     * Returns the center and zoom of the map as it is currently positioned.
     */
    getCamera() {
        return {
            center: this.getCenter(),
            zoom: this.getZoom()
        };
    }

    /**
     * Set Map controls based on passed params.
     *
     * By default, map will have zoom navigation, geolocate, and
     * standard LayerControl menu in the bottom-right corner.
     */
    setControls() {
        const location = this.settings.controlsLocation
            ? this.settings.controlsLocation
            : 'bottom-right';
        if (!this.settings.navcontrolOff) {
            this.addControl(new mapboxgl.NavigationControl({showCompass: false}), location);
        }
        if (!this.settings.geolocateOff) {
            this.addControl(new mapboxgl.GeolocateControl({
                positionOptions: {enableHighAccuracy: true}, trackUserLocation: true}), location);
        }
        if (!this.settings.layercontrolOff) {
            layerModel.layerControl = new LayerControl();
            this.addControl(layerModel.layerControl, location);
        }
    }

    /**
     * Returns promise re: style loaded on mapbox object.
     */
    mapLoaded() {
        return new Promise(resolve => {
            if (this.isStyleLoaded()) {
                resolve();
            } else {
                this.once('styledata', () => {
                    resolve();
                });
            }
        });
    }

    /**
     * Adds a color circle as the second map layer (on top of the basemap).
     * Required params: id for source/layer, [lngLat] for center of circle
     * Optional params: opts = {radius, hex, opacity}
     */
    addColorCircle(id, lngLats, opts = {}) {
        const beforeLayer = this.getStyle().layers.find(layer => layer.type !== 'raster');
        this.addSource(id, {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': lngLats.map((lngLat) => {
                    return {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': lngLat
                        }
                    };
                })
            }
        });
        this.addLayer({
            'id': id,
            'type': 'circle',
            'source': id,
            'paint': {
                'circle-radius': opts.radius || 200,
                'circle-color': opts.hex || '#109494', // defaults to $teal1
                'circle-opacity': opts.opacity || 0.2
            }
        }, beforeLayer && beforeLayer.id);
    }

    /**
     * Pans to the passed coords and displays a popup at the center with the provided text.
     * Required params: lngLat for center of popup, mapbox opts for panning, popupText (1 or 2 strings in array)
     */
    panToAndHighlight(lngLat, opts = {}, popupText) {
        if (this.searchPopup) {
            this.searchPopup.remove(); // Remove left over search popup and circle if it exists
        }
        const id = 'circle-search-result';
        this.addColorCircle(id, [lngLat]);
        this.searchPopup = new mapboxgl.Popup({
            offset: 0,
            maxWidth: '300px',
            className: 'search-result-popup',
            closeButton: false
        }).setHTML(`<div class="search-popup-inner"><i class="icon-center-on-map"></i><div class="search-popup-text"><span class="first-line">${popupText[0] || ''}</span><span class="second-line">${popupText[1] || ''}</span></div></div>`)
            .setLngLat(lngLat)
            .addTo(this);
        this.searchPopup.once('close', () => this.safeRemoveSource(id));
        this.panTo(lngLat, opts);
    }


    // ------------ Magnifying Glass Setup and Event Handling ------------

    /**
     * Creates the necessary elements for the map magnifier, which is activated via dragging a Stake.
     * Called from stakeableModel.
     */
    addMagnifier(useLightBg) {
        this.canvas2d = document.createElement('canvas');
        this.ctx2d = this.canvas2d.getContext('2d');
        this.mapLoaded().then(() => {
            this.magnifier = new MagnifierModel(this, useLightBg);
            this.getCanvasContainer().appendChild(this.magnifier.reticule);
            this.canvas2d.width = this.getCanvas().width;
            this.canvas2d.height = this.getCanvas().height;
        });
        this.on('resize', () => this.updateMagnifierSizes());
    }

    /**
     * Called on resize of map: Updates dimensions for magnifier. Also updates the magnifier's
     * pixel density ratio in case map was moved between retina and non-retina.
     */
    updateMagnifierSizes() {
        if (this.magnifier) {
            this.magnifier.reticule.width = this.getCanvas().width;
            this.magnifier.reticule.height = this.getCanvas().height;
            this.canvas2d.width = this.getCanvas().width;
            this.canvas2d.height = this.getCanvas().height;
            this.magnifier.devicePixelRatio = window.devicePixelRatio || window.screen.deviceXDPI / window.screen.logicalXDPI;
        }
    }

    /**
     * Runs on dragstart of Stake - Capture 2d snapshot of map for magnifying.
     */
    startMagnifying(stake) {
        this.magnifier.reticule.classList.remove('hidden');
        this.magnifier.stake = stake;
        this.on('zoom', this.zoomMagnifier);

        // Capture the 2d canvas copy once on render (and then force a rerender),
        // So we have an updated snapshot of the current map for zooming.
        // Otherwise webgl will haved dumped the buffer, leaving us with nothing to copy.
        this.once('render', this.copyGlTo2d);
        this.triggerRepaint();
    }

    /**
     * Runs on drag of Stake - get location of stake, pass into magnifier to render.
     */
    magnify(point) {
        this.magnifier.render(point);
    }

    /**
     * Runs on dragend of Stake - cleanup event listeners and resources from active magnifier.
     */
    tearDownMagnifier() {
        this.off('zoom', this.zoomMagnifier);
        this.magnifier.cleanup();
    }

    /*
     * Runs when both magnifier is active and mouse wheelzoom event occurs.
     */
    zoomMagnifier() {
        this.once('render', this.copyGlTo2d);
        this.magnifier.renderZoom();
    }

    /**
     * Copies the webgl map data to a 2d ctx canvas, saved to the mapbox object.
     */
    copyGlTo2d() {
        this.ctx2d.clearRect(0, 0, this.canvas2d.width, this.canvas2d.height);
        this.ctx2d.drawImage(this.getCanvas(), 0, 0);
    }

    // ------------ Mapbox helper methods (some code copied from layerModel) ------------

    /**
     * Sets the basemap using the passed ID. If none provided, sets default basemap.
     */
    setBasemap(Id) {
        const basemapId = Id ? Id : Object.keys(constants.basemaps)[0];
        if (basemapId === '0') {
            this.safeRemoveSource('basemap');
        } else {
            const basemap = constants.basemaps[basemapId];
            this.createSource('basemap', basemap);
            if (!this.getLayer('basemap')) {
                const beforeLayer = this.getStyle().layers[0];
                this.addLayer({
                    id: 'basemap',
                    source: 'basemap',
                    type: basemap.type
                }, beforeLayer && beforeLayer.id);
            }
        }
    }

    /**
     * Shows the survey of the ID provided and sets map bounds to it.
     */
    showSurveyForStaking(Id) {
        const surveyId = Id ? Id : router.params.survey;
        return api.get.tileset(store.surveys[surveyId].baseTilesetId).then(tileset => {
            const tilesetBounds = lngLatsToBounds(latLngsToLngLats(tileset.bounds));
            this.showTileset(tileset);
            this.fitBounds(tilesetBounds, {
                animate: false,
                padding: {top: 20, bottom: 20, left: 20, right: 20}
            });
        });
    }

    /**
     * Readies a tileset for editing (staking, cropping, etc).
     * Using the tileset passed, creates a source and layer and fits the map to the asset.
     */
    setUpEditingLayer(tileset) {
        const tilesetId = tileset.tilesetId;
        const urlTemplate = helpers.URLTemplateFMTToPNG(tileset.urlTemplate);
        this.createRasterSource(tilesetId, urlTemplate + '&color=None', {maxzoom: 24});
        this.addLayer({
            id: tilesetId,
            url: tileset.urlTemplate + '&color=None',
            source: tilesetId,
            type: 'raster'
        });
        const assetBounds = tileset.bounds;
        if (assetBounds) {
            const bounds = lngLatsToBounds(latLngsToLngLats(assetBounds));
            this.fitBounds(bounds, {
                animate: false,
                padding: {top: 20, bottom: 20, left: 20, right: 20}
            });
        }
    }

    /**
     * Creates a layer using the tileset passed, and adds it before the beforeLayer passed.
     */
    showTileset(tileset, beforeLayer) {
        const tilesetId = tileset.tilesetId;
        const urlTemplate = helpers.URLTemplateFMTToPNG(tileset.urlTemplate);

        this.createRasterSource(tilesetId, urlTemplate, {
            maxzoom: 24,
            bounds: tileset.bounds
        });
        if (!beforeLayer) {
            const firstNonRasterLayer = this.getStyle().layers.find(layer => layer.type !== 'raster');
            if (firstNonRasterLayer) {
                beforeLayer = firstNonRasterLayer.id;
            }
        }
        this.addLayer({
            id: tilesetId,
            source: tilesetId,
            type: 'raster'
        }, beforeLayer);
    }

    /**
     * Checks first that the source exists, removes any layers using it,
     * and finally removes the source.
     * @param {*} id - Unique ID for the source to remove.
     */
    safeRemoveSource(id) {
        if (this.getSource(id)) {
            if (this.getLayer(id)) {
                this.removeLayer(id);
            }
            this.removeSource(id);
        }
    }

    /**
     * Adds a new source to the mapbox object.
     * @param {*} id - Unique ID for the new source
     * @param {*} source - Source to add to mapbox object
     */
    createSource(id, source) {
        this.safeRemoveSource(id);
        return this.addSource(id, source);
    }

    /**
     * Creates a new geojson source and adds to the mapbox object.
     * @param {*} id - Unique ID for the new source
     * @param {*} lineMetrics - Boolean, defaults to false
     */
    createGeoJSONSource(id, lineMetrics = false) {
        return this.createSource(id, Object.assign({
            type: 'geojson',
            lineMetrics: lineMetrics,
            data: {
                type: 'FeatureCollection',
                features: []
            }
        }));
    }

    /**
     * Creates a new raster source on the mapbox object.
     * @param {*} id - Unique ID for the new source
     * @param {*} url - URL of the raster source tiles
     * @param {*} opts - Mapbox options to include for the source
     */
    createRasterSource(id, url, opts) {
        if (opts.bounds) {
            opts.bounds = layerModel.flattenBounds(opts.bounds);
        }
        this.createSource(id, Object.assign({
            type: 'raster',
            tiles: [url],
            maxzoom: 24,
            tileSize: 256
        }, opts));
    }

}

export default MapModel;
