import constants from 'util/data/constants';

// Special handling for embed control type (read-only form controls displaying media)
const Embed = {

    image: (state) => <img alt="" class="embed-image" src={state.mediaSrc} />,

    oninit({state, attrs: {control}}) {
        if (control.attributes.type === 'image') {
            const mediaId = control.attributes.mediaId;
            state.mediaSrc = constants.mediaURL + mediaId;
        }
    },

    view({state, attrs: {control}}) {
        return <>
            <div class="clear">
                {!control.attributes.hideLabel && <label>{control.label}</label>}
            </div>
            <div class="embed-control">
                {Embed[control.attributes.type](state)}
            </div>
        </>;
    }
};

export default Embed;
