import Radio from 'views/radio';
import tableModel from 'models/table/table-model';
import sideNavModel from 'models/side-nav-model';
import stopPropagation from 'util/events/stop-propagation';
import toolboxModel from 'models/toolbox-model';

export default {
    view: () => {
        return <div class="export-menu side-nav-popup" onclick={stopPropagation()}>
            <div class="side-nav-popup-title">
                <i class="icon-down-arrow" /> Export
            </div>
            Export the current dataset as:
            <div>
                <Radio defaultOption={sideNavModel.selectedFormat} options={sideNavModel.exportFormats} onchange={opt => {
                    sideNavModel.selectedFormat = opt;
                }}/>
            </div>
            <div class="center">
                {sideNavModel.exporting
                    ? <div class="btn btn-pill btn-small btn-primary disabled">
                        <i class="teal spinner spinning" /> Exporting {
                            toolboxModel.customExports[sideNavModel.selectedFormat]
                                ? ''
                                : `${tableModel.assetCount} record${tableModel.assetCount === 1 ? '' : 's'}`
                        }
                    </div>
                    : <div class="btn btn-pill btn-small btn-primary" onclick={() => sideNavModel.prepExport()}>
                        {sideNavModel.selectedFormat === 'PDF Map' ? 'Select Options' : 'Export'}
                    </div>}
                {sideNavModel.exporting && <a class="export-cancel" onclick={() => sideNavModel.cancelExport()}>Cancel</a>}
            </div>
        </div>;
    }

};
