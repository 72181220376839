import router from 'uav-router';
import layerModel from 'models/layer-model';
import store from 'util/data/store';
import layerColorModel from '../../models/layer-color-model';
import ColorPicker from '../color-picker/color-picker';
import ToggleColorSwitch from '../color-picker/toggle-color-switch';
import formModel from 'models/form-model';

const PlanDetails = {
    onbeforeremove: ({dom}) => {
        dom.classList.remove('visible');
        return new Promise(resolve => setTimeout(resolve, 200));
    },

    oncreate: ({dom}) => {
        requestAnimationFrame(() => dom.classList.add('visible'));
    },

    view: () => {
        const plan = layerModel.focusedPlan;
        const tileset = store.tilesets[plan.tilesetId];
        const starterHex = tileset && tileset.color ? tileset.color.toLowerCase() : '#ffffff';
        return <div class="layer-detail">
            <div class="layer-picker-head">
                <i class="icon-back" onclick={layerModel.togglePlanDetails}/>
                <span class="panel-title">{plan.title}</span>
            </div>
            <div class="layer-scroll">
                <div class={'layer-opts layer-opts-plan ' + (plan.status === 'complete' && tileset.color !== 'None' ? 'color-on' : 'color-off')}>
                    {plan.status === 'complete' && tileset && tileset.size > 0 &&
                    <ToggleColorSwitch tileset={tileset}
                        colorOn={tileset.color !== 'None'}
                        toggleFn={() => layerColorModel.toggleColor(tileset.color, tileset.tilesetId)}/>}
                    {plan.status === 'complete' && tileset && tileset.size
                        ? <ColorPicker
                            containerId="unearth-color-picker-layer-menu"
                            starterColor={starterHex}
                            setColorFn={(hex) => layerColorModel.setColor(hex, tileset.tilesetId)}
                        />
                        : null}
                    <div class="layer-opts-buttons overlay-row">
                        {tileset && tileset.size && tileset.tilesetType !== 'external_vector'
                            ? <div class="menu-option show-on-large" onclick={() => formModel.validateThenRun( () => router.merge({view: 'layer', planId: plan.planId}))}>
                                <span class="btn-label"><i class="icon-edit"/>Edit Layer</span>
                            </div>
                            : null}
                        <div class="menu-option btn-delete-plan" onclick={() => layerModel.deletePlan(plan)}>
                            <span class="btn-label"><i class="icon-trash"/>Delete Layer</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
};

export default PlanDetails;
