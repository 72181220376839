import App from 'views/app';
import socket from 'util/network/socket';
import router from 'uav-router';
import userModel from 'models/user-model';
import formModel from 'models/form-model';
import MenuModel from 'models/menu-model';
import notificationsModel from 'models/notifications-model';

export default {


    oninit: ({state}) => {
        state.menu = new MenuModel({
            onAllMenuClicks: () => m.redraw(),
            items: [{
                itemId: 'notifications',
                text: 'Notification Preferences',
                onClick: () => notificationsModel.open()
            }, {
                itemId: 'password',
                text: 'Change Password',
                onClick: () => formModel.validateThenRun(() => router.merge({view: 'changepassword'}))
            }]
        });
    },

    view: ({state}) => {
        return <div class="me-menu side-nav-popup">
            <div class="padding-wrap">
                <div class="side-nav-popup-title">Hi, {userModel.givenName}.</div>
                <ul class="side-nav-popup-links">
                    {state && state.menu.items.map((item, index) =>
                        <li key={index} class={`side-nav-popup-item ${item.itemId}`}>
                            <a class={`${state.menu.activeItemId === item.itemId ? 'active' : 'inactive'}`} onclick={(e) => state.menu.selectItem(e, index)}>{item.text}</a></li> )}
                </ul>
                <div class="btn btn-pill btn-secondary btn-small" onclick={() => formModel.validateThenRun(() => App.onNextRender(socket.logout))}><span class="btn-label">Sign Out</span></div>
            </div>
        </div>;
    }

};
