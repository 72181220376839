import constants from 'util/data/constants';
import randomId from 'util/numbers/random-id';
import Filepicker from 'util/interfaces/filepicker';
import router from 'uav-router';
import api from 'legacy/util/api';
import userModel from 'models/user-model';
import turfLength from '@turf/length';
import isMetric from 'util/numbers/is-metric';
import area from '@turf/area';
import round from 'util/numbers/round';
import turfLineString from 'turf-linestring';
import measure from 'legacy/util/numbers/measure';
import {getVolume, lngLatsToLatLngs, lngLatsToBounds} from 'util/geo';
import store from 'util/data/store';
import mediaModel from 'models/media-model';
import featureModel from 'models/feature-model';
import assetModel from 'models/asset-model';
import formModel from 'models/form-model';

const controlType = constants.controlTypeNameToId;

function autosave(assetId, controlLabel) {

    formModel.saving[controlLabel] = true;

    assetModel.autosave(assetId);

    m.redraw();

}

const mediaToText = (mediaRecords, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    const text = mediaRecords.map(m => m.label).join(', ');
    if (asset.properties[controlLabel] !== text) {
        asset.properties[controlLabel] = text;
        autosave(assetId, controlLabel);
    }
};

const mediaToURL = ([media], controlLabel, assetId) => {
    const asset = store.assets[assetId];
    const url = constants.mediaURL + media.mediaId;
    if (asset.properties[controlLabel] !== url) {
        asset.properties[controlLabel] = url;
        autosave(assetId, controlLabel);
    }
};

const mediaToFile = (mediaRecords, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    let doSave = !asset.properties[controlLabel];
    asset.mediaIds = asset.mediaIds || [];
    asset.properties[controlLabel] = mediaRecords.map(m => {
        if (!asset.mediaIds.includes(m.mediaId)) {
            doSave = true;
            asset.mediaIds.push(m.mediaId);
        }
        return m.mediaId;
    });
    if (doSave) {
        autosave(assetId, controlLabel);
    }
    return Promise.resolve();
};

const mediaToCoordinates = ([media], controlLabel, assetId) => {
    const asset = store.assets[assetId];
    const feature = mediaModel.getFeature(media.mediaId, assetId),
        coordinates = feature ? feature.geometry.coordinates : Filepicker.getMediaCoordinates(media);
    if (coordinates) {
        const oldPoint = asset.properties[controlLabel],
            oldCoords = oldPoint ? oldPoint.coordinates : [];
        asset.properties[controlLabel] = {
            type: 'Point',
            coordinates
        };
        if (!(oldCoords[0] === coordinates[0] && oldCoords[1] === coordinates[1])) {
            autosave(assetId, controlLabel);
        }
    }
};

const mediaToDate = ([media], controlLabel, assetId) => {
    const asset = store.assets[assetId];
    const captureDate = new Date(media.captureDateTime).getTime();
    if (asset.properties[controlLabel] !== captureDate) {
        asset.properties[controlLabel] = captureDate;
        autosave(assetId, controlLabel);
    }
};

const mediaToLinks = (mediaOrAssets, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    const rpc = [];
    const rpcAfterCreation = [];
    const linkIds = asset.linkIds || [];
    const missingAssetPromises = [];
    const linkToAsset = (assetOne, assetTwo, list) => {
        const assetTwoContentId = assetTwo.contentId;
        const assetOneContentId = assetOne.contentId;
        const assetOnelinkIds = assetOne.linkIds || [];

        if (!assetOnelinkIds.includes(assetTwoContentId)) {
            assetOnelinkIds.push(assetTwoContentId);
        } else {
            return false;
        }

        list.push(['modifyContent', {
            contentId: assetOneContentId,
            linkIds: assetOnelinkIds
        }]);
    };

    const addLink = (assetOne, assetTwo) => {
        const assetTwoContentId = assetTwo.contentId;

        if (!linkIds.includes(assetTwoContentId)) {
            linkIds.push(assetTwoContentId);
        } else {
            return false;
        }
    };

    mediaOrAssets.forEach((mediaOrAsset) => {
        if (mediaOrAsset.recordType === 'media') {
            const media = mediaOrAsset;
            const coordinates = Filepicker.getMediaCoordinates(media),
                tool = mediaModel.getLinkTool(media),
                featureType = tool.featureTypes[0],
                contentId = randomId(),
                featureIds = [];
            if (coordinates) {
                const feature = {
                    type: 'Feature',
                    id: randomId(),
                    featureTypeId: featureType.featureTypeId,
                    geometry: {
                        type: 'Point',
                        coordinates: coordinates
                    },
                    properties: Object.assign({}, featureType.properties, {
                        assetId: contentId,
                        mediaId: media.mediaId,
                        featureTypeId: featureType.featureTypeId
                    })
                };
                featureModel.addFeatures([feature]);
                featureIds.push(feature.id);
            }
            const content = {
                contentId,
                toolId: tool.toolId,
                linkIds: [asset.contentId],
                projectId: router.params.projectId,
                recipientIds: [userModel.userId],
                featureIds,
                properties: {},
                mediaId: media.mediaId,
                mediaIds: [media.mediaId],
                attributes: {
                    toolId: tool.toolId,
                    toolGroupIds: []
                }
            };
            assetModel.addToStore(content);
            sync('filepicker', [media], contentId, featureType);
            rpc.push(['createContent', api.apiSafeContent(content)]);
            addLink(asset, content);
            return;
        }

        missingAssetPromises.push(assetModel.fetch(mediaOrAsset.contentId).then((assetOther) => {
            linkToAsset(assetOther, asset, rpcAfterCreation);
            addLink(asset, assetOther);
        }));
    });

    const buildRequests = () => {
        rpcAfterCreation.push(['modifyContent', {
            contentId: asset.contentId,
            linkIds: linkIds
        }]);
        return api.rpc.requests(rpc).then(() => api.rpc.requests(rpcAfterCreation));
    };

    if (missingAssetPromises.length > 0) {
        return Promise.all(missingAssetPromises).then(() => buildRequests());
    }

    return buildRequests();
};

const mediaToDropdown = ([media], controlLabel, assetId) => {
    const asset = store.assets[assetId];
    if (asset.properties[controlLabel] !== media.mediaId) {
        asset.properties[controlLabel] = media.mediaId;
        autosave(assetId, controlLabel);
    }
};

const mediaToNumber = (mediaRecords, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    if (asset.properties[controlLabel] !== mediaRecords.length) {
        asset.properties[controlLabel] = mediaRecords.length;
        autosave(assetId, controlLabel);
    }
};

const mediaToToggle = (mediaRecords, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    const trueOrFalse = !!mediaRecords.length;
    if (asset.properties[controlLabel] !== trueOrFalse) {
        asset.properties[controlLabel] = trueOrFalse;
        autosave(assetId, controlLabel);
    }
};

const planToFile = (plan, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    const mediaId = plan.document.mediaId;
    if (!(asset.properties[controlLabel] && asset.properties[controlLabel][0] === mediaId)) {
        asset.properties[controlLabel] = [mediaId];
        autosave(assetId, controlLabel);
    }
};

const layerToText = (layer, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    if (asset.properties[controlLabel] !== layer.title) {
        asset.properties[controlLabel] = layer.title;
        autosave(assetId, controlLabel);
    }
};

const planToPlan = (plan, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    if (asset.properties[controlLabel] !== plan.planId) {
        asset.properties[controlLabel] = plan.planId;
        autosave(assetId, controlLabel);
    }
};

const planToURL = (plan, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    const tileset = store.tilesets[plan.tilesetId];
    if (tileset && asset.properties[controlLabel] !== tileset.urlTemplate) {
        asset.properties[controlLabel] = tileset.urlTemplate;
        autosave(assetId, controlLabel);
    }
};

const planToDate = (plan, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    const createdDate = new Date(plan.createdDateTime).getTime();
    if (asset.properties[controlLabel] !== createdDate) {
        asset.properties[controlLabel] = createdDate;
        autosave(assetId, controlLabel);
    }
};

// Project tools are unique in that they produce both a feature record
// and a project record. So when the featureType is linked to a control,
// sometimes the input to the linker is a feature and other times it is
// a project. The following helpers disambiguate the input type so that
// the appropriate strategy for updating the given control is used.

const ifIsProject = linker => (featureOrProject, controlLabel, assetId) =>
    featureOrProject.projectId
        ? linker(featureOrProject, controlLabel, assetId)
        : null;

const ifIsFeature = linker => (featureOrProject, controlLabel, assetId) =>
    featureOrProject.geometry
        ? linker(featureOrProject, controlLabel, assetId)
        : null;

const projectToProject = (projectOrFeature, controlLabel, assetId) => {
    // If the input is a feature, update the site bounds
    if (projectOrFeature.geometry) {
        polygonToProject(projectOrFeature, controlLabel, assetId);
    // If the input is a project, then update the projectId
    } else {
        const asset = store.assets[assetId],
            projectId = projectOrFeature.projectId;
        if (asset.properties[controlLabel] !== projectId) {
            asset.properties[controlLabel] = projectId;
            autosave(assetId, controlLabel);
        }
    }
};

const projectToText = ifIsProject((project, controlLabel, assetId) => {
    const asset = store.assets[assetId],
        name = project.name;
    if (asset.properties[controlLabel] !== name) {
        asset.properties[controlLabel] = name;
        autosave(assetId, controlLabel);
    }
});

const projectToArea = ifIsFeature(polygonToArea);
const projectToLength = ifIsFeature(polygonToLength);
const projectToNumber = ifIsFeature(polygonToLength);
const projectToVolume = ifIsFeature(polygonToVolume);

const pointToCoordinates = (feature, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    let coordinates = feature.geometry.coordinates;
    if (feature.geometry.type.startsWith('Multi')) {
        coordinates = coordinates[0];
    }
    if (coordinates && coordinates.length) {
        const oldPoint = asset.properties[controlLabel],
            oldCoords = oldPoint ? oldPoint.coordinates : [];
        asset.properties[controlLabel] = {
            type: 'Point',
            coordinates
        };
        if (!(oldCoords[0] === coordinates[0] && oldCoords[1] === coordinates[1])) {
            autosave(assetId, controlLabel);
        }
    }
};

const pointToText = (feature, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    let coordinates = feature.geometry.coordinates;
    if (feature.geometry.type.startsWith('Multi')) {
        coordinates = coordinates[0];
    }
    if (coordinates && coordinates.length) {
        asset.properties[controlLabel] = `${coordinates[1]}, ${coordinates[0]}`;
        autosave(assetId, controlLabel);
    }
};

const pointToToggle = (feature, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    const trueOrFalse = !!(feature.geometry && feature.geometry.coordinates && feature.geometry.coordinates.length);
    if (asset.properties[controlLabel] !== trueOrFalse) {
        asset.properties[controlLabel] = trueOrFalse;
        autosave(assetId, controlLabel);
    }
};

const surveyToURL = (survey, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    const tileset = store.tilesets[survey.visibleTilesetId];
    if (tileset) {
        if (asset.properties[controlLabel] !== tileset.urlTemplate) {
            asset.properties[controlLabel] = tileset.urlTemplate;
            autosave(assetId, controlLabel);
        }
    }
};

const surveyToSurvey = (survey, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    if (asset.properties[controlLabel] !== survey.surveyId) {
        asset.properties[controlLabel] = survey.surveyId;
        autosave(assetId, controlLabel);
    }
};

const surveyToDate = (survey, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    const surveyDate = new Date(survey.surveyDateTime).getTime();
    if (asset.properties[controlLabel] !== surveyDate) {
        asset.properties[controlLabel] = surveyDate;
        autosave(assetId, controlLabel);
    }
};

const polylineToLength = (feature, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    const coordinates = feature.geometry.coordinates;
    if (coordinates && coordinates.length > 1) {
        const length = turfLength(
            feature,
            {units: 'meters'}
        );
        if (asset.properties[controlLabel] !== length) {
            asset.properties[controlLabel] = length;
            autosave(assetId, controlLabel);
        }
    }
};

const polylineToText = (feature, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    const metric = isMetric(),
        units = metric ? 'm' : 'ft',
        previousValue = asset.properties[controlLabel];
    if (previousValue && isNaN(Number(previousValue.replace(units, '')))) {
        return;
    }
    const coordinates = feature.geometry.coordinates;
    if (coordinates && coordinates.length > 1) {
        let value = turfLength(feature, {units: 'meters'});
        if (!metric) {
            value = measure.metersToFeet(value);
        }
        const text = round(value) + units;
        if (previousValue !== text) {
            asset.properties[controlLabel] = text;
            autosave(assetId, controlLabel);
        }
    }
};

const polygonToArea = (feature, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    try {
        const areaValue = area(feature);
        if (asset.properties[controlLabel] !== areaValue) {
            asset.properties[controlLabel] = areaValue;
            autosave(assetId, controlLabel);
        }
    } catch (e) {
        // noop
    }
};

function getPerimeterMeters(coordinates) {
    return turfLength(
        turfLineString(coordinates[0]),
        {units: 'meters'}
    );
}

const polygonToLength = (feature, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    try {
        let length;
        if (feature.geometry.type.startsWith('Multi')) {
            const perimeters = feature.geometry.coodinates.map(getPerimeterMeters);
            length = perimeters.reduce((a, b) => a + b);
        } else {
            length = getPerimeterMeters(feature.geometry.coordinates);
        }
        if (asset.properties[controlLabel] !== length) {
            asset.properties[controlLabel] = length;
            autosave(assetId, controlLabel);
        }
    } catch (e) {
        // noop
    }
};

const polygonToText = (feature, controlLabel, assetId) => {
    const asset = store.assets[assetId],
        metric = isMetric(),
        units = metric ? 'm²' : 'ft²',
        previousValue = asset.properties[controlLabel];
    if (previousValue && isNaN(Number(previousValue.replace(units, '')))) {
        return;
    }
    try {
        let value = area(feature);
        if (!metric) {
            value = measure.squareMetersToSquareFeet(value);
        }
        value = round(value) + units;
        if (asset.properties[controlLabel] !== value) {
            asset.properties[controlLabel] = value;
            autosave(assetId, controlLabel);
        }
    } catch (e) {
        // noop
    }
};

const polygonToProject = ifIsFeature((feature, controlLabel, assetId) => {
    if (!store.assets[assetId].updatedDateTime) {
        return;
    }
    let newBoundary = feature.geometry.coordinates[0];
    if (feature.geometry.type.startsWith('Multi')) {
        newBoundary = newBoundary[0];
    }
    const toolInterface = formModel.toolInterface;
    if (newBoundary && toolInterface) {
        const project = toolInterface.project;
        if (!project) {
            return;
        }
        const site = project.sites.items[0];
        let bounds = lngLatsToBounds(newBoundary);
        bounds = [
            [bounds.getNorth(), bounds.getWest()],
            [bounds.getSouth(), bounds.getEast()]
        ];

        api.put.site({
            projectId: project.projectId,
            siteId: site.siteId,
            bounds,
            boundary: {
                type: 'Polygon',
                coordinates: [lngLatsToLatLngs(newBoundary)]
            }
        });
    }
});

const textToParagraph = (feature, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    const text = feature.properties._textField;
    if (asset.properties[controlLabel] !== text) {
        asset.properties[controlLabel] = text;
        autosave(assetId, controlLabel);
    }
};

const textToText = (feature, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    const text = (feature.properties._textField || '').replace(/\n/g, ' ');
    if (asset.properties[controlLabel] !== text) {
        asset.properties[controlLabel] = text;
        autosave(assetId, controlLabel);
    }
};

const polygonToVolume = (feature, controlLabel, assetId) => {
    const asset = store.assets[assetId];
    getVolume(feature).then(volume => {
        volume = Math.max(volume.fill, volume.cut) || undefined;
        if (asset.properties[controlLabel] !== volume) {
            asset.properties[controlLabel] = volume;
            m.redraw();
            autosave(assetId, controlLabel);
        }
    });
};

function sync(interfaceType, input, assetId, featureType) {

    featureType = featureType || store.featureTypes[input.properties.featureTypeId];

    const asset = store.assets[assetId],
        tool = store.tools[asset.attributes.toolId],
        linkedControls = featureType.attributes.linkedControls;

    if (linkedControls) {

        linkedControls.forEach(controlLabel => {

            const control = tool.assetForm.controls.find(c => c.label === controlLabel);

            if (control) {

                const linkInputToControl = featureToControl[interfaceType][control.controlTypeId];

                if (linkInputToControl) {

                    linkInputToControl(input, control.label, assetId);

                }

            }

        });

    }
}

const featureToControl = {
    filepicker: {
        [controlType.links]: mediaToLinks,
        [controlType.coordinates]: mediaToCoordinates,
        [controlType.dropdown]: mediaToDropdown,
        [controlType.file]: mediaToFile,
        [controlType.name]: mediaToText,
        [controlType.number]: mediaToNumber,
        [controlType.paragraph]: mediaToText,
        [controlType.text]: mediaToText,
        [controlType.toggle]: mediaToToggle,
        [controlType.URL]: mediaToURL,
        [controlType.date]: mediaToDate
    },
    plan: {
        [controlType.file]: planToFile,
        [controlType.name]: layerToText,
        [controlType.paragraph]: layerToText,
        [controlType.plan]: planToPlan,
        [controlType.text]: layerToText,
        [controlType.URL]: planToURL,
        [controlType.date]: planToDate
    },
    survey: {
        [controlType.name]: layerToText,
        [controlType.paragraph]: layerToText,
        [controlType.survey]: surveyToSurvey,
        [controlType.text]: layerToText,
        [controlType.URL]: surveyToURL,
        [controlType.date]: surveyToDate
    },
    project: {
        [controlType.name]: projectToText,
        [controlType.paragraph]: projectToText,
        [controlType.text]: projectToText,
        [controlType.project]: projectToProject,
        [controlType.area]: projectToArea,
        [controlType.length]: projectToLength,
        [controlType.number]: projectToNumber,
        [controlType.volume]: projectToVolume
    },
    symbol: {
        [controlType.coordinates]: pointToCoordinates,
        [controlType.name]: pointToText,
        [controlType.paragraph]: pointToText,
        [controlType.text]: pointToText,
        [controlType.toggle]: pointToToggle
    },
    polyline: {
        [controlType.length]: polylineToLength,
        [controlType.name]: polylineToText,
        [controlType.number]: polylineToLength,
        [controlType.paragraph]: polylineToText,
        [controlType.text]: polylineToText
    },
    polygon: {
        [controlType.area]: polygonToArea,
        [controlType.length]: polygonToLength,
        [controlType.name]: polygonToText,
        [controlType.number]: polygonToLength,
        [controlType.paragraph]: polygonToText,
        [controlType.project]: polygonToProject,
        [controlType.text]: polygonToText,
        [controlType.volume]: polygonToVolume
    },
    text: {
        [controlType.name]: textToText,
        [controlType.paragraph]: textToParagraph,
        [controlType.text]: textToText
    },
    sync
};

export default featureToControl;
