import constants from 'util/data/constants';
import {appUrl} from 'util/data/env';

const loaded = {};

function loadGoogle() {

    return loadExternal(`https://maps.googleapis.com/maps/api/js?key=${constants.googleAPIKey}&libraries=places`);

}

function loadChargebee() {

    return loadExternal('https://js.chargebee.com/v2/chargebee.js', {
        'data-cb-site': 'unearth',
        'data-cb-ga-enabled': 'true'
    });

}

function loadExpressionEvaluator() {
    if (window.unearth && window.unearth.evaluator) {
        return Promise.resolve();
    }
    const pathSuffix = constants.unearthLibHash ? `-${constants.unearthLibHash}` : '';
    return constants.isDeployed ? loadExternal(`${appUrl}/lib/unearth${pathSuffix}.js`) : loadExternal(`/lib/unearth${pathSuffix}.js`);
}


function loadExternal(src, addAttributes) {

    return new Promise((resolve, reject) => {

        if (loaded[src]) {

            return resolve(false);

        }

        const script = document.createElement('script');

        if (addAttributes) {

            Object.keys(addAttributes).forEach((key) => {

                const value = addAttributes[key];

                script.setAttribute(key, value);

            });

        }

        script.src = src;

        script.onerror = () => {

            delete loaded[src];

            reject(false);

        };

        script.onload = () => {

            loaded[src] = true;

            resolve(true);

        };

        document.body.appendChild(script);

    });

}

export default {
    loadChargebee,
    loadGoogle,
    loadExternal,
    loadExpressionEvaluator
};
