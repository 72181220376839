import userModel from 'models/user-model';
import accountModel from 'models/account-model';
import peopleModel from 'models/people/people-model';
import {ProfileCard} from 'views/people/profile';

const PeopleList = {

    onremove: () => {
        accountModel.menu.resetActive();
    },

    view: () => {
        const length = peopleModel.list.length;
        return <>
            <div class="people-list-header">
                <div class="people-list-title">People</div>
                {userModel.isAccountAdmin && <button class="btn btn-secondary btn-pill btn-smaller btn-add-person" onclick={() => peopleModel.openNewPerson()}><i class="icon-plus"/><i class="icon-user"/></button>}
            </div>
            <span class="full-width-label">There {length !== 1 ? `are ${length} people` : `is ${length} person`} on this account.</span>
            <div class="user-list">
                {peopleModel.list.map(userId =>
                    <ProfileCard person={peopleModel.getPerson(userId)}/>)}
            </div>
        </>;
    }
};

export default PeopleList;
