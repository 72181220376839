import api from 'legacy/util/api';
import router from 'uav-router';
import userModel from 'models/user-model';
import accountModel from 'models/account-model';
import peopleModel from 'models/people/people-model';
import initializer from 'util/initializer';
import store from 'util/data/store';
import appModel from 'models/app-model';
import hasElevationData from 'legacy/util/data/has-elevation-data';
import FeatureMenu from 'views/feature-menu';
import assetModel from 'models/asset-model';
import popup from 'util/popup';
import mapboxgl from 'mapbox-gl';
import debounce from 'util/events/debounce';
import {latLngsToLngLats, lngLatsToBounds, queryFeatures} from 'util/geo';
import toolboxModel from 'models/toolbox-model';
import styleModel from 'models/style-model';
import formModel from 'models/form-model';
import AssetForm from 'views/asset-form';
import layerModel from 'models/layer-model';
import loaderModel from 'models/loader-model';
import MapModel from './map-model';
import {mapLongPress} from 'util/dom/mapbox-events';
import featureModel from 'models/feature-model';
import Table from 'views/table/table';
import publish from 'legacy/util/api/publish';
import helpers from 'legacy/util/api/helpers';
import oneUp from 'views/one-up/one-up';
import screenHelper from 'legacy/util/device/screen-helper';
import tableModel from 'models/table/table-model';
import {metersToPixels} from 'util/geo';
import capitalize from 'util/data/capitalize';
import exportPDFModel from 'models/export-pdf-model';
import notificationsModel from 'models/notifications-model';
import placeModel from 'models/place-model';

initializer.add(() => siteModel.cleanup());

const SIDEBAR_WIDTH = 360,
    TABLE_HEIGHT = 383;

const siteModel = {

    cleanup() {
        Object.assign(siteModel, {
            name: '',
            hasElevationData: false,
            inspector: null,
            sidebar: Table,
            view: null,
            hoveringMediaId: null,
            hoveringFeatureIds: [],
            bounds: null,
            isMetaProject: false,
            canBatchModify: false,
            featuresLoadedCallbacks: []
        });

    },

    leftClick(features, lngLat, placeIds = []) {

        if (appModel.isDrawing || features.length === 0 && placeIds.length === 0) {

            popup.remove();

            return Promise.resolve();

        }

        let assetIds = {};
        const promises = [];

        features.forEach(feature => {

            assetIds[feature.properties.assetId] = true;

        });

        assetIds = Object.keys(assetIds);

        assetIds.forEach(assetId => {

            promises.push(assetModel.fetch(assetId));

        });

        if (promises.length || placeIds.length > 1) {

            return Promise.all(promises).then(() => popup.add({
                className: 'feature-menu-popup',
                content: <FeatureMenu
                    assetIds={assetIds}
                    placeIds={placeIds}
                    features={features}
                    lngLat={lngLat}
                />,
                lngLat,
                maxWidth: 'none'
            }));

        } else if (placeIds.length === 1) {

            placeModel.showPlacePopups(placeIds, lngLat);

        }

        return Promise.resolve();

    },

    rightClick(lngLat) {
        if (popup.isOpen()) {
            popup.remove();
            return;
        }
        if (layerModel.state.surveyId
            && store.surveys[layerModel.state.surveyId]
            && store.surveys[layerModel.state.surveyId].hasElevationData) {
            api.rpc.request([['getSurveyElevation', {
                'surveyId': layerModel.state.surveyId,
                'geometry': {
                    'type': 'Point',
                    'coordinates': lngLat.toArray()
                }
            }]]).then((elevationData) => {
                const elevation = elevationData.values ? elevationData.values[0] : undefined;
                popup.add({
                    className: 'feature-menu-popup',
                    content: <FeatureMenu lngLat={lngLat} elevation={elevation}/>,
                    lngLat,
                    maxWidth: 'none'
                });
            });
            return;
        }
        popup.add({
            className: 'feature-menu-popup',
            content: <FeatureMenu lngLat={lngLat}/>,
            lngLat,
            maxWidth: 'none'
        });
    },

    /**
     * Fetches site data, sends to store, and initializes
     * main site map with default behaviors.
     */
    fetch: () => {
        const projectId = router.params.projectId;

        siteModel.isMetaProject = store.account.attributes.metaProjectId === projectId;

        appModel.setState('editingProjectId', projectId);

        const rpc = [
            ['getProject', {projectId}],
            ['listPlaces', {projectId, limit: 0}],
            ['listSurveys', {projectId, limit: 0}],
            ['listPlans', {projectId, limit: 0}],
            ['listTilesets', {projectId, limit: 0}]
        ];

        if (siteModel.isMetaProject && !userModel.projectIds && !userModel.isSuperAdmin) {
            // gets the list of projects that this user has access to
            rpc.push(['listProjects', {
                isVisible: true,
                limit: 0,
                accountId: store.account.accountId
            }]);
        }

        const requests = api.rpc.requests(rpc).then(([
            project, places, surveys, plans, tilesets, projects
        ]) => {

            if (projects) {
                userModel.projectIds = projects.map(p => p.projectId);
            }

            project.attributes = project.attributes || {};
            project.sites = helpers.list(project.sites);
            project.users = helpers.list(project.users);
            project.attributes = project.attributes || {};

            let site = project.sites[0];
            const siteId = router.params.siteId;

            if (siteId) {
                site = project.sites.find(s => s.siteId === siteId) || site;
            } else {
                router.url.mergeReplace({siteId: site.siteId});
            }

            document.title = siteModel.isMetaProject
                ? store.account.name
                : project.name || 'Unearth';

            loaderModel.updateMessage(`Loading ${document.title}...`);

            project.users = helpers.list(project.users);
            project.sites = helpers.list(project.sites);

            const storePlaces = {};

            helpers.list(places).forEach(place => {

                place.boundary.coordinates = [latLngsToLngLats(place.boundary.coordinates[0])];

                storePlaces[place.placeId] = place;

            });

            store.setMutableContainer('places', storePlaces);

            const storeSurveys = {};

            surveys.forEach(survey => {

                storeSurveys[survey.surveyId] = survey;

            });

            store.setMutableContainer('surveys', storeSurveys);

            const storePlans = {};

            const planZOrder = project.attributes && project.attributes.planZOrder || [];

            // planZOrder is stored in bottom-to-top order,
            // but it's much easier to use as top-to-bottom,
            // so we'll keep it reversed in memory.
            planZOrder.reverse();

            plans.forEach(plan => {

                storePlans[plan.planId] = plan;

                if (planZOrder.indexOf(plan.planId) === -1) {

                    planZOrder.push(plan.planId);

                }

            });

            store.setMutableContainer('plans', storePlans);

            project.attributes.planZOrder = planZOrder.filter(planId => planId);

            store.setMutableContainer('project', project);

            peopleModel.onSiteFetch();

            const storeTilesets = {};

            tilesets.forEach(tileset => {
                storeTilesets[tileset.tilesetId] = tileset;
            });

            store.setMutableContainer('tilesets', storeTilesets);

            const elevationTilesetsExist = !!surveys.find(s => s.hasElevationData);

            siteModel.hasElevationData = feature => {

                if (feature) {

                    return Object.values(store.surveys).find(survey => hasElevationData(survey, feature));

                }

                return elevationTilesetsExist;

            };

            siteModel.setLastVisitedSite();

            siteModel.setSiteBounds(site);

            // Meta project-specific project adjustments
            if (siteModel.isMetaProject) {
                siteModel.setMetaProjectOverrides();
            }

            m.redraw();

        }).catch(error => {

            console.error(error);

        });

        return requests;

    },

    _removeLoadingClass: debounce(() => {

        m.redraw();

        setTimeout(() => document.body.classList.remove('loading'), 2000);

    }, 1000),

    removeLoadingClass: () => {

        if (siteModel.map.loaded()) {

            if (siteModel.featuresAreLoaded() && exportPDFModel.isDoneLoading()) {

                siteModel._removeLoadingClass();

            }

        } else {

            setTimeout(() => siteModel.removeLoadingClass(), 500);

        }

    },

    featuresAreLoaded() {

        if (siteModel.map.loaded()
            && !featureModel.streams.length
            && (siteModel.isMetaProject || featureModel.loadedBoundary)
            && featureModel.featureCount !== undefined
            && Object.keys(store.features).length >= featureModel.featureCount) {

            siteModel.featuresLoadedCallbacks.forEach(cb => cb());

            siteModel.featuresLoadedCallbacks = [];

            return true;

        }

    },

    onFeaturesLoaded(callback) {
        siteModel.featuresLoadedCallbacks.push(callback);
    },

    init: () => {

        loaderModel.load();

        let map;

        oneUp.init();

        return siteModel.fetch().then(() => {

            siteModel.mapModel = new MapModel({container: 'mapbox'}, {basemapOff: true});

            siteModel.map = siteModel.mapModel;

            map = siteModel.map;

            siteModel.center = siteModel.map.getCenter();

            map.fitBounds(siteModel.bounds, {
                animate: false
            });

            mapLongPress(map, ({lngLat}) => siteModel.rightClick(lngLat));

            map.on('contextmenu', ({lngLat}) => siteModel.rightClick(lngLat));

        }).then(() => new Promise(resolve => {

            if (map.isStyleLoaded()) {

                resolve();

            } else {

                map.once('styledata', () => {

                    resolve();

                });

            }

        })).then(() => toolboxModel.fetch())
            .then(() => {

                placeModel.addPlaceControls();

                siteModel.addBoundsToPlaces();

                siteModel.isWaitingForIdle = false;
                siteModel.canBatchModify = userModel.isAccountAdmin && siteModel.toolboxFeatures && siteModel.toolboxFeatures['Batch modify'];

                map.on('dataloading', () => document.body.classList.add('loading'))
                    .on('data', e => e.isSourceLoaded && siteModel.removeLoadingClass())
                    .on('moveend', () => featureModel.onMove());

                layerModel.initFromURL();

                const assetId = router.params.assetId || router.params.contentId;

                if (assetId) {

                    assetModel.fetch(assetId)
                        .then(asset => {
                            if (asset && asset.isVisible) {
                                formModel.viewAsset(assetId);
                            } else {
                                router.url.remove('assetId', 'contentId');
                            }
                        });
                }

                siteModel.camera = siteModel.map.getCamera();
                siteModel.awaitChanges();
                siteModel.map.on('moveend', () =>  siteModel.onceIdle());

                // Check for deep link to notification preferences modal
                const manage = router.params.manage;
                if (manage && manage === 'notifications') {
                    notificationsModel.open();
                }

            }).finally(() => loaderModel.hide());
    },

    // Overrides to update meta project-specific data after fetch and project init
    setMetaProjectOverrides() {
        siteModel.name = store.account.name;
    },

    awaitChanges() {
        publish.await({
            changeType: 'modified',
            recordType: 'project',
            test: change =>  {
                return change.projectId === router.params.projectId;
            },
            callback: change => {
                store.setContainerValue(store.project, change.projectId, change);
            },
            persist: true
        });
    },

    changeMapClick(mapClick) {

        formModel.stopEdit();

        siteModel.removeSiteMapClick();

        siteModel.map.on('click', mapClick);

        siteModel.mapClick = mapClick;

    },

    removeSiteMapClick() {

        const mapClick = siteModel.mapClick || siteModel._siteMapClick;

        siteModel.map.off('click', mapClick);

        delete siteModel.mapClick;

    },

    siteMapClick() {

        siteModel.map.on('click', siteModel._siteMapClick);

    },

    _siteMapClick({point, lngLat}) {

        const allFeatures = queryFeatures(point, undefined, (feature) => feature.properties._id
            || feature.properties._placeId);

        const features = [];
        const placeIds = [];

        allFeatures.forEach((feature) => {
            const {properties} = feature;
            if (properties._id) {
                features.push(feature);
            } else if (properties._placeId) {
                placeIds.push(properties._placeId);
            }
        });

        if (siteModel.sidebar === AssetForm) {
            formModel.mapClick(features, lngLat);
        } else if (!toolboxModel.toolInterface) {
            siteModel.leftClick(features, lngLat, placeIds);
        }
    },

    /**
     * Captures the current camera data of the site map and saves it to the projectView.
     */
    saveMapCamera: () => {
        siteModel.camera = siteModel.map.getCamera();
        userModel.mergePreferences( {
            sitePreferences: {
                [siteModel.siteId]: {camera: siteModel.camera}
            }
        });
    },

    /**
     * Returns true if site map changed enough to warrant save.
     */
    mapCameraChanged: () =>  {
        return !siteModel.map.getBounds().contains(siteModel.camera.center)
            || Math.abs(siteModel.map.getZoom() - siteModel.camera.zoom) > 3;
    },

    /**
     * After the map has moved and is idle, check if we've moved far enough to warrant a save of our map camera, but only save once per moveend+idle (rather than per moveend).
     */
    onceIdle: () => {
        if (!siteModel.isWaitingForIdle) {
            siteModel.isWaitingForIdle = true;
            siteModel.map.once('idle', () => {
                siteModel.isWaitingForIdle = false;
                if (siteModel.mapCameraChanged()) {
                    siteModel.saveMapCamera();
                }
            });
        }
    },

    addBoundsToPlaces() {
        store.setContainerValue(store.places, 'siteBounds', {
            name: capitalize(toolboxModel.projectSingular) + ' Bounds',
            placeId: 'siteBounds',
            levels: [],
            boundary: {
                coordinates: siteModel.boundary.coordinates
            }
        });
    },

    setSiteBounds: (site = siteModel) => {

        Object.assign(siteModel, site);

        siteModel.bounds = latLngsToLngLats(siteModel.bounds);

        if (router.params.nw && router.params.se) {

            siteModel.bounds = new mapboxgl.LngLatBounds(
                router.params.nw.split(',').map(Number),
                router.params.se.split(',').map(Number)
            );

        } else {

            siteModel.bounds = lngLatsToBounds(siteModel.bounds);

        }

        if (!siteModel.boundary) {

            siteModel.boundary = {
                type: 'Polygon',
                coordinates: [[
                    siteModel.bounds.getNorthWest().toArray(),
                    siteModel.bounds.getNorthEast().toArray(),
                    siteModel.bounds.getSouthEast().toArray(),
                    siteModel.bounds.getSouthWest().toArray(),
                    siteModel.bounds.getNorthWest().toArray()
                ]]
            };

        }

        siteModel.addBoundsToPlaces();

        styleModel.stylesheet.center = siteModel.bounds.getCenter().toArray();
    },

    /**
     * Only update userPreferences if no lastVisitedSite exists or if it's different than the current site
     */
    setLastVisitedSite: () => {
        if (!userModel.preferences.lastVisitedSite
            || userModel.preferences.lastVisitedSite.siteId !== router.params.siteId) {
            userModel.mergePreferences({
                lastVisitedSite: {
                    siteId: router.params.siteId,
                    projectId: router.params.projectId
                }
            });
        }
        // These can get out of sync if site is visited directly via url.
        if (store.account.accountId !== store.project.accountId) {
            accountModel.setAccount(store.accounts[store.project.accountId]);
        }
    },

    focusOnBounds(targetBounds) {

        const panOptions = {duration: 1500, padding: {}},
            map = siteModel.map;

        let currentBounds = map.getBounds();

        const swPx = map.project(currentBounds.getSouthWest());

        if (screenHelper.large()) {
            if (tableModel.tableMode === 'list-left' || siteModel.sidebar === AssetForm) {
                panOptions.padding = {left: SIDEBAR_WIDTH};
                swPx.x += SIDEBAR_WIDTH;
            } else if (tableModel.tableMode === 'table-bottom') {
                panOptions.padding = {bottom: TABLE_HEIGHT};
                swPx.y -= TABLE_HEIGHT;
            }
            currentBounds = new mapboxgl.LngLatBounds(
                map.unproject(swPx).wrap(),
                currentBounds.getNorthEast()
            );
        }

        const currentCenter = currentBounds.getCenter(),
            newCenter = targetBounds.getCenter(),
            currentZoom = map.getZoom(),
            distanceMeters = newCenter.distanceTo(currentCenter),
            distancePx = metersToPixels(distanceMeters, currentCenter.lat, currentZoom),
            animationOptions = {
                offset: [
                    (panOptions.padding.left || 0) / 2,
                    -(panOptions.padding.bottom || 0) / 2
                ],
                duration: 500
            };

        if (distancePx < 5) {

            map.zoomIn(animationOptions);

        } else {

            const {minX, maxX, minY, maxY} = targetBounds;

            // If the shape is a point, pan without zooming
            if (minX === maxX && minY === maxY) {
                map.panTo([minX, minY], animationOptions);
            } else {
                map.fitBounds([[minX, minY], [maxX, maxY]], panOptions);
            }

        }
    }

};


export default siteModel;
