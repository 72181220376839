import siteModel from 'models/site-model';
import onBodyClick from 'legacy/util/dom/on-body-click';
import pointMenu from 'legacy/components/point-menu';
import constants from 'util/data/constants';
import router from 'uav-router';
import api from 'legacy/util/api';
import dialogModel from 'models/dialog-model';
import message from 'legacy/components/message';
import colors from 'legacy/util/data/colors';
import publish from 'legacy/util/api/publish';
import featureModel from 'models/feature-model';
import debounce from 'util/events/debounce';
import userModel from 'models/user-model';
import store from 'util/data/store';
import GeometryDetail from 'views/geometry-detail';
import initializer from 'util/initializer';
import stakeableModel from 'models/stakeable-model';
import layerColorModel from 'models/layer-color-model';
import tableModel from 'models/table/table-model';
import deepCloneObject from 'util/data/deep-clone-object';
import helpers from 'legacy/util/api/helpers';
import placesTabModel from 'models/places-tab-model';
import popup from 'util/popup';

initializer.add(() => {

    Object.assign(layerModel.state, {
        basemapId: Object.keys(constants.basemaps)[0],
        surveyId: undefined,
        doShowPlaces: false,
        doShowLinks: false,
        planTilesetIds: new Set(),
        placeIds: new Set()
    });

    layerModel.isOpen = false;

});

const _saveState = debounce(() => {

    const jsonSafeState = {};

    Object.keys(layerModel.state).forEach(key => {

        let stateValue = layerModel.state[key];

        if (stateValue instanceof Set) {

            stateValue = Array.from(stateValue);

        }


        jsonSafeState[key] = stateValue;

    });

    userModel.mergePreferences({
        sitePreferences: {
            [siteModel.siteId]: jsonSafeState
        }
    });

    router.url.mergeReplace({userId: userModel.userId});
}, 1000);

const layerModel = {

    state: {},

    expanded: {},

    saveState: () => layerModel.initialized && _saveState(),

    removeSource(id) {

        if (siteModel.map.getSource(id)) {

            if (siteModel.map.getLayer(id)) {

                siteModel.map.removeLayer(id);

            }

            siteModel.map.removeSource(id);

        }

    },

    closeLayerPanel: () => {
        if (!layerModel.isOpen) {
            return;
        }

        const el = document.querySelector('.layer-control.mapboxgl-ctrl.mapboxgl-ctrl-group');
        if (el) {
            el.click();
        }
    },

    createSource(id, source) {

        layerModel.removeSource(id);

        return siteModel.map.addSource(id, source);

    },

    flattenBounds(bounds) {

        const n = bounds[0][0],
            w = bounds[0][1],
            s = bounds[1][0],
            e = bounds[1][1];
        return [w, s, e, n];

    },

    createRasterSource(id, url, opts = {}) {

        const bounds = opts.bounds;

        if (bounds) {
            opts.bounds = this.flattenBounds(bounds);
        } else {
            delete opts.bounds;
        }

        layerModel.createSource(id, Object.assign({
            type: 'raster',
            tiles: [url],
            maxzoom: 24,
            tileSize: 256
        }, opts));

    },

    createGeoJSONSource(id, lineMetrics = false) {

        return layerModel.createSource(id, {
            type: 'geojson',
            lineMetrics,
            data: {
                type: 'FeatureCollection',
                features: []
            }
        });

    },

    showSurveyTileset(survey, tileset) {

        const state = layerModel.state,
            surveyLayerIndex = state.basemapId === '0' ? 0 : 1,
            beforeLayer = siteModel.map.getStyle().layers[surveyLayerIndex];

        const urlTemplate = helpers.URLTemplateFMTToPNG(tileset.urlTemplate);

        layerModel.createRasterSource('survey', urlTemplate, {
            bounds: tileset.bounds
        });

        siteModel.map.addLayer({
            id: 'survey',
            source: 'survey',
            type: 'raster'
        }, beforeLayer && beforeLayer.id);

        state.surveyId = survey.surveyId;

        if (survey.hasElevationData) {

            GeometryDetail.update();

        }

    },

    setSurvey(surveyId) {

        if (siteModel.map.getLayer('survey')) {

            siteModel.map.removeLayer('survey');

        }

        const state = layerModel.state;

        if (surveyId && store.surveys[surveyId]) {

            const survey = store.surveys[surveyId];

            let tileset = store.tilesets[survey.visibleTilesetId];

            if (tileset) {

                this.showSurveyTileset(survey, tileset);

            } else {

                api.rpc.get('Tileset', survey.visibleTilesetId).then(_tileset => {

                    tileset = _tileset;

                    if (_tileset) {

                        store.tilesets[survey.visibleTilesetId] = _tileset;

                        this.showSurveyTileset(survey, _tileset);

                    }

                });

            }

        } else {

            state.surveyId = undefined;

        }

        layerModel.saveState();

    },

    setBasemap(basemapId, shouldSave = true) {
        if (basemapId === '0') {

            layerModel.removeSource('basemap');

        } else {

            const basemap = constants.basemaps[basemapId];

            layerModel.createSource('basemap', basemap);

            if (!siteModel.map.getLayer('basemap')) {

                const beforeLayer = siteModel.map.getStyle().layers[0];

                siteModel.map.addLayer({
                    id: 'basemap',
                    source: 'basemap',
                    type: basemap.type
                }, beforeLayer && beforeLayer.id);

            }

        }

        layerModel.state.basemapId = basemapId;

        if (shouldSave) {

            layerModel.saveState();

        }

    },

    toggleLinks() {

        if (tableModel.loadingTable) {
            return;
        }

        layerModel.state.doShowLinks = !layerModel.state.doShowLinks;

        featureModel.search(layerModel.state.doShowLinks);

        layerModel.saveState();

    },

    togglePicker() {

        layerModel.isOpen = !layerModel.isOpen;


        if (layerModel.isOpen) {

            onBodyClick.once(() => {

                if (!dialogModel.isOpen) {

                    layerModel.isOpen = false;

                    layerModel.layerControl._container.classList.remove('active');

                    m.redraw();
                } else {

                    // Dialog was open, so reset the body click toggle event:
                    onBodyClick.once(() =>  {
                        layerModel.isOpen = false;
                        layerModel.togglePicker();
                    });

                }

            });

        }

        m.redraw();

    },

    showTileset(tileset, beforeLayer, bounds) {

        const tilesetId = tileset.tilesetId;
        const urlTemplate = helpers.URLTemplateFMTToPNG(tileset.urlTemplate);

        layerModel.createRasterSource(tilesetId, urlTemplate, {
            maxzoom: 24,
            bounds
        });

        if (!beforeLayer) {

            const firstNonRasterLayer = siteModel.map.getStyle().layers.find(layer => layer.type !== 'raster');

            if (firstNonRasterLayer) {

                beforeLayer = firstNonRasterLayer.id;

            }

        }

        siteModel.map.addLayer({
            id: tilesetId,
            source: tilesetId,
            type: 'raster'
        }, beforeLayer);

    },

    getPlanIcon(plan) {

        if (plan.status === 'complete') {

            return layerModel.state.planTilesetIds.has(plan.tilesetId) ? 'toggle-switch toggle-is-on' : 'toggle-switch toggle-is-off';

        }

        return 'icon-triangle-exclaim';

    },

    showPlan(plan) {

        const state = layerModel.state;

        if (!state.planTilesetIds.has(plan.tilesetId)) {

            const planZOrder = Array.from(store.project.attributes.planZOrder).reverse(),
                planIndex = planZOrder.indexOf(plan.planId);

            let beforeLayer;

            if (planIndex < planZOrder.length - 1) {

                const nextVisiblePlanId = planZOrder
                    .slice(planIndex + 1)
                    .find(planId => {
                        const p = store.plans[planId];
                        return p && state.planTilesetIds.has(p.tilesetId);
                    });

                const nextVisiblePlan = nextVisiblePlanId && store.plans[nextVisiblePlanId];

                beforeLayer = nextVisiblePlan && nextVisiblePlan.tilesetId;
            }

            const tileset = store.tilesets[plan.tilesetId];

            if (!tileset) { // This is the case when the plan has not been processed yet

                return;

            }

            layerModel.showTileset(tileset, beforeLayer, tileset.bounds);

            state.planTilesetIds.add(plan.tilesetId);

            layerModel.saveState();

        }

        state.doShowPlans = true;

    },

    hidePlan(plan) {

        const state = layerModel.state;

        if (state.planTilesetIds.has(plan.tilesetId)) {

            if (siteModel.map.getLayer(plan.tilesetId)) {
                siteModel.map.removeLayer(plan.tilesetId);
            }

            state.planTilesetIds.delete(plan.tilesetId);

            layerModel.saveState();

        }

    },

    togglePlan(plan) {

        if (plan.status === 'complete') {

            const doShow = !layerModel.state.planTilesetIds.has(plan.tilesetId);

            const layer = siteModel.map.getLayer(plan.tilesetId);

            if (doShow && !layer) {

                layerModel.showPlan(plan);

            } else if (!doShow && layer) {

                layerModel.hidePlan(plan);

            }

        } else {

            layerModel.togglePlanDetails(plan);

        }

    },


    hideAllLayers() {

        const state = layerModel.state,
            plans = Object.values(store.plans);

        state.doShowPlans = false;

        plans.forEach(layerModel.hidePlan);

        if (state.doShowLinks) {
            layerModel.toggleLinks();
        }

        layerModel.hidePlaces();

        layerModel.setSurvey();

    },

    togglePlans() {

        const state = layerModel.state,
            plans = Object.values(store.plans);

        state.doShowPlans = !state.doShowPlans;

        if (state.doShowPlans) {

            plans.forEach(layerModel.showPlan);

        } else {

            plans.forEach(layerModel.hidePlan);

        }

    },

    turnAllPlacesOn() {
        const source = siteModel.map.getSource('places'),
            features = source._data.features,
            state = layerModel.state;

        Object.values(store.places).forEach((place)=> {

            if (!state.placeIds.has(place.placeId)) {
                features.push(featureModel.getPlaceFeature(place));
                state.placeIds.add(place.placeId);
            }
        });

        source.setData(source._data);
        layerModel.showPlaces();
    },

    turnOnPlace(place) {
        if (!layerModel.state.placeIds.has(place.placeId)) {
            layerModel.togglePlace(place);
        }
    },

    togglePlace(place) {
        const source = siteModel.map.getSource('places'),
            features = source._data.features,
            placeId = place.placeId,
            state = layerModel.state;

        if (state.placeIds.has(placeId)) {

            const index = features.findIndex(feature => feature.id === placeId);

            features.splice(index, 1);

            state.placeIds.delete(placeId);

            popup.remove();

        } else {

            features.push(featureModel.getPlaceFeature(place));

            state.placeIds.add(placeId);

            if (!state.doShowPlaces) {

                layerModel.showPlaces();

            }

        }

        source.setData(source._data);

        layerModel.saveState();

    },

    showPlaces() {

        layerModel.state.doShowPlaces = true;

        if (!siteModel.map.getLayer('places')) {

            const beforeLayer = siteModel.map.getStyle().layers.find(layer => layer.type !== 'raster');

            siteModel.map.addLayer({
                'id': 'places',
                'source': 'places',
                type: 'fill',
                paint: {
                    'fill-color': '#70bfbf',
                    'fill-opacity': 0
                }
            }, beforeLayer && beforeLayer.id);

            siteModel.map.addLayer({
                'id': 'places-border',
                'source': 'places',
                'type': 'line',
                'paint': {
                    'line-color': '#70bfbf',
                    'line-width': 3,
                    'line-dasharray': [
                        2,
                        1
                    ]
                }
            }, 'places');

        }

    },

    hidePlaces() {

        layerModel.state.doShowPlaces = false;

        if (siteModel.map.getLayer('places')) {

            siteModel.map.removeLayer('places');

            siteModel.map.removeLayer('places-border');

        }

    },

    togglePlaces() {

        const source = siteModel.map.getSource('places'),
            data = source._data,
            state = layerModel.state;

        state.placeIds.clear();

        if (state.doShowPlaces) {

            data.features = [];

            layerModel.hidePlaces();

            popup.remove();

        } else {

            data.features = Object.values(store.places).map(place => {

                state.placeIds.add(place.placeId);

                return featureModel.getPlaceFeature(place);

            });

            layerModel.showPlaces();

        }

        source.setData(data);

        layerModel.saveState();

    },

    expandContract(e, key) {

        e.stopPropagation();

        layerModel.expanded[key] = !layerModel.expanded[key];

    },

    togglePlanDetails(plan, e) {

        if (e) {

            e.stopPropagation();

        }

        if (layerModel.focusedPlan) {

            layerModel.focusedPlan = null;

        } else {

            layerModel.focusedPlan = plan;

        }

    },

    // modifySurvey(survey) {

    //     api.rpc.modify('Survey', survey);

    //     modal.hide();

    // },

    deleteSurveyDialog(survey) {

        pointMenu.close();

        dialogModel.open({
            headline: 'Delete this survey?',
            text: 'Please note that this operation cannot be undone.',
            yesClass: 'btn btn-pill btn-red',
            noText: 'Cancel',
            noClass: 'btn btn-pill btn-secondary',
            onYes: () => {

                layerModel.state.surveyId = null;

                api.rpc.requests([
                    ['deleteSurvey', {surveyId: survey.surveyId}],
                    ['deleteTileset', {tilesetId: survey.baseTilesetId}],
                    ['deleteTileset', {tilesetId: survey.elevationTilesetId}],
                    ['deleteTileset', {tilesetId: survey.visibleTilesetId}]
                ]);

                message.show('Survey deleted.');

            }
        });

    },

    setTilesetColor(color) {

        const tileset = deepCloneObject(store.tilesets[layerModel.focusedPlan.tilesetId]);

        color = colors.byName[color] || null;

        tileset.color = color;
        tileset.defaultColor = color;

        api.patch.tileset({
            tilesetId: tileset.tilesetId,
            color: color,
            defaultColor: color
        });

        store.setContainerValue(store.tilesets, tileset.tilesetId, tileset);

    },

    deletePlan(plan) {

        dialogModel.open({
            headline: 'Delete plan layer?',
            text: 'Please note that this operation cannot be undone.',
            yesText: 'Delete',
            yesClass: 'btn btn-pill btn-red',
            noText: 'Cancel',
            noClass: 'btn btn-pill btn-secondary',
            cssClass: 'layer-menu-stop-prop',
            onYes: () => {

                if (layerModel.state.planTilesetIds.has(plan.tilesetId)) {

                    layerModel.hidePlan(plan);

                }

                const project = store.project,
                    requests = [
                        ['deletePlan', {planId: plan.planId}]
                    ];

                if (plan.tilesetId) {

                    requests.push(['deleteTileset', {tilesetId: plan.tilesetId}]);

                }

                const planZIndex = project.attributes.planZOrder.indexOf(plan.planId);

                if (planZIndex !== -1) {

                    project.attributes.planZOrder.splice(planZIndex, 1);

                    requests.push(['modifyProject', {
                        projectId: project.projectId,
                        attributes: Object.assign({}, project.attributes, {
                            planZOrder: Array.from(project.attributes.planZOrder).reverse()
                        })
                    }]);

                }

                api.rpc.requests(requests);

                message.show('Plan deleted.');

                layerModel.focusedPlan = null;

            },

            onNo: (e) => {
                e.stopPropagation(); // Prevent layer menu from closing
            }
        });

    },

    savePlanOrder(planId) {

        const project = store.project;

        const planZOrder = Array.from(project.attributes.planZOrder).filter(p => p);

        // planZOrder is stored from bottom to top,
        // but displayed in the picker as top-to-bottom.
        planZOrder.reverse();

        api.rpc.modify('Project', {
            projectId: project.projectId,
            attributes: Object.assign({}, project.attributes, {
                planZOrder
            })
        });

        const plan = store.plans[planId];

        if (plan && layerModel.state.planTilesetIds.has(plan.tilesetId)) {

            layerModel.hidePlan(plan);

            layerModel.showPlan(plan);

        }

    },

    awaitLayerChanges() {

        publish.await({
            changeType: 'new',
            recordType: 'survey',
            callback: survey => {

                store.surveys[survey.surveyId] = survey;

                m.redraw();

            },
            persist: true
        });

        publish.await({
            changeType: 'modified',
            recordType: 'survey',
            callback: survey => {

                store.surveys[survey.surveyId] = survey;

                const surveyId = survey.surveyId;

                if (siteModel.processingSurvey === surveyId) {

                    // Check that we're 1) On survey view and 2) on the right survey
                    if (router.params.view === 'survey' && router.params.survey === surveyId) {
                        stakeableModel.handleUpdatedAsset();
                    }

                    siteModel.processingSurvey = null;
                }

                layerModel.removeSource(survey.visibleTilesetId);

                requestAnimationFrame(() => {

                    if (layerModel.state.surveyId === surveyId) {

                        layerModel.setSurvey(surveyId);

                    }

                });

                m.redraw();


            },
            persist: true
        });

        publish.await({
            changeType: 'deleted',
            recordType: 'survey',
            test: survey => store.surveys[survey.surveyId],
            callback: survey => {

                delete store.surveys[survey.surveyId];

                m.redraw();

                if (layerModel.state.surveyId === survey.surveyId) {

                    layerModel.setSurvey();

                }

            },
            persist: true
        });

        publish.await({
            changeType: 'new',
            recordType: 'plan',
            callback: plan => {

                store.plans[plan.planId] = plan;

                const planZOrder = store.project.attributes && store.project.attributes.planZOrder;

                if (planZOrder && !planZOrder.includes(plan.planId)) {

                    planZOrder.unshift(plan.planId);

                }

                m.redraw();

            },
            persist: true
        });

        publish.await({
            changeType: 'deleted',
            recordType: 'plan',
            callback: plan => {

                delete store.plans[plan.planId];

                delete store.tilesets[plan.tilesetId];

                const planZOrder = store.project.attributes && store.project.attributes.planZOrder || [],
                    planZIndex = planZOrder.indexOf(plan.planId);

                if (planZIndex !== -1) {

                    planZOrder.splice(planZIndex, 1);

                }

                m.redraw();

                layerModel.removeSource(plan.tilesetId);

            },
            persist: true
        });

        publish.await({
            changeType: 'new',
            recordType: 'tileset',
            callback: tileset => {

                store.tilesets[tileset.tilesetId] = tileset;

                m.redraw();

            },
            persist: true
        });

        publish.await({
            changeType: 'modified',
            recordType: 'tileset',
            callback: tileset => {

                const tilesetId = tileset.tilesetId;

                store.tilesets[tilesetId] = tileset;

                if (layerModel.state.planTilesetIds.has(tilesetId)) {

                    if (tileset.planId) {

                        layerModel.hidePlan(store.plans[tileset.planId]);

                        layerModel.showPlan(store.plans[tileset.planId]);

                    } else {

                        layerModel.showTileset(tileset);

                    }

                }

                // Check that we're 1) On layer view and 2) on the right plan
                if (router.params.view === 'layer' && router.params.planId === tileset.planId) {
                    layerColorModel.handleUpdatedLayer();
                }

                m.redraw();

            },
            persist: true
        });

    },

    initFromPreferences(sitePreferences = {}) {

        const _state = sitePreferences[siteModel.siteId];

        layerModel.setBasemap(_state && _state.basemapId || Object.keys(constants.basemaps)[0]);

        if (!_state) {

            layerModel.initialized = true;

            return;

        }

        if (router.params.view === 'pdf') {

            siteModel.map.fitBounds(siteModel.bounds, {animate: false});

        } else if (_state.camera) {

            siteModel.map.setCamera(_state.camera);

        } else {

            siteModel.map.fitBounds(siteModel.bounds, {
                animate: false
            });

        }


        if (_state.surveyId) {

            layerModel.setSurvey(_state.surveyId);

        }

        if (_state.planTilesetIds) {

            _state.planTilesetIds.forEach(planTilesetId => {

                const plan = Object.values(store.plans).find(p => p.tilesetId === planTilesetId);

                if (plan) {

                    layerModel.showPlan(plan);

                } else {

                    console.error('planTilesetId not found:', planTilesetId);

                }

            });

        }

        if (_state.doShowPlaces) {

            if (_state.placeIds) {

                _state.placeIds.forEach(placeId => {

                    const place = store.places[placeId];

                    if (place) {

                        layerModel.togglePlace(place);

                    } else {

                        console.error('placeId not found:', placeId);

                    }

                });

            }

            layerModel.showPlaces();

        }

        if (_state.doShowLinks) {

            layerModel.toggleLinks();

        }

        layerModel.initialized = true;

    },

    addFilter(layer, filter) {

        if (layer.filter) {

            if (layer.filter[0] === 'all') {

                layer.filter.push(filter);

            } else {

                layer.filter = ['all', layer.filter, filter];

            }

        } else {

            layer.filter = filter;

        }

    },

    reduceOpacity(paint, propertyName) {

        const opacity = paint[propertyName];

        if (opacity === undefined) {

            paint[propertyName] = 0.5;

        } else if (typeof opacity === 'number') {

            paint[propertyName] = paint[propertyName] / 2;

        } else if (opacity.type === 'identity') {

            paint[propertyName] = ['/', ['get', opacity.property], 2];

        } else if (Array.isArray(opacity)) {

            paint[propertyName] = ['/', opacity, 2];

        }

    },

    focusOnAssetFeatures(assetIds = []) {

        const map = siteModel.map,
            style = map.getStyle();

        style.layers.forEach(layer => {

            if (layer.type === 'raster' || layer.id === placesTabModel.placeLayer) {

                return;

            }

            layerModel.addFilter(layer, ['!in', 'assetId', ...assetIds]);

            layer.paint = layer.paint || {};

            layer.layout = layer.layout || {};

            const propertyNameGetter = {
                fill: () => 'fill-opacity',
                line: () => 'line-opacity',
                circle: () => 'circle-opacity',
                symbol: () => {

                    if (layer.layout['icon-image']) {

                        return 'icon-opacity';

                    } else if (layer.layout['text-field']) {

                        return 'text-opacity';

                    }

                }
            }[layer.type];

            const propertyName = propertyNameGetter && propertyNameGetter();

            if (propertyName) {

                layerModel.reduceOpacity(layer.paint, propertyName);

            }

        });

        Object.keys(store.tools).forEach(toolId => {

            const tool = store.tools[toolId];

            if (tool.featureStyles) {

                tool.featureStyles.forEach(featureStyle => {

                    if (featureStyle.style) {

                        const layer = Object.create(featureStyle.style);

                        layerModel.addFilter(layer, ['in', 'assetId', ...assetIds]);

                        layer.id = '_tmp_' + layer.id;

                        const index = style.layers.findIndex((l) => l.id === layer.id);

                        if (index !== -1) {
                            style.layers.splice(index, 1);
                        }

                        style.layers.push(layer);

                    }

                });

            }

        });

        map.setStyle(style);

    },

    resetToolLayers() {

        const map = siteModel.map;

        if (map && map.isStyleLoaded()) {

            const style = map.getStyle(),
                layers = [],
                specialLayerStyles = {
                    places: 'line-opacity',
                    searchArea: 'line-opacity',
                    'places-border': 'line-opacity'
                };

            if (style) {

                style.layers.forEach(layer => {

                    if (!layer.id.startsWith('_tmp_')) {

                        if (specialLayerStyles[layer.id]) {

                            delete layer.paint[specialLayerStyles[layer.id]];

                            layers.push(layer);

                        } else {

                            const featureStyle = store.featureStyles[layer.id];

                            layers.push(featureStyle ? featureStyle.style : layer);

                        }

                    }

                });

                style.layers = layers;

                map.setStyle(style);

            }

        }

    },

    initFromURL() {

        layerModel.initialized = false;

        const style = siteModel.map.getStyle();

        // zOrder is from bottom to top
        Object.values(store.tools)
            .sort((a, b) => a.zOrder - b.zOrder)
            .forEach(tool => tool.featureStyles
                .sort((a, b) => a.zOrder - b.zOrder)
                .forEach(featureStyle =>
                    featureStyle.style && style.layers.push(featureStyle.style)
                )
            );

        siteModel.map.setStyle(style);

        const userId = router.params.userId;

        if (userId && userId !== userModel.userId) {

            api.rpc.get('User', userId).then(({preferences}) => {

                layerModel.initFromPreferences(preferences.sitePreferences);

            });

        } else {

            layerModel.initFromPreferences(userModel.getPreference('sitePreferences'));

        }


        // Handle links generated by the back end containing mapIds
        const surveyVisibleTilesetId = router.params.mapId;

        if (surveyVisibleTilesetId) {

            router.url.removeReplace('mapId');

            const survey = Object.values(store.surveys).find(s => s.visibleTilesetId === surveyVisibleTilesetId);

            if (survey) {

                layerModel.setSurvey(surveyVisibleTilesetId);

            }

        }

    }

};

export default layerModel;
