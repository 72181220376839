import siteModel from 'models/site-model';
import screenHelper from 'legacy/util/device/screen-helper';
import pointInPoly from '@turf/boolean-point-in-polygon';
import {getTextRect} from 'util/geo';
import store from 'util/data/store';
import filterModel from 'models/table/filter-model';

const radius = screenHelper.ios ? 6 : 3;

const grabFeatureId = (feature) => feature.properties._id;

function queryFeatures({x, y}, map = siteModel.map, getFeatureId = grabFeatureId) {

    let coordinates,
        point;

    const featureIds = {};

    const searchAreaFeatureId = filterModel.searchArea && filterModel.searchArea.id;

    // Unfortunately mapbox is going to throw a warning here when a feature property is
    // an array or object. We can safely ignore. https://github.com/mapbox/mapbox-gl-js/issues/2434
    const features = map.queryRenderedFeatures([[x - radius, y + radius], [x + radius, y - radius]], {
        validate: false
    }).filter(feature => {

        const featureId = getFeatureId(feature);

        feature.id = featureId;

        // queryRenderedFeatures returns the same feature multiple times
        if (featureIds[featureId] || featureId === searchAreaFeatureId) {
            return false;
        }

        featureIds[featureId] = true;

        // Mapbox includes a huge amount of padding around text features when deciding
        // whether they intersect with the queried geometry. This code filters out text
        // features that were returned by queryRenderedFeatures that shouldn't have been.
        if (feature.properties._textSizeMeters && feature.geometry.type === 'Point') {

            const isLabel = store.featureTypeToStyles[feature.source].length > 1;

            if (!isLabel) {

                coordinates = coordinates || map.unproject({x, y}).toArray();

                point = point || {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates
                    }
                };

                // Uncomment to preview the rectangle
                // const source = map.getSource(Object.values(window.store.featureTypes).find(f => f.name === 'Shape').featureTypeId);
                // source._data.features.push(getTextRect(feature));
                // source.setData(source._data);

                if (!pointInPoly(point, getTextRect(feature))) {

                    return false;

                }

            }

        }

        return true;

    // Mapbox transforms some of the feature properties,
    // and removes the ID. Let's work around that:
    }).map(feature => store.features[feature.properties._id] || feature);

    return features;

}

export default queryFeatures;
