import featureModel from 'models/feature-model';
import getToolInterface from 'util/interfaces/get-tool-interface';
import api from 'legacy/util/api';
import router from 'uav-router';
import initializer from 'util/initializer';
import formModel from 'models/form-model';
import constants from 'util/data/constants';
import store from 'util/data/store';
import siteModel from 'models/site-model';
import layerModel from 'models/layer-model';
import element from 'util/dom/element';
import message from 'legacy/components/message';
import previewToolbox from 'util/data/preview-toolbox';
import reverseLoop from 'util/data/reverse-loop';
import Table from 'views/table/table';
import assetModel from 'models/asset-model';
import sideNavModel from 'models/side-nav-model';
import panelModel from 'models/panel-model';
import oneUpModel from 'models/one-up-model';

initializer.add(() => toolboxModel.cleanup());

const toolboxModel = {

    linkTools: {
        video: true,
        file: true,
        image: true,
        audio: true
    },

    cleanup: () => Object.assign(toolboxModel, {
        tool: null,
        toolInterface: null,
        customExports: {},
        projectSingular: 'site',
        projectPlural: 'sites',
        importMode: false
    }),

    toggleMode() {
        toolboxModel.importMode = !toolboxModel.importMode;
    },

    fetch: () => {
        return (router.params.preview ? previewToolbox() : api.rpc.requests([
            ['listProjectToolboxes', {projectId: router.params.projectId}]
        ])).then(([[toolbox]]) => {

            toolbox = window.mockToolbox || toolbox || {
                attributes: {},
                group: {
                    groups: []
                }
            };

            const featureStyles = store.featureStyles,
                featureTypes = store.featureTypes,
                featureTypeToStyles = store.featureTypeToStyles,
                toolZOrder = toolbox.attributes.toolZOrder || [],
                imageLoadingPromises = [];

            if (!toolbox) {

                toolbox = {group: {groups: []}, attributes: {}};

            }

            store.toolboxId = toolbox.toolboxId;

            // If toolbox has optional features enabled, add them to the site-model:
            siteModel.toolboxFeatures = toolbox.attributes.featureFlags;

            toolboxModel.customExports = {};

            if (toolbox.attributes.customExports) {
                toolbox.attributes.customExports.forEach(exp => {
                    toolboxModel.customExports[exp.name] = exp.id;
                    sideNavModel.exportFormats.push(exp.name);
                });
            }

            if (toolbox.attributes.projectSingular) {
                toolboxModel.projectSingular = toolbox.attributes.projectSingular;
            }

            if (toolbox.attributes.projectPlural) {
                toolboxModel.projectPlural = toolbox.attributes.projectPlural;
            }

            const toolGroupOrder = toolbox.attributes.toolGroupOrder || [];

            const sortedToolGroups = new Array(toolGroupOrder.length);

            toolbox.group.groups.forEach(toolGroup => {

                const toolOrder = toolGroup.attributes.toolOrder || [];

                const groupIndex = toolGroupOrder.indexOf(toolGroup.name);

                if (groupIndex === -1) {

                    sortedToolGroups.push(toolGroup);

                } else {

                    sortedToolGroups[groupIndex] = toolGroup;

                }

                // looping in reverse makes it safe to remove items
                // while we continue iterating
                reverseLoop(toolGroup.tools, (tool, i) => {

                    tool.zOrder = toolZOrder.indexOf(tool.name);
                    tool.uiOrder = toolOrder.indexOf(tool.name);

                    const lowerToolName = tool.name.toLowerCase();

                    if (toolboxModel.linkTools[lowerToolName]) {

                        toolboxModel.linkTools[lowerToolName] = tool;

                    }

                    store.tools[tool.toolId] = tool;

                    if (tool.attributes.hidden) {

                        if (tool.name === 'Comment') {

                            constants.commentAssetTypeId = tool.assetForm.assetType.assetTypeId;
                            constants.commentToolId = tool.toolId;

                        }

                        toolGroup.tools.splice(i, 1);

                    }

                    tool.toolGroup = toolGroup;

                    const assetForm = tool.assetForm,
                        assetFormId = assetForm.assetFormId,
                        assetTypeId = assetForm.assetType.assetTypeId,
                        styleZOrder = tool.attributes.styleZOrder,
                        controlTypeNameToId = constants.controlTypeNameToId;

                    const nameControl = assetForm.controls.find(c => c.controlTypeId === controlTypeNameToId.name);

                    store.assetForms[assetFormId] = assetForm;

                    store.assetTypeIdToNameControl[assetTypeId] = nameControl && nameControl.label;

                    store.assetTypeIdToToolGroupId[assetTypeId] = toolGroup.toolGroupId;

                    store.assetTypes[assetTypeId] = assetForm.assetType;

                    store.assetTypeToFormId[assetTypeId] = assetFormId;

                    store.assetTypeFields[assetTypeId] = {};

                    assetForm.assetType.fields.forEach(field => {

                        store.assetTypeFields[assetTypeId][field.name] = field;

                    });

                    tool.featureTypes.forEach(featureType => {

                        featureType.attributes = featureType.attributes || {};
                        featureType.properties = featureType.properties || {};

                        featureTypes[featureType.featureTypeId] = featureType;

                        featureTypeToStyles[featureType.featureTypeId] = [];

                        if (featureType.geometry) {

                            const lineMetrics = featureType.geometry.type === 'LineString'
                            && tool.featureStyles.length > 1
                            && tool.featureStyles.find(f => f.style && f.style.layout && f.style.layout['text-field']);

                            layerModel.createGeoJSONSource(featureType.featureTypeId, !!lineMetrics);

                        }

                    });

                    tool.featureStyles.forEach(featureStyle => {

                        const featureTypeId = featureStyle.featureType.featureTypeId,
                            style = featureStyle.style;

                        featureStyle.zOrder = styleZOrder.indexOf(featureStyle.name);

                        featureStyle.featureType = featureTypes[featureTypeId];

                        featureStyles[featureStyle.featureStyleId] = featureStyle;

                        featureTypeToStyles[featureTypeId].push(featureStyle.featureStyleId);

                        if (style) {

                            style.id = featureStyle.featureStyleId;

                            style.source = featureTypeId;

                            const iconImage = style.layout && style.layout['icon-image'];

                            if (iconImage) {

                                if (iconImage[0] === 'match') {

                                    iconImage.slice(3).forEach((item, k) => {

                                        if (k % 2 === 0) {

                                            imageLoadingPromises.push(
                                                featureModel.addImage(item, constants.mediaURL + item)
                                            );

                                        }

                                    });

                                } else if (iconImage.stops) {

                                    iconImage.stops.forEach(stop => {

                                        const mediaId = stop[1];

                                        imageLoadingPromises.push(
                                            featureModel.addImage(mediaId, constants.mediaURL + mediaId)
                                        );

                                    });

                                    if (iconImage.default) {

                                        imageLoadingPromises.push(
                                            featureModel.addImage(iconImage.default, constants.mediaURL + iconImage.default)
                                        );

                                    }

                                } else if (typeof iconImage === 'string') {

                                    imageLoadingPromises.push(
                                        featureModel.addImage(iconImage, constants.mediaURL + iconImage)
                                    );

                                }

                            }

                        }

                    });

                });

                toolGroup.tools.sort((a, b) => a.uiOrder - b.uiOrder);

            });

            sortedToolGroups.forEach(toolGroup => {

                store.toolGroups[toolGroup.toolGroupId] = toolGroup;

            });

            toolboxModel.hasMultipleGroups = Object.values(store.toolGroups).filter(group => group.tools.length).length > 1;

            m.redraw();

            return Promise.all(imageLoadingPromises);

        });
    },

    toggleGroup(toolGroup, e) {

        e.stopPropagation();

        toolGroup.collapsed = !toolGroup.collapsed;

    },

    closeActiveTool() {

        if (toolboxModel.toolInterface) {

            toolboxModel.toolInterface.close();

        }

        if (formModel.toolInterface) {

            formModel.close();

        }

    },

    selectTool(toolGroup, tool, e) {

        if (e) {

            e.stopPropagation();

        }

        panelModel.close();

        siteModel.view = null;

        siteModel.sidebar = Table; // return to the feed if tool launcher is closed

        toolboxModel.closeActiveTool();

        toolboxModel.activeGroupId = toolGroup.toolGroupId;

        const toolInterface = getToolInterface(tool);

        if (toolboxModel.importMode) {
            return toolInterface.showToolLauncher();
        }

        oneUpModel.clearActiveFlow();

        toolInterface.launch().then(assets => {

            if (assets) {

                api.rpc.requests(assets.map(asset => {
                    assetModel.addToStore(asset);
                    return ['createContent', api.apiSafeContent(asset)];
                }));

            }

            if (assets && assets.length === 1) {

                formModel.viewAsset(assets[0].contentId, 'Properties', true);

            } else if (assets && assets.length > 1) {

                if (toolboxModel.toolInterface) {

                    toolboxModel.toolInterface.close();

                }

                const assetTypeName = store.assetTypes[assets[0].assetTypeId].name;

                const messageElement = element`<div>${assets.length} new ${assetTypeName} assets added.</div>`;

                message.show(messageElement);

            } else if (toolboxModel.toolInterface) {

                toolboxModel.toolInterface.close();

            }

        });

    }

};

export default toolboxModel;
