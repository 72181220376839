/**
 * RequestPool: Allows <size> requests out at one time.
 *      If no request has returned before <expiredTimeout> and the outbound requestCount >= <size>
 *          RequestPool drops the first request lock and allows another request to fire off.
 *          The expired timer resets every time a request is sent.
 *          The expired timer also increases in time once the queue of backed up requests is > 25
 *
 *      <fire> is the callback for "firing" a request.
 */

class RequestPool {
    constructor(size, expireTimeout, fire) {
        this.size = size;
        this.expireTimeout = expireTimeout;
        this.requestCount = 0;
        this.pool = {};
        this.queue = [];
        this.fire = fire;
    }

    addRequest(request) {
        const {msgId} = request;
        if (!msgId) {
            this.fire(request);
            return;
        }
        if (this.size > this.requestCount) {
            this.fireRequest(request);
        } else {
            if (!this.expireLongRunning) {
                this.resetExpireClock();
            }
            this.queue.push(request);
        }
    }

    fireRequest(request) {
        const {msgId} = request;
        this.pool[msgId] = true;
        this.fire(request);
        this.requestCount++;
        if (this.queue.length > this.size) {
            this.resetExpireClock();
        } else {
            this.clearExpiredTimout();
        }
    }

    resetExpireClock() {
        clearTimeout(this.expireLongRunning);
        const expiredTime = Math.max(this.expireTimeout, this.expireTimeout * this.queue.length / 25);
        this.expireLongRunning = setTimeout(() => {
            if (this.queue.length > this.size) {
                this.unlockRequest(this.queue.shift().msgId);
            }
        }, expiredTime);
    }

    unlockRequest(msgId) {
        if (this.pool[msgId]) {
            this.requestCount--;
            delete this.pool[msgId];
            if (this.size > this.requestCount && this.queue.length > 0) {
                this.fireRequest(this.queue.shift());
            } else if (this.queue.length === 0 && this.requestCount === 0) {
                this.clearExpiredTimout();
            }
        }
    }

    clearExpiredTimout() {
        if (this.expireLongRunning) {
            clearTimeout(this.expireLongRunning);
            this.expireLongRunning = null;
        }
    }

    clearRequests() {
        this.clearExpiredTimout();
        this.requestCount = 0;
        this.pool = {};
        this.queue = [];
    }

    /**
     * Removes cancelled requests that have not been sent over the socket yet.
     */
    clearQueued(msgId) {
        // The request is already out.
        if (this.pool[msgId]) {
            return;
        }
        if (this.queue.length > 0) {
            const i = this.queue.findIndex((request) => request.msgId === msgId);
            if (i) {
                this.queue.splice(i, 1);
            }
        }
    }
}

export default RequestPool;
