import siteModel from 'models/site-model';
import initializer from 'util/initializer';
import PDFExporter from 'views/pdf-exporter/pdf-exporter';
import exportPDFModel from 'models/export-pdf-model';

const PDF = {
    oncreate: ({state}) => {
        siteModel.onFeaturesLoaded(() => PDF.ready(state));
        siteModel.init().then(initializer.siteInitialized);
    },

    ready: (state) => {
        exportPDFModel.initPDF();
        state.initialized = true;
        m.redraw();
    },

    view: ({state}) => {
        return <div class="pdf">
            <div id="mapbox"/>
            {state.initialized && <PDFExporter/>}
        </div>;
    }
};

export default PDF;
