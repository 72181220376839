import TabModel from 'models/tab-model';
import formModel from 'models/form-model';
import constants from 'util/data/constants';
import store from 'util/data/store';
import api from 'legacy/util/api/api';
import mediaViewerModel from './media-viewer-model';
import message from 'legacy/components/message/message';
import assetModel from 'models/asset-model';
import publish from 'legacy/util/api/publish';
import helpers from 'legacy/util/api/helpers';

const LIMIT_20 = 20;

class LinksModel extends TabModel {

    constructor() {
        const asset = store.assets[formModel.assetId];
        super({
            assetTypeIdNe: constants.commentAssetTypeId,
            contentIdIn: asset.linkIds || []
        });
        this.checkForLinkedMedia();
    }

    // Removes the link relationship between the currently viewed asset and the passed assetId.
    unlink(assetId) {
        const requests = [];
        const asset = store.assets[assetId];

        if (asset.linkIds) {
            const linkIds = asset.linkIds.filter((id) => this.assetId !== id);
            requests.push(['modifyContent', {
                contentId: asset.contentId,
                linkIds: linkIds
            }]);
            const index = this.assetIds.indexOf(asset.contentId);
            if (index !== -1) {
                this.assetIds.splice(index, 1);
            }

        }

        const parentAsset = store.assets[this.assetId];
        if (parentAsset.linkIds) {
            const linkIds = parentAsset.linkIds.filter((id) => asset.contentId !== id);
            requests.push(['modifyContent', {
                contentId: parentAsset.contentId,
                linkIds: linkIds
            }]);
        }

        api.rpc.requests(requests).then((assets) => {
            assets.forEach(assetReturned => {
                const updatedAsset = store.assets[assetReturned.contentId];
                updatedAsset.linkIds = helpers.list(assetReturned.linkIds);
                store.assets[assetReturned.contentId] = updatedAsset;
            });
            if (this.hasLinkedMedia) {
                this.checkForLinkedMedia();
            }
            m.redraw();
        });
    }

    /*
     * Await additional publishes on linked assets in links tab
     */
    awaitChanges() {
        super.awaitChanges();

        publish.await({
            changeType: 'deleted',
            recordType: 'thread',
            callback: () => {
                this.assetIds.forEach(assetId => {
                    const asset = store.assets[assetId] || {};
                    if (!asset.contentId) {
                        const index = this.assetIds.indexOf(assetId);
                        if (index !== -1) {
                            this.assetIds.splice(index, 1);
                        }

                        // Check if existence of linked media is now changed.
                        if (this.hasLinkedMedia) {
                            this.checkForLinkedMedia();
                        }
                    }
                });
                m.redraw();
            },
            persist: true
        });

    }


    isForThisTab(asset) {
        return asset.linkIds && asset.linkIds.indexOf(this.assetId) !== -1 && constants.commentAssetTypeId !== asset.assetTypeId;
    }

    onResults(assetIds) {
        formModel.focusOnLinkFeatures(assetIds);
    }

    /**
     * Called upon init of link tab: Checks if any linked assets contain media.
     */
    checkForLinkedMedia() {
        const asset = store.assets[this.assetId] || {};
        if (asset.linkIds && asset.linkIds.length) {
            api.rpc.count('Content', {
                mediaIdsNe: null,
                contentIdIn: store.assets[this.assetId].linkIds || []
            }).then(({count}) => {
                this.hasLinkedMedia = count > 0;
                m.redraw();
            });
        } else {
            this.hasLinkedMedia = false;
            m.redraw();
        }
    }


    /**
     * Begins paginated fetches of all linked assets containing media and opens a gallery viewer with media displayed.
     */
    viewLinkedMedia() {
        this.linkedMediaIds = [];
        mediaViewerModel.wait();
        // Start with the existing assets loaded in the links tab before first fetch:
        const starterAssetIds = this.assetIds.filter(assetId => store.assets[assetId].mediaIds.length);
        if (starterAssetIds.length) {
            this.onLinkedMediaResults(starterAssetIds);
        }
        if (!this.loadedAll) {
            this.getNextPageLinkedMedia(starterAssetIds.length);
        }
    }

    /**
     * Given an array of assets that contain media, opens all the attachments in a gallery.
     */
    onLinkedMediaResults(assetIds = []) {
        assetIds.forEach(assetId => this.linkedMediaIds.push(...store.assets[assetId].mediaIds));
        if (this.linkedMediaIds.length) {
            mediaViewerModel.open(this.linkedMediaIds, mediaViewerModel.state.index);
        } else {
            // We only display the view linked media button if linked media exists, but in case an asynchronous change occurred (or an error), have a fallback:
            mediaViewerModel.close();
            message.show('No linked media attachments found.', 'warning');
        }
    }

    /**
     * Retrieves a page of assets linked to the current asset held in state. If results are found,
     * calls function to handle. If limit not reached, calls for next page after offset.
     */
    getNextPageLinkedMedia(offset = 0) {
        const args = {
            limit: LIMIT_20,
            order: 'createdDateTime desc',
            mediaIdsNe: null,
            contentIdIn: store.assets[this.assetId].linkIds || [],
            offset
        };
        api.rpc.list('Content', args, true)
            .then(results => {
                this.onLinkedMediaResults(results.map(asset => {
                    assetModel.addToStore(asset);
                    return asset.contentId;
                }));
                if (results.length === LIMIT_20) {
                    this.getNextPageLinkedMedia(offset + LIMIT_20);
                }
            });
    }

    /**
     * If changes are published, run necessary check to see if linked media existence has changed.
     */
    onContentChange(changeType, assetId) {

        const asset = store.assets[assetId] || {};

        const parentAsset = store.assets[this.assetId] || {};

        parentAsset.linkIds = parentAsset.linkIds || []; // Some assets have undefined linkIds

        // Make sure the asset is included in our list of links:
        if (parentAsset.linkIds.indexOf(assetId) === -1) {
            parentAsset.linkIds.push(assetId);
        }

        switch (changeType) {
        case 'new':
            if (!this.hasLinkedMedia) {
                this.hasLinkedMedia = asset.mediaIds && asset.mediaIds.length;
            }
            break;
        case 'deleted':
            if (this.hasLinkedMedia) {
                this.checkForLinkedMedia();
            }
            break;
        default:
            this.checkForLinkedMedia();
        }
        m.redraw();
    }

}

export default LinksModel;
