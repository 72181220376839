import SortableModel from 'models/sortable-model';

const Sortable = {
    view: ({attrs: {view, collection, className, dropZoneClass, onDrop}}) => 
        <div class={className}>
            {collection.map(item =>
                <div key={item}
                    draggable 
                    ondragstart={e => SortableModel.onDragStart(e, {
                        collection,
                        dropZoneClass,
                        onDrop
                    })}
                    ondragover={e => SortableModel.onDragOver(e)}>
                    {view(item)}
                </div>
            )}
        </div>
};

export default Sortable;
