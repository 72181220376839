import peopleModel from 'models/people/people-model';

/**
 Required args:
 * @param options - Array of options available for the select dropdown
 * @param onselect - Function to run when an option has been selected
 Optional args:
 * @param displayOptionAs - Callback to format option data for display, e.g, userId displays as user's name
 * @param selected - Currently selected option
 */
const AutoComplete = {

    oninit: ({state, attrs: {model}}) => {
        state.model = model;
    },

    view: ({state, attrs: {selected, wrapClass}}) => {
        const model = state.model;
        return <div class={`auto-complete-wrap ${wrapClass || ''}`}>
            {model && model.optionsVisible && <div class='auto-complete' style={`top: ${model.offsetTop}px`}>
                {model.titleText && <span class="auto-complete-title-tip">{model.titleText}</span>}
                <div class="options">
                    {selected && model.filteredOptions.length ?
                        <div class={'option option-clear' + (model.focusedIndex < 0 ? ' focus' : '')}
                            onmousedown={() => model.clearSelection()}
                            ontouchstart={() => model.clearSelection()}>Clear selection</div> : null}
                    {model.filteredOptions.map((option, index) => {
                        return <div class={'option' + (model.focusedIndex === index ? ' focus' : '')}
                            onmousedown={() => model.selectIndex(index)}
                            ontouchstart={() => model.selectIndex(index)}>
                            <div class='user-avatar icon-initials small'>{peopleModel.getPerson(option.value).initials()}</div>
                            <div class="inner-text user-name">{option.text}</div>
                        </div>;
                    })}
                </div>
            </div>}
        </div>;
    }

};

export default AutoComplete;
