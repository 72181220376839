import pointMenu from 'legacy/components/point-menu';
import convertMithril from 'util/dom/convert-mithril';
import mediaModel from 'models/media-model';
import download from 'legacy/util/device/download';
import siteModel from 'models/site-model';
import assetModel from 'models/asset-model';
import formModel from 'models/form-model';
import AssetForm from 'views/asset-form/asset-form';
import mediaViewerModel from 'models/media-viewer-model';
import store from 'util/data/store';
import featureModel from 'models/feature-model';
import userModel from 'models/user-model';

const popup = (media, isFullScreen) => new Promise(resolve =>
    assetModel.fetch(media.contentId || formModel.assetId)
        .then(asset => {

            const mediaId = media.mediaId,
                feature = mediaModel.getFeature(mediaId, asset.contentId),
                isViewOnly = userModel.isViewOnly,
                isInfoPanelOpen = siteModel.sidebar === AssetForm;

            const element = convertMithril.toDom(<div>
                {!isInfoPanelOpen && <div className="menu-option" onclick={() => {
                    formModel.viewAsset(asset.contentId, 'Properties');
                    pointMenu.close();
                    mediaModel.close();
                }}>
                    <i className="icon-asset-form"/> Asset Info
                </div>}
                {isFullScreen
                    ? null
                    : <div class="menu-option" onclick={() => {
                        const formAsset = store.assets[formModel.assetId];
                        mediaViewerModel.open(formAsset.mediaIds, formAsset.mediaIds.indexOf(mediaId));
                        pointMenu.close();
                    }}><i class="icon-expand"/> View Media</div>}
                <div class="menu-option" onclick={() => {
                    download.force(media);
                    pointMenu.close();
                }}><i class="icon-download"/> Download
                </div>
                {feature
                    ? <div class="menu-option" onclick={() => {
                        siteModel.view = null;
                        featureModel.panToFeatures([feature]);
                        pointMenu.close();
                    }}><i class="icon-center-on-map"/> Center on Map</div>
                    : null}
                {userModel.isContentEditable(asset.contentId) && assetModel.supportsFileFeatures(asset.contentId)
                    ? <div class="menu-option" onclick={() => {
                        mediaModel.editFeature(feature, mediaId);
                        pointMenu.close();
                    }}><i class="icon-marker-outline"/> {feature ? 'Edit' : 'Add'} Location</div>
                    : null}
            </div>);

            const mimeType = media.mimeType;

            if (!isViewOnly
                && (mimeType.startsWith('image/') || mimeType === 'application/pdf' || mimeType === 'application/zip')) {

                element.appendChild(convertMithril.toDom(
                    <div class="menu-option show-on-large" onclick={() => {
                        mediaModel.createLayer(mediaId);
                        pointMenu.close();
                    }}><i class="icon-add-layer"/> Create New Layer</div>
                ));

            }

            resolve(element);

        }));

const MediaOptions = {
    view: ({attrs: {mediaId, isFullScreen}}) => {
        return <i class="icon-ellipsis" onclick={e => pointMenu({
            e,
            content: mediaModel.getMedia(mediaId).then(media => popup(media, isFullScreen)),
            cssClass: 'link-list',
            forceDesktop: true,
            noLine: true
        })}/>;
    }
};

export default MediaOptions;
