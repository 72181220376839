import formatDate from 'legacy/util/date/format-date';
import modal from 'legacy/components/modal';
import DatePicker from 'views/date-picker';

const DateRange = {
    oninit({state, attrs: {model}}) {
        state.model = model;
        model.midnight = new Date();
        formatDate.setTimeToMidnight(model.midnight);
        if (!model.fromDate) {
            model.fromDate = new Date();
            formatDate.setTimeToZero(model.fromDate);
            model.fromDate.setDate(model.fromDate.getDate() - 30);
        } else {
            model.fromDate = new Date(model.fromDate);
        }
        if (!model.toDate) {
            model.toDate = new Date(model.midnight);
        } else {
            model.toDate = new Date(model.toDate);
        }
    },
    view: ({state: {model}, attrs: {onDateChange, onDone, hideDone}}) => {
        const {fromDate, toDate, midnight} = model;
        return <div class="select-date-wrap">
            <div>
                <div class="date-input-box">{formatDate.ddmmmyy(fromDate)} - {formatDate.ddmmmyy(toDate)}</div>
            </div>
            <div class="select-date-opts">
                <label class="select-date-txt">From</label>
                <DatePicker date={fromDate} required={true} max={toDate} onchange={newFromDate => {
                    model.fromDate = newFromDate;
                    onDateChange();
                }}/>
                <label class="select-date-txt">Until</label>
                <DatePicker date={toDate} required={true} max={midnight} onchange={newToDate => {
                    model.toDate = newToDate;
                    onDateChange();
                }}/>
                {!hideDone && <div class="btn btn-pill btn-primary btn-small" onclick={() => {
                    onDone();
                    modal.hide();
                }}><span class="btn-label">Done</span></div>}
            </div>
        </div>;
    }
};

export default DateRange;
