import constants from 'util/data/constants';
import mediaModel from 'models/media-model';
import getImageSource from 'legacy/util/data/get-image-source';
import MediaOptions from 'views/media-options';
import 'pannellum';
import api from 'legacy/util/api';
import deepCloneObject from 'util/data/deep-clone-object';
import store from 'util/data/store';

const PhotoViewer = {

    destroyPannellum() {

        if (PhotoViewer.pannellum) {

            PhotoViewer.pannellum.destroy();

            PhotoViewer.pannellum = null;

        }

    },

    show360Photo(media) {

        const mediaId = media.mediaId;

        if (PhotoViewer.mediaId !== mediaId) {

            return;

        }

        media.attributes.is360 = true;

        const img = document.querySelector('.photo-viewer-img'),
            container = document.getElementById('pannellum'),
            maxWidth = mediaModel.getMax360PhotoSize();

        let src = img.src;

        if (img.width > maxWidth) {

            src = getImageSource(mediaId, {width: maxWidth});

        }

        PhotoViewer.pannellum = window.pannellum.viewer(container, {
            type: 'equirectangular',
            panorama: src,
            autoLoad: true,
            showFullscreenCtrl: false,
            showControls: false
        });

        m.redraw();

    },

    toggle360(media) {

        if (media.attributes.is360) {

            media.attributes.is360 = false;

            PhotoViewer.destroyPannellum();

        } else {

            PhotoViewer.show360Photo(media);

        }

        api.rpc.modify('Media', {
            mediaId: media.mediaId,
            attributes: media.attributes
        });

    },

    onremove() {

        PhotoViewer.destroyPannellum();

    },

    oninit({attrs: {media}}) {

        // this is an immutable-friendly version of --> media.attributes = media.attributes || {};
        if (!media.hasOwnProperty('attributes')) {
            const newMedia = deepCloneObject(store.media[media.mediaId]);
            newMedia.attributes = {};
            store.setContainerValue(store.media, newMedia.mediaId, newMedia);
        }

    },

    oncreate({dom, attrs: {media}, state}) {

        PhotoViewer.destroyPannellum();

        const mediaId = PhotoViewer.mediaId = media.mediaId,
            is360 = media.attributes && media.attributes.is360,
            img = dom.firstElementChild;

        // All 360 photos are JPEGs
        if (media.mimeType === 'image/jpg' || media.mimeType === 'image/jpeg') {

            img.onload = () => {

                // If the image aspect ratio is wide enough,
                // Show the 360 photo toggle
                if (img.width >= img.height * 1.75) {

                    state.show360 = true;

                    m.redraw();

                    if (is360) {

                        PhotoViewer.show360Photo(media);

                    // If the photo is wide and has exif data,
                    // we'll assume it's a 360 photo.
                    } else if (media.info) {

                        PhotoViewer.show360Photo(media);

                    }

                }

            };

        }

        img.src = constants.mediaURL + mediaId;

    },

    view: ({attrs: {media}, state}) =>
        <div class="photo-viewer">
            <img class="photo-viewer-img" key={media.mediaId} />
            <div id="pannellum" key="pannellum" />
            {state.show360
                ? <i class={`icon-360view ${media.attributes.is360 ? 'active' : ''}`}
                    onclick={() => PhotoViewer.toggle360(media)}
                    key="360icon" />
                : <div key="360icon" />}
            <MediaOptions mediaId={media.mediaId} isFullScreen={true} key="opts" />
        </div>
};

export default PhotoViewer;
