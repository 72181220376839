import formModel from 'models/form-model';
import formControls from 'views/form-controls';
import Comments from 'views/comments';
import Links from 'views/links';
import ProjectPeopleTab from 'views/people/project-people-tab';
import GeometryDetail from 'views/geometry-detail';
import AssetOptions from 'views/asset-options';
import userModel from 'models/user-model';
import peopleModel from 'models/people/people-model';
import SidebarToggle from 'views/sidebar-toggle';
import store from 'util/data/store';
import pointMenu from 'legacy/components/point-menu/point-menu';
import PlacesTab from 'views/places-tab';
import m from 'mithril';
import router from 'uav-router';
import Embed from 'views/asset-form/embed';
import constants from 'util/data/constants';

const tabs = {
    Properties: {
        oninit({state}) {
            state.onScroll = e => {
                e.redraw = false;
                pointMenu.onScroll();
            };
            state.hidePlace = Object.values(store.places).length <= 1;
        },
        view: ({state}) => {
            const asset = store.assets[formModel.assetId];
            return <div class="tab-content info-content" onscroll={state.onScroll}>
                {formModel.controls.map(control => {
                    if (formModel.isEmbedControlType(control)) {
                        return <div class="asset-form-control clear" key={control.label}><Embed control={control} /></div>;
                    }
                    let readOnlyValue;
                    if (formModel.isReadOnlyControl(asset.assetTypeId, control)) {
                        readOnlyValue = asset.properties[control.label];
                        if (readOnlyValue === undefined || readOnlyValue === null
                            || formModel.propertyIsEmptyCoordinate(control, readOnlyValue)) {
                            return <div key={control.label} />;
                        }
                        readOnlyValue = formModel.getReadOnlyValue(readOnlyValue, control);
                    }
                    const formControl = formControls[control.controlTypeId];
                    if (!formControl || control.controlTypeId === constants.controlTypeNameToId.place && state.hidePlace) {
                        return <div key={control.label} />;
                    }
                    return <div key={control.label}
                        class={`asset-form-control clear ${
                                formModel.saving[control.label] ? ' saving' : ''}${
                                formModel.invalid[control.label] ? ' error' : ''}`}>
                        <div class={`clear ${control.attributes.hideLabel ? 'label-hidden' : ''}`}>
                            {!control.attributes.hideLabel && <label>{control.label} {formControl.suffix && formControl.suffix()}</label>}
                            <i class="spinner spinning" />
                        </div>
                        {readOnlyValue === undefined ? formControl(control) : <div class="eval-value">{readOnlyValue}</div>}
                        {control.attributes.eval &&  <div class="eval-msg"><span class="eval-icon"></span><span class="eval-text">{control.attributes.eval.description || 'Derived Value'}</span></div>}
                        {formModel.invalid[control.label] ? <div class="error-msg relative">Please complete this field.</div> : null}
                    </div>;
                })}
                {asset.featureIds && asset.featureIds.map(featureId =>
                    <GeometryDetail key={featureId} featureId={featureId} />
                )}
            </div>;
        }
    },
    Comments,
    'Places/Levels': PlacesTab,
    Links,
    People: ProjectPeopleTab
};

const AssetForm = {

    oninit() {
        document.body.classList.add('asset-form-open');
    },

    onremove: () => {
        formModel.cleanup();
        document.body.classList.remove('asset-form-open');
    },

    tab: tab =>
        <div class={'tab' + (router.params.tab === tab.name ? ' active' : '')} onclick={() => formModel.selectTab(tab)} key={tab.name}>
            <i class={`icon-${tab.icon} icon`} />
            <span class="tab-name">{tab.name}</span>
        </div>,

    view: () => {

        const tab = router.params.tab;
        const Tab = tabs[tab];
        const nameControl = formModel.nameControl;
        const asset = store.assets[formModel.assetId];
        const assetType = formModel.assetForm.assetType;
        const showArrows = !formModel.isNew && Object.values(store.assets).length > 1;
        const author = peopleModel.getPerson(asset.authorId);
        const authorName = peopleModel.displayName(author);
        const hasProjectTabs = formModel.childProjectId && userModel.isAccountAdmin;

        return <div>
            {showArrows && <div class="asset-form-arrow-wrap">
                <span class="asset-form-arrow" onclick={() => formModel.validateThenRun(() => formModel.next(true))}><i className="icon-arrow-up"/></span>
                <span class="asset-form-arrow" onclick={() => formModel.validateThenRun(() => formModel.next(false))}><i className="icon-arrow-down"/></span>
            </div>}
            <div class={`asset-form ${formModel.visible ? ' visible' : ''} ${formModel.isNew ? 'is-new' : ''}`}>
                <div class="asset-title-wrap">
                    <img class="asset-icon" src={`https://media.unearthlabs.com/${assetType.attributes.icon.mediaId}`}/>
                    <div class="asset-title">
                        {nameControl && asset.properties[nameControl.label] || assetType.name}
                    </div>
                    <AssetOptions assetId={asset.contentId}/>
                    <i class="icon-close asset-close" onclick={() => formModel.validateThenRun(() => formModel.close())}/>
                </div>
                <SidebarToggle onclick={() => formModel.toggleVisibility()}/>
                <div class="asset-form-wrap">
                    <div class="asset-meta-wrap">
                        <div class="asset-type"><span class="asset-meta-label">Type</span> {assetType.name}</div>
                        <div class="asset-added"><span class="asset-meta-label">Added</span> {formModel.displayDate} / {authorName}</div>
                        <div class="asset-updated"><span class="asset-meta-label">Last Updated</span> {formModel.lastUpdatedDate}</div>
                    </div>
                    {formModel.childProjectId && <div class="asset-project-wrap">
                        <div class="btn btn-pill btn-secondary btn-small" onclick={() => router.set({projectId: formModel.childProjectId})}>
                            <span class="btn-label">Go to {assetType.name}</span>
                        </div>
                    </div>}
                    <div class={'tabset tabcount-' + formModel.tabCount}>
                        {AssetForm.tab({name: 'Properties', icon: 'asset-form'})}
                        {formModel.hasCommentTab ? AssetForm.tab({name: 'Comments', icon: 'discussion'}) : m.fragment({key: 'comments'})}
                        {formModel.hasLinksTab ? AssetForm.tab({name: 'Links', icon: 'link'}) : m.fragment({key: 'links'})}
                        {hasProjectTabs ? AssetForm.tab({name: 'People', icon: 'user'}) : m.fragment({key: 'people'})}
                        {hasProjectTabs ? AssetForm.tab({name: 'Places/Levels', icon: 'place-with-levels'}) : m.fragment({key: 'places'})}
                    </div>
                    {!!Tab && <><Tab key={asset.contentId} /></>}
                    <div class="asset-form-footer">
                        {formModel.unmappedFeatureType
                        && userModel.isContentEditable(asset)
                        && <div class="btn btn-secondary btn-pill btn-small btn-addtomap left" onclick={() => formModel.addToMap()}>
                            <span class="btn-label">Add to Map</span>
                        </div>}
                        <div class="btn btn-primary btn-pill btn-small right" onclick={() => formModel.validateThenRun(() => formModel.close())}>
                            <span class="btn-label">Done</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>;

    }
};

export default AssetForm;
