import filterModel from 'models/table/filter-model';
import store from 'util/data/store';
import constants from 'util/data/constants';
import HeaderHideMessage from 'views/table/header-hide-message';
import message from 'legacy/components/message/message';
import api from 'legacy/util/api/api';
import featureModel from 'models/feature-model';
import publish from 'legacy/util/api/publish';
import formModel from 'models/form-model';
import deepMerge from 'util/data/deep-merge';
import initializer from 'util/initializer';
import siteModel from 'models/site-model';
import debounce from 'util/events/debounce';
import commonFilterModel from 'models/table/common-filter-model';
import screenHelper from 'legacy/util/device/screen-helper';
import tween from 'util/animation/tween';
import elementScroll from 'util/dom/element-scroll';
import assetModel from 'models/asset-model';
import popupModel from 'models/popover-model';
import activeCell from 'models/table/active-cell-model';
import userModel from 'models/user-model';
import router from 'uav-router';
import {assetIdToProjectId} from 'util/data/asset-id-to-project-id';
import panelModel from 'models/modal-model';
import batchModifyModel from 'models/batch-modify-model';
import toolboxModel from 'models/toolbox-model';
import SharedColumnModel from './shared-column-model';
import Cached from 'util/data/cached';
import TableStylesModel from 'models/table/table-styles-model';
import timeCache from 'util/data/time-cache';

const UNSUPPORTED_CONTROL_TYPES = {
    [constants.controlTypeNameToId.comments]: 1,
    [constants.controlTypeNameToId.links]: 1,
    [constants.controlTypeNameToId.name]: 1,
    [constants.controlTypeNameToId.coordinates]: 1,
    [constants.controlTypeNameToId.asset]: 1,
    [constants.controlTypeNameToId.color]: 1,
    [constants.controlTypeNameToId.plan]: 1,
    [constants.controlTypeNameToId.project]: 1,
    [constants.controlTypeNameToId.survey]: 1,
    [constants.controlTypeNameToId.file]: 1,
    [constants.controlTypeNameToId.multiselect]: 1,
    [constants.controlTypeNameToId.embed]: 1
};

const COMMON_HEADERS = ['unearthId', 'category', 'type', 'addedDateTime', 'lastUpdated', 'addedBy', 'places'];
const COLUMN_GROUP_HEADERS = 'column-groups-headers';
const PROPERTY_LIST = 'properties-list';

const X_SCROLL = 'x-scroll-container';
const Y_SCROLL = 'y-scroll-container';

const COL_WIDTH = 240;
const WIDTH_CATEGORY = 180;
const WIDTH_PRIMARY = 360;

const LOAD_PAGE_AT = 4330;
const ROW_HEIGHT = 48;
const BUFFER_ROW_COUNT = 50;

/**
 * Hide, Show and manage table headers
 */

class TableModel {

    constructor() {
        this.COLUMN_GROUP_HEADERS = COLUMN_GROUP_HEADERS;
        this.PROPERTY_LIST = PROPERTY_LIST;
        this.X_SCROLL = X_SCROLL;
        this.COL_WIDTH = COL_WIDTH;
        this.commonHeaders = COMMON_HEADERS;
        this.assetIds = [];
        this.cache = new Cached();
        this.styles = new TableStylesModel();
        this._sharedColumns = {};
        this.setupDebounceEvents();
    }

    getWidthCommon() {
        return this.cache.get('widthCommon', () => {
            let total = WIDTH_PRIMARY + this.getVisibleSharedColumnHeaders().length * COL_WIDTH;
            tableModel.commonHeaders.forEach((header) => {
                if (this.visibleHeaders[header]) {
                    if (header === 'category') {
                        total += WIDTH_CATEGORY;
                    } else {
                        total += COL_WIDTH;
                    }
                }
            });
            return total;
        });
    }

    get width() {
        return this.cache.get('width', () => {
            const widthCommon = this.getWidthCommon();
            return this.getVisibleTableHeaders().length * COL_WIDTH + widthCommon;
        });
    }

    get visibleHeaders() {
        return this.cache.get('visibleHeaders', () => this.tableOrList === 'table' ? this._visibleHeaders.table : this._visibleHeaders.list);
    }

    getVisibleTableHeaders() {
        return this.cache.get('visibleTableHeaders', () => this.tableHeaders.filter(({assetType, controlType}) => this.visibleHeaders[assetType.assetTypeId] && this.visibleHeaders[assetType.assetTypeId][controlType.label]));
    }

    getVisibleSharedColumnHeaders() {
        return this.cache.get('visibleSharedColumnHeaders', () => Object.keys(this._sharedColumns).filter(sharedColumnName => this.visibleHeaders[sharedColumnName]));
    }

    getVisibleTableHeaderSections() {
        return this.cache.get('visibleTableHeaderSections', () => {
            const headerSections = {};
            this.getVisibleTableHeaders().forEach(({assetType}) => {
                headerSections[assetType.assetTypeId] = {
                    assetType,
                    count: headerSections[assetType.assetTypeId] ? headerSections[assetType.assetTypeId].count + 1 : 1
                };
            });
            return Object.values(headerSections);
        });
    }

    getSharedColumnNames() {
        return Object.keys(this._sharedColumns);
    }

    getSharedColumnCount() {
        return this.cache.get('sharedColumnCount', () => this.getSharedColumnNames().length);
    }

    getSharedColumn(sharedColumnName) {
        return this._sharedColumns[sharedColumnName];
    }

    /* Used to determine positioning when navigating between cells in edit mode */
    getColumnIndexToCellMap() {
        return this.cache.get('columnIndexToCellMap', () => {
            const headersCommon = this.commonHeaders.filter(header => this.visibleHeaders[header]);
            const headersVisible = Object.keys(this.getVisibleTableHeaders()).map(index => `${this.getVisibleTableHeaders()[index].assetType.assetTypeId}${this.getVisibleTableHeaders()[index].controlType.label}`);
            return ['name', ...headersCommon, ...this.getVisibleSharedColumnHeaders(), ...headersVisible];
        });
    }

    get lastRowIndex() {
        return this.assetCount - 1;
    }

    get lastColumnIndex() {
        return this.getColumnIndexToCellMap().length - 1;
    }

    get tableOrList() {
        return this.cache.get('tableOrList', () => this.tableMode === 'list-left' ? 'list' : 'table');
    }

    get inEditMode() {
        // Users may leave "editModeOn" as their projectView default, but still not be in edit mode
        // (because of screen size, for example)
        return this.editModeOn && this.editingAllowed;
    }

    shouldDisplayTableFooter() {
        return siteModel.canBatchModify && tableModel.inEditMode;
    }

    setupDebounceEvents() {
        this.debounceRedraw = debounce(() => m.redraw(), 40);

        this.debounceHorizontalScroll = debounce(() => {
            if (this.tableMode === 'list-left') {
                return;
            }

            if (this.loadingFresh || isNaN(this.horizontalScrollPercent)) {
                if (!this.hideArrows) {
                    this.hideArrows = true;
                    this.resetTransform();
                    m.redraw();
                }
                return;
            } else if (this.hideArrows === true) {
                m.redraw();
            }

            this.hideArrows = false;
            this.columnGroupHeader = document.querySelector(`.${COLUMN_GROUP_HEADERS}`);
            this.propertyList = document.querySelector(`.${PROPERTY_LIST}`);
        }, 20);

        this.grabNextPage = debounce((e) => {
            if (!this.allContentLoaded && this.verticalScrollRemaining < LOAD_PAGE_AT && e.target && e.target.scrollTop !== 0) {
                this.fetch(this.offset);
            }
        }, 300);

        window.addEventListener('resize', debounce(() => {
            if (screenHelper.small()) {
                this.setMode('list-left', 600, false);
            } else {
                this.updateVisibleCellRange();
            }
        }));

    }

    reset() {
        this.assetIds = [];
        this.modifiedAssetIds = [];
        this.tableHeaders = [];
        this.transition = '';
        this.dismissedClass = '';
        this.commonHeaders = COMMON_HEADERS; // In case routed from meta to nonmeta project
        this._visibleHeaders = {list: {}, table: {}};
        this._sharedColumns = {};
        this.horizontalScrollPercent = 0;
        this.scrollTop = undefined;
        this.offset = 0;
        this.loadingFresh = true;
        this.loadingTable = true;
        this.showBatchModifyNotice = false;
        this.isCollapsed = false;
        this.loadRange = {
            startRow: 0,
            endRow: BUFFER_ROW_COUNT,
            startCol: 0,
            endCol: document.body.offsetWidth / COL_WIDTH + 1
        };
        if (screenHelper.small()) {
            this.setMode('list-left', 600, false);
            this.sideBarToggle();
        } else {
            this.setMode('table-bottom', 600, false);
        }

        this.cache = new Cached();
        this.styles = new TableStylesModel();

        // Asset row and cell data
        this.editModeOn = false;
        this.assetRows = {};
        this.activeCell = activeCell;
        this.visibleRange = {startRow: 0, endRow: 0, startCol: 0, endCol: 0};
    }

    resetCache(keepStyles = false) {
        this.cache = new Cached();
        if (!keepStyles) {
            this.resetStyles();
        }
        // Also reset any active cells in case cleared cache invalidates them
        if (this.inEditMode) {
            activeCell.resetState();
        }
    }

    resetStyles() {
        this.styles.resetCache();
        m.redraw();
    }

    init() {
        this.loadingTable = true;
        this.reset();
        this.tableHeaders = [];
        if (siteModel.isMetaProject) {
            // Exclude places header from meta projects.
            this.commonHeaders = this.commonHeaders.filter(id => id !== 'places');
        }
        const assetForms = Object.values(store.assetForms);
        assetForms.forEach((assetForm) => {
            const assetType = assetForm.assetType;
            if (assetType.assetTypeId !== constants.commentAssetTypeId) {
                this._visibleHeaders.list[assetType.assetTypeId] = {};
                this._visibleHeaders.table[assetType.assetTypeId] = {};
                // If tag exists, memo it in sharedColumns
                assetForm.controls.forEach((controlType) => {
                    if (controlType.attributes.sharedColumn) {
                        const sharedColumnName = controlType.attributes.sharedColumn;
                        if (this._sharedColumns[sharedColumnName]) {
                            this._sharedColumns[sharedColumnName].addAssetTypeId(assetType.assetTypeId);
                        } else {
                            this._sharedColumns[sharedColumnName] = new SharedColumnModel(sharedColumnName, assetType.assetTypeId, controlType);
                        }
                        popupModel.disableCloseFor();
                    }

                    if (!UNSUPPORTED_CONTROL_TYPES[controlType.controlTypeId]) {
                        this.tableHeaders.push({
                            controlType,
                            assetType
                        });
                    }
                });
            }
        });
        commonFilterModel.init().then(() => filterModel.loadFilterStates().then(() => {
            this.fetchAllAssetsCount();
            this.fetch();
            this.checkScrollArrows();
            this.loadingTable = false;
        })).then(()=> {
            // Hide category if there is only one toolGroup.
            if (!toolboxModel.hasMultipleGroups) {
                this._visibleHeaders.list.category = false;
                this._visibleHeaders.table.category = false;
            }
            this.resetCache();
        });
    }

    initDefaultVisibility() {
        this._visibleHeaders.list.category = true;
        this._visibleHeaders.list.addedBy = true;
        this._visibleHeaders.list.type = true;
        this._visibleHeaders.list.places = false;
        this._visibleHeaders.list.addedDateTime = true;
        this._visibleHeaders.list.lastUpdated = true;
        this._visibleHeaders.list.unearthId = false;
        this._visibleHeaders.table.category = true;
        this._visibleHeaders.table.addedBy = true;
        this._visibleHeaders.table.type = true;
        this._visibleHeaders.table.places = false;
        this._visibleHeaders.table.addedDateTime = true;
        this._visibleHeaders.table.lastUpdated = true;
        this._visibleHeaders.table.unearthId = false;
    }

    fetchAllAssetsCount() {
        const assetTypeIdIn = [];
        Object.values(store.tools).forEach(tool => {
            if (!tool.attributes.hidden && tool.toolId !== constants.commentToolId) {
                assetTypeIdIn.push(tool.assetForm.assetType.assetTypeId);
            }
        });
        const args = {
            isVisible: true,
            projectId: siteModel.projectId,
            siteId: siteModel.siteId,
            assetTypeIdIn
        };
        filterModel.addProjectAuthFilters(args);
        return api.rpc.count('Content', args, false, false).then((results) => {
            this.totalAssetCount = results.count;
            m.redraw();
        });
    }

    isColumnVisible(opts) {
        const visibleHeaders = this.visibleHeaders;
        if (opts.sharedColumnName) {
            return visibleHeaders[opts.sharedColumnName];
        }
        return visibleHeaders[opts.assetTypeId] && visibleHeaders[opts.assetTypeId][opts.controlTypeName];
    }

    getState() {
        return {
            _visibleHeaders: this._visibleHeaders,
            tableMode: this.tableMode,
            editModeOn: this.editModeOn
        };
    }

    setState(stateObject) {
        if (!stateObject) {
            return;
        }
        this._visibleHeaders = Object.assign({}, this._visibleHeaders, stateObject._visibleHeaders);
        this.resetCache(); // Reset all column header styling with saved project view settingss

        //FIXME this can be removed in the future. This is to avoid having to back fill.
        if (this.visibleHeaders.category === undefined) {
            this.initDefaultVisibility();
        }

        // Hide places filter on meta projects
        if (siteModel.isMetaProject) {
            this._visibleHeaders.list.places = false;
            this._visibleHeaders.table.places = false;
        }

        if (stateObject.tableMode && !screenHelper.small()) {
            this.setMode(stateObject.tableMode);
        }

        if (stateObject.editModeOn) {
            this.toggleEditMode(true);
        }

        this.resetCache();
    }

    renderWithDefaultFilters() {
        this.loadingFresh = true;
        filterModel.resetAllFilters();
        commonFilterModel.resetAllFilters().then(() => {
            this.fetch(0, true);
            filterModel.isDirty = true;
            this.showBatchModifyNotice = false;
            filterModel.saveFilterStates();
        });
    }

    toggleColumnVisibility(assetTypeId, fieldName) {
        if (this._visibleHeaders[this.tableOrList][assetTypeId] === undefined) {
            this._visibleHeaders[this.tableOrList][assetTypeId] = {};
        }
        this._visibleHeaders[this.tableOrList][assetTypeId][fieldName] = !this._visibleHeaders[this.tableOrList][assetTypeId][fieldName];
        filterModel.isDirty = true;
        tableModel.checkScrollArrows();
        popupModel.disableCloseFor();
        this.resetCache();
    }

    toggleCommonHeaders(id) {
        this._visibleHeaders[this.tableOrList][id] = !this._visibleHeaders[this.tableOrList][id];
        filterModel.isDirty = true;
        tableModel.checkScrollArrows();
        popupModel.disableCloseFor();
        this.resetCache();
    }

    sideBarToggle() {
        this.isCollapsed = !this.isCollapsed;
    }

    toggleEditMode(toMode = !this.editModeOn) {
        toMode = this.editingAllowed ? toMode : false;
        if (this.editModeOn === toMode) {
            return; // Already in correct mode.
        }
        this.editModeOn = toMode;
        if (!this.inEditMode) {
            this.modifiedAssetIds.forEach(assetId => this.checkThenRemoveFromTable(assetId));
            this.modifiedAssetIds = [];
        } else {
            this.resetCache();
            this.updateVisibleCellRange();
            activeCell.resetState();
        }
        filterModel.isDirty = true;
        m.redraw();
    }

    scrollIfOutOfView(row, column) {
        if (this.inEditMode) {
            let scrolled;
            // Determine if vertical scroll is required
            if (this.visibleRange.startRow > row) {
                scrolled = true;
                const targetY = document.querySelector(`.${Y_SCROLL}`);
                // Increment scroll to bring row into view
                targetY.scrollTop -= (this.visibleRange.startRow - row) * ROW_HEIGHT;
            } else if (this.visibleRange.endRow < row) {
                scrolled = true;
                // Reset scroll to make selected row the first visible
                const targetY = document.querySelector(`.${Y_SCROLL}`);
                targetY.scrollTop = row * ROW_HEIGHT;
                // Determine if horizontal scroll is required
            } else if (column > 0) { // First column is fixed, ignore it.
                if (this.visibleRange.startCol > column) {
                    scrolled = true;
                    this.scrollLeft(this.visibleRange.startCol - column);
                } else if (this.visibleRange.endCol < column) {
                    scrolled = true;
                    this.scrollRight(column - this.visibleRange.endCol);
                }
            }
            return scrolled;
        }
    }

    updateVisibleCellRange() {
        if (this.inEditMode) {
            const target = document.querySelector('#app');
            this.visibleRange.middleX = Math.floor(target.clientWidth / 2);
            this.visibleRange.middleY = Math.floor(target.clientHeight / 2);
            this.updateVisibleColumnRange();
            this.updateVisibleRowRange();
        }
    }

    updateVisibleColumnRange() {
        const targetX = document.querySelector(`.${X_SCROLL}`);
        if (targetX) {
            const scrollLeft = targetX.scrollLeft;
            this.visibleRange.startCol = Math.ceil(scrollLeft / COL_WIDTH) + 1;
            this.visibleRange.endCol = Math.floor((targetX.clientWidth + scrollLeft) / COL_WIDTH) - 1;
        }
    }

    updateVisibleRowRange() {
        const targetY = document.querySelector(`.${Y_SCROLL}`);
        if (targetY) {
            const scrollTop = tableModel.scrollTop ? tableModel.scrollTop : 0;
            this.visibleRange.startRow = Math.ceil(scrollTop / ROW_HEIGHT);
            this.visibleRange.endRow = Math.floor((targetY.clientHeight + scrollTop) / ROW_HEIGHT) - 1;
        }
    }

    setMode(mode, transitionTime = 600, saveFilter = true) {
        this.transition = ` ${this.tableMode}-to-${mode}`;
        this.tableMode = mode;
        // Can't edit unless in table view
        this.editingAllowed = !router.params.assetId && (mode === 'table-bottom' || mode === 'table-full');

        popupModel.close();
        this.propertyList = undefined;
        this.columnGroupHeader = undefined;
        filterModel.saveFilterStates(saveFilter);

        const target = document.querySelector(`.${Y_SCROLL}`);
        if (target) {
            target.scrollTop = 0;
        }

        setTimeout(() => {
            this.transition = '';
            m.redraw();
        }, transitionTime);

        if (this.tableMode === 'list-left') {
            this.resetTransform();
            this.resetCache();
        } else {
            // If we're moving from table-bottom to table-full in edit mode, arrowing through rows/columns needs adjusting
            setTimeout(() => {
                this.updateVisibleCellRange();
                if (activeCell.row >= 0) {
                    this.scrollIfOutOfView(activeCell.row, activeCell.column);
                }
                this.resetCache();
            }, 100); // Enough time for new table size to take effect
        }
    }

    getControlTypes(toolId) {
        const tool = store.tools[toolId];
        if (!tool) {
            return [];
        }
        const placeCount = timeCache(() => Object.values(store.places).length, 'placesLength', 1000);
        return tool.assetForm.controls.filter((control) =>
            !UNSUPPORTED_CONTROL_TYPES[control.controlTypeId]
            && !control.attributes.hidden
            && !(control.controlTypeId === constants.controlTypeNameToId.place && placeCount <= 1));
    }

    hideAssetType(assetType, showRevertMessage) {
        const filtersInUse = filterModel.getInUseFilters(assetType.assetTypeId);
        const inUseHeaders = {};
        let foundInUse = false;
        let columnNameString = '';
        popupModel.disableCloseFor();

        for (const filter of filtersInUse) {
            if (this.visibleHeaders[assetType.assetTypeId][filter.fieldName]) {
                inUseHeaders[filter.fieldName] = true;
                foundInUse = true;
                columnNameString += ` ${filter.fieldName},`;
            }
        }

        if (foundInUse) {
            columnNameString = columnNameString.substr(0, columnNameString.length - 1);
            message.show(`Columns with filters applied (${columnNameString}) cannot be hidden. Reset the filters before hiding.`);
        } else if (showRevertMessage) {
            const copy = Object.assign({}, this.visibleHeaders[assetType.assetTypeId]);
            message.show(HeaderHideMessage.toDom({
                assetType,
                onClick: () => {
                    this._visibleHeaders[this.tableOrList][assetType.assetTypeId] = copy;
                    filterModel.saveFilterStates(true);
                    this.resetCache();
                    m.redraw();
                }
            }));
        }
        this._visibleHeaders[this.tableOrList][assetType.assetTypeId] = inUseHeaders;
        filterModel.saveFilterStates(true);
        this.resetCache();
        this.resetTransform();
    }

    checkScrollArrows() {
        setTimeout(() => {
            const target = document.querySelector(`.${X_SCROLL}`);
            if (!target) {
                return;
            }
            this.onHorizontalScroll({target});
        }, 15);

    }

    /**
     * Scroll events and scroll methods
     */

    onHorizontalScroll(e) {
        e.redraw = false;
        this.debounceHorizontalScroll(e);
        const target = e.target;
        const scrollLeft = target.scrollLeft;
        const screenWidthCols = document.body.offsetWidth / COL_WIDTH + 1;
        const startCol = Math.floor(target.scrollLeft / COL_WIDTH);
        const width = target.clientWidth;
        const scrollWidth = target.scrollWidth - width;

        this.horizontalScrollPercent = scrollLeft / scrollWidth * 100;
        this.loadRange.startCol = startCol - screenWidthCols;
        this.loadRange.endCol = startCol + screenWidthCols;

        if (this.columnGroupHeader) {
            this.columnGroupHeader.style.cssText = `transform: translateX(-${scrollLeft}px);`;
        }

        if (this.propertyList) {
            this.propertyList.style.cssText = `transform: translateX(-${scrollLeft}px);`;
        }

        this.updateVisibleColumnRange();

        this.debounceRedraw();

    }

    onVerticalScroll(e) {
        e.redraw = false;
        if (e.stopPropagation) {
            e.stopPropagation();
        }
        const target = e.target;
        this.scrollTop = target.scrollTop;
        const height = target.clientHeight;
        const scrollHeight = target.scrollHeight - height;
        this.verticalScrollRemaining = scrollHeight - this.scrollTop;
        const startRow = this.tableMode === 'list-left' ? elementScroll.findFirstVisibleIndex(e) : Math.floor(this.scrollTop / ROW_HEIGHT);
        this.loadRange.startRow = startRow - BUFFER_ROW_COUNT;
        this.loadRange.endRow = startRow + BUFFER_ROW_COUNT;
        this.grabNextPage(e);
        this.updateVisibleRowRange();

        this.debounceRedraw();
    }

    scrollRight(numColumns = 1) {
        const xScrollContainer = document.querySelector(`.${X_SCROLL}`);
        if (xScrollContainer) {
            tween(xScrollContainer, 'scrollLeft', xScrollContainer.scrollLeft, xScrollContainer.scrollLeft + COL_WIDTH * numColumns, 300);
        }
    }

    scrollLeft(numColumns = 1) {
        const xScrollContainer = document.querySelector(`.${X_SCROLL}`);
        if (xScrollContainer) {
            tween(xScrollContainer, 'scrollLeft', xScrollContainer.scrollLeft, xScrollContainer.scrollLeft - COL_WIDTH * numColumns, 300);
        }
    }

    rememberScroll() {
        if (this.scrollTop === undefined) {
            return;
        }
        const xScrollContainer = document.querySelector(`.${Y_SCROLL}`);
        if (xScrollContainer) {
            xScrollContainer.scrollTop = this.scrollTop;
        }
    }

    resetTransform() {

        this.columnGroupHeader = document.querySelector(`.${COLUMN_GROUP_HEADERS}`);
        this.propertyList = document.querySelector(`.${PROPERTY_LIST}`);
        const xScrollContainer = document.querySelector(`.${X_SCROLL}`);

        if (this.columnGroupHeader) {
            this.columnGroupHeader.style.cssText = 'transform: translateX(0);';
        }

        if (this.propertyList) {
            this.propertyList.style.cssText = 'transform: translateX(0);';
        }

        if (xScrollContainer) {
            xScrollContainer.scrollLeft = 0;
        }
    }

    switchAllTableVisibility(controls, assetType, isOn) {
        if (isOn === false) {
            popupModel.disableCloseFor();
            this.hideAssetType(assetType);
            this.resetCache();
            return;
        }
        const assetTypeId = assetType.assetTypeId;
        if (!this._visibleHeaders[this.tableOrList][assetTypeId]) {
            this._visibleHeaders[this.tableOrList][assetTypeId] = {};
        }
        for (const control of controls) {
            this._visibleHeaders[this.tableOrList][assetTypeId][control.label] = isOn;
        }
        popupModel.disableCloseFor();
        this.resetCache();
        filterModel.isDirty = true;
    }

    switchEntireTableVisibility(isOn) {
        if (isOn) {
            const headers = this.tableHeaders;
            for (const header of headers) {
                const assetTypeId = header.assetType.assetTypeId;
                if (this._visibleHeaders[this.tableOrList][assetTypeId] === undefined) {
                    this._visibleHeaders[this.tableOrList][assetTypeId] = {};
                }
                this._visibleHeaders[this.tableOrList][header.assetType.assetTypeId][header.controlType.label] = isOn;
            }
        } else {
            const assetTypes = Object.values(store.assetTypes);
            assetTypes.forEach((assetType) => {
                this.hideAssetType(assetType);
            });
        }
        this.commonHeaders.forEach((name) => {
            this._visibleHeaders[this.tableOrList][name] = isOn;
        });
        popupModel.disableCloseFor();
        this.resetCache();
        filterModel.isDirty = true;
    }

    isEntireTableVisible() {
        let isAllVisible = true;
        let isAllNotVisible = true;
        const headers = this.tableHeaders;
        for (const header of headers) {
            if (this.visibleHeaders[header.assetType.assetTypeId] && this.visibleHeaders[header.assetType.assetTypeId][header.controlType.label]) {
                isAllNotVisible = false;
            } else {
                isAllVisible = false;
            }
        }
        this.commonHeaders.forEach((name) => {
            if (this.visibleHeaders[name]) {
                isAllNotVisible = false;
            } else {
                isAllVisible = false;
            }
        });
        return {isAllVisible, isAllNotVisible};
    }

    isAllVisible(controls, assetType) {
        let isAllVisible = true;
        let isAllNotVisible = true;
        const headers = this.visibleHeaders[assetType.assetTypeId];
        if (!headers) {
            return {isAllVisible: false, isAllNotVisible: true};
        }
        for (const controlType of controls) {
            if (headers[controlType.label]) {
                isAllNotVisible = false;
            } else {
                isAllVisible = false;
            }
        }
        return {isAllVisible, isAllNotVisible};
    }

    // Force sync will update current assets in store with asset data retrieved even if they already exist
    fetch(offset = 0, forceSync = false) {
        const args = filterModel.getArgs(offset);
        if (offset === 0) {
            featureModel.search();
            this.assetIds = [];
            this.offset = 0;
            this.loadingFresh = true;
            this.allContentLoaded = false;
        } else {
            this.loadingPage = true;
        }

        if (this.cancelFetchRequest) {
            this.cancelFetchRequest();
        }

        if (this.cancelCountRequest) {
            this.cancelCountRequest();
        }

        this.cancelCountRequest = api.rpc.cancelableRequest([['countContent', Object.assign({}, args, {
            limit: undefined,
            offset: undefined,
            order: undefined
        })]], ([results]) => {
            this.cancelCountRequest = undefined;
            this.assetCount = results.count;
            m.redraw();
        });

        this.cancelFetchRequest = api.rpc.cancelableRequest([['listContent', args]], ([results]) => {
            this.cancelFetchRequest = undefined;
            this.loadingFresh = this.loadingPage = false;

            results.forEach(asset => {
                assetModel.addToStore(asset, forceSync);
                this.assetIds.push(asset.contentId);
                return asset.contentId;
            });
            if (results.length < filterModel.itemsPerPage) {
                this.allContentLoaded = true;
            }
            this.offset = this.assetIds.length;
            this.checkScrollArrows();
            this.resetCache();
            m.redraw();

        });
    }

    reload() {
        activeCell.deactivateCell();
        tableModel.showBatchModifyNotice = false;
        tableModel.fetch(0, true);
    }

    awaitChanges() {

        publish.await({
            changeType: 'deleted',
            recordType: 'content',
            test: asset => store.assets[asset.contentId],
            callback: asset => this.removeDeletedAsset(asset.contentId),
            persist: true
        });

        publish.await({
            changeType: 'deleted',
            recordType: 'thread',
            callback: thread => {

                Object.values(store.assets).forEach(asset => {

                    const assetId = asset.contentId;

                    if (asset.threadId === thread.threadId && assetId !== formModel.assetId) {

                        this.removeDeletedAsset(assetId);

                        this.deleteAssetFeatures(asset.featureIds);

                    }

                });

                m.redraw();

            },
            persist: true
        });

        publish.await({
            changeType: 'modified',
            recordType: 'content',
            test: asset => store.assets[asset.contentId],
            callback: asset => {
                const changedByUser = asset.changedBy.userId;
                if (this.inEditMode && changedByUser !== userModel.userId) {
                    asset.recentlyEdited = true;
                    store.assets[asset.contentId] = asset;
                    setTimeout(() => {
                        asset.recentlyEdited = false;
                        store.assets[asset.contentId] = asset;
                        m.redraw();
                    }, 10000);
                }

                if (constants.commentAssetTypeId === asset.assetTypeId) {
                    return;
                }

                const assetId = asset.contentId;

                if (formModel.assetId === assetId) {
                    store.assets[assetId] = deepMerge(store.assets[assetId], asset);
                } else {
                    assetModel.addToStore(asset, true);
                }

                // If this asset is currently being edited by another user, update to the new current value for undoing edits
                if (this.inEditMode && changedByUser !== userModel.userId) {
                    if (asset.contentId === activeCell.assetId) {
                        activeCell.saveCellValue();
                        m.redraw();
                    }
                }

                if (!this.inEditMode) {
                    this.checkThenRemoveFromTable(assetId);
                } else {
                    this.modifiedAssetIds.push(assetId);
                }

                m.redraw();
            },
            persist: true
        });

        publish.await({
            changeType: 'new',
            recordType: 'content',
            callback: asset => {

                const assetTypeId = asset.assetTypeId;

                if (constants.commentAssetTypeId === assetTypeId) {
                    return;
                }

                const assetId = asset.contentId;


                if (!siteModel.isMetaProject || siteModel.isMetaProject && assetModel.isProjectAsset(asset)) {
                    this.totalAssetCount++;
                }

                if (formModel.assetId === assetId) {
                    asset = deepMerge(store.assets[assetId], asset);
                }

                assetModel.addToStore(asset, true);

                commonFilterModel.onNewContent(assetId);

                if (filterModel.doesMatchFilters(assetId)) {
                    this.addNewAsset(assetId);
                }

                m.redraw();

            },
            persist: true
        });

        publish.await({
            changeType: 'batchModify',
            recordType: 'content',
            test: (change) => change.projectId === router.params.projectId,
            callback: (response) => this.handleBatchModifyResponse(response)
        });
    }

    checkThenRemoveFromTable(assetId) {
        const asset = store.assets[assetId];
        if (!assetId || !asset || !asset.authorId) {
            return;
        }

        if (!filterModel.doesMatchFilters(assetId)) {
            const index = this.assetIds.indexOf(assetId);
            if (index !== -1) {
                this.assetIds.splice(index, 1);
                delete this.assetRows[assetId];
                this.assetCount--;
                m.redraw();
            }
        }
    }

    removeDeletedAsset(assetId) {

        const asset = store.assets[assetId];

        if (!asset || constants.commentAssetTypeId === asset.assetTypeId) {
            delete store.assets[assetId];
            return;
        }

        commonFilterModel.onDeletedContent(assetId);

        const index = this.assetIds.indexOf(assetId);

        if (index !== -1) {

            this.assetIds.splice(index, 1);

            delete this.assetRows[assetId];

            if (!siteModel.isMetaProject || siteModel.isMetaProject && assetModel.isProjectAsset(asset)) {

                this.assetCount--;

            }

        }

        if (assetId !== formModel.assetId) {

            delete store.assets[assetId];

            if (!siteModel.isMetaProject || siteModel.isMetaProject && assetModel.isProjectAsset(asset)) {

                this.totalAssetCount--;

            }

            m.redraw();

            this.deleteAssetFeatures(asset.featureIds);

        }

    }

    deleteAssetFeatures(featureIds) {

        const sourcesToRender = {};

        if (featureIds && featureIds.length > 0) {

            featureIds.forEach(featureId => {

                const feature = store.features[featureId];

                if (feature) {

                    const featureTypeId = feature.properties.featureTypeId;

                    sourcesToRender[featureTypeId] = featureModel.deleteFeature(feature, sourcesToRender[featureTypeId]);

                }

            });

        }

        Object.values(sourcesToRender).forEach(source => {

            source.setData(source._data);

        });

    }

    handleCellClick(e, row, column) {
        if (this.inEditMode) {
            if (e) {
                e.stopPropagation();
            }
            activeCell.onCellClick(row, column);
        }
    }

    saveTableState() {
        filterModel.saveFilterStates();
        m.redraw();
    }

    addNewAsset(assetId) {

        this.assetIds.unshift(assetId);

        if (store.assets[assetId].media) {

            this.assetIds.sort((a, b) =>
                store.assets[b].createdDateTime.localeCompare(store.assets[a].createdDateTime)
            );

        }

        this.assetCount++;

    }

    viewProject(assetId) {
        const projectId = assetIdToProjectId(assetId);
        if (projectId) {
            // Close open panels (eg the People panel)
            if (panelModel.isOpen) {
                panelModel.close();
            }
            router.set({projectId});
        }
    }

    // Visually hides the table (keeping all data in state, simply hiding from sight)
    dismissTable() {
        tableModel.dismissedClass = ' table-dismissed ';
    }

    // Brings back table from dismissTable call
    recallTable() {
        tableModel.dismissedClass = '';
    }


    // Call back to execute upon publish of batch modify changes for current project.
    // Given the assetTypeId, check if any assets exist of this type, and if so, display notice to table popups:
    handleBatchModifyResponse(response) {
        batchModifyModel.handleApiResponse(response);

        // Only update if >0 records were updated
        if (response.numberOfRecordsModified) {
            // and if we have >0 assets of that type
            if (commonFilterModel.assetTypeCounts[response.assetTypeId]) {
                this.showBatchModifyNotice = true;
                m.redraw();
            }
        }
    }

}

const tableModel = new TableModel();

initializer.add(() => tableModel.reset());
initializer.addSiteCallback(() => tableModel.init());

export default tableModel;
