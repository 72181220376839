import {Marker} from 'mapbox-gl';
import randomId from 'util/numbers/random-id';
import turfLength from '@turf/length';
import turfLineString from 'turf-linestring';

class Draw {

    randomId() {

        return randomId();

    }

    require(opts, key) {

        return opts[key] || console.error(`Option "${key}" is required.`);

    }

    constructor(opts) {

        this.opts = opts;

        this.map = this.require(opts, 'map');
        this.source = this.require(opts, 'source');

        this.units = opts.metric ? 'meters' : 'feet';

        this.color = opts.color;

        this.shortUnits = {
            feet: 'ft',
            meters: 'm'
        }[this.units];

        this.vertices = [];

    }

    setCursor(cursor) {

        this.map.getCanvasContainer().style.cursor = cursor;

    }

    length(coordinates) {

        return parseFloat(turfLength(turfLineString(coordinates), {units: this.units}).toFixed(2)) + this.shortUnits;

    }

    create(properties) {

        this.isNewFeature = true;

        this.properties = properties || {};

        this.setCursor('crosshair');

        return this.newFeature();

    }

    removeFeature(feature = this.feature) {

        const featureIndex = this.source._data.features.findIndex(f => f.id === feature.id);

        this.source._data.features.splice(featureIndex, 1);

        return featureIndex;

    }

    // Split a Multi- feature into multiple single features
    split(feature) {

        const featureIndex = this.removeFeature(feature);

        const delegates = [];

        feature.geometry.coordinates.forEach((coordinates, i) => {

            const id = feature.id + i;

            const tempFeature = {
                type: 'Feature',
                id,
                properties: Object.assign({}, feature.properties, {_id: id}),
                geometry: {
                    type: this.type,
                    coordinates
                }
            };

            this.source._data.features.splice(featureIndex, 0, tempFeature);

            const delegate = new this.constructor(this.opts).edit(tempFeature);

            function syncDelegate(handler, f) {

                feature.geometry.coordinates[i] = f.geometry.coordinates;

                return handler && handler(feature);

            }

            delegate.onVertexAdded = f => syncDelegate(this.onVertexAdded, f);
            delegate.onVertexChanged = f => syncDelegate(this.onVertexChanged, f);
            delegate.onComplete = f => syncDelegate(this.onComplete, f);
            delegate.onStop = f => syncDelegate(this.onStop, f);

            delegates.push(delegate);

        });

        this.reset = () => {

            delegates.forEach(delegate => {

                Object.assign(delegate.feature.properties, feature.properties, {
                    _id: delegate.feature.properties._id
                });

                delegate.reset();

            });

        };

        this.stop = () => {

            delegates.forEach(delegate => {

                delegate.stop();

                const index = this.source._data.features.findIndex(f => f.id === delegate.feature.id);

                this.source._data.features.splice(index, 1);

            });

            this.source._data.features.splice(featureIndex, 0, feature);

            this.source.setData(this.source._data);

        };

        return this;

    }

    edit(feature) {

        if (feature.geometry.type.startsWith('Multi')) {            

            return this.split(feature);

        }

        const geomType = this.type;

        if (feature.geometry.type !== geomType) {

            return console.error(`The ${geomType} class should only be used to edit ${geomType}s or Multi${geomType}s. You provided a ${feature.geometry.type}.`);

        }

        this.isNewFeature = false;

        this.properties = feature.properties;

        this.feature = feature;

        this.feature.properties._id = feature.id;

        this.render();

        return this.editFeature();

    }

    makeVertex(lngLat, index = this.vertices.length, opts) {

        const element = document.createElement('i');

        element.classList.add('icon-vertex');

        const pulse = document.createElement('div');

        pulse.classList.add('pulse');

        element.appendChild(pulse);

        const vertex = new Marker(Object.assign({
            draggable: !this.isNewFeature,
            element,
            anchor: 'center'
        }, opts))
            .setLngLat(lngLat)
            .addTo(this.map);

        this.setVertexColor(vertex);

        vertex.index = index;

        this.vertices.slice(index).forEach(v => {

            v.index++;

        });

        this.vertices.splice(index, 0, vertex);

        return vertex;

    }

    setVertexColor(vertex) {

        vertex._element.style.borderColor = this.feature.properties._lineColor || this.feature.properties._fillColor || this.color || '#000000';

    }

    reset() {

        const coordinates = this.getCoordinates();

        this.vertices.forEach((vertex, i) => {

            vertex.setLngLat(coordinates[i]);

            this.setVertexColor(vertex);

        });

        this.render();

    }

    removeVertices() {

        this.vertices.forEach(vertex => vertex.remove());

        this.vertices = [];

    }

    stop() {

        this.setCursor('');

        this.removeVertices();

        this.removeEventListeners();

        if (this.onStop) {

            this.onStop(this.feature);

        }

        return this;

    }

}

export default Draw;
