import Popover from 'legacy/components/popover';
import initializer from 'util/initializer';

initializer.add(() => inspectorModel.cleanup());

const inspectorModel = {

    cleanup() {

        Object.assign(inspectorModel, {
            title: null,
            view: null,
            className: null,
            onClose: null,
            tip: null
        });

    },

    close() {

        Popover.hide();

        if (inspectorModel.onClose) {

            inspectorModel.onClose();

        }

        inspectorModel.cleanup();

        m.redraw();

    }

};

export default inspectorModel;
