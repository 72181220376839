import toggle from 'legacy/util/dom/toggle-element';
import uav from 'uav';

const defaults = {
    width: 300
};

let model = uav.model({
    visible: false,
    view: null,
    cssClass: '',
    width: defaults.width
});

function modal() {
    return uav.component(`
    <div u-class="modal {visible}" style="display:none">
        <div u-class="modal-wrap {cssClass}" u-style="max-width:{width}px;">
            {view}
        </div>
    </div>
    `, model);

}

modal.isOpen = () => model.visible;

modal.hide = () => {

    toggle.hide(model._el, () => {

        model.cssClass = '';

        model.view = null;

    });

    model.visible = false;

    if (model.onclose) {

        model.onclose();

    }

};

modal.show = opts => {

    Object.keys(defaults).forEach(key => {

        model[key] = opts[key] === undefined ? defaults[key] : opts[key];

    });

    model.view = opts.view;

    model.onclose = opts.onclose;

    model.cssClass = opts.cssClass;

    if (!model._el) {

        model = modal();

    }

    document.querySelector('#app').appendChild(model._el);

    return new Promise(resolve => toggle.show(model._el, () => {

        model.visible = true;

        resolve();

    }));

};

export default modal;
