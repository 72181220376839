import Draw from './draw';
import {Marker} from 'mapbox-gl';
import getTextElement from './util/get-text-element';

class Text extends Draw {

    constructor(opts) {

        super(opts);

        this.addVertex = this._addVertex.bind(this);

        this.type = 'Point';

    }

    getCoordinates() {

        return [this.feature.geometry.coordinates];

    }

    render() {

        if (this.feature.geometry.coordinates.length) {

            this.source.setData(this.source._data);

        }

    }

    _addVertex(e) {

        const lngLat = e.lngLat.toArray();

        this.feature.geometry.coordinates = lngLat;

        this.stop();

        if (this.onVertexAdded) {

            this.onVertexAdded(this.feature);

        }

        if (this.onComplete) {

            this.onComplete(this.feature);

        }

    }

    newFeature() {

        const id = this.randomId();

        this.feature = {
            type: 'Feature',
            id,
            geometry: {
                type: 'Point',
                coordinates: []
            },
            properties: Object.assign(this.properties, {_id: id})
        };

        this.map.on('click', this.addVertex);

        return this;

    }

    editFeature() {

        this.render();

        const element = document.createElement('div'),
            wrap = getTextElement(this.feature.properties._textField || 'text', this.feature, this.map),
            input = wrap.firstElementChild,
            vertex = document.createElement('i');

        element.classList.add('textbox');
        vertex.classList.add('icon-vertex');

        element.appendChild(wrap);
        wrap.appendChild(vertex);

        input.onkeydown = e => e.stopPropagation();
        // div.ondragstart = e => e.stopPropagation();
        input.onmousedown = e => e.stopPropagation();
        // div.onmouseup = e => e.stopPropagation();
        input.onclick = e => e.stopPropagation();

        input.oninput = () => {

            this.feature.properties._textField = input.innerText;

            this.render();

            if (this.onVertexChanged) {

                this.onVertexChanged(this.feature);

            }

        };

        this.resetSize = () => {
            getTextElement.setSizeAndColor(input, this.feature, this.map);
            input.innerText = this.feature.properties._textField;
        };

        this.textbox = new Marker(Object.assign({
            draggable: true,
            element,
            anchor: 'center'
        })).setLngLat(this.feature.geometry.coordinates)
            .addTo(this.map)
            .on('drag', () => {

                const lngLat = this.textbox.getLngLat().toArray();

                this.feature.geometry.coordinates = lngLat;
                this.feature.properties._latitude = lngLat[1] * Math.PI / 180; // to radians

                this.resetSize();

                this.render();

            }).on('dragend', () => {

                if (this.onVertexChanged) {

                    this.onVertexChanged(this.feature);

                }

            });

        input.focus();

        document.execCommand('selectAll', false, null);

        this.setCursor('');

        this.map.on('zoom', this.resetSize);

        this.map.on('rotate', this.resetSize);

        return this;

    }

    reset() {

        if (this.textbox) {

            const div = this.textbox._element.querySelector('.textbox-content');

            if (div) {

                const textField = this.feature.properties._textField;

                if (textField !== div.textContent) {

                    div.textContent = textField;

                    if (this.onVertexChanged) {

                        this.onVertexChanged(this.feature);

                    }

                }

            }

        }

        this.render();

        // We can't reset the size unless the feature has been added to the map.
        if (this.resetSize) {
            this.resetSize();
        }

    }

    removeEventListeners() {

        this.map.off('click', this.addVertex);
        this.map.off('zoom', this.resetSize);
        this.map.off('rotate', this.resetSize);

    }

    stop() {

        if (this.textbox) {

            this.textbox.remove();

        }

        Draw.prototype.stop.call(this);

    }

}

export default Text;
