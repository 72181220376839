import uav from 'uav';
import signUp from 'legacy/components/auth/sign-up';
import validatedField from 'legacy/components/validated-field';
import submitButton from 'legacy/components/submit-button';
import startTrial from 'legacy/components/auth/sign-up/start-trial';
import promoteUser from 'legacy/components/auth/sign-up/promote-user';
import formatPhone from 'legacy/util/numbers/format-phone';

/**
 * STEP 1: The initial view
 */
export default (params, id) => {

    function formIsValid() {

        return step1.email.isValid()
            && step1.firstName.isValid()
            && step1.lastName.isValid()
            && step1.phone.isValid()
            && step1.password.isValid()
            && step1.terms.isValid()
            && step1.companyName.isValid();

    }

    function onSubmit(e) {

        if (e) {

            e.preventDefault();

        }

        if (formIsValid()) {

            step1.submit.spinning = true;

            id.phone = formatPhone(step1.phone.value);

            id.password = step1.password.value;

            id.terms = step1.terms.value;

            id.accountName = step1.companyName.value;

            if (signUp.isTrial) {

                startTrial(id);

            } else {

                promoteUser(id);

            }

        } else {

            step1.submit.spinning = false;

        }

    }

    function oninput() {

        step1.submit.disabled = !formIsValid();

        id.firstName = step1.firstName.value;

        id.lastName = step1.lastName.value;

        id.phone = formatPhone(step1.phone.value);

        id.email = step1.email.value;

        id.trimNames();

    }


    const step1 = uav.component(`
    <div class="step1">
        <form class="form sign-up-form new-form" u-onsubmit="{submit.onclick}">
            <div class="sign-up-form-fields">
                {firstName}
                {lastName}
                {email}
                {phone}
                {password}
                {companyName}
                {terms}
            </div>
            <div class="form-bottom">
                {submit}
            </div>
        </form>
    </div>`, {
        email: validatedField({
            event: {
                category: 'signup'
            },
            value: params.unearthemail || id.email,
            label: 'Business Email',
            prompt: 'You\'ll use this email address to sign in.',
            validationType: 'email',
            oninput
        }),
        firstName: validatedField({
            event: {
                category: 'signup'
            },
            value: params.unearthfname || id.firstName,
            label: 'First Name',
            validationType: 'name',
            oninput,
            autofocus: true
        }),
        lastName: validatedField({
            event: {
                category: 'signup'
            },
            value: params.unearthlname || id.lastName,
            label: 'Last Name',
            validationType: 'name',
            oninput
        }),
        phone: validatedField({
            event: {
                category: 'signup'
            },
            type: 'tel',
            value: params.unearthphone || id.phone,
            label: 'Mobile Number',
            prompt: 'We\'ll never share your personal info.',
            validationType: 'phone',
            oninput
        }),
        password: validatedField({
            event: {
                category: 'signup',
                action: 'Password'
            },
            type: 'password',
            value: id.password,
            label: 'Select a Password',
            prompt: 'Minimum of 8 characters, including 1 number and 1 uppercase letter.',
            validationType: 'password',
            oninput
        }),
        companyName: validatedField({
            event: {
                category: 'signup',
                action: 'Company Name'
            },
            type: 'text',
            value: params.companyName || id.accountName,
            label: 'Company Name',
            validationType: 'name',
            cssClass: 'company-name',
            oninput
        }),
        terms: validatedField({
            event: {
                category: 'signup',
                action: 'Terms Agreement'
            },
            type: 'checkbox',
            label: 'I agree to the <a class="terms-href">terms of service</a>.',
            prompt: '',
            validationType: 'terms',
            cssClass: 'terms-link',
            oninput
        }),
        submit: submitButton({
            disabled: true,
            onclick: onSubmit,
            text: signUp.isTrial ? 'Start Your Free Trial' : 'Done',
            onDisabledClick: () => {
                step1.firstName.validate();
                step1.lastName.validate();
                step1.email.validate();
                step1.phone.validate();
                step1.password.validate();
                step1.terms.validate();
                step1.companyName.validate();
                oninput();
            }
        })
    }, () => {

        if (signUp.isTrial) {

            signUp.component.cta = uav.component(`
            <div>Welcome to OnePlace<sup>TM</sup></div>`
            );

            signUp.component.subtitle = uav.component(`
            <div class="subtitle-copy">Your free trial of our simple GIS software includes full access for 14 days to our <span class="plan-callout">Pro Plan</span>
            <ul class="bullets-callout">
                <li><span class="bullet-icon"></span> Map annotation</li>
                <li><span class="bullet-icon"></span> Mobile field capture</li>
                <li><span class="bullet-icon"></span> Drone survey processing</li>
                <li><span class="bullet-icon"></span> Spatial measures</li>
                <li><span class="bullet-icon"></span> PDF exports</li>
                <li><span class="bullet-icon"></span> Priority support response</li>
            </ul>
            <span class="and-more-text">... and <a u-onclick="{more}">much more</a>.</span>
            <span class="pricing-page-link"><a href="https://www.unearthlabs.com/pricing" target="_blank">Plan Details <i class="icon-arrow-right"></i></a></span>
            </div>`, {
                more: () => {

                    window.open('https://www.unearthlabs.com/pricing');

                }
            });

        } else {

            signUp.component.cta = 'Create an Account';

            signUp.component.subtitle = 'We just need a few details to create your account so that you can join the conversation.';

        }

        oninput();

        document.querySelector('.terms-href').onclick = () => {

            window.open('https://unearthlabs.com/terms-of-service/');

        };

    });

    return step1;

};
