import store from 'util/data/store';
import batchModifyModel from 'models/batch-modify-model';
import Toggle from 'views/toggle/toggle';
import BatchFormControl from 'views/batch-modify/batch-form-control';
import debounce from 'util/events/debounce';
import AssetTypeMenu from 'views/batch-modify/asset-type-menu';

const MatchFieldSelection = {
    view: () => <div>
        <div class="instructional-text">Find records where:</div>
        <div class="dropdown-control select-wrapper">
            <select key={batchModifyModel.assetTypeId} defaultValue={batchModifyModel.matchField}
                onkeydown={(e) => e.stopPropagation()}
                onchange={e => batchModifyModel.selectMatchField(e.target.value)}>
                {batchModifyModel.editableControls.map(ctrl =>
                    <option key={ctrl.controlTypeId} value={ctrl.name}>{ctrl.name}</option>
                )}
            </select>
        </div>
    </div>
};

const MatchValueSelection = {
    view: () => <div class="match-value-selection">
        <div class="instructional-text">matches import values:</div>
        {batchModifyModel.importSource.mediaId ? <div class="imported-file-title">
            <span class="linked-text" onclick={batchModifyModel.selectFileSource}>change file</span>
            <div class="import-selected-wrap">
                <div class="import-file-label">{batchModifyModel.importSource.label}</div>
            </div>
        </div>
            :
            <div class="import-button-wrap">
                <span class="btn btn-primary btn-small btn-pill"
                    onclick={batchModifyModel.selectFileSource}>Select File</span>
            </div>}
    </div>
};

const ModificationsList = {
    view: () => <div class={`modifications-list ${batchModifyModel.stateClass}`}>
        <div class="instructional-text">For all matching records, modify:</div>
        {batchModifyModel.editableControls.map((control, index) =>
            <div key={batchModifyModel.assetTypeId + index}
                class={`modification-item ${control.isToggledOpen ? 'open' : 'closed'}`}>
                {control.attributes && control.attributes.readOnly ? <Toggle active={false} disabled={true}/> :
                    <Toggle active={false} onchange={trueOrFalse => {
                        control.isToggledOpen = trueOrFalse;
                        batchModifyModel.toggleModifierField(control, trueOrFalse);
                    }}/>}
                <div
                    class="toggle-label modifier-field-name">{control.name} {control.attributes && control.attributes.readOnly ?
                        <div class='disabled-field-msg'>This field cannot be modified because it is
                        read-only.</div> : ''}</div>
                {control.isToggledOpen && <div
                    class={`batch-form-control ${batchModifyModel.invalidModifiers[control.name] ? ' invalid' : batchModifyModel.emptyModifiers[control.name] ? ' empty ' : ''} `}>
                    <div class="instructional-text">Set value to:</div>
                    <BatchFormControl control={control}/></div>}
            </div>
        )}
    </div>
};

const BatchModify = {

    view: () => {
        const assetTypeId = batchModifyModel.assetTypeId;
        const assetType = assetTypeId ? store.assetTypes[batchModifyModel.assetTypeId] : null;
        return assetTypeId ?
            <div class="batch-modify">
                <div class="form-title-wrap">
                    <span class="form-title">Batch Modify</span>
                    <span onclick={batchModifyModel.close} class="btn btn-secondary btn-pill btn-smallest">Cancel</span>
                </div>
                <div class={`form-meta-wrap ${batchModifyModel.stateClass}`}>
                    <div class="asset-type-selection">
                        <div class="flex-1">
                            <div class="asset-category">{batchModifyModel.toolGroupName}</div>
                            <div class="asset-type-name">
                                <img class="asset-icon"
                                    src={`https://media.unearthlabs.com/${assetType.attributes.icon.mediaId}`}/>
                                <div class="instructional-text">{assetType.name}</div>
                            </div>
                        </div>
                        <div class="asset-total flex-0">
                            <div class="asset-total-title">Total in Project</div>
                            <div class="asset-type-count">{batchModifyModel.assetTypeCount}</div>
                        </div>
                        <div class={`asset-type-menu-toggle border-left flex-0 ${batchModifyModel.assetMenuOpen ? ' menu-open ' : ' menu-closed '}`}>
                            <span class="btn btn-circle btn-smallest btn-secondary" onclick={() => batchModifyModel.openAssetMenu()}><span class="btn-label"><i class="icon-down"/></span></span>
                            <AssetTypeMenu/>
                        </div>
                    </div>
                </div>
                <div class={`form-content-wrap ${batchModifyModel.stateClass}`}
                    onscroll={debounce(() => batchModifyModel.closeAssetMenu())}>
                    <MatchFieldSelection/>
                    <MatchValueSelection/>
                    <ModificationsList/>
                </div>
            </div>
            : '';
    }

};

export default BatchModify;
