import tableModel from 'models/table/table-model';
import Cell from 'views/table/cells/cell';
import assetModel from 'models/asset-model';
import stopPropagation from 'util/events/stop-propagation';
import formModel from 'models/form-model';
import AssetOptions from 'views/asset-options/asset-options';
import mediaModel from 'models/media-model';
import getAssetName from 'util/data/get-asset-name';
import popupModel from 'models/popover-model';
import screenHelper from 'legacy/util/device/screen-helper';

const MapStatusButton = {
    view: ({attrs: {assetId, hasFeatures}}) => <>
        {hasFeatures || assetModel.getCompleteLayerId(assetId)
            ? <i class="icon-center-on-map"
                onclick={stopPropagation(() => {
                    assetModel.panToAsset(assetId);
                    popupModel.close();
                    if (screenHelper.small()) {
                        if (!tableModel.isCollapsed) {
                            tableModel.sideBarToggle();
                        }
                        m.redraw();
                    }
                })}/>
            : assetModel.canAddToMap(assetId)
                ? <i class="icon-add-to-map" onclick={stopPropagation(() => {
                    formModel.viewAsset(assetId, 'Properties');
                    formModel.addToMap();
                    popupModel.close();
                })}/>
                : <i class="icon-asset-form"
                    onclick={stopPropagation(() => {
                        formModel.viewAsset(assetId, 'Properties');
                        popupModel.close();
                    })}/>
        } </>
};

class CellID extends Cell {

    view({state, attrs: {assetRow, columnIndex}}) {
        state.cssClasses = `asset-cell ${state.wrapClass || ''} ${state.activeClass || ''}`;
        return <div class='asset-primary-label'>
            <div onclick={(e) => tableModel.handleCellClick(e, assetRow.rowIndex, columnIndex)} class={state.cssClasses}>
                <span class="row-number">{assetRow.rowIndex + 1}</span>
                <span class="asset-icon-wrap">{mediaModel.getIcon(assetRow.assetId)}</span>
                <span class="asset-title property-value">{getAssetName(assetRow.assetId)}</span>
            </div>
            <span class="map-status"><MapStatusButton assetId={assetRow.assetId} hasFeatures={assetRow.hasFeatures} /></span>
            <span class="asset-options-menu"><AssetOptions assetId={assetRow.assetId}/></span>
        </div>;
    }
}

export default CellID;
