import Stake from 'components/stake';
import stakeableModel from 'models/stakeable-model';
import convertMithril from 'util/dom/convert-mithril';
import measure from 'legacy/util/numbers/measure';
import roundIfNLong from 'util/numbers/round-if-n-long';
import StakePopup from 'views/stakeable/stake-popup';

const LNG_RANGE = 180;
const LAT_RANGE = 90;
const LNG_INDEX = 0;

// Round numbers that have decimals greater than 10 digits
// (bc the long decimal is likely due to converting feet/meters)
const DECIMAL_LENGTH_LIMIT = 10;

/**
 * StakeTarget objects appear on left-hand side of stakeable views. They display a popup with
 * the editable lat/lng menu rather than just the Stake number and delete button.
 */
class StakeTarget extends Stake {
    constructor(opts) {
        super(opts);
        this.addTo(stakeableModel.target);
        if (opts.elevation) {
            if (!stakeableModel.isMetric) {
                const convertedToFeet = measure.metersToFeet(opts.elevation);
                this.elevation = roundIfNLong(convertedToFeet, DECIMAL_LENGTH_LIMIT);
            } else {
                this.elevation = opts.elevation;
            }
        }
    }

    /**
     * Extends super showPopup() to add the lat/lng menu.
     */
    showPopup() {
        super.showPopup();
        this.updateLatLngMenu();
    }

    /**
     * Adds the lat/lng menu popup content with associated event handlers.
     */
    updateLatLngMenu() {
        this.getPopup().setDOMContent(convertMithril.toDom(
            <StakePopup
                stake={this}
                parentPair={this._parentPair}
                lngLat={this.getLngLat()}
                elevation={this.elevation}
            />
        ));
    }

    /**
     * Runs on input event, validates input for the provided range and adds/remove
     * error wrap accordingly.
     */
    displayValidationMessage(e, range) {
        if (this.valueIsValid(Number(e.target.value), range)) {
            event.target.parentElement.classList.remove('error-wrap');
        } else {
            event.target.parentElement.classList.add('error-wrap');
        }
    }

    /**
     * Runs on input change event, updates the lat/lng data for this stake if valid.
     */
    handleLatLngInput(e, index) {
        const range = index === LNG_INDEX ? LNG_RANGE : LAT_RANGE;
        const value = Number(e.target.value);
        if (this.valueIsValid(value, range)) {
            const newLngLat = index === LNG_INDEX ?
                [value, this.getLngLat().lat] :
                [this.getLngLat().lng, value];
            stakeableModel.target.easeTo({
                center: newLngLat,
                speed: 0.3
            });
            this.setLngLat(newLngLat);
            stakeableModel.validateStake(newLngLat);
            stakeableModel.updateStakePair(this._parentPair);
        }
    }

    /**
     * Runs on input change event, updates the elevation data for this stake if valid.
     */
    handleElevationInput(event) {
        const value = Number(event.target.value);
        if (this.valueIsValid(value)) {
            this.elevation = value;
            stakeableModel.updateStakePair(this._parentPair);
        }
    }

    /**
     * Helper function to validate input for a given range (returns boolean)
     */
    valueIsValid(value, range) {
        if (!range) {
            return !isNaN(value);
        }
        return !isNaN(value) && -range <= value && value <= range;
    }
}

export default StakeTarget;
