import store from 'util/data/store';

const apiSafeFeature = (feature, isModifyRequest) => ({
    featureId: feature.id,
    featureTypeId: isModifyRequest ? undefined : feature.properties.featureTypeId,
    geometry: feature.geometry.coordinates && feature.geometry.coordinates.length ? feature.geometry : undefined,
    properties: Object.assign({}, feature.properties, {
        _id: undefined,
        assetId: undefined,
        creatorId: undefined,
        featureTypeId: undefined,
        _latitude: undefined,
        _sourceWidthPx: undefined
    })
});

function apiSafeContent(asset) {

    const content = Object.assign({}, asset, {
        authorId: undefined,
        assetId: undefined,
        assetTypeId: undefined,
        createdDateTime: undefined,
        captureDateTime: undefined,
        recordType: undefined,
        name: undefined,
        contentType: undefined,
        isVisible: true,
        media: undefined,
        display: undefined,
        placeIds: undefined,
        levelIds: undefined
    });

    content.features = content.featureIds
        ? content.featureIds.map(featureId => apiSafeFeature(store.features[featureId]))
        : undefined;

    delete content.featureIds;

    return content;

}

const list = _list => _list && _list.items ? _list.items : _list || [];

const URLTemplateFMTToPNG =  (url) => url.replace('{fmt}', 'png');

export default {
    apiSafeFeature,
    apiSafeContent,
    list,
    URLTemplateFMTToPNG
};
