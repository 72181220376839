import uav from 'uav';
import validatedField from 'legacy/components/validated-field';
import router from 'uav-router';
import message from 'legacy/components/message';
import submitButton from 'legacy/components/submit-button';
import socket from 'util/network/socket';
import userModel from 'models/user-model';

function changePassword() {

    function formIsValid() {

        return component.currentPassword.isValid() && component.newPassword.isValid() && component.confirmPassword.isValid();

    }

    function submit(e) {

        if (e) {

            e.preventDefault();

        }

        if (!component.currentPassword.validate() || !component.newPassword.validate() || !component.confirmPassword.validate()) {

            return;

        }

        component.submit.spinning = true;

        socket.send('changePassword', {
            data: {
                username: userModel.userId,
                password: component.currentPassword.value,
                newPassword: component.confirmPassword.value
            },
            resolve: () => {

                component.submit.spinning = false;

                socket.logout();

            },
            reject: data => {

                component.submit.spinning = false;

                if (data.code === 4001) {

                    message.show('Please check your password and try again.', 'error');

                } else {

                    message.show('Something went wrong. Please try again or contact support.', 'error');

                }

            }
        });

    }

    function oninput() {

        component.submit.disabled = !formIsValid();

    }

    const component = uav.component(`
    <div class="login">
        <h1>Change Password</h1>
        <form class="form new-form change-password">
            <p>You will be prompted to sign in after setting your new password.</p>
            {currentPassword}
            {newPassword}
            {confirmPassword}
            <div class="form-bottom">
                {submit}
                <div class="login-footer">
                    <a class="cancel-btn" u-onclick="{cancel}">Cancel</a>
                </div>
            </div>
        </form>
    </div>`, {
        cancel: () => history.length ? history.back() : router.set(),
        submit: submitButton({
            disabled: true,
            onclick: submit,
            text: 'Change Password'
        }),
        currentPassword: validatedField({
            type: 'password',
            label: 'Current Password',
            validationType: 'name',
            autofocus: true,
            oninput
        }),
        newPassword: validatedField({
            type: 'password',
            label: 'New Password',
            prompt: 'Minimum of 8 characters, including 1 number, and 1 uppercase letter.',
            validationType: 'password',
            oninput
        }),
        confirmPassword: validatedField({
            type: 'password',
            label: 'Confirm New Password',
            validationType: 'match',
            match: () => component.newPassword.value,
            oninput
        }),
        classname: 'change-pass-view'
    });

    return component;

}

export default changePassword;
