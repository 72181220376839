import importModel from 'models/import-model';
import Radio from 'views/radio';
import stopPropagation from 'util/events/stop-propagation';
import formControls from 'views/form-controls';
import Toggle from 'views/toggle';
import constants from 'util/data/constants';

const ValuePicker = {
    view: ({attrs: {control, asset}}) => {
        const hasOverride = importModel.overrideAsset.properties.hasOwnProperty(control.label);
        return <div>
            Insert Value
            <div class="import-control-val" onclick={stopPropagation()}>
                {formControls[control.controlTypeId](control, asset.contentId)}
            </div>
            {hasOverride
                ? <div class="import-control-result">
                    <div class="import-control-matches">
                        <i class="icon-check" /> Records will be imported with {control.label} set to this value.
                    </div>
                </div>
                : null}
        </div>;
    }
};

const ColumnMatcher = {
    oninit({attrs: {control}, state}) {
        state.nullOpts = [
            'Leave empty',
            <ValuePicker control={control} asset={importModel.defaultAsset} />
        ];
    },
    view: ({attrs: {control}, state}) => {
        const label = control.label;
        const hasOverride = importModel.overrideAsset.properties.hasOwnProperty(label);
        const rowCount = importModel.rowCount[label];
        const hasDefault = importModel.defaultAsset.properties.hasOwnProperty(label);
        return <div>
            Match To
            <div class="select-wrapper import-control-val" onclick={stopPropagation()}>
                <select value={importModel.args.formMappings[label]} onchange={e => importModel.matchColumn(label, e.target.value)}>
                    <option />
                    {importModel.columns.map(col => <option value={col}>{col}</option>)}
                </select>
            </div>
            {importModel.validating[label]
                ? <div class="import-validate"><i class="teal spinner spinning " /> Validating</div>
                : hasOverride
                    ? null
                    : rowCount && <div class="import-control-result">
                        <div class={`import-control-matches ${rowCount.matches ? '' : 'error'}`}>
                            <i class={`icon-${rowCount.matches ? 'check' : 'error'}`} /> {rowCount.matches} record{rowCount.matches === 1 ? ' has' : 's have'} a valid value for this column
                        </div>
                        {rowCount.errors
                            ? <div class="import-control-matches warning">
                                {rowCount.matches
                                    ? <><i class="icon-warning" /> {rowCount.errors} record{rowCount.errors === 1 ? ' has' : 's have'} an invalid value</>
                                    : null}
                                <Radio defaultOption={state.nullOpts[hasDefault ? 1 : 0]} options={state.nullOpts} onchange={(_, i) => importModel.resetDefault(control, !i)} />
                            </div>
                            : null}
                    </div>}
        </div>;
    }
};

const ColumnBox = {
    oninit({attrs: {control}, state}) {
        state.type = constants.controlTypeIdToName[control.controlTypeId];
    },
    view: ({attrs: {control}, state}) => {
        const label = control.label;
        const opts = [
            <ColumnMatcher control={control} />,
            <ValuePicker control={control} asset={importModel.overrideAsset} />
        ];
        return <div class="import-control">
            <div class="import-control-head clear">
                <div class="left">
                    <div class="import-control-name">{label}</div>
                    <code>{state.type}</code>
                </div>
                <div class="right">
                    <label class={importModel.skip[label] && 'active'}>Skip</label>
                    <Toggle onchange={doSkip => {
                        if (doSkip) {
                            delete importModel.args.formMappings[label];
                            importModel.skip[label] = true;
                        } else {
                            delete importModel.skip[label];
                        }
                    }} />
                </div>
            </div>
            <div class="import-control-body">
                {importModel.skip[label]
                    ? <div class="import-control-result"><div class="skip-text"><i class="icon-circle-slash"></i> {label} will be set to null for all records in this import.</div></div>
                    : <Radio
                        defaultOption={opts[0]}
                        options={opts}
                        onchange={(_, i) => importModel.changeMatchStrategy(control, i)} />}
            </div>
        </div>;
    }
};

const MatchColumnsStep = {
    name: 'Match Columns',
    view: () => <div class="match-columns-outter">
        <div class="match-columns-wrap">
            <div class="match-columns-inner">
                {importModel.controls.map(control => <ColumnBox control={control} />)}
            </div>
        </div>
        <div class="match-columns-tip">Match each {importModel.tool.name} property to a column from <strong>{importModel.media.label}</strong> or skip the property to leave empty.</div>
    </div>
};

export default MatchColumnsStep;
