import router from 'uav-router';
import store from 'util/data/store';
import peopleModel from 'models/people/people-model';
import {ProfilePreview} from 'views/people/profile';
import userModel from 'models/user-model';
import popupModel from 'models/popover-model';

const CheckBox = {
    view({attrs: {userCanEditAccess, handleClick, isSaving, isChecked}}) {
        return  <div class="check-wrap" onclick={handleClick ? handleClick : null}>
            <i class={`icon-checkbox-${isChecked ? 'on' : 'off'} ${userCanEditAccess ? '' : 'locked'}`}/>
            {isSaving && <span class="spinner spinning"></span>}
        </div>;
    }
};

const ProjectPeopleTab = {

    oninit: ({state}) => {
        state.initProject(state);
    },

    onupdate: ({state}) => {
        if (state.assetId !== router.params.assetId) {
            state.initProject(state);
        }
    },

    initProject: (state) => {
        const asset = store.assets[router.params.assetId];
        state.projectType = store.assetTypes[asset.assetTypeId].name.toLowerCase();
        peopleModel.getPeopleForProjectId().then((projectPeople) => {
            state.projectPeople = projectPeople;
            state.assetId = router.params.assetId;
            m.redraw();
        });
    },

    view({state}) {
        const projectPeople = state.projectPeople;
        const userCount = projectPeople ? projectPeople.list.length : '...';
        return <div class="tab-content project-people-list" onscroll={popupModel.close}>
            {userModel.isAccountAdmin && <div class="people-list-header">
                <div class="people-list-title">People</div>
                <button class="btn btn-secondary btn-pill btn-smaller btn-add-person" onclick={() => peopleModel.openNewPerson()}><i class="icon-plus"/><i class="icon-user"/></button>
            </div> }
            {projectPeople ? <>
                <span class="full-width-label">{projectPeople.userAccessCount} (of {userCount}) {userCount > 1 ? 'people have' : 'person has'} access to this {state.projectType}.</span>
                <div class="project-people-list-inner">
                    {projectPeople.list.map(userId => {
                        const person = peopleModel.getPerson(userId);
                        const userCanEditAccess = person.userCanEdit('access');
                        const personHasAccess = projectPeople.personHasAccess(userId);
                        const isSaving = !!person.savingClass;
                        const handleClick = userCanEditAccess && !isSaving && (() => peopleModel.toggleProjectAccess(userId, projectPeople.projectId)); // Same fn for checkbox or profile card click 
                        return <div class={`project-person ${personHasAccess ? 'has-access' : 'no-access'}`}>
                            <CheckBox userCanEditAccess={userCanEditAccess} isChecked={personHasAccess} isSaving={isSaving} handleClick={handleClick} />
                            <ProfilePreview person={person} handleClick={handleClick}/>
                        </div>;
                    })}
                </div>
                {userModel.isAccountAdmin &&
                    <div class="links-control-input" onclick={() => peopleModel.openNewPerson()}>
                        <span class="link-control-tip">Add a new person to this account.</span>
                        <span class="btn btn-pill btn-secondary btn-smaller">
                            <span class="btn-label">
                                <i class="icon-plus"></i><i class="icon-user icon"></i></span>
                        </span>
                    </div>}
            </> : <> <span class="full-width-label">Loading people with access to this {state.projectType}...</span>
                <span class="spinner spinning"></span> </>
            }
        </div>;
    }
};

export default ProjectPeopleTab;
