import mediaModel from 'models/media-model';
import PhotoViewer from 'views/photo-viewer';
import VideoViewer from 'views/video-viewer';
import AudioViewer from 'views/audio-viewer';
import FileViewer from 'views/file-viewer';
import mediaViewerModel from 'models/media-viewer-model';
import ThumbnailGallery from './thumbnails/thumbnail-gallery';

function getView(mediaId) {

    const media = mediaModel.getMediaAndRedraw(mediaId);

    if (media) {

        if (mediaModel.isImage(media.mimeType)) {

            return <PhotoViewer media={media} key={media.mediaId} />;

        } else if (media.mimeType.startsWith('video')) {

            return <VideoViewer media={media} key={media.mediaId} />;

        } else if (media.mimeType.startsWith('audio')) {

            return <AudioViewer media={media} key={media.mediaId} />;

        } else if (mediaModel.isSupportedFileType(media)) {

            return <FileViewer media={media} key={media.mediaId} />;

        }

        return FileViewer.notSupported(media);
    }
}


const MediaViewer = {

    view: () => {
        if (mediaViewerModel.isWaiting) {
            return <div className="media-viewer">
                <div className="media-viewer-stage">
                    <i className="icon-close" onClick={() => mediaViewerModel.close()}/>
                    <i className="spinner spinning"/>
                </div>
            </div>;
        }
        return <div class="media-viewer">
            <div class="media-viewer-stage">
                <i class="icon-close" onclick={() => mediaViewerModel.close()} />
                {mediaViewerModel.hasPrev() && <i class="icon-left" onclick={() => mediaViewerModel.selectIndex(mediaViewerModel.state.index - 1)} />}
                {mediaViewerModel.hasNext() && <i class="icon-right" onclick={() => mediaViewerModel.selectIndex(mediaViewerModel.state.index + 1)} />}
                <div>{getView(mediaViewerModel.mediaIds[mediaViewerModel.state.index])}</div>
            </div>
            <ThumbnailGallery mediaIds={mediaViewerModel.mediaIds}
                activeIndex={mediaViewerModel.state.index}
                onClickFn={(index) => mediaViewerModel.selectIndex(index)}
            />
        </div>;


    }
};

export default MediaViewer;

