import layerModel from 'models/layer-model';

class LayerControl {

    constructor(toggleHandler, containerClass, iconClass) {
        this.toggleHandler = toggleHandler ? toggleHandler : () => layerModel.togglePicker();
        this.containerClass = containerClass ? containerClass : 'layer-control';
        this.iconClass = iconClass ? iconClass : 'icon-layers';
    }

    onAdd(map) {
        this._map = map;
        this._container = document.createElement('div');
        this._container.className = `${this.containerClass} mapboxgl-ctrl mapboxgl-ctrl-group`;
        this._container.innerHTML = `<button class='${this.iconClass}'></button>`;

        this._container.onclick = (e) => {
            this._container.classList.toggle('active');
            this.toggleHandler(e);
        };

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }

}

export default LayerControl;
