import FilepickerInterface from 'util/interfaces/filepicker';
import SymbolInterface from 'util/interfaces/symbol';
import PlanInterface from 'util/interfaces/plan';
import PolygonInterface from 'util/interfaces/polygon';
import PolylineInterface from 'util/interfaces/polyline';
import SurveyInterface from 'util/interfaces/survey';
import TextInterface from 'util/interfaces/text';
import ProjectInterface from 'util/interfaces/project';
import ActionInterface from 'util/interfaces/action';

function getToolInterface(tool, assetId, featureTypeId) {

    const featureType = featureTypeId
        ? tool.featureTypes.find(f => f.featureTypeId === featureTypeId)
        : tool.featureTypes[0];

    const Interface = {
        action: ActionInterface,
        filepicker: FilepickerInterface,
        symbol: SymbolInterface,
        plan: PlanInterface,
        polygon: PolygonInterface,
        polyline: PolylineInterface,
        survey: SurveyInterface,
        text: TextInterface,
        project: ProjectInterface
    }[featureType.attributes.interface];

    return new Interface(tool, assetId, featureTypeId);

}

export default getToolInterface;
