import constants from 'util/data/constants';

const FILESTACK_MAX_SIZE = 10000;

export default (id, opts = {}) => {

    let url = 'https://process.filestackapi.com/' + constants.filestackApiKey;

    if (opts.handle) {

        url += '/rotate=d:exif';

    }

    if (opts.width) {

        url += '/resize=width:' + Math.min(opts.width, FILESTACK_MAX_SIZE);

    } else if (opts.height) {

        url += '/resize=height:' + Math.min(opts.height, FILESTACK_MAX_SIZE);

    }

    if (opts.handle) {

        url += `/${id}`;

    } else {

        url += '/https://s3-us-west-2.amazonaws.com/media.unearthlabs.com/' + id;

    }

    return url;

};
