import CommentsModel from 'models/comments-model';
import formatDate from 'legacy/util/date/format-date';
import userModel from 'models/user-model';
import peopleModel from 'models/people/people-model';
import pointMenu from 'legacy/components/point-menu';
import convertMithril from 'util/dom/convert-mithril';
import deleteContent from 'legacy/util/data/delete-content';
import router from 'uav-router';
import store from 'util/data/store';
import TextEditor from 'views/text-editor';

let commentsModel;

const NewCommentForm = {
    view: () => <div class={`comment card clear${!commentsModel || commentsModel.textEditor.isEmpty() ? ' empty' : ' editing'}${commentsModel.isSaving ?  ' saving' : ''}${commentsModel.textEditor.state.insertingMention ? ' inserting-mention' : ''}`}>
        <i class="icon-comment asset-icon" />
        <TextEditor 
            wrapClass={'comment-box comment-editable-div'} 
            model={commentsModel.textEditor} />
        <button class="comment-clear" onclick={() => commentsModel.clearCommentBox()}>Clear</button>
        <div class="right">
            <button class="btn btn-pill btn-secondary btn-smaller btn-mention" type="submit" onclick={e => commentsModel.textEditor.insertMention(e)}><span class="btn-label">@</span></button>
            <button class='btn btn-pill btn-secondary btn-smaller btn-post' type="submit" onclick={e => commentsModel.submit(e)}><i class="spinner teal" /><span class="btn-label">Post </span></button>
        </div>   
    </div>
};

export const EditCommentForm = {
    view: () => <div class={`comment-editor edit-comment comment clear${!commentsModel || commentsModel.commentEditor.isEmpty ? ' empty' : ' editing'}`}>
        <div class="dialog-title">Edit Message</div>
        <TextEditor 
            wrapClass={'comment-box comment-editable-div'} 
            model={commentsModel.commentEditor} />
        <div class="btn-pair">
            <div class="btn btn-secondary btn-pill btn-dialog" onclick={() => commentsModel.closeEditComment()}>
                <span class="btn-label">Cancel</span>
            </div>
            <div class="btn btn-primary btn-pill btn-dialog" onclick={() => commentsModel.saveCommentEdits()}>
                <span class="btn-label">Save</span>
            </div>  
        </div>
    </div>
};


const Comment = {

    popup(assetId) {
        return convertMithril.toDom(<div>
            <div class="menu-option" onclick={() => {
                commentsModel.openEditComment(assetId);
                pointMenu.close();
            }}><i class="icon-edit" /> Edit
            </div>
            <div class="menu-option remove-link" onclick={() => {
                deleteContent(assetId);
                pointMenu.close();
            }}><i class="icon-trash" /> Delete
            </div>
        </div>);

    },

    oninit({attrs: {assetId}, state}) {
        const asset = store.assets[assetId];
        const author = peopleModel.getPerson(asset.authorId);
        state.timestamp = formatDate.timestamp(asset.createdDateTime);
        state.authorName = peopleModel.displayName(author);
        state.openPopup = e => pointMenu({
            e,
            content: () => Comment.popup(assetId),
            cssClass: 'link-list asset-options',
            forceDesktop: true,
            noLine: true
        });
    },

    view: ({attrs: {assetId}, state}) => {
        const asset = store.assets[assetId];
        return <div class="comment card">
            <i class="icon-comment asset-icon" />
            <div class="asset-title">{commentsModel.commentToRichText(assetId + asset.updatedDateTime, asset.properties.Comment)}</div>
            <div class="asset-added">
                {state.timestamp} / {state.authorName}
            </div>
            {deleteContent.isAllowed(asset.contentId)
                ? <i class="icon-ellipsis asset-options" onclick={state.openPopup} />
                : null}
        </div>;
    }
};

const Comments = {

    onremove() {
        commentsModel.textEditor.clear();
        commentsModel.commentEditor.clear();
        commentsModel = Comments.model = null;
    },

    oninit({state}) {
        commentsModel = Comments.model = new CommentsModel();
        state.onScroll = e => {
            e.redraw = false;
            pointMenu.onScroll();
            commentsModel.onscroll(e);
        };
    },

    list() {
        return commentsModel.assetIds.length
            ? commentsModel.assetIds.map(assetId => <Comment key={assetId} assetId={assetId} />)
            : <div class="empty-state-message"><img src="/images/no-comments.png" class="empty-state-image" /><span class="message-intro">No comments just yet.</span>Start the conversation by adding a comment.</div>;
    },

    view: ({state}) =>
        <div class="tab-content comments-content" onscroll={state.onScroll}>
            {userModel.isInvited
                ? <p style="text-align: center;">
                    <a class="btn btn-primary btn-pill btn-small" onclick={() => router.merge({signup: 'fromguest'})}>
                        Sign up to join the conversation
                    </a>
                </p>
                : <NewCommentForm />}
            {commentsModel.loading
                ? <i class="gray spinner spinning" />
                : <div>{Comments.list()}</div>}
        </div>
};

export default Comments;

