import siteModel from 'models/site-model';
import SideNav from 'views/side-nav';
import Sidebar from 'views/sidebar';
import layerModel from 'models/layer-model';
import LayerPicker from 'views/layer-picker';
import message from 'legacy/components/message';
import sideNavModel from 'models/side-nav-model';
import Inspector from 'views/inspector';
import inspectorModel from 'models/inspector-model';
import featureModel from 'models/feature-model';
import formModel from 'models/form-model';
import tableModel from 'models/table/table-model';
import VolumeLegend from 'views/volume-legend';
import initializer from 'util/initializer';
import Popover from 'views/popover/popover';
import filterModel from 'models/table/filter-model';
import CloseButton from 'views/close-button';
import oneUpModel from 'models/one-up-model';
import Table from 'views/table';
import Panel from 'views/panel/panel';

const Site = {

    oncreate: () => {

        if (siteModel.processingSurvey) {

            message.show('Your survey changes are processing.', 'success');

        }

        sideNavModel.selected = null;

        siteModel.init().then(siteModel.siteMapClick).then(() => {

            initializer.siteInitialized();

            layerModel.awaitLayerChanges();

            featureModel.awaitChanges();

            tableModel.awaitChanges();

        });

    },

    onremove() {
        oneUpModel.clearActiveFlow();
    },

    view: () => {

        const View = siteModel.view,
            ToolUI = formModel.toolInterface && formModel.toolInterface.ToolUI,
            tableMode = siteModel.sidebar === Table ? tableModel.tableMode || '' : '';

        return <div class={`basemap-${layerModel.state && layerModel.state.basemapId || 'none'} ${tableMode}${tableModel.isCollapsed ? ' collapsed' : ''}${siteModel.isMetaProject ? ' meta-project dark-theme' : ''}${tableModel.dismissedClass}`}>
            {filterModel.draw 
                ? <div class="side-nav"><CloseButton onclick={() => filterModel.cancelDrawSearchArea()} /></div>
                : <SideNav/>}
            {siteModel.isMetaProject && <Panel/>}
            <Sidebar/>
            <Popover/>
            {layerModel.isOpen ? <LayerPicker/> : null}
            {View && <View/>}
            <div id="mapbox" class="mapbox"></div>
            {inspectorModel.view ? <Inspector/> : null}
            {ToolUI && <ToolUI/>}
            <VolumeLegend/>
        </div>;

    }

};

export default Site;
