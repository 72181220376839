import uav from 'uav';
import idComponent from 'legacy/components/auth/sign-up/id-component';
import step1 from 'legacy/components/auth/sign-up/step1';
import socket from 'util/network/socket';
import router from 'uav-router';
// import auth from 'legacy/components/auth';
import userModel from 'models/user-model';

function signUp() {

    const params = router.params;

    socket.reset();

    // FIXME: This is a hack so that we sign up on opv1. Eventually we will remove all of this code.
    if (params.signup === 'fromguest') {
        console.log('Redirecting to v1');
        location.pathname = '/1/';
        return {};
    }

    signUp.isTrial = !(params.invitationId && params.secret && params.projectId || params.signup && params.signUpCode);

    const id = idComponent();

    signUp.component = uav.component(`
    <div class="sign-up clear">
        <div class="sign-up-cta-container">
            <h1>{cta}</h1>
            <div class="sign-up-subtitle">{subtitle}</div>
        </div>
        <div class="sign-up-form-container">
            {form}
        </div>
    </div>`, {
        cta: '',
        subtitle: '',
        form: null,
        classname: 'new-account'
    });

    function render() {

        signUp.component.form = step1(params, id);

        // vm.replaceView(auth.component);

    }

    //optional chargebee field
    if (params.companyName && params.companyName !== '') {

        id.accountName = params.companyName;

    }

    //chargebee customer id
    if (params.customerid && params.customerid !== '') {

        id.customerId = params.customerid;

    }

    socket.connect(null, () => {

        id.firstName = userModel.givenName;
        id.lastName = userModel.familyName;
        id.initials = userModel.initials;
        id.email = userModel.emailAddress;
        id.phone = userModel.phoneNumber;

        render();

    }, render);

    return signUp.component;

}

export default signUp;
