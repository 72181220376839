import router from 'uav-router';
import randomId from 'util/numbers/random-id';
import siteModel from 'models/site-model';
import formatDate from 'legacy/util/date/format-date';
import userModel from 'models/user-model';
import constants from 'util/data/constants';
import Filepicker from 'util/interfaces/filepicker';
import element from 'util/dom/element';
import featureToControl from 'util/interfaces/feature-to-control';
import initializer from 'util/initializer';
import api from 'legacy/util/api';
import AssetForm from 'views/asset-form';
import featureModel from 'models/feature-model';
import getToolInterface from 'util/interfaces/get-tool-interface';
import assetModel from 'models/asset-model';
import store from 'util/data/store';
import layerModel from 'models/layer-model';
import controlToFeature from 'util/interfaces/control-to-feature';
import popup from 'util/popup';
import mediaModel from 'models/media-model';
import mediaViewerModel from 'models/media-viewer-model';
import {bounds} from 'util/geo';
import message from 'legacy/components/message';
import screenHelper from 'legacy/util/device/screen-helper';
import Table from 'views/table/table';
import modalModel from 'models/modal-model';
import tableModel from 'models/table/table-model';
import toolboxModel from 'models/toolbox-model';
import {evaluateControl, evaluateExpression} from 'util/evaluate';
import publish from 'legacy/util/api/publish';
import debounce from 'util/events/debounce';
import dialogModel from 'models/dialog-model';
import pointMenu from 'legacy/components/point-menu';
import helpers from 'legacy/util/api/helpers';
import peopleModel from 'models/people/people-model';
import ajax from 'legacy/util/api/ajax';
import {appUrl} from 'util/data/env';
import oneUpModel from 'models/one-up-model';
import proxyRequest from 'util/network/proxy-request';
import panelModel from 'models/panel-model';
import placesTabModel from 'models/places-tab-model';
import appModel from 'models/app-model';
import sideNavModel from './side-nav-model';
import measure from 'legacy/util/numbers/measure';
import isMetric from 'util/numbers/is-metric';

const {updateAssetFeatures} = controlToFeature;

const ASSET_FORM_WIDTH = 362;

class FormModel {

    init() {

        layerModel.resetToolLayers();

        if (this.editingFeatureId) {

            this.onEditStop();

        }

        Object.assign(this, {
            assetId: undefined,         // The id of the asset whose form is open
            hasCommentTab: false,       // Whether the form has a control of type comments
            hasLinksTab: false,         // Whether the form has a control of type links (link on client attachment in db)
            controls: [],               // The list of form controls
            tabCount: 1,                // The number of available tabs
            visible: true,              // Whether the form is expanded or contracted
            toolInterface: null,        // The active feature creation tool interface
            isNew: false,               // Whether this asset was just created or not
            unmappedFeatureType: false, // Whether this asset could have a feature and doesn't
            focusedAssets: {},          // The assets whose features are not dimmed out on the map
            saving: {},                 // The controls currently being autosaved
            invalid: {},                // The controls with invalid data (ie, a field that is empty when marked as required)
            viewAsLimitedUser: null,    // If a user status is limited, we will set this variable to determine if the asset properties override that,
            displayDate: null,          // Cached formatted asset createdDateTime
            lastUpdatedDate: null,      // Cached formatted asset updatedDateTime
            childProjectId: false,      // The ID of the project that this asset represents (only used in meta projects)
            linkFlowId: undefined,
            linksModel: undefined,
            isNextEnabled: true
        });

        this.clearAwait();

        this.debouncedTriggerEval = debounce(controlToSkip => this.triggerEval(controlToSkip));

        this.next = debounce((isUp) => this._next(isUp), 200);
    }

    cleanup(isOpeningAnotherAsset = sideNavModel.isOpeningMetaAsset) {

        this.init();

        if (router.params.assetId && !isOpeningAnotherAsset) {

            sideNavModel.isOpeningMetaAsset = false;

            router.url.remove('assetId', 'tab');
        }

    }

    controlIsInvalid(control, assetId = this.assetId) {
        let isInvalid = false;
        const asset = store.assets[assetId];
        if (control.attributes.required && !formModel.isReadOnlyControl(asset.assetTypeId, control)) {
            switch (control.controlTypeId) {
            case constants.controlTypeNameToId.coordinates:
                // Valid if: Both coords exist
                isInvalid = !asset.properties.Coordinates
                        || !asset.properties.Coordinates.coordinates[0]
                        || !asset.properties.Coordinates.coordinates[1];
                break;
            case constants.controlTypeNameToId.links ||
                    constants.controlTypeNameToId.file:
                // Valid if: Any asset or media been linked
                // TODO: Validation method inaccurate if asset is deleted, per API-515
                isInvalid = !asset.linkIds || !asset.linkIds.length;
                break;
            default:
                // Valid if: The property exists on the asset and is not an empty string
                isInvalid = !asset.properties.hasOwnProperty(control.label)
                    || asset.properties[control.label] === '' || !asset.properties[control.label];
                break;
            }
        }

        return isInvalid;
    }

    validateControl(control) {
        this.invalid[control.label] = formModel.controlIsInvalid(control, this.assetId);
    }

    /**
     * Run through each field on the form and validate for completion.
     * If all required control fields are completed, run the callback function provided as arg.
     * If not, display a warning message to the user re missing fields.
     */
    validateThenRun(callbackFunction) {

        this.invalid = {}; // Clear the validation first.

        this.controls.forEach(control => this.validateControl(control));

        const numInvalid = Object.keys(this.invalid).filter(control => this.invalid[control]).length;

        if (!numInvalid) {

            callbackFunction(); // Valid, so run callback function

        } else {

            pointMenu.close(); // Close menu in case it was left open.

            const numString = `${numInvalid} field${numInvalid > 1 ? 's' : ''} marked in red.`;

            dialogModel.open({
                headline: 'This form is incomplete.',
                text: <div>Please check <span>{numString}</span></div>,
                cssClass: 'incomplete-form-warning',
                yesText: 'Return to Form',
                yesClass: 'btn btn-pill btn-primary btn-return-to-form',
                noText: 'Continue',
                noClass: 'btn btn-pill btn-secondary',
                onYes: () => {
                    // Return to asset form.
                    this.selectTab({name: 'Properties'});
                    this.visible = true;
                    popup.remove();
                    if (layerModel.isOpen) {
                        layerModel.togglePicker();
                    }
                    m.redraw();
                },
                onNo: () => {
                    // Run the callback function.
                    callbackFunction();
                    m.redraw();
                }
            });

        }

    }

    close(isOpeningAnotherAsset) {

        const asset = store.assets[this.assetId];

        this.clearAwait();

        popup.remove();

        if (oneUpModel.hasFlow(this.linkFlowId)) {
            oneUpModel.clearActiveFlow();
            this.linkFlowId = undefined;
        }

        if (this.toolInterface) {

            this.toolInterface.close();

        }

        if (this.isNew) {

            const assetTypeName = store.assetTypes[asset.assetTypeId].name;

            let messageElement;

            if (tableModel.assetIds.indexOf(this.assetId) === -1) {

                featureModel.removeFilteredFeatures(asset.featureIds);

                messageElement = element`<div>New <a>${assetTypeName}</a> added. May not be visible due to current filter conditions.</div>`;

            } else {

                messageElement = element`<div>New <a>${assetTypeName}</a> added.</div>`;

            }

            messageElement.firstElementChild.onclick = () => {
                message.hide();
                this.viewAsset(asset.contentId, 'Properties');
            };

            message.show(messageElement);

        }

        siteModel.sidebar = Table;

        modalModel.close();

        if (!isOpeningAnotherAsset) {

            router.url.remove('assetId', 'tab');

        }

    }

    toggleVisibility() {

        this.visible = !this.visible;

        if (this.editingFeatureId) {

            this.toolInterface.close();

        }

    }


    mapClick(features, lngLat) {

        if (userModel.isViewOnly) {

            return;

        }

        if (this.editingFeatureId) {

            const draw = this.toolInterface.draw;

            if (draw && !draw.popup && !features.find(f => f.id.startsWith(this.editingFeatureId))) {

                this.toolInterface.close();

            }

        } else {

            const assetFeatures = features.filter(f => this.focusedAssets[f.properties.assetId]);

            siteModel.leftClick(assetFeatures.length ? assetFeatures : features, lngLat);

        }

    }

    addFeatureLocation(assetId, featureType, media) {

        const asset = store.assets[assetId];

        let coordinates = Filepicker.getMediaCoordinates(media);

        if (!coordinates) {

            const container = siteModel.map.getContainer(),
                sidebarWidth = window.innerWidth <= ASSET_FORM_WIDTH ? 0 : ASSET_FORM_WIDTH,
                midPoint = {
                    x: container.offsetWidth / 2 + sidebarWidth / 2 + 12,
                    y: container.offsetHeight / 2
                };

            coordinates = siteModel.map.unproject(midPoint).toArray();

        }

        const feature = {
            type: 'Feature',
            id: randomId(),
            geometry: {
                type: 'Point',
                coordinates
            },
            properties: Object.assign({}, featureType.properties, {
                assetId: assetId,
                mediaId: media.mediaId,
                featureTypeId: featureType.featureTypeId
            })
        };

        asset.featureIds = asset.featureIds || [];

        asset.featureIds.push(feature.id);

        this.unmappedFeatureType = false;

        featureModel.addFeatures([feature]);

        featureToControl.sync('filepicker', [media], assetId, featureType);

        const apiSafeFeature = Object.assign({}, api.apiSafeFeature(feature), {
            projectId: router.params.projectId,
            assetId: asset.contentId
        });

        api.rpc.request([
            ['createFeature', apiSafeFeature]
        ]).then(() => this.editFeature(feature));

    }

    addMediaFeature(mediaId) {

        mediaModel.getMedia(mediaId).then(media => {

            assetModel.fetch(media.contentId || this.assetId).then(asset => {

                const featureType = assetModel.supportsFileFeatures(asset.contentId);

                if (featureType) {

                    return this.addFeatureLocation(asset.contentId, featureType, media);

                }

                console.error('Can\'t add media feature to asset type ', asset.contentType);

            });

        });

    }

    getAssetFeatures(assetId) {

        const features = [],
            requests = [];

        const asset = store.assets[assetId];

        if (asset.featureIds && asset.featureIds.length > 0) {

            asset.featureIds.forEach(featureId => {

                if (store.features[featureId]) {

                    features.push(store.features[featureId]);

                } else {

                    requests.push(['listFeatures', {
                        featureId,
                        isVisible: true
                    }]);

                }

            });

        }

        if (requests.length) {

            return api.rpc.requests(requests).then(([results]) => {

                featureModel.addFeatures(results);

                return features.concat(results.map(result => store.features[result.featureId]));

            });

        }

        return Promise.resolve(features);

    }

    focusOnLinkFeatures(linkedAssetIds = []) {

        linkedAssetIds.forEach(assetId => {

            this.focusedAssets[assetId] = true;

        });

        layerModel.resetToolLayers();

        layerModel.focusOnAssetFeatures(Object.keys(this.focusedAssets));

    }

    onEditStop() {

        formModel.editingFeatureId = null;

        formModel.toolInterface.ToolUI = null;

    }

    addToMap() {

        const asset = store.assets[this.assetId];

        const tool = store.tools[asset.attributes.toolId],
            toolInterface = getToolInterface(tool, this.assetId, tool.featureTypes[0].featureTypeId),
            mediaId = asset.mediaId || asset.mediaIds && asset.mediaIds[0];

        if (mediaId) {

            if (toolInterface.type === 'filepicker') {

                return this.addMediaFeature(mediaId);

            } else if (toolInterface.type === 'plan') {

                if (!screenHelper.canEditLayers()) {

                    return; /* noop on small screens - should open asset form instead */

                }

                const plan = assetModel.getLayer(this.assetId, 'plan');

                if (plan) {

                    return router.merge({view: 'layer', planId: plan.planId});

                }

                return mediaModel.createLayer(asset.mediaId, this.assetId);

            } else if (toolInterface.type === 'survey') {

                if (!screenHelper.canEditLayers() || assetModel.getLayer(this.assetId, 'survey')) {

                    return;

                }

            }

        }

        toolInterface.launch()
            .then(() => {

                const feature = store.features[asset.featureIds[0]];

                if (feature) {

                    const apiSafeFeature = Object.assign({}, api.apiSafeFeature(feature), {
                        projectId: router.params.projectId,
                        assetId: asset.contentId
                    });

                    api.rpc.request([
                        ['createFeature', apiSafeFeature]
                    ]).then(() => this.viewAsset(asset.contentId, 'Properties', true));

                } else {

                    this.viewAsset(asset.contentId, 'Properties');

                }

            });

    }

    editFeature(feature) {

        if (this.editingFeatureId) {

            if (this.editingFeatureId === feature.id) {

                return;

            }

            this.toolInterface.close();

        }

        if (feature.properties[constants.trimbleDataKey]) {
            console.error('Attempted to edit a trimble feature.');
            return;
        }

        // Watch out! This feature's asset isn't necessarily this.assetId,
        // because it could belong to a linked asset.
        assetModel.fetch(feature.properties.assetId).then(asset => {

            if (!userModel.isContentEditable(asset.contentId)) {

                return;

            }

            const tool = store.tools[asset.attributes.toolId];

            if (tool && feature.geometry && feature.geometry.coordinates) {

                this.toolInterface = getToolInterface(tool, asset.contentId, feature.properties.featureTypeId);

                this.toolInterface.edit(feature);

                if (screenHelper.small()) {

                    this.visible = false;

                }

                if (!bounds(siteModel.map.getBounds().toArray()).contains(feature)) {

                    featureModel.panToFeatures([feature]);

                }

                this.editingFeatureId = feature.id;

                if (asset.contentId !== this.assetId) {

                    this.focusOnLinkFeatures([asset.contentId]);

                }

                m.redraw();

            }

        });

    }

    // Some imported assets have properties set to null
    // rather than omitting the property.
    removeNullProps() {

        if (this.assetId) {

            const asset = store.assets[this.assetId];

            Object.keys(asset.properties).forEach(key => {
                if (asset.properties[key] === null) {
                    delete asset.properties[key];
                }
            });

        }

    }

    _viewAsset(assetId, tab = router.params.tab, isNew) {

        if (this.assetId === assetId) {

            return;

        }

        const asset = store.assets[assetId];

        tab = tab || 'Properties';

        this.assetId = assetId;

        this.removeNullProps();

        const assetFormId = store.assetTypeToFormId[asset.assetTypeId];

        this.assetForm = store.assetForms[assetFormId];

        this.isNew = isNew;

        this.initAssetForm();

        siteModel.sidebar = AssetForm;

        this.selectTab({name: tab});

        m.redraw();

        this.getAssetFeatures(assetId).then(features => {

            if (features.length) {

                // // Test code for Multi- features

                // const feature = features[0];

                // if (!feature.geometry.type.startsWith('Multi')) {

                //     if (feature.geometry.type === 'Polygon') {

                //         feature.geometry.coordinates = [feature.geometry.coordinates, [feature.geometry.coordinates[0].map(c => [c[0] + 0.0005, c[1] + 0.0005])]];

                //     } else if (feature.geometry.type === 'LineString') {

                //         feature.geometry.coordinates = [feature.geometry.coordinates, feature.geometry.coordinates.map(c => [c[0] + 0.0005, c[1] + 0.0005])];

                //     } else {

                //         feature.geometry.coordinates = [feature.geometry.coordinates, [feature.geometry.coordinates[0] + 0.0005, feature.geometry.coordinates[1] + 0.0005]];

                //     }

                //     feature.geometry.type = 'Multi' + feature.geometry.type;

                //     const source = siteModel.map.getSource(feature.properties.featureTypeId);

                //     source.setData(source._data);

                // }

                if (isNew) {
                    if (screenHelper.large()) {

                        this.editFeature(features.pop());

                    } else if (screenHelper.small() && toolboxModel && toolboxModel.toolInterface && toolboxModel.toolInterface.type === 'text') {

                        this.editFeature(features.pop());

                        this.visible = false;

                    } else if (toolboxModel.toolInterface) {

                        toolboxModel.toolInterface.close();

                    }
                } else if (tab === 'Properties') {

                    featureModel.panToFeatures(features);

                }

            } else {

                this.unmappedFeatureType = assetModel.supportsGeometry(assetId);

                assetModel.panToAsset(assetId);

            }

            this.focusedAssets[assetId] = true;

            layerModel.focusOnAssetFeatures([assetId]);

            this.awaitChanges();

        });

        popup.remove();

        this.turnOnLayer(assetId);

    }

    turnOnLayer(assetId) {

        const asset = store.assets[assetId],
            planControlTypeId = constants.controlTypeNameToId.plan,
            surveyControlTypeId = constants.controlTypeNameToId.survey,
            tool = store.tools[asset.attributes.toolId];

        tool.assetForm.controls.find(control => {

            if (control.controlTypeId === planControlTypeId) {

                const plan = store.plans[asset.properties[control.label]];

                if (plan && plan.status === 'complete') {

                    layerModel.showPlan(plan);

                    return true;

                }

            } else if (control.controlTypeId === surveyControlTypeId) {

                const survey = store.surveys[asset.properties[control.label]];

                if (survey && survey.status === 'ready') {

                    layerModel.setSurvey(survey.surveyId);

                    return true;

                }

            }

            return false;

        });

    }

    viewAsset(assetId, tab, isNew) {

        if (this.assetId) {
            this.close(true);
            this.cleanup(true);
        }

        // Close open panels (eg the People panel)
        if (panelModel.isOpen) {
            panelModel.close();
        }

        const asset = store.assets[assetId];

        if (asset) {

            assetModel.panToAsset(assetId);

            // This handles opening a comment's parents asset form.
            if (asset.linkIds && asset.linkIds.length && asset.assetTypeId === constants.commentAssetTypeId) {

                if (!tab) {

                    tab = 'Comments';

                }

                // If this is an attachment and its parent asset was deleted,
                // then just open this attachment's asset form.
                // Otherwise, open the parent asset's form.
                return assetModel.fetch(asset.linkIds[0], true)
                    .then(parentAsset => {
                        if (parentAsset && parentAsset.isVisible) {
                            this.viewAsset(parentAsset.contentId, tab, isNew);
                        } else {
                            this._viewAsset(assetId, 'Properties', isNew);
                        }
                    });

            }

            this._viewAsset(assetId, tab, isNew);

            // After asset is opened and rendered from store, re-fetch it to make sure the latest data is synced
            assetModel.fetch(assetId, true).then(() => {
                this.removeNullProps();
                m.redraw();
            });
        }

    }

    selectTab(tab) {
        router.url.merge({
            assetId: this.assetId,
            tab: tab.name
        });

        if (tab.name === 'Places/Levels') {
            placesTabModel.init();
        }

    }

    uploadFiles(control) {

        const linkMedia = featureToControl.filepicker[control.controlTypeId];

        if (linkMedia) {

            formModel.linkFlowId = randomId();

            return oneUpModel.addUploadFlow({
                flowId: formModel.linkFlowId,
                projectId: router.params.projectId,
                name: 'Link',
                isLink: true, // Allows the library to return assets and mediaRecords.
                assetId: this.assetId
            }).then((mediaRecords) => {

                linkMedia(mediaRecords, control.label, this.assetId).then(() => {

                    updateAssetFeatures(control);

                    if (control.controlTypeId === constants.controlTypeNameToId.links) {

                        this.selectTab({name: 'Links'});

                    }

                    m.redraw();

                });

            });

        }

    }

    initAssetForm() {

        const assetForm = this.assetForm,
            asset = store.assets[this.assetId],
            controlTypeNameToId = constants.controlTypeNameToId,
            controls = [];

        this.initEvalArgs();

        asset.featureIds = asset.featureIds || [];

        this.displayDate = formatDate.timestamp(asset.createdDateTime);
        this.lastUpdatedDate = formatDate.timestamp(asset.updatedDateTime || new Date());

        this.tabCount = 1;

        const addControl = control => control.attributes.hidden || controls.push(control);

        // Set up flags for special control types
        assetForm.controls.forEach(control => {

            switch (control.controlTypeId) {
            case controlTypeNameToId.name:
                this.nameControl = control;
                addControl(control);
                break;

            case controlTypeNameToId.comments:
                this.hasCommentTab = true;
                this.tabCount++;
                break;

            case controlTypeNameToId.links:
                this.hasLinksTab = true;
                this.tabCount++;
                addControl(control);
                break;

            case controlTypeNameToId.project:
                this.childProjectId = asset.properties[control.label];
                appModel.setState('editingProjectId', asset.properties[control.label]);

                if (userModel.isAccountAdmin) {
                    this.tabCount += 2;
                }
                addControl(control);
                break;

            default:
                addControl(control);
            }

        });

        this.controls = controls;

        this.triggerEval();

    }

    // If the user is typing a float with a trailing dot or zero,
    // then we can't save or redraw because the string will lose
    // characters when cast to a number.
    numIsUncastable(e) {
        let str = e.target.value;
        if (str[0] === '.') {
            str = '0' + str;
        }
        const num = Number(str);
        return e.data === '.' || Number.isNaN(num) || str.length !== num.toString().length;
    }

    handleCoordinate(e, index, point, control) {

        const strValue = e.target.value.trim();

        if (this.numIsUncastable(e)) {
            e.redraw = false;
            return;
        }

        const value = Number(strValue);

        const isValid = !Number.isNaN(value) &&
            (index === 0 && value >= -180 && value <= 180 || index === 1 && value >= -90 && value <= 90);

        e.target.parentNode.classList[isValid ? 'remove' : 'add']('invalid-coord');

        if (isValid) {

            point.coordinates[index] = strValue === '' ? strValue : value; // To avoid casting empty strings to 0

            updateAssetFeatures(control);

        }

    }

    handleNumber(e, control, assetId) {

        const strValue = e.target.value;
        const properties = store.assets[assetId].properties;

        if (strValue === '') {
            delete properties[control.label];
        } else if (this.numIsUncastable(e)) {
            e.redraw = false;
            return;
        } else {
            properties[control.label] = Number(strValue);
        }

        updateAssetFeatures(control, assetId);

    }

    handleLength(e, control, assetId) {

        const strValue = e.target.value;
        const properties = store.assets[assetId].properties;

        if (strValue === '') {
            delete properties[control.label];
        } else if (this.numIsUncastable(e)) {
            e.redraw = false;
            return;
        } else {
            const value = Number(strValue);
            properties[control.label] = isMetric()
                ? value
                : measure.feetToMeters(value);
        }
        updateAssetFeatures(control, assetId);
    }

    handleArea(e, control, assetId) {

        const strValue = e.target.value;
        const properties = store.assets[assetId].properties;

        if (strValue === '') {
            delete properties[control.label];
        } else if (this.numIsUncastable(e)) {
            e.redraw = false;
            return;
        } else {
            const value = Number(strValue);
            properties[control.label] = isMetric()
                ? value
                : measure.squareFeetToSquareMeters(value);
        }
        updateAssetFeatures(control, assetId);
    }

    handleVolume(e, control, assetId) {

        const strValue = e.target.value;
        const properties = store.assets[assetId].properties;

        if (strValue === '') {
            delete properties[control.label];
        } else if (this.numIsUncastable(e)) {
            e.redraw = false;
            return;
        } else {
            const value = Number(strValue);
            properties[control.label] = isMetric()
                ? value
                : measure.cubicYardsToCubicMeters(value);
        }
        updateAssetFeatures(control, assetId);

    }

    recalculateVolume() {

        const survey = store.surveys[layerModel.state.surveyId],
            asset = store.assets[this.assetId];

        if (survey && survey.hasElevationData && asset && asset.featureIds && asset.featureIds.length > 0) {

            const assetFormId = store.assetTypeToFormId[asset.assetTypeId],
                assetForm = store.assetForms[assetFormId],
                volumeControlTypeId = constants.controlTypeNameToId.volume;

            asset.featureIds.forEach(featureId => {

                const feature = store.features[featureId];

                if (feature.geometry.type === 'Polygon') {

                    const featureType = store.featureTypes[feature.properties.featureTypeId],
                        linkedControls = featureType.attributes.linkedControls || [];

                    if (linkedControls.length) {

                        const linkedVolumeControl = assetForm.controls.find(control =>
                            control.controlTypeId === volumeControlTypeId && linkedControls.indexOf(control.label) !== -1
                        );

                        if (linkedVolumeControl) {

                            featureToControl.polygon[volumeControlTypeId](feature, linkedVolumeControl.label, this.assetId);

                        }

                    }

                }

            });

        }

    }

    viewSurveyFiles(surveyId) {

        mediaViewerModel.wait();

        api.rpc.get('Survey', surveyId, {
            include: ['dataUpload', 'imageryUpload']
        }).then(survey => {

            mediaViewerModel.open([
                ...helpers.list(survey.dataUpload.mediaIds),
                ...helpers.list(survey.imageryUpload.mediaIds)
            ]);

            m.redraw();

        });

    }

    _next(isUp) {
        const assetIds = tableModel.assetIds;
        const index = assetIds.findIndex((assetId) => assetId === this.assetId);
        let nextAssetId;
        if (isUp) {
            if (index !== -1 && index - 1 > 0) {
                nextAssetId = assetIds[index - 1];
                this.viewAsset(nextAssetId);
            } else {
                nextAssetId = assetIds[assetIds.length - 1];
                this.viewAsset(nextAssetId);
            }
        } else {
            if (index !== -1 && index + 1 < assetIds.length) {
                nextAssetId = assetIds[index + 1];
                this.viewAsset(nextAssetId);
            } else {
                nextAssetId = assetIds[0];
                this.viewAsset(nextAssetId);
            }
        }
        assetModel.panToAsset(nextAssetId);
    }

    propertyIsEmptyCoordinate(control, property) {
        return control.controlTypeId === constants.controlTypeNameToId.coordinates && !property.coordinates.length;
    }

    getReadOnlyValue(value, control) {

        const controlTypeNameToId = constants.controlTypeNameToId;

        switch (control.controlTypeId) {
        case controlTypeNameToId.file:
        case controlTypeNameToId.plan:
        case controlTypeNameToId.survey:
        case controlTypeNameToId.length:
        case controlTypeNameToId.area:
        case controlTypeNameToId.volume:
            // In this case, rendering a read-only version
            // of the control value will be handled by the
            // control itself in form-controls.js
            return undefined;
        case controlTypeNameToId.date:
            return formatDate.dateAndTime(new Date(value));
        case controlTypeNameToId.coordinates:
            return Array.from(value.coordinates).reverse().join(', ');
        case controlTypeNameToId.toggle:
            return value ? 'On' : 'Off';
        case controlTypeNameToId.user:
            return peopleModel.displayNameOrEmail(value);
        }


        if (Array.isArray(value)) {
            return value.join(', ');
        }

        return value;

    }


    // Returns true if the control is restricted and the user is not an account admin.
    controlIsRestricted(assetTypeId, control) {
        return !userModel.isAccountAdmin && store.assetTypeFields[assetTypeId][control.label].restricted;
    }

    // Returns true if the current user access is limited to the asset in the form (based on: 1) Role, 2) Authorship, and 3) Assignment)
    isLimitedToUser() {
        if (formModel.viewAsLimitedUser === null) {
            formModel.viewAsLimitedUser = !userModel.isContentEditable(formModel.assetId);
        }
        return formModel.viewAsLimitedUser;
    }

    // Checks various ways a control field can be readonly, returns true if so.
    isReadOnlyControl(assetTypeId, control) {
        return control.attributes.readOnly
            || formModel.controlIsRestricted(assetTypeId, control)
            || formModel.isLimitedToUser();
    }

    // Checks if a control type is an embed (displays the content with no input)
    isEmbedControlType(control) {
        return control.controlTypeId === constants.controlTypeNameToId.embed;
    }

    initEvalArgs() {
        this.evalArgs = {};
        this.controls.forEach((control) => {
            if (control.attributes.eval) {
                control.attributes.eval.args.forEach((arg) => {
                    this.evalArgs[arg] = true;
                });
            }
        });
    }

    awaitChanges() {

        this.clearAwait();

        if (this.evalArgs.account || this.evalArgs.accountUser) {
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'modified',
                recordType: 'account',
                test: change => change.accountId === siteModel.accountId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
        }
        if (this.evalArgs.accountUser || this.evalArgs.projectUser) {
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'new',
                recordType: 'user',
                test: change => change.userId === userModel.userId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'modified',
                recordType: 'user',
                test: change => change.userId === userModel.userId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'deleted',
                recordType: 'user',
                test: change => change.userId === userModel.userId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
        }
        if (this.evalArgs.accountAuthor || this.evalArgs.projectAuthor) {
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'new',
                recordType: 'user',
                test: change => change.userId === store.assets[this.assetId].authorId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'modified',
                recordType: 'user',
                test: change => change.userId === store.assets[this.assetId].authorId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'deleted',
                recordType: 'user',
                test: change => change.userId === store.assets[this.assetId].authorId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
        }
        if (this.evalArgs.project || this.evalArgs.site || this.evalArgs.sites || this.evalArgs.projectUser) {
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'modified',
                recordType: 'project',
                test: change => change.projectId === router.params.projectId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
        }
        if (this.evalArgs.asset || this.evalArgs.assetProperties) {
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'new',
                recordType: 'content',
                test: change => change.contentId === this.assetId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'modified',
                recordType: 'content',
                test: change => change.contentId === this.assetId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
        }
        if (this.evalArgs.feature || this.evalArgs.featureProperties || this.evalArgs.features) {
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'modified',
                recordType: 'feature',
                test: change => change.properties.assetId === this.assetId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'new',
                recordType: 'feature',
                test: change => change.properties.assetId === this.assetId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
            this.calculatedFieldAwaits.push(publish.await({
                changeType: 'deleted',
                recordType: 'feature',
                test: change => change.properties.assetId === this.assetId,
                callback: this.debouncedTriggerEval,
                persist: true
            }));
        }
    }

    clearAwait() {
        if (this.calculatedFieldAwaits && this.calculatedFieldAwaits.length > 0) {
            this.calculatedFieldAwaits.forEach((remove) => remove());
        }
        this.calculatedFieldAwaits = [];
    }

    triggerEval(controlToSkip) {
        if (!this.assetId) {
            return;
        }
        const asset = store.assets[this.assetId];
        this.controls.forEach((control) => {
            if (control.label !== controlToSkip) {
                if (control.controlTypeId === constants.controlTypeNameToId.request) {
                    this.updateRequestControl(control);
                } else if (control.attributes.eval) {
                    evaluateControl(control, asset).then((evaluated)=>{
                        asset.properties[control.label] = evaluated;
                        if (asset.featureIds) {
                            updateAssetFeatures(control);
                        }
                    });
                }
            }
        });
        m.redraw();
    }

    updateRequestControl(control) {
        const attributes = control.attributes;
        let asset = store.assets[this.assetId];
        const urlPromise = attributes.url ? Promise.resolve(attributes.url) : evaluateControl(control, asset);

        urlPromise.then(url => {
            const headers = {};
            if (url[0] === '/' && url[1] !== '/') {
                url = appUrl + url;
            }
            if (url.startsWith(appUrl)) {
                headers.authorization = proxyRequest.authorization();
            }
            ajax(url, {
                headers,
                resolve: response => evaluateExpression(attributes.parse, {response}).then(result => {
                    asset = store.assets[this.assetId];
                    const previousValue = asset.properties[control.label];
                    if (result) {
                        asset.properties[control.label] = String(result);
                    } else {
                        delete asset.properties[control.label];
                    }
                    if (asset.properties[control.label] !== previousValue) {
                        this.triggerEval(control.label);
                        assetModel.autosave(asset.contentId);
                    }
                    m.redraw();
                })
            });
        });
    }

    stopEdit() {

        if (this.editingFeatureId) {

            this.onEditStop();

            this.toolInterface.close();

        }

    }
}

const formModel = new FormModel();

initializer.add(() => formModel.init());

export default formModel;
