import tableModel from 'models/table/table-model';
import store from 'util/data/store';
/*
 * args: altHeaderFn <Function> replaces the default header with a header from the parent. Function args controlTypes[] and AssetType.
 */
const AssetPropertyVisibility = {

    header: (controlTypes, assetType) => {
        return <div className="filter-header">
            <span className="asset-type-title">{assetType.name}</span>
            {controlTypes.length ?
                <div className="type-columns-count">
                    {`${controlTypes.length} ${controlTypes.length === 1 ? 'property' : 'properties'}`}
                </div> : <div className="type-columns-count is-na"> 0 properties</div>}
        </div>;
    },

    view: ({attrs}) => {
        const {toolId} = attrs;
        const assetType = store.tools[toolId].assetForm.assetType;
        const controls = tableModel.getControlTypes(toolId);
        const {isAllVisible, isAllNotVisible} = tableModel.isAllVisible(controls, assetType);
        const selectAllCssClass = `select-all${isAllVisible ? ' disabled' : ''}`;
        const deselectAllCssClass = `select-all${isAllNotVisible ? ' disabled' : ''}`;
        const headerFn = attrs.altHeaderFn ? attrs.altHeaderFn : AssetPropertyVisibility.header;
        return <div className="type-columns-inner">
            {headerFn(controls, assetType)}
            <div className="filter-contents">
                {attrs.tip && <div className="type-columns-tip">{attrs.tip}</div>}
                {controls.length >= 2 && <div className="bulk-selection-links">
                    <span className={selectAllCssClass} onclick={() => tableModel.switchAllTableVisibility(controls, assetType, true)}>All On</span>&nbsp;|&nbsp;
                    <span className={deselectAllCssClass} onclick={() => tableModel.switchAllTableVisibility(controls, assetType, false)}>
                            All Off</span>
                </div>}
                {controls.map((controlType, i) => {
                    const headerStyles = tableModel.styles.getTypeSpecificHeaderStyles(assetType.assetTypeId, controlType.name);
                    const toggleCssClass = `toggle-switch ${tableModel.visibleHeaders[assetType.assetTypeId] && tableModel.visibleHeaders[assetType.assetTypeId][controlType.name] ? 'toggle-is-on' : 'toggle-is-off'}`;
                    return <div className="filter-option-row">
                        <div className={`filter-option ${headerStyles}`} onclick={() => tableModel.toggleColumnVisibility(assetType.assetTypeId, controlType.name)}><i className={toggleCssClass}/><span className="filter-option-label">{controlType.name}</span></div>
                    </div>;
                })}
            </div>
        </div>;
    }
};

export default AssetPropertyVisibility;
