import router from 'uav-router';
import constants from 'util/data/constants';
import dialogModel from 'models/dialog-model';
import siteModel from 'models/site-model';
import layerModel from 'models/layer-model';
import randomId from 'util/numbers/random-id';
import {appUrl} from 'util/data/env';

const flowStatuses = {
    PROCESSING: 1,
    DONE: 2,
    READY: 3,
    UPLOADING: 4,
    CANCELLED: 5
};

class OneUpModel {

    constructor() {
        this.requestCallbacks = {};
        this.promises = {};
        this.cssClass = 'hidden';
        const functions = {
            requestFlows: ({requestId, ...args}) => {
                if (this.requestCallbacks[requestId]) {
                    this.requestCallbacks[requestId]({...args});
                    delete this.requestCallbacks[requestId];
                }
            },
            redrawParent: () => m.redraw(),
            resolve: ({flowId, args}) => {
                this.promises[flowId].resolve(args);
                delete this.promises[flowId];
            },
            error: ({flowId, args}) => {
                this.promises[flowId].error(args);
                delete this.promises[flowId];
            },
            close: ({flowId, shouldShow}) => {
                const promises = this.promises[flowId];
                if (promises && promises.close) {
                    promises.close();
                    delete this.promises[flowId];
                }
                if (shouldShow) {
                    delete this.cssClass;
                } else {
                    this.cssClass = 'hidden';
                    this.promises = {};
                }
                m.redraw();
            },
            setCss: ({cssClass}) => {
                this.cssClass = cssClass;
                m.redraw();
            },
            init: () => delete this.loadLocal
        };

        window.addEventListener('message', message => {
            if (message.origin !== constants.oneUpOrigin && constants.isDeployed) {
                return;
            }
            const {data} = message;
            if (data && data.function && functions[data.function]) {
                functions[data.function](data);
            }
        }, false);
    }

    /**
     * addUploadFlow: The main entry for starting an upload using one-up.
     *
     * flowOpts:
     *          accept: The accepted mimeTypes.
     *          maxFiles: Max number of allowed files.
     *          projectId: The id of the project to associate the upload with.
     *          name: The name on the upload. Shows in the headers in one-up.
     *          flowId: The id associated with the upload. Useful for callbacks or when running multiple uploads at once.
     *          isLink: Determines if the library should show assets or just media. Defaults to jus media.
     *          close: If the download is cancelled the close callback is called.
     *
     * Returned: Promise([mediaRecord]).
     */

    addUploadFlow(flowOpts = {}) {
        const flowData = Object.assign( {
            flowId: randomId(),
            projectId: router.params.projectId
        }, flowOpts);

        layerModel.closeLayerPanel();
        const close = flowData.close;
        delete flowData.close;
        this.addFlow(flowData.flowId, flowData);
        this.cssClass = undefined;
        this.changeProject();
        return new Promise((resolve, error) => {
            this.promises[flowData.flowId] = {resolve, error, close};
        });
    }

    canNavigate(retry) {
        const promises = Object.keys(this.promises);
        if (promises.length > 0) {
            this.requestFlows(router.params.projectId, ({flow}) => {
                const flows = Object.values(flow);
                for (let i = 0; i < flows.length; i++) {
                    const currentFlow = flows[i];
                    if (currentFlow.status === flowStatuses.PROCESSING || currentFlow.status === flowStatuses.UPLOADING) {
                        dialogModel.open({
                            headline: 'Leave before upload is complete?',
                            text: `One or more files are still uploading. Leaving ${siteModel.name} will cancel those uploads. Do you still want to leave?`,
                            onYes: () => {
                                this.promises = {};
                                this.clearFlows();
                                retry();
                            },
                            yesClass: 'btn btn-pill btn-red',
                            noClass: 'btn btn-pill btn-secondary',
                            yesText: 'Leave',
                            noText: 'Stay'
                        });
                        return;
                    }
                }
                this.promises = {};
                this.clearFlows();
                retry();
            });
            return false;
        }
        return true;
    }

    showBeforeUnload() {
        const promises = Object.keys(this.promises);
        return promises.length > 0;
    }

    init(vnode) {
        const iframe = vnode.instance.dom.children[0];
        this.window = iframe.contentWindow;
    }

    hasFlow(flowId) {
        return !!this.promises[flowId];
    }

    changeProject() {
        const {projectId} = router.params;
        if (projectId && this.window) {
            this.setProject(projectId);
        }
    }

    requestFlows(projectId, callback) {
        const requestId = randomId();
        this.requestCallbacks[requestId] = (...args) => callback(...args);
        this.window.postMessage({function: 'requestFlows', args: {requestId}}, constants.oneUpOrigin);
    }

    setProject(projectId) {
        this.window.postMessage({function: 'setProject', args: projectId}, constants.oneUpOrigin);
    }

    addFlow(flowId, flowData) {
        this.window.postMessage({function: 'addFlow', args: {flowId, flowData}}, constants.oneUpOrigin);
    }

    clearActiveFlow() {
        this.window.postMessage({function: 'clearActiveFlow'}, constants.oneUpOrigin);
    }

    clearFlows() {
        this.window.postMessage({function: 'clearFlows'}, constants.oneUpOrigin);
    }

    awaitInit() {

        if (constants.oneUpOrigin === 'http://localhost:8003') {

            this.loadLocal = () => {
                constants.oneUpOrigin = appUrl;
                m.redraw();
            };

            if (!constants.isDeployed) {
                setTimeout(() => {
                    if (this.loadLocal) {
                        this.loadLocal();
                    }
                }, 5000);
            }
        }
    }
}

export default new OneUpModel();
