import swap from 'legacy/util/swap';
import store from 'util/data/store';
import router from 'uav-router';
import loadExternal from 'legacy/util/data/load-external';
import userModel from 'models/user-model';

function argNamesToObjects(argNames, asset, value, control) {
    if (!argNames) {
        return [];
    }
    const ret = {};
    let projectUser;
    let projectAuthor;
    const userId = userModel.userId;

    function mapArg(argName) {
        ret[argName] = swap(argName, {
            asset: () => asset,
            value: () => value,
            control: () => control,
            label: () => control.label,
            account: () => store.account,
            assetProperties: () => asset.properties,
            project: () => store.project,
            tools: () => {
                const tools = {};
                Object.values(store.tools).forEach(tool => {
                    tools[tool.toolId] = Object.assign({}, tool, {toolGroup: undefined});
                });
                return tools;
            },
            tool: () => Object.assign({}, store.tools[asset.attributes.toolId], {toolGroup: undefined}),
            accountAuthorRole: () => store.account.users.find(u => u.userId === asset.authorId).role,
            projectAuthorRole: () => {
                if (!projectAuthor) {
                    projectAuthor = store.project.users.find(u => u.userId === asset.authorId);
                }
                return projectAuthor.role;
            },
            accountUserRole: () => store.account.users.find(u => u.userId === userId).role,
            projectUserRole: () => {
                if (!projectUser) {
                    projectUser = store.project.users.find(u => u.userId === userId);
                }
                return projectUser.role;
            },
            user: () => {
                if (!projectUser) {
                    projectUser = store.project.users.find(u => u.userId === userId);
                }
                return projectUser;
            },
            author: () => {
                if (!projectAuthor) {
                    projectAuthor = store.project.users.find(u => u.userId === asset.authorId);
                }
                return projectAuthor;
            },
            site: () => store.project.sites.find(s => s.siteId === router.params.siteId) || store.project.sites[0],
            feature: () => store.features[asset.featureIds[0]],
            featureProperties: () => store.features[asset.featureIds[0]].properties
        });
    }
    argNames.forEach(mapArg);
    return ret;
}

function evaluateExpression(expression, args) {
    return loadExternal.loadExpressionEvaluator().then(() => {
        const evaluate = window.unearth.expressionevaluator.evaluate;
        try {
            args = JSON.stringify(args);
            const {response, error} = JSON.parse(evaluate(expression, args));
            if (error) {
                console.warn(error);
            } else {
                return response;
            }
        } catch (e) {
            console.error(e);
        }
    });
}

function evaluateControl(control, asset) {
    const value = asset.properties[control.label];
    if (!control.attributes.eval) {
        return Promise.resolve(value);
    }
    const args = argNamesToObjects(control.attributes.eval.args, asset, value, control);
    return evaluateExpression(control.attributes.eval.expression, args);
}

export {evaluateControl, evaluateExpression};
