import ToolInterface from 'util/interfaces/tool-interface';
import store from 'util/data/store';

class ActionInterface extends ToolInterface {

    constructor(...args) {
        super(...args);
        this.type = 'action';
    }

    launch() {
        const asset = store.assets[this.assetId];
        return Promise.resolve([asset]);
    }

}

export default ActionInterface;
