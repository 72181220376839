import EditableTextModel from 'models/editable-text-model';

const EditableText = {
    oninit({attrs, state}) {
        state.model = new EditableTextModel(attrs);
    },

    view({state}) {
        const model = state.model;
        return <div class={`editable-text-wrap ${!!model.untitled && 'untitled' || ''}`}>
            <span class="editable-text"
                onkeydown={e => model.catchEnter(e)}
                onblur={e => model.rename(e)}
                onkeyup={e => model.catchEscape(e)}>{model.text}</span>
            {model.isEditing ? <i class={model.spinning ? 'gray spinner spinning' : 'icon-arrow-right'} onclick={e => {
                e.stopPropagation();
                e.preventDefault();
            }}></i> : <i class="icon-edit" onclick={e => model.edit(e)}></i>}
        </div>;
    }
};

export default EditableText;
