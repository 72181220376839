import userModel from 'models/user-model';
import sideNavModel from 'models/side-nav-model';
import siteModel from 'models/site-model';

const SideNav = {

    oninit() {

        const user = userModel;

        if (user.status === 'trial') {

            sideNavModel.setUpBuyNow();

        }

    },

    view: () => <div className='side-nav'>
        {siteModel.isMetaProject
            ? <a class="icon-unearth nav-btn" onclick={sideNavModel.toggle('account')}>
                {sideNavModel.popupFor('account')}
            </a>
            : <a class="icon-unearth nav-btn" onclick={sideNavModel.toggle('project')}>
                {sideNavModel.popupFor('project')}
            </a>
        }
        <div class='side-nav-group'>
            <div class='icon-plus nav-btn' onclick={sideNavModel.toggle('tools')}>
                {sideNavModel.popupFor('tools')}
            </div>
            <div class='icon-download nav-btn' onclick={sideNavModel.toggle('export')}>
                {sideNavModel.popupFor('export')}
            </div>
        </div>
        {sideNavModel.isTrial &&
            <div class="trial-btn" onclick={sideNavModel.buyNow}>
                <span class="number-callout">{sideNavModel.dateInterval.num}</span>
                <span class="days-free">{sideNavModel.dateInterval.unit} free</span>
                <span class="buy-now">BUY<br/>NOW</span>
            </div>}
        <div class="side-nav-bottom">
            <div class="nav-btn" onclick={sideNavModel.toggle('help')}>
                <div class="icon-qmark">?</div>
                {sideNavModel.popupFor('help')}
            </div>
            <div class="nav-btn" onclick={sideNavModel.toggle('menu')}>
                <div class="icon-initials">{userModel.initials()}</div>
                {sideNavModel.popupFor('menu')}
            </div>
        </div>
    </div>
};

export default SideNav;
