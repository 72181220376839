import message from 'legacy/components/message';
import uav from 'uav';
import api from 'legacy/util/api';
import constants from 'util/data/constants';

import helpers from 'legacy/util/api/helpers';

function download(url, name) {

    name = name.replace(/[ ,]+/g, '');

    const iframe = uav.component(`<iframe src="https://process.filestackapi.com/${constants.filestackApiKey}/content=t:attachment,f:${name}/${url}" />`);

    document.body.appendChild(iframe);

    setTimeout(() => iframe.remove(), 5000);

}

download.force = (media, callback) => {

    if (callback) {

        callback();

    }

    if (!media) {

        return;

    }

    download(`https://s3-us-west-2.amazonaws.com/media.unearthlabs.com/${media.mediaId}`, media.label);

};

download.content = (content, callback) => {

    const mediaId = download.getMediaId(content);

    if (!mediaId) {
        return message.show('Download failed.', 'error');
    }

    download.prepare(mediaId, content, callback).then((media) => {
        download.execute(media, content, mediaId, callback);
    });

};

download.getMediaId = (content) => {

    let mediaId;

    if (content.mediaIds && content.mediaIds[0]) {

        mediaId = content.mediaIds[0];

    } else if (content.mediaId) {

        mediaId = content.mediaId;

    } else {

        const media = helpers.list(content.media);

        if (media.length) {

            mediaId = media[0].mediaId;

        }
    }

    return mediaId;

};

download.prepare = (mediaId) => {

    return api.get.media({mediaId});

};

download.execute = (media, content, mediaId, callback) => {

    media = helpers.list(media)[0];

    if (!media) {

        download.force({
            mediaId,
            label: content.title || content.label || mediaId
        }, callback);

    } else {

        download.force(media, callback);

    }

};

export default download;
