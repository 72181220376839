import randomId from 'util/numbers/random-id';
import controlToFeature from 'util/interfaces/control-to-feature';
import debounce from 'util/events/debounce';
import api from 'legacy/util/api';
import userModel from 'models/user-model';
import router from 'uav-router';
import toolboxModel from 'models/toolbox-model';
import constants from 'util/data/constants';
import siteModel from 'models/site-model';
import DrawPallete from 'views/draw-palette';
import ToolLauncher from 'views/tool-launcher';
import sideNavModel from 'models/side-nav-model';
import formModel from 'models/form-model';
import store from 'util/data/store';
import featureToControl from 'util/interfaces/feature-to-control';
import AssetForm from 'views/asset-form';
import formatDate from 'legacy/util/date/format-date';
import Table from 'views/table/table';
import validate from 'util/geo/validate';
import popup from 'util/popup';
import {evaluateControl} from 'util/evaluate';
import assetModel from 'models/asset-model';
import deepCloneObject from 'util/data/deep-clone-object';
import tableModel from 'models/table/table-model';
import layerModel from 'models/layer-model';
import oneUpModel from 'models/one-up-model';
import commonFilterModel from 'models/table/common-filter-model';

class ToolInterface {

    close() {

        if (this.draw) {

            this.draw.stop();

        }

        return this.onClose();

    }

    onClose() {

        oneUpModel.clearActiveFlow();

        toolboxModel.toolInterface = null;

        if (router.params.assetId) {

            if (formModel.assetId !== router.params.assetId) {

                formModel.viewAsset(router.params.assetId, 'Properties');

            } else {

                siteModel.sidebar = AssetForm;

            }

        } else {

            siteModel.sidebar = Table;

            if (tableModel.isCollapsed) {

                tableModel.sideBarToggle();

            }

        }

        m.redraw();

    }

    blankAsset(tool) {

        const date = formatDate.forPython(new Date()),
            assetTypeId = tool.assetForm.assetType.assetTypeId;

        const asset = {
            contentId: randomId(),
            assetTypeId,
            contentType: tool.assetForm.assetType.name,
            threadId: randomId(),
            authorId: userModel.userId,
            recipientIds: [userModel.userId],
            createdDateTime: date,
            captureDateTime: date,
            toolId: tool.toolId,
            projectId: router.params.projectId,
            properties: {},
            attributes: {
                toolId: tool.toolId,
                toolGroupIds: toolboxModel.activeGroupId ? [toolboxModel.activeGroupId] : []
            },
            featureIds: []
        };

        assetModel.addToStore(asset);

        const hasEval = [];

        // Set default values
        tool.assetForm.controls.forEach(control => {

            if (control.attributes.eval && control.controlTypeId !== constants.controlTypeNameToId.request) {

                hasEval.push(control);

            }

            if (control.attributes.default !== undefined) {

                asset.properties[control.label] = deepCloneObject(control.attributes.default);

            }

        });

        if (hasEval.length > 0) {

            hasEval.forEach(control => {

                if (control.attributes.eval) {

                    evaluateControl(control, asset).then((evaluated) => {
                        asset.properties[control.label] = evaluated;
                    });

                }

            });

        }

        commonFilterModel.showNewAsset(assetTypeId, userModel.userId);

        return asset;

    }

    showToolLauncher() {

        layerModel.closeLayerPanel();

        popup.remove();

        siteModel.sidebar = ToolLauncher;

    }

    constructor(tool, assetId, featureTypeId) {

        featureTypeId = featureTypeId || tool.featureTypes[0].featureTypeId;

        this.tool = tool;
        this.assetId = assetId;
        this.featureType = tool.featureTypes.find(f => f.featureTypeId === featureTypeId);
        this.linkedControls = this.featureType.attributes.linkedControls;

        toolboxModel.toolInterface = this;

        toolboxModel.tool = this.tool;

        sideNavModel.close();

        this.autosave = new Function();

        if (assetId) {

            const asset = store.assets[assetId];

            if (asset.featureIds && asset.featureIds.length) {

                this.autosave = debounce(f => {

                    if (validate(f.geometry)) {

                        api.rpc.request([['modifyFeature', Object.assign(api.apiSafeFeature(f, true))]]);

                    }

                }, 500);

            }

        } else {

            this.assetId = this.blankAsset(tool).contentId;

        }

        const geometry = this.featureType.geometry || {type: 'Point'};

        this.feature = {
            type: 'Feature',
            id: randomId(),
            geometry: Object.assign(geometry, {
                coordinates: []
            }),
            properties: Object.assign({
                assetId: this.assetId,
                featureTypeId
            }, this.featureType.properties)
        };

        tool.assetForm.controls.forEach(control => {

            controlToFeature.syncFeatureProperties(control, this.feature, this.assetId);

        });

        this.ToolUI = {
            view: () => <DrawPallete feature={this.feature} render={(update, skipRedraw = false) => {

                update(this.feature.properties);

                this.draw.reset();

                this.autosave(this.feature);

                featureToControl.sync(this.type, this.feature, this.assetId, this.featureType);

                if (!skipRedraw) {

                    m.redraw();

                }

            }}/>
        };

    }

}

export default ToolInterface;
