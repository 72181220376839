import filterModel from 'models/table/filter-model';
import commonFilterModel from 'models/table/common-filter-model';
import peopleModel from 'models/people/people-model';
import tableModel from 'models/table/table-model';
import Cell from 'views/table/cells/cell';
import store from 'util/data/store';
import activeCell from 'models/table/active-cell-model';
import {copyToClipboard} from 'util/dom/clipboard';

const commonCells = {

    addedBy: {
        view: ({attrs: {assetRow}}) => {
            const author = assetRow.author;
            return commonFilterModel.users[author.userId]
                ? <div class="asset-author-inner is-clickable">
                    <span class="list-label">Added by:</span> <span class="shortcut-filter-link" onclick={function (e) {
                        if (commonFilterModel.users[author.userId]) {
                            filterModel.open(this, {
                                viewKey: 'commonDefinedOptions',
                                label: peopleModel.displayName(author),
                                callback: () => commonFilterModel.turnOffAllAddedByExcept(author.userId),
                                isCellMenu: true
                            });
                            e.stopPropagation();
                        }
                    }}>{peopleModel.displayName(author)}</span>
                </div>
                : <span class="asset-author"><span class="list-label">Added by:</span> {peopleModel.displayName(author)}</span>;
        }
    },

    addedDateTime: {
        view: ({attrs: {assetRow}}) => <span class="asset-timestamp"><span class="list-label">Added:</span> {assetRow.addedDate}</span>
    },

    lastUpdated: {
        view: ({attrs: {assetRow}}) => <span class="asset-timestamp"><span class="list-label">Last Updated:</span> {assetRow.updatedDate}</span>
    },

    category: {
        view: ({attrs: {assetRow}}) =>
            <div class='asset-category-inner is-clickable'>
                <span class='shortcut-filter-link' onclick={function (e) {
                    filterModel.open(this, {
                        viewKey: 'commonDefinedOptions',
                        label: assetRow.category,
                        callback: () => commonFilterModel.turnOffAllToolGroupsExcept(assetRow.toolGroupId),
                        isCellMenu: true
                    });
                    e.stopPropagation();
                }}>{assetRow.category}</span>
            </div>
    },

    places: {
        view: () => {
            return <span class="place-inner-wrap"/>;
        }
    },

    type: {
        oninit: ({attrs: {assetRow}, state}) => {
            const asset = store.assets[assetRow.assetId];
            state.controls = tableModel.getControlTypes(asset.attributes.toolId) || [];
        },

        view: ({attrs: {assetRow}, state}) => {
            const assetType = store.assetTypes[assetRow.assetTypeId];
            return <>
                <span class="is-clickable shortcut-filter-link" onclick={function (e) {
                    filterModel.open(this, {
                        viewKey: 'commonDefinedOptions',
                        label: assetType.name,
                        callback: () => commonFilterModel.turnOffAllAssetTypesExcept(assetType.assetTypeId),
                        isCellMenu: true
                    });
                    e.stopPropagation();
                }}>{assetType.name}</span>
                {state.controls.length ? <div class="type-columns-count" onclick={function (e) {
                    filterModel.open(this.parentElement, {
                        assetType,
                        asset: store.assets[assetRow.assetId],
                        viewKey: 'assetTypeCell',
                        isCellMenu: true
                    });
                    e.stopPropagation();
                }}> {`${state.controls.length} ${state.controls.length === 1 ? 'property' : 'properties'}`}
                </div> : <div class="type-columns-count is-na"> 0 properties</div>
                }
            </>;
        }
    },

    unearthId: {
        view: ({attrs: {assetRow}}) => {
            const asset = store.assets[assetRow.assetId];
            return <div class='asset-unearth-id is-clickable'>
                <span className="list-label">Unearth ID: </span>
                {asset.contentId}
                <span className="copy right type-columns-count" onclick={(e) => {
                    copyToClipboard(asset.contentId);
                    e.stopPropagation();
                }}> copy</span>
            </div>;
        }
    }
};

class CellCommon extends Cell {

    view({state, attrs: {assetRow, cellKey, columnIndex}}) {
        const View = commonCells[cellKey];
        state.cssClasses = `asset-cell asset-${cellKey} ${state.wrapClass || ''} ${state.activeClass || ''}`;
        return <li tabindex={activeCell.cellTabindex} class={state.cssClasses} onfocus={(e) => tableModel.handleCellClick(e, assetRow.rowIndex, columnIndex)} onclick={(e) => tableModel.handleCellClick(e, assetRow.rowIndex, columnIndex)}>
            <View assetRow={assetRow}/>
        </li>;
    }
}

export default CellCommon;
