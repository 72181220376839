import uav from 'uav';
// import submitButton from 'components/submit-button';
// import filePicker from 'components/file-picker';

function idComponent() {

    // We don't use the photos, so we're disabling the
    // uploader for now

    // const fp = filePicker();

    // const submit = submitButton({
    //     onclick: fp.open,
    //     text: 'Add a Photo'
    // });

    // const fileInput = fp.input({
    //     initialize: () => {

    //         submit.spinning = true;

    //     },
    //     single: true,
    //     ignoreGeo: true,
    //     callback: files => {

    //         id.picture = files[0].id;

    //         submit.text = 'Change Photo';

    //         submit.spinning = false;

    //     }
    // });

    const id = uav.component(`
    <div class="sign-up-profile-pic">
        <span u-class="{initials ? 'user-initials' : 'hidden'}">{initials}</span>
        <i u-class="{initials ? 'hidden' : 'icon-user sign-up-id-container-icon'}"></i>
    </div>`, {
        // fileInput,
        // submit,
        // picture: null,
        firstName: '',
        lastName: '',
        initials: '',
        trimNames: () => {

            id.firstName = id.firstName.trim();

            id.lastName = id.lastName.trim();

            if (id.firstName && id.lastName) {

                id.initials = id.firstName[0] + id.lastName[0];

            }

        }
    });

    return id;
}

export default idComponent;
