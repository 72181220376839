import initializer from 'util/initializer';
import store from 'util/data/store';

class DrawPaletteModel {

    init(feature) {
        this.currentFeatureTypeId = feature.properties.featureTypeId;
        if (!this.states[this.currentFeatureTypeId]) {
            this.states[this.currentFeatureTypeId] = {};
        }
    }

    persistValue(propKey, property) {
        const state = this.states[this.currentFeatureTypeId];
        state[propKey] = property;
    }

    getStateValue(propKey, defaultValue) {
        if (this.currentFeatureTypeId === undefined || !this.states || !this.states[this.currentFeatureTypeId]) {
            console.error('DrawPaletteModel not initialized.');
            return;
        }
        return this.states[this.currentFeatureTypeId][propKey] || defaultValue;
    }

    reset() {
        this.states = {};
    }

    showReset() {
        if (Object.keys(this.states).length === 0 || Object.keys(this.states[this.currentFeatureTypeId]).length === 0) {
            return false;
        }
        return true;
    }

    defaultValue(propKey) {
        return store.featureTypes[this.currentFeatureTypeId].properties[propKey];
    }

    resetDefaults(properties) {
        this.states[this.currentFeatureTypeId] = {};
        const featureType = store.featureTypes[this.currentFeatureTypeId];
        for (const key in featureType.properties) {
            if (properties[key] !== undefined) {
                properties[key] = featureType.properties[key];
            }
        }
    }
}

const drawPaletteModel = new DrawPaletteModel();

initializer.add(() => drawPaletteModel.reset());

export default drawPaletteModel;
