import {getNormalizedKey} from 'util/events/get-normalized-key';
import peopleModel from 'models/people/people-model';


class AutoCompleteModel {

    constructor(types, onselect, selected = '') {
        this.handleSelect = onselect;
        this.selectedOption = selected;
        this.types = {};
        this.filteredOptions = [];

        this.term = ''; // Query term 
        this.cachedFocusedIndex = null; // Set in getter or reset to null if voided
        this.optionsVisible = false;

        this.handleKeyDown = this._handleKeyDown.bind(this);

        types.forEach(type => this.initType(type));
    }

    initType(type) {
        if (type === 'users') {
            let mappedOptions = [];
            const options = peopleModel.getControlOptions();
            const displayFn = (option) => peopleModel.displayUserControlOption(option);
            mappedOptions = this.initOptions(options, (option) => displayFn(option));

            this.types.users = {
                id: 'users',
                options: mappedOptions,
                displayOptionAs: (option) => displayFn(option),
                titleText: 'Send notification of mention to:'
            };

            this.filteredOptions = [...mappedOptions];
            this.activeType = this.types.users;
        }
    }

    // Create a mapping of the select option value and the display terms
    initOptions(options, displayOptionAs) {
        return options.map(value => {
            const text = displayOptionAs(value) || value; // If no display function passed in, just use value
            const terms = text.toLowerCase().split(' ');
            return {value, text, terms};
        });
    }

    // -------- Handle depending on active data type --------

    get allOptions() {
        return this.activeType ? this.activeType.options : [];
    }

    get titleText() {
        return this.activeType ? this.activeType.titleText : '';
    }

    displayOptionAs(option) {
        return this.activeType ? this.activeType.displayOptionAs(option) : option;
    }

    // -------- Type independent autocompleter handlers --------

    get focusedOption() {
        return this.filteredOptions[this.focusedIndex];
    }

    // If the index is cached, just return it. If not, find the index of the item matching our selection (if it exists)
    get focusedIndex() {
        if (this.cachedFocusedIndex === null) {
            this.cachedFocusedIndex = this.selectedOption ? this.filteredOptions.findIndex(opt => opt === this.selectedOption) : -1;
        }
        return this.cachedFocusedIndex;
    }

    get selectedOptionsText() {
        // Returns the text for the current selected value, with a fallback in case that value was deleted from the options list (for the case of user controls, specifically).
        return this.allOptions[this.selectedOption] ? this.allOptions[this.selectedOption].text : this.displayOptionAs(this.selectedOption);
    }

    showOptions() {
        if (!this.optionsVisible) {
            this.optionsVisible = true;
            m.redraw();
        }
    }

    hideOptions() {
        if (this.optionsVisible) {
            this.optionsVisible = false;
            m.redraw();
        }
    }

    start(andShowOptions = false) {
        if (!this.isListening) {
            this.isListening = true;
            window.addEventListener('keydown', this.handleKeyDown);
        }
        if (andShowOptions) {
            this.showOptions();
        }
    }

    stop() {
        if (this.isListening) {
            this.isListening = false;
            window.removeEventListener('keydown', this.handleKeyDown);
        }
        this.resetInput();
    }

    // To run when a selection is made or user has stopped editing/onBlur. Returns the input state to the selected item and resets the filtering.
    resetInput(keepOpen = false) {
        this.term = '';
        this.filteredOptions = this.allOptions;
        this.cachedFocusedIndex = null;
        if (!keepOpen) {
            this.hideOptions();
        }
    }

    // To run when a selection is made or user has stopped editing/onBlur. Returns the input state to the selected item and resets the filtering.
    selectIndex(index) {
        this.cachedFocusedIndex = index;
        this.selectedOption = this.focusedOption.value;
        // Run the onselect callback passed in from the view
        this.handleSelect(this.focusedOption.value);
        this.resetInput();
    }

    filterOptions(string) {
        this.showOptions();
        const filterTerm = string.toLowerCase();
        const strings = filterTerm.split(' ');
        this.filteredOptions = this.filteredOptions.filter(option => this.optionHasMatchingTerms(option, strings) || this.optionHasMatchingText(option, string));
        m.redraw();
    }

    // -------- Input event handling --------

    onArrowDownKey() {
        if (!this.optionsVisible) {
            this.showOptions();
        } else if (this.focusedIndex < this.filteredOptions.length - 1) {
            this.cachedFocusedIndex++;
            m.redraw();
        }
    }

    onArrowUpKey() {
        if (this.focusedIndex >= 0) {
            this.cachedFocusedIndex--;
            m.redraw();
        } else if (this.optionsVisible) {
            this.hideOptions();
        }
    }

    onEnterKey() {
        // If the options aren't open, just open it.
        if (!this.optionsVisible) {
            return this.showOptions();
        }

        // Only run select callback if a valid option is in focus
        const currentOption = this.focusedOption ? this.focusedOption.value : null;

        if (currentOption) {
            this.selectedOption = currentOption;
            this.handleSelect(currentOption);
        }
        this.resetInput();
    }

    _handleKeyDown(e) {
        const key = getNormalizedKey(e.key);
        switch (key) {
        case 'ArrowDown':
            e.preventDefault(); // Dont arrow through text input
            e.stopPropagation();
            return this.onArrowDownKey();
        case 'ArrowUp':
            e.preventDefault();
            e.stopPropagation();
            return this.onArrowUpKey();
        case 'Enter': 
            e.preventDefault();
            e.stopPropagation();
            return this.onEnterKey();
        }
    }

    // Check single words of text (combination of terms matters, order does not)
    // Completion of term only matters for the first term if followed by a second
    // Example: if option === 'john smith'
    // WILL match: 'smith jo' or 'john smi'
    // WILL NOT MATCH: 'smi jo' or 'jo smith'
    optionHasMatchingTerms(option, strings) {
        if (strings.length === 2) {
            const firstTerm = strings[0];
            const secondTerm = strings[1];
            if (firstTerm === option.terms[0]) { 
                return secondTerm.startsWith(option.terms[1]);
            } else if (secondTerm === option.terms[0]) {
                return firstTerm.startsWith(option.terms[1]);
            }
            return false;
        }
        // Stop matching after 2 strings of terms.
        return strings.length === 1 && option.terms.find(term => term.startsWith(strings[0]));
    }

    // Check full text
    optionHasMatchingText(option, string) {
        if (option.text.toLowerCase().startsWith(string)) {
            return true;
        }
    }

}

export default AutoCompleteModel;
