import randomId from 'util/numbers/random-id';
import constants from 'util/data/constants';
import store from 'util/data/store';
import featureToControl from 'util/interfaces/feature-to-control';
import featureModel from 'models/feature-model';
import ToolInterface from 'util/interfaces/tool-interface';
import isMetric from 'util/numbers/is-metric';
import Point from 'util/draw/point';
import siteModel from 'models/site-model';
import formModel from 'models/form-model';
import mediaModel from 'models/media-model';
import deepCloneObject from 'util/data/deep-clone-object';
import controlToFeature from 'util/interfaces/control-to-feature';
import assetModel from 'models/asset-model';
import oneUpModel from 'models/one-up-model';
import router from 'uav-router';
import message from 'legacy/components/message/message';
import popup from 'util/popup';
import helpers from 'legacy/util/api/helpers';

function getMediaCoordinates(media) {

    const gps = media && media.info && media.info.GPS,
        longitude = gps && gps.GPSLongitude,
        latitude = gps && gps.GPSLatitude;

    if (longitude && latitude) {

        return [longitude, latitude];

    }

}

function getControlOptions(assetId, control = {}) {

    const asset = store.assets[assetId],
        assetExists = asset.updatedDateTime,
        attributes = control.attributes || {},
        maxFiles = assetExists || attributes.maxFiles > 1 ? attributes.maxFiles || 1000 : 1000,
        minFiles = assetExists || attributes.minFiles > 1 ? attributes.minFiles || 1 : 1;

    let accept = null,
        multipleFilesPerAsset = false;

    if (control) {

        if (attributes.maxFiles > 1 || attributes.minFiles > 1) {

            multipleFilesPerAsset = true;

        }

        accept = attributes.accept || accept;

    }

    return {
        uploadOpts: {
            accept,
            maxFiles,
            minFiles
        },
        multipleFilesPerAsset
    };

}

class FilepickerInterface extends ToolInterface {

    constructor(...args) {

        super(...args);

        this.type = 'filepicker';

    }

    createAsset(media) {

        const contentId = randomId();

        const coordinates = getMediaCoordinates(media);

        let feature;

        if (coordinates) {

            feature = {
                type: 'Feature',
                id: randomId(),
                geometry: {
                    type: 'Point',
                    coordinates
                },
                properties: Object.assign({}, this.feature.properties, {
                    assetId: contentId,
                    mediaId: media.mediaId
                })
            };

            featureModel.addFeatures([feature]);

        }

        const newAsset = deepCloneObject(store.assets[this.assetId]);

        Object.assign(newAsset, {
            featureIds: feature ? [feature.id] : [],
            contentId,
            mediaIds: [media.mediaId],
            threadId: randomId()
        });

        assetModel.addToStore(newAsset);

        featureToControl.sync('filepicker', [media], contentId, this.featureType);

        return newAsset;

    }

    launch() {

        popup.remove();

        let controlOpts;

        if (this.featureType.attributes.linkedControls) {

            const fileControlTypeId = constants.controlTypeNameToId.file,
                fileControl = this.tool.assetForm.controls.find(c => c.controlTypeId === fileControlTypeId);

            if (this.linkedControls.indexOf(fileControl.label) !== -1) {

                controlOpts = getControlOptions(this.assetId, fileControl);

            }

        }

        controlOpts = controlOpts || getControlOptions(this.assetId);

        return oneUpModel.addUploadFlow( {
            projectId: router.params.projectId,
            name: this.tool.name,
            close: () => this.close(),
            accept: controlOpts.uploadOpts.accept
        }).then(mediaRecords => {

            let assets;

            mediaRecords.forEach(media => {

                store.media[media.mediaId] = media;

            });

            const asset = store.assets[this.assetId];

            if (mediaRecords.length > 1 && controlOpts.multipleFilesPerAsset) {

                const features = [];

                mediaRecords.forEach(media => {

                    const coordinates = getMediaCoordinates(media);

                    if (coordinates) {

                        const id = randomId();

                        asset.featureIds = helpers.list(asset.featureIds);
                        asset.featureIds.push(id);

                        features.push({
                            id,
                            geometry: {
                                type: 'Point',
                                coordinates: coordinates
                            },
                            properties: Object.assign({}, this.feature.properties, {
                                mediaId: media.mediaId
                            })
                        });

                    }

                });

                featureModel.addFeatures(features);

                featureToControl.sync('filepicker', mediaRecords, this.assetId, this.featureType);

                features.forEach(feature => {

                    controlToFeature.syncAllFeatureProperties(this.assetId, feature);

                });

                assets = [asset];

            } else {

                const firstMedia = mediaRecords.shift();

                assets = [this.createAsset(firstMedia)];

                mediaRecords.forEach(media => {

                    assets.push(this.createAsset(media));

                });

            }

            return assets;

        }).catch((e) => {
            message.show(e, 'error');
            this.close();
        });

    }

    edit(_feature) {

        const map = siteModel.map,
            source = map.getSource(this.featureType.featureTypeId);

        this.feature = source._data.features.find(f => f.id === _feature.id);

        if (!this.feature) {

            return;

        }

        this.draw = new Point({
            map,
            source,
            metric: isMetric()
        }).edit(this.feature);

        this.draw.onVertexChanged = () => {

            featureModel.addLatitudeToFeature(this.feature);

            mediaModel.getMedia(this.feature.properties.mediaId).then(media => {

                featureToControl.sync('filepicker', [media], this.assetId, this.featureType);

                controlToFeature.syncAllFeatureProperties(this.assetId, this.feature);

                source.setData(source._data);

                m.redraw();

            });

            this.autosave(this.feature);

        };

        this.draw.onStop = formModel.onEditStop;

    }

}

FilepickerInterface.getMediaCoordinates = getMediaCoordinates;
FilepickerInterface.getControlOptions = getControlOptions;

export default FilepickerInterface;
